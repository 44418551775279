import { Tabs } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { /*useNavigate,*/ useParams } from 'react-router-dom'
import LcMessagesContainer from '../messages'
import type { stateType } from '@types'
import type { FC } from 'react'
import LcVerificationCompanyTable from '@organisms/tables/lcVerificationCompanies/company'
import LcVerificationFilesTable from '@organisms/tables/lcVerificationCompanies/files'
import { setSessionProperties } from '@redux/reducers/userSlice'
import './style.scss'

const TabsCompany: FC = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { tab_company, id } = useParams()
	const [tab, setTab] = useState<any>('history')
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	//const navigate = useNavigate()

	const companyId = id || sessionProperties?.companyId
	const tabKey = tab_company || sessionProperties?.tabKey

	useEffect(() => {
		setTab(tabKey)
	}, [tabKey])

	const onChange = (value: string) => {
		const updatedSessionProperties = {
			...sessionProperties,
			companyId: companyId,
			tabKey: value,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
		setTab(value)
		//navigate(`/lc-companies/company/${companyId}/${value}`)
	}

	const TabTitle: any = ({ text }: any) => <div className='company-tabs__title'>{t(text)}</div>

	return (
		<Tabs
			onChange={onChange}
			activeKey={tab}
			centered
			className={classNames('company-tabs')}
			items={[
				{
					label: <TabTitle text={'Company History'} />,
					key: 'history',
					children: <LcVerificationCompanyTable />,
				},
				{
					label: <TabTitle text={'Files'} />,
					key: 'files',
					children: <LcVerificationFilesTable />,
				},
				{
					label: <TabTitle text={'Comments'} />,
					key: 'comments',
					children: <LcMessagesContainer />,
				},
			]}
		/>
	)
}

export default TabsCompany
