import { Button, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'

const PositionFunctionalityModalFooter: FC<any> = ({ onClick }: any) => {
	const { t } = useTranslation()
	return (
		<div className='functionality-modal__footer'>
			<Row justify='end'>
				<Button type='primary' shape='round' onClick={onClick}>
					{t('Apply')}
				</Button>
			</Row>
		</div>
	)
}

export default PositionFunctionalityModalFooter
