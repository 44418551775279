import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import type { stateType } from '@types'
import type { FC } from 'react'

import './style.scss'

const StepList: FC<any> = ({ page, list }: any) => {
	const [active, setActive] = useState(list[0])
	const { info } = useSelector((state: stateType) => state.user)

	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		const result = _.find(list, el => el.id === page)
		if (result) {
			setActive(result)
			return
		}
		setActive(list[0])
	}, [page])

	const linkAction = ({ index, link }: any) => {
		if (index === 1) {
			return navigate(link)
		}

		if (info?.company?.id && index > 1) {
			return navigate(link)
		}
	}

	return (
		<div className='step-list'>
			{list.map((item, index) => (
				<div
					key={index}
					className={classNames(
						'step-list__item',
						{ ['step-list__item-move']: index + 1 <= active?.index },
						{ ['step-list__item-active']: item.id === active.id }
					)}
				>
					<div
						className={classNames(
							'step-list__index',
							{ ['step-list__index-move']: index + 1 <= active?.index },
							{ ['step-list__index-active']: item.id === active.id }
						)}
					>
						{index + 1}
					</div>
					<div
						className={classNames(
							'step-list__name',
							{ ['step-list__name-move']: index + 1 <= active?.index },
							{ ['step-list__name-active']: item.id === active.id }
						)}
						onClick={() => linkAction(item)}
					>
						{t(item.name)}
					</div>
				</div>
			))}
		</div>
	)
}

export default StepList
