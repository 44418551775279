import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'
import CollapsedButton from '@atoms/buttons/CollapsedButton'
import UserAvatar from '@molecules/app/avatar'
import AppMenu from '@molecules/app/menu'

import { setMenuList } from '@redux/reducers/menuSlice'

import { menuLcVerificationLists, menuLists } from '@tools/menu'

interface MenuType {
	set: 'default' | 'lc-verification'
}

const Menu: FC<MenuType> = ({ set }: MenuType) => {
	const LOCAL_STORAGE_KEY = 'menuCollapsedState'
	const [collapsed, setCollapsed] = useState(() => {
		const storedState = localStorage.getItem(LOCAL_STORAGE_KEY)
		return storedState ? JSON.parse(storedState) : false
	})
	const dispatch = useDispatch()

	useEffect(() => {
		set === 'default' && dispatch(setMenuList(menuLists))
		set === 'lc-verification' && dispatch(setMenuList(menuLcVerificationLists))
	}, [])

	useEffect(() => {
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(collapsed))
	}, [collapsed])

	return (
		<nav className='main-menu'>
			<UserAvatar isCollapsed={collapsed} />
			<AppMenu isCollapsed={collapsed} />
			<div className='main-menu__footer'>
				<CollapsedButton onClick={() => setCollapsed(!collapsed)} isCollapsed={collapsed} />
			</div>
		</nav>
	)
}

export default Menu
