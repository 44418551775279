import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
/*
 *
 * Addresses reducer
 *
 */
const initialState = {
	loading: false,
	error: null,
	list: null,
	cities: [],
	streets: null,
}

export type addressesInitStateType = typeof initialState

const addressesSlice = createSlice({
	name: 'addresses',
	initialState,
	reducers: {
		getAddressesList: () => console.log('getAddressesList'),
		setAdrList: (state, action: PayloadAction<any>) => {
			state.list = action.payload
		},
		setCities: (state, action: PayloadAction<any>) => {
			state.cities = action.payload
		},
		setStreets: (state, action: PayloadAction<any>) => {
			state.streets = action.payload
		},
		initAddresses: state => {
			state.loading = true
			state.error = null
		},
		setAddressesSuccess: (state, action) => {
			state.loading = false
			state.list = action.payload
		},
		setAddressesFailed: (state, action) => {
			state.loading = false
			state.list = null
			state.error = action.payload
		},
	},
})

export default addressesSlice.reducer
export const {
	getAddressesList,
	setAdrList,
	setCities,
	setStreets,
	initAddresses,
	setAddressesSuccess,
	setAddressesFailed,
}: any = addressesSlice.actions
