import type { AxiosResponse } from 'axios'
import type { loginDataType, resetDataType } from 'types/app-data'
import { post } from '@api/api_helper'

export const api = {
	login: async (data: loginDataType): Promise<AxiosResponse> => {
		try {
			return await post('/profile/loginUser', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	logout: async (token: string): Promise<AxiosResponse> => {
		try {
			return await post('/profile/logoutUser', token)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	registration: async (data: loginDataType): Promise<AxiosResponse> => {
		// const { email, password } = data;
		try {
			return await post('/profile/registration', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	updateVerifierPassword: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/updatePassword', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	reset: async (data: resetDataType): Promise<AxiosResponse> => {
		try {
			return await post('/profile/resetPassword', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	resetByPhone: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post('/profile/resetPasswordConfirmPhone', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	forgot: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post('/profile/forgotPassword', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},
}
