import { Modal } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'

import AddSocialForm from '@organisms/forms/addSocial'
import { getOptions } from '@redux/reducers/appSlice'
import './style.scss'

interface AddSocialModalType {
	component?: any
	action?: any
	open?: boolean
	setOpen?: any
}

const AddSocialModal: FC<AddSocialModalType> = ({
	component: Component,
	//action,
	open,
	setOpen,
}: AddSocialModalType) => {
	const [visible, setVisible] = useState(true)

	const [_loader, _setLoader] = useState(false)

	const dispatch = useDispatch()

	useEffect(() => {
		open && setVisible(true)
	}, [open])

	useEffect(() => {
		if (visible) {
			dispatch(
				getOptions({
					request: {
						source: ['social_media'],
					},
				})
			)
		}
	}, [visible])

	const onCancel = () => {
		setVisible(false)
		setOpen(false)
	}

	// const call = () => {
	// 	setLoader(true)
	// 	action(onCancel)
	// }

	return (
		<>
			{Component && (
				<Component
					onClick={() => {
						setVisible(true)
					}}
				/>
			)}
			<Modal
				className='modal-add-social'
				open={visible}
				width={696}
				footer={null}
				closable={true}
				styles={{ body: { padding: '64px 0' } }}
				centered
				onCancel={onCancel}
			>
				<div className='modal-add-social__body'>
					<div className='modal-add-social__header'>
						<div className='modal-add-social__title'>{t('Add social network')}</div>
						<div className='modal-add-social__desc'>
							{t('Go to social network profile and copy URL ')}
						</div>
					</div>
					<div className='modal-add-social__form'>
						<AddSocialForm setVisible={onCancel} />
					</div>
				</div>
			</Modal>
		</>
	)
}

export default AddSocialModal
