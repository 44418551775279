import { Modal } from 'antd'
import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'

import FormCode from '@organisms/forms/code/confirmPhoneProfile'

import { sendSmsOnPhone } from '@redux/reducers/userSlice'
import { createPhoneMask } from '@tools/helpers'
import './style.scss'

interface PhoneProfileModalType {
	component?: any
	action?: any
	phone?: string
	open?: any
	setOpen?: any
	setModalPhone?: any
}

const PhoneProfileModal: FC<PhoneProfileModalType> = ({
	component: Component,
	action,
	phone,
	open,
	setOpen,
	setModalPhone,
}: PhoneProfileModalType) => {
	const [visible, setVisible] = useState(false)
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		open && setVisible(open)
	}, [open])

	useEffect(() => {
		dispatch(
			sendSmsOnPhone({
				request: {
					phone,
				},
			})
		)
	}, [])
	const onCancel = () => {
		setVisible(false)
		setLoader(false)
		setOpen && setOpen(false)
		setModalPhone && setModalPhone(false)
	}

	const call = () => {
		setLoader(true)
		action(onCancel)
	}

	return (
		<>
			{Component && (
				<Component
					onClick={() => {
						setVisible(true)
					}}
				/>
			)}
			<Modal
				className='modal-confirm'
				open={visible}
				width={504}
				footer={null}
				closable={true}
				styles={{ body: { padding: '64px' } }}
				centered
				onCancel={onCancel}
			>
				<div className='modal-confirm__body'>
					<div className='modal-confirm__title'>{t('Phone Verification')}</div>
					<div className='modal-confirm__desc'>
						{parse(
							t('We sent an SMS with a 6-digit code', {
								phone: phone ? createPhoneMask(phone) : '',
							})
						)}
					</div>
					<FormCode action={call} setVisible={setVisible} loading={loader} />
					<button className='btn-default btn-gray' onClick={onCancel}>
						{t('Cancel')}
					</button>
				</div>
			</Modal>
		</>
	)
}

export default PhoneProfileModal
