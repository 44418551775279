import { Tabs } from 'antd'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'

import './style.scss'

import LcVerificationCompaniesTable from '@organisms/tables/lcVerificationCompanies'
import { appPage } from '@redux/reducers/appSlice'
import { getCompanies } from '@redux/reducers/lcVerificatorSlice'
import { setSessionProperties } from '@redux/reducers/userSlice'

const TabsCompanies: FC = () => {
	const { page } = useSelector((state: stateType) => state.app)
	//const { info } = useSelector((state: stateType) => state.user)
	const { filter, companies }: any = useSelector((state: stateType) => state.lcVerificator)
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { tab } = useParams()

	useEffect(() => {
		dispatch(
			getCompanies({
				request: {
					type: tab
						? `companies${tab.charAt(0).toUpperCase() + tab.slice(1)}`
						: 'companiesAll',
					filter: filter,
				},
			})
		)
	}, [])

	useEffect(() => {
		dispatch(appPage(tab))
		if (!tab) {
			dispatch(appPage('all'))
		}
	}, [tab])

	const onChange = (value: string) => {
		const updatedSessionProperties = {
			...sessionProperties,
			tabActiveCompany: value,
		}
		dispatch(setSessionProperties(updatedSessionProperties))

		dispatch(appPage(value))

		dispatch(
			getCompanies({
				request: {
					type: `companies${value.charAt(0).toUpperCase() + value.slice(1)}`,
					filter: filter,
				},
			})
		)
		navigate(`/lc-companies/${value}`)
	}

	// const statusCompanyController = ({ component: Component }: any) => {
	// 	if (info.company.status.id === 2) {
	// 		return <span className='companies-tabs-empty__text'>{t('No data')}.</span>
	// 	}

	// 	return <Component />
	// }

	const TabTitle: any = ({ text, count }: any) => (
		<div className='companies-tabs__title'>
			{t(text)}
			<div className='companies-tabs__title-counter'>{count}</div>
		</div>
	)

	return (
		<Tabs
			onChange={onChange}
			activeKey={page ? page : 'all'}
			centered
			className={classNames('companies-tabs')}
			items={[
				{
					label: (
						<TabTitle
							text={t('All companies')}
							count={companies?.counts?.companiesAll}
						/>
					),
					key: 'all',
					children: (
						<LcVerificationCompaniesTable headerTitle={'All companies'} keyId='all' />
					),
				},
				{
					label: (
						<TabTitle
							text={t('Under consideration')}
							count={companies?.counts?.companiesUnderConsideration}
						/>
					),
					key: 'UnderConsideration',
					children: (
						<LcVerificationCompaniesTable
							headerTitle={t('Under consideration')}
							keyId='UnderConsideration'
						/>
					),
				},
				{
					label: (
						<TabTitle
							text={'Authenticated'}
							count={companies?.counts?.companiesActive}
						/>
					),
					key: 'active',
					children: (
						<LcVerificationCompaniesTable
							headerTitle={t('Authenticated')}
							keyId='active'
						/>
					),
				},
				{
					label: (
						<TabTitle text={t('Denied')} count={companies?.counts?.companiesReject} />
					),
					key: 'reject',
					children: (
						<LcVerificationCompaniesTable headerTitle={t('Denied')} keyId='reject' />
					),
				},
				{
					label: (
						<TabTitle text={t('Remote')} count={companies?.counts?.companiesDeleted} />
					),
					key: 'deleted',
					children: (
						<LcVerificationCompaniesTable headerTitle={t('Remote')} keyId='deleted' />
					),
				},
			]}
		/>
	)
}

export default TabsCompanies
