/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button, Form, Input, message } from 'antd'
import { func, number } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanyInfo } from '@api/asyncHelpers'
import AppText from '@atoms/text/AppText'
import table_1 from '@image/table-1.svg'
import AppSelect from '@molecules/app/select'
import FormAddNewDepartment from '@organisms/forms/setDepartment/FormSetDepartment'
import StaticFormModal from '@organisms/modals/staticForm'
import { getAddressesList } from '@redux/actions/addressesActions'
import {
	addNewDepartment,
	getDepartmentInfo,
	getDepartments,
	setDepartmentsName,
} from '@redux/actions/departmentsActions'
import { getDropDownList } from '@redux/actions/dropDownListActions'
import { resetPositionFunctionality } from '@redux/actions/functionalityActions'
import { resetPositionKPI } from '@redux/actions/kpiActions'
import { resetPositions, setNewPositionsInfo } from '@redux/actions/positionsActions'
import { resetPositionTarget } from '@redux/actions/targetActions'
import { resetPositionZun } from '@redux/actions/zunActions'
import { getAddress, resetAddresses } from '@tools/helpers'
import { rules } from '@tools/messages'

FormSetDepartment.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetDepartment({ changeStep, step, isGoBack }) {
	const dispatch = useDispatch()

	const {
		departments: { loading, list, info, name: departmentName },
		positions: { info: positionsInfo },
		company,
	} = useSelector(state => ({
		departments: state.departments,
		positions: state.positions,
		company: state.company.info,
	}))
	const [form] = Form.useForm()

	const [visible, setVisible] = useState(false)
	const [isLocked, setIsLocked] = useState(true)
	const [addressesList, setAddressesList] = useState([])

	const handleOnAddDepartments = async values => {
		try {
			await addNewDepartment(values)
			await dispatch(getDropDownList())
			const departments = Array.isArray(company?.tl_lists)
				? [...company.tl_lists.map(el => el.name), values.name]
				: [values.name]
			await setCompanyInfo({ tl_lists: departments })
			await dispatch(getDepartments())
			await dispatch(getAddressesList())
		} catch (err) {
			console.error(err)
			message.error(err.message)
		}
	}
	const handleOnSelectPosition = name => {
		if (name !== departmentName) {
			setIsLocked(false)
			dispatch(resetPositions())
			dispatch(resetPositionFunctionality())
			dispatch(resetPositionTarget())
			dispatch(resetPositionKPI())
			dispatch(resetPositionZun())
			dispatch(setDepartmentsName(name))
		}
	}
	const onFinish = () => {
		const values = form.getFieldsValue()
		values.address = resetAddresses(values.address, info.address)
		dispatch(setNewPositionsInfo({ ...values, s_activity: company.s_activity }))
		changeStep(step + 1)
	}

	useEffect(() => {
		if (info) {
			let addresses = []
			if (Array.isArray(info.address)) {
				addresses = info.address.map(address => getAddress(address))
			} else {
				addresses = info?.address ? [getAddress(info?.address)] : []
			}
			setAddressesList(addresses)
			form.setFields([
				{
					name: 'manager_position',
					value: info?.manager_position && info.manager_position,
				},
				{ name: 'manager_surname', value: info?.manager_surname && info.manager_surname },
				{ name: 'manager_name', value: info?.manager_name && info.manager_name },
				{
					name: 'manager_middle_name',
					value: info?.manager_middle_name && info.manager_middle_name,
				},
				{
					name: 'address',
					value: addresses.length ? addresses.map(address => address.name) : [],
				},
			])
		}
	}, [info, positionsInfo])

	useEffect(() => {
		if (departmentName) {
			setIsLocked(false)
			dispatch(getDepartmentInfo(departmentName))
			form.setFields([{ name: 'department_name', value: departmentName }])
		}
	}, [departmentName])

	return (
		<>
			<StaticFormModal
				visible={visible}
				changeVisible={setVisible}
				title={t('New department')}
				text={t('Fill in department information')}
				component={
					<FormAddNewDepartment
						changeVisible={() => setVisible(!visible)}
						onSubmit={handleOnAddDepartments}
					/>
				}
				img={table_1}
				onSubmit={() => setVisible(false)}
			/>
			<Form
				name='set-department'
				layout='vertical'
				size='large'
				onFinish={onFinish}
				form={form}
				className='form'
			>
				<AppText
					text={t('General')}
					style={{
						color: '#20272E',
						fontSize: 18,
						fontWeight: 700,
						marginBottom: 24,
						lineHeight: 1.5715,
						display: 'block',
					}}
				/>
				{/*Названия отделов / Подразделений / Направлений*/}
				<Form.Item
					name='department_name'
					label={t(
						'Department name indicate the department to which the vacancy belongs'
					)}
					rules={[{ required: true, message: rules.required }]}
				>
					<AppSelect
						placeholder={t('Financial / Front-End / Marketing and Advertising')}
						options={list ? list : []}
						isHaveFooter
						isHaveModal
						isLoading={loading}
						modalBtnTxt={t('Add department')}
						onSelect={handleOnSelectPosition}
						changeVisible={() => setVisible(prev => !prev)}
						onDropdownVisibleChange={async open =>
							open && (await dispatch(getDepartments()))
						}
					/>
				</Form.Item>

				{/*Название должности непосредственного руководителя*/}
				<Form.Item name='manager_position' label={t('Job title of immediate supervisor')}>
					<Input
						disabled={isLocked}
						className='form__input'
						placeholder={t('Director')}
					/>
				</Form.Item>

				{/*Руководитель*/}
				<Form.Item noStyle>
					<div className='form__row'>
						<Form.Item label={t('Last name of the Manager')} name='manager_surname'>
							<Input
								disabled={isLocked}
								className='form__input'
								placeholder={t('Last name')}
							/>
						</Form.Item>

						<Form.Item label={t('Managers name')} name='manager_name'>
							<Input
								disabled={isLocked}
								className='form__input'
								placeholder={t('Name')}
							/>
						</Form.Item>

						<Form.Item
							label={t('Middle name of the Manager')}
							name='manager_middle_name'
						>
							<Input
								disabled={isLocked}
								className='form__input'
								placeholder={t('Patronymic')}
							/>
						</Form.Item>
					</div>
				</Form.Item>

				{/*Адрес Отдела / Подразделения / Направления*/}
				<Form.Item
					name='address'
					label={t('Address of the Department / Division / Directions')}
					rules={[{ required: !isLocked, message: rules.required }]}
				>
					<AppSelect
						disabled={isLocked}
						options={addressesList}
						placeholder={t('Please enter your address')}
						mode='multiple'
					/>
				</Form.Item>

				<Form.Item>
					<div className={`form__row ${isGoBack ? '_between' : '_right'} `}>
						{isGoBack && (
							<Button
								type='default'
								htmlType='button'
								shape='round'
								onClick={() => changeStep(step - 1)}
							>
								{t('Back')}
							</Button>
						)}
						<Button type='primary' shape='round' htmlType='submit'>
							{t('Save and continue')}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</>
	)
}

export default FormSetDepartment
