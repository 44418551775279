import { Col, Row } from 'antd'
import React, { Fragment, useState } from 'react'
import type { FC } from 'react'
import AppSearchInput from '@molecules/app/search'
import { checkIsHaveElements } from '@tools/helpers'

const PositionFunctionalityModalPositionNames: FC<any> = ({
	positionsId,
	setPositionId,
	positionData,
}: any) => {
	const { categoryNames, checkedList }: any = positionData
	const [searchItem, _setSearchItem] = useState('')

	return (
		<>
			<AppSearchInput />
			{categoryNames &&
				categoryNames.map((c: any) => (
					<ul key={c.id} className='functionality-position'>
						{c.positions
							.filter((opt: any) =>
								opt.name.toLowerCase().includes(searchItem.toLowerCase())
							)
							.map(({ id, name, functionality }: any) => {
								const isSomeSelect = checkIsHaveElements(functionality, checkedList)
								return (
									<Fragment key={id}>
										<li
											className={`functionality-position__item ${
												id.toString() === positionsId ? 'active' : ''
											}`}
											onClick={() => setPositionId(id.toString())}
										>
											<Row
												wrap={false}
												gutter={8}
												align='middle'
												justify='space-between'
											>
												<Col>
													<span className='functionality-position__name'>
														{name}
													</span>
												</Col>
												<Col>
													<span
														className={`functionality-position__length ${
															isSomeSelect && 'active'
														}`}
													>
														{functionality.length}
													</span>
												</Col>
											</Row>
										</li>
									</Fragment>
								)
							})}
					</ul>
				))}
		</>
	)
}

export default PositionFunctionalityModalPositionNames
