import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import type { stateType } from '@types'
import type { FC } from 'react'
import { IconLcEdit, IconLcRemove } from '@assets/icons/lcVerificator'
import ButtonDefault from '@atoms/buttons/button'
import { UserProfile } from '@molecules/app/avatar'
import { editMessage, removeMessage } from '@redux/reducers/lcVerificatorSlice'

import './style.scss'

const LcMessagesList: FC = () => {
	const { t } = useTranslation()
	const { id } = useParams()
	const { company }: any = useSelector((state: stateType) => state.lcVerificator)
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	const [edit, setEdit] = useState<any>(null)
	const [editValue, setEditValue] = useState('')

	const companyId = id || sessionProperties?.companyId

	const dispatch = useDispatch()

	const getChange = (value: any) => {
		setEditValue(value)
	}

	const editAction = (messageId: any) => {
		dispatch(
			editMessage({
				request: {
					company_id: companyId,
					message_id: messageId,
					text: editValue,
				},
				callback: {
					success: () => {
						setEdit(null)
						//NOTIFICATION.success(t('Post edited'));
					},
				},
			})
		)
	}

	const removeAction = (messageId: any) => {
		dispatch(
			removeMessage({
				request: {
					company_id: companyId,
					message_id: messageId,
				},
				callback: {
					success: () => {
						setEdit(null)
						//NOTIFICATION.success(t('Message remotely'));
					},
				},
			})
		)
	}

	return (
		<div className='lc-messages-list'>
			{company?.messages &&
				company?.messages?.map((item: any) => (
					<div className='lc-messages-list__wrapper' key={item?.id}>
						<div
							className={classNames('lc-messages-list__item', {
								['lc-messages-list__item-focus']: edit === item?.id ? true : false,
							})}
						>
							<div className='lc-messages-list__item-body'>
								<div className='lc-messages-list__item-header'>
									<div className='lc-messages-list__item-profile'>
										<UserProfile
											name={item?.author?.name ? item?.author?.name : 'Admin'}
											role={t('Verifier')}
										/>
									</div>
									<div className='lc-messages-list__item-date'>
										{dayjs(item?.update_date || item?.create_date).format(
											'MM/DD/YYYY, hh:mm'
										)}
									</div>
								</div>
								{edit !== item?.id && (
									<div className='lc-messages-list__item-text'>{item?.text}</div>
								)}
								{edit === item?.id && (
									<EditText item={item} setChange={getChange} />
								)}
							</div>
							<div className='lc-messages-list__item-nav'>
								{edit !== item?.id && (
									<IconLcEdit
										onClick={() => {
											setEdit(item?.id)
										}}
									/>
								)}
								<IconLcRemove
									onClick={() => {
										removeAction(item?.id)
									}}
								/>
							</div>
						</div>
						{edit === item?.id && (
							<div className='lc-messages-list__footer'>
								<div className='send-message__btns'>
									<span
										className='send-message__btn-text'
										onClick={() => setEdit(null)}
									>
										{t('Cancellation')}
									</span>
									<ButtonDefault
										name={t('Edit')}
										type={'submit'}
										disabled={editValue ? false : true}
										action={() => editAction(item?.id)}
									/>
								</div>
							</div>
						)}
					</div>
				))}
		</div>
	)
}

export default LcMessagesList

interface EditTextType {
	item?: any
	setChange?: any
}

const EditText: FC<EditTextType> = ({ item, setChange }: EditTextType) => {
	const inputReference = useRef<any>(null)
	const [value, setValue] = useState('')

	useEffect(() => {
		inputReference.current.focus()
	}, [])

	useEffect(() => {
		item?.text && setValue(item?.text)
		item?.text && setChange(item?.text)
	}, [item])

	const onchange = (e: any) => {
		setValue(e.target.value)
		setChange(e.target.value)
	}

	return (
		<textarea
			ref={inputReference}
			className='lc-messages-list__textarea'
			value={value}
			onChange={onchange}
		/>
	)
}
