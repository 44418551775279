import axios from 'axios'
import { fileApi } from '../routers'
import type { AxiosResponse } from 'axios'
import type { CreateCompanyRequesType, SetAdminToCompanyType } from 'types/company-data'
import { formDataPost, post } from '@api/api_helper'
import { consts } from '@tools/consts'

const { domain } = consts

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['lang'] = localStorage.getItem('lang')
	? localStorage.getItem('lang')
	: 'ru'

export const api = {
	getCompanyInfo: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/get_companyInfo', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	createCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/createCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	updateCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/updateCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getCompanies: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/get_companyList ', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	setCompanyInfoContacts: async (data): Promise<AxiosResponse> => {
		try {
			return await post('/company/set_companyInfoContacts', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	addToArchive: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/add_companyArchive', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	recoveryCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/del_companyArchive', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	removeCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/companyRemove', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	removeCompanyDepartmentAddress: async (
		data: CreateCompanyRequesType
	): Promise<AxiosResponse> => {
		try {
			return await post('/company/remove_companyDepartmentAddress', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	removeCompanyDepartment: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/remove_companyDepartment', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	restoreCompany: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/company/companyRestore', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	loginToCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/loginCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	logoutFromCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/logoutCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	setCompanyStatus: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/set_statusCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	setFileToCompany: async (data: any): Promise<AxiosResponse> => {
		// const { token, dir, file, withCredentials, getFileName, setFileList } = request;
		// const formData = new FormData();
		// formData.append('file', data.file);

		try {
			return await formDataPost('/company/add_verificationFileCompany', data, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json',
				},
			})
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	removeFileCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/remove_verificationFileCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	searchUserInCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/searchUser', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	addUserToCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/addUserToCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	transferCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/transferCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	updateUserToCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/updateUserToCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	removeUserFromCompany: async (data: CreateCompanyRequesType): Promise<AxiosResponse> => {
		try {
			return await post('/company/removeUserFromCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},
	/*
  updateCompany: async (request: any): Promise<AxiosResponse> => {
    const { token, data } = request;
    return await axios({
      url: `${companyApi.set_company_info}?token=${token}&data=${JSON.stringify(data)}`,
      method: 'POST',
    });
  },
*/
	getCompany: async (token: string): Promise<AxiosResponse> => {
		try {
			return await post(`/company/get_companyInfo?token=${token}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	setCompanyAdmin: async (request: SetAdminToCompanyType): Promise<AxiosResponse> => {
		const { token, data } = request

		let url = `/add_company_admin/?token=${token}&domain=${domain}/`
		Object.keys(data).forEach(key => {
			if (data[key]) url += `&${key}=${data[key]}`
		})

		try {
			return await post(url)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	setFile: async (request: any): Promise<AxiosResponse> => {
		const { token, dir, file, withCredentials, getFileName, setFileList } = request
		const formData = new FormData()
		formData.append('file', file)

		return await axios({
			url: `${fileApi.add_file}?token=${token}&dir=${dir}`,
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: 'application/json',
			},
			withCredentials,
			onUploadProgress: e => {
				const progress =
					e.total !== undefined
						? Math.floor((e.loaded / e.total) * 100)
						: Math.floor(e.loaded)

				const name = getFileName(file)
				setFileList((prev: any) =>
					prev.map((item: any) => {
						if (item.name === name) {
							item.progress = progress - 1
						}
						return item
					})
				)
			},
			data: formData,
		})
	},

	removeFile: async (request: any): Promise<AxiosResponse> => {
		const { token, dir, file_name } = request

		try {
			return await post(`/del_file/?token=${token}&dir=${dir}&file_name=${file_name}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	addCompanyDeputy: async (request: any): Promise<AxiosResponse> => {
		const { token, data } = request

		try {
			return await post(
				`/add_company_deputy/?token=${token}&data=${encodeURIComponent(
					JSON.stringify(data)
				)}&domain=${domain}/`
			)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getAddressesList: async (token: string): Promise<AxiosResponse> => {
		try {
			return await post(`/get_list_company_address/?token=${token}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	setCompanyDepartmentAddress: async (data): Promise<AxiosResponse> => {
		try {
			return await post('/company/set_companyDepartmentAddress', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getCompanyDepartmentAddressList: async (data): Promise<AxiosResponse> => {
		try {
			return await post('/company/get_companyDepartmentAddressList', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	addCompanyDepartment: async (data): Promise<AxiosResponse> => {
		try {
			return await post('/company/add_companyDepartment', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getCompanyDepartmentList: async (data): Promise<AxiosResponse> => {
		try {
			return await post('/company/get_companyDepartmentList', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	addFileDefaultCompany: async (data): Promise<AxiosResponse> => {
		try {
			return await formDataPost('/company/add_fileCompany', data, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json',
				},
			})
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	removeFileDefaultCompany: async (data): Promise<AxiosResponse> => {
		try {
			return await post('/company/remove_fileCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},
}
