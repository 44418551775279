import parse from 'html-react-parser'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'

import { IconAppSuccess } from '@assets/icons/app'
import IconClose from '@assets/icons/page/close'
import { appPage, setStatus, setSuccess } from '@redux/reducers/appSlice'
import { logoutFromCompany } from '@redux/reducers/companySlice'
import { setUser } from '@redux/reducers/userSlice'
import useLogout from '@tools/hooks/useLogout'
import './style.scss'

interface SuccessType {
	type?: string
	action?: string
}

const Success: FC<SuccessType> = ({ type, action }: SuccessType) => {
	const { info } = useSelector((state: stateType) => state.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { logout } = useLogout()

	const bodyController = () => {
		switch (type) {
			case 'registration':
				return (
					<>
						<p className='success__message'>
							{t('Account was successfully registered')}. <br />
							{t('An email with a confirmation link has been sent to your E-Mail')}
						</p>
					</>
				)
			case 'forgot':
				return (
					<>
						<p className='success__message'>
							{t('An email has been sent to your E-Mail')} <br />
							{t('with password recovery instructions')}
						</p>
					</>
				)

			case 'reset':
				return (
					<>
						<p className='success__message'>
							{parse(t('New password saved successfully'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(appPage('login'))
									dispatch(setStatus('auth'))
								}}
							>
								{t('Sign in')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									dispatch(appPage('registration'))
									dispatch(setStatus('auth'))
								}}
							>
								{t('Registration')}
							</button>
						</div>
					</>
				)

			case 'company-create':
				return (
					<>
						<p className='success__message'>
							{parse(t('New Company successfully created'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									//dispatch(appPage('login'));
									//dispatch(setStatus('auth'));

									dispatch(setStatus('company-verification'))
									navigate('/company-create/verification')
								}}
							>
								{t('Pass Verification')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									//dispatch(appPage('registration'));
									//dispatch(setStatus('auth'));

									if (!info?.company?.id) {
										dispatch(setStatus('companies-profile'))
										navigate('/companies')
										return
									}

									dispatch(
										logoutFromCompany({
											request: { company_id: info.company.id },
											callback: () => {
												dispatch(setUser({ ...info, company: false }))
												dispatch(setStatus('companies-profile'))
												navigate('/companies')
											},
										})
									)
								}}
							>
								{t('Move to Companies')}
							</button>
						</div>
					</>
				)

			case 'company-verification':
				return (
					<>
						<p className='success__message'>
							{parse(t('The company has been sent for verification'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									//dispatch(appPage('login'));
									//dispatch(setStatus('auth'));
									dispatch(setStatus('main'))
									navigate('/')
								}}
							>
								{t('Go to Home')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									//dispatch(appPage('registration'));
									//dispatch(setStatus('auth'));
									if (info?.company?.id) {
										dispatch(
											logoutFromCompany({
												request: { company_id: info.company.id },
												callback: () => {
													dispatch(setUser({ ...info, company: false }))
													dispatch(setStatus('company-create'))
													navigate('/company-create')
												},
											})
										)
										return
									}
									dispatch(setStatus('company-create'))
									navigate('/company-create')
								}}
							>
								{t('Add Company')}
							</button>
						</div>
					</>
				)

			case 'company-verification-again':
				return (
					<>
						<p className='success__message'>
							{parse(t('The company has been resubmitted for review'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/my-company/profileCompany')
								}}
							>
								{t('My Companies')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									if (info?.company?.id) {
										dispatch(
											logoutFromCompany({
												request: { company_id: info.company.id },
												callback: () => {
													dispatch(setUser({ ...info, company: false }))
													dispatch(setStatus('company-create'))
													navigate('/company-create')
												},
											})
										)
										return
									}
									dispatch(setStatus('company-create'))
									navigate('/company-create')
								}}
							>
								{t('Add Company')}
							</button>
						</div>
					</>
				)

			case 'company-updated':
				return (
					<>
						<p className='success__message'>
							{parse(t('Company data has been successfully updated'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/')
								}}
							>
								{t('Add a vacancy')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/')
								}}
							>
								{t('Go to Home')}
							</button>
						</div>
					</>
				)

			case 'user-registration':
				return (
					<>
						<p className='success__message'>{parse(t('User data saved'))}</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('company-create'))
									navigate('/company-create')
								}}
							>
								{t('Add company')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									dispatch(setStatus('companies-profile'))
									navigate('/companies')
								}}
							>
								{t('Go to Company Profile')}
							</button>
						</div>
					</>
				)

			case 'add-user-to-company':
				return (
					<>
						<p className='success__message'>
							{parse(t('New Company User has been successfully assigned'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/')
								}}
							>
								{t('Home')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/settings')
								}}
							>
								{t('Personal Area')}
							</button>
						</div>
					</>
				)

			case 'add-admin-to-company':
				return (
					<>
						<p className='success__message'>
							{parse(t('New Company Administrator has been successfully assigned'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/')
								}}
							>
								{t('Home')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/settings')
								}}
							>
								{t('Personal Area')}
							</button>
						</div>
					</>
				)

			case 'update-user-to-company':
				return (
					<>
						<p className='success__message'>
							{parse(t('Users access rights have been successfully changed'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/')
								}}
							>
								{t('Home')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/settings')
								}}
							>
								{t('Personal Area')}
							</button>
						</div>
					</>
				)

			case 'transfer-company-to-user':
				return (
					<>
						<p className='success__message'>
							{parse(t('New Company Owner has been successfully assigned'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/')
								}}
							>
								{t('Home')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									dispatch(setStatus('main'))
									navigate('/settings')
								}}
							>
								{t('Personal Area')}
							</button>
						</div>
					</>
				)

			case 'transfer-company-to-user-logout':
				return (
					<>
						<p className='success__message'>
							{parse(t('New Company Owner has been successfully assigned'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('company-create'))
									navigate('/company-create')
								}}
							>
								{t('Add Company')}
							</button>
							<button
								className='btn-default btn-clasic'
								onClick={() => {
									dispatch(setStatus('companies-profile'))
									navigate('/companies')
								}}
							>
								{t('My Companies')}
							</button>
						</div>
					</>
				)

			case 'lc-verification':
				return (
					<>
						<p className='success__message'>
							{parse(
								t(
									'The new company has been successfully verified and added to the database'
								)
							)}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('verifier'))
									navigate('/lc-companies')
								}}
							>
								{t('Companies')}
							</button>
						</div>
					</>
				)

			case 'lc-not-verification':
				return (
					<>
						<p className='success__message'>
							{parse(t('Verification denial sent to the owner'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('verifier'))
									navigate('/lc-companies')
								}}
							>
								{t('Companies')}
							</button>
						</div>
					</>
				)

			case 'lc-verification-remove':
				return (
					<>
						<p className='success__message'>
							{parse(t('Company successfully deleted'))}
						</p>

						<div className='success__btns'>
							<button
								className='btn-default btn-white'
								onClick={() => {
									dispatch(setStatus('verifier'))
									navigate('/lc-companies')
								}}
							>
								{t('Companies')}
							</button>
						</div>
					</>
				)

			default:
				return <></>
		}
	}

	const handleConfirm = () => {
		switch (action) {
			case 'logout':
				logout()
				break
			default:
				dispatch(setSuccess({}))
				dispatch(setStatus('auth'))
				dispatch(appPage('login'))
				break
		}
		dispatch(setSuccess({}))
	}

	return (
		<div className='success'>
			<div className='success__icon-close' onClick={handleConfirm}>
				<IconClose />
			</div>
			<div className='success__icon'>
				<IconAppSuccess />
			</div>
			<div className='success__body'>{bodyController()}</div>
		</div>
	)
}

export default Success
