import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import FormHeader from '@atoms/text/formHeader'
import FormForgotPassword from '@organisms/forms/forgot'
import { appPage } from '@redux/reducers/appSlice'
import AsideTemplate from '@templates/asideTemplate'

const Authentication: FC = () => {
	const { page } = useSelector((state: stateType) => state.app)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(appPage('forgot'))
	}, [])

	return (
		<>
			<Helmet title={page} />
			<AsideTemplate showBenefits center page={page}>
				<FormHeader type={'forgot'} />
				<FormForgotPassword />
			</AsideTemplate>
		</>
	)
}

export default Authentication
