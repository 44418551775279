import classNames from 'classnames'
import React from 'react'
import type { FC } from 'react'

import formAdd from '@image/icons/form-add-button-plus.svg'

import './style.scss'

interface FormAddButtonType {
	onClick?: any
	text?: string
	type?: string
	extraClasses?: string
	containerStyle?: React.CSSProperties
	icon?: React.ReactNode
	disabled?: boolean
}

const FormAddButton: FC<FormAddButtonType> = ({
	onClick,
	text,
	extraClasses,
	containerStyle,
	icon,
}: FormAddButtonType) => (
	<div
		style={{ ...containerStyle }}
		className={`btn-nav ${extraClasses ? extraClasses : ''}`}
		onClick={onClick}
	>
		<div className='btn-nav__plus'>
			{icon || <img src={formAdd} alt='form-add' className='btn-nav__icon' />}
		</div>
		<p className='btn-nav__text'>{text}</p>
	</div>
)

export default FormAddButton

export const MainNavButton: FC<FormAddButtonType> = ({
	onClick,
	text,
	type,
	icon,
	disabled,
}: FormAddButtonType) => (
	<div
		className={classNames(
			'btn-nav',
			{ [type ? type : '']: type },
			{ ['btn-nav-disabled']: disabled }
		)}
		onClick={() => !disabled && onClick()}
	>
		<div className='btn-nav__plus'>
			{icon || <img src={formAdd} alt='form-add' className='btn-nav__icon' />}
		</div>
		<p className='btn-nav__text'>{text}</p>
	</div>
)
