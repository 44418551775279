import { yupResolver } from '@hookform/resolvers/yup'

import './style.scss'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'

import { IconRemoveInput } from '@assets/icons/modals/addUsers'
import { IconTooltipInfo } from '@assets/icons/nav/profileHeader'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault, {
	InputDatePicker,
	InputDropdownPrefix,
	InputMaskDefault,
	InputSelectDefault,
} from '@atoms/inputs'
import DropdownSocialsControlled from '@molecules/app/dropdwons/socials'
import TooltipJobTitleBody from '@molecules/tooltips/jobTitle'
import PhoneProfileModal from '@organisms/modals/confirms/phoneProfile'
import RemoveEmailModal from '@organisms/modals/confirms/removeEmail'
import RemovePhoneModal from '@organisms/modals/confirms/removePhone'
import RemoveSocialModal from '@organisms/modals/confirms/removeSocial'
import AddSocialModal from '@organisms/modals/settings/addSocial'
import { profileUpdate } from '@redux/reducers/userSlice'
import { NOTIFICATION } from '@redux/sagas/notification'
import i18n from '@src/i18n'
import { editUserSchema } from '@tools/shemas'

const EditProfile: FC<any> = ({ setVisible }: any) => {
	const [loading, setLoading] = useState<any>(false)
	const [openSocialModal, setOpenSocialModal] = useState<any>(false)
	const [openConfirmPhoneModal, setOpenConfirmPhoneModal] = useState<any>(false)

	const { info } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const {
		country,
		birth_date,
		lname,
		name,
		sname,
		phone,
		email,
		gender_id,
		is_confirm_phone,
		otherEmails,
		otherPhones,
		socials = [],
		is_verifier,
	} = info

	const schema = useMemo(() => editUserSchema(), [i18n.language])

	const birth_date_format = birth_date ? dayjs(birth_date).format('YYYY-MM-DD') : ''

	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
		getValues,
	} = useForm({
		defaultValues: {
			birth_date: birth_date_format,
			country: country ? country : '',
			lname: lname ? lname : '',
			name: name ? name : '',
			sname: sname ? sname : '',
			phone: phone ? phone : '',
			email: email ? email : '',
			gender_id: gender_id ? gender_id : null,
			otherEmails: otherEmails ? otherEmails.map((el: any) => ({ other_email: el })) : [],
			otherPhones: otherPhones ? otherPhones.map((el: any) => ({ other_phone: el })) : [],
			socials: socials ? socials : [],
		},
		resolver: yupResolver(schema),
	})

	const phoneGroup = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'otherPhones', // unique name for your Field Array
	})

	const emailGroup = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'otherEmails', // unique name for your Field Array
	})

	const socialsGroup = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'socials', // unique name for your Field Array
	})

	const { t } = useTranslation()
	const dispatch = useDispatch()

	useEffect(() => {
		country && setValue('country', country)
		phone && setValue('phone', phone)
		birth_date && setValue('birth_date', birth_date_format)
		lname && setValue('lname', lname)
		name && setValue('name', name)
		sname && setValue('sname', sname)
		email && setValue('email', email)
		gender_id && setValue('gender_id', gender_id)
		socials && setValue('socials', socials)

		if (socials === null) {
			socialsGroup.append({ type: 1, name: '' })
		}
	}, [info])

	const watchPhone = watch('phone')

	const onSubmit = async (data: any) => {
		setLoading(true)
		const request = {
			...data,
			phone: info?.is_confirm_phone
				? data.phone
				: data.phone.length > 10
				  ? data.phone.replace(/[^\d]/g, '').slice(2)
				  : data.phone,
			birth_date: data?.birth_date ? dayjs(data.birth_date).format('YYYY-MM-DD') : '',
		}

		if (data.otherEmails && data.otherEmails.length > 0) {
			for (let index = data.otherEmails.length - 1; index >= 0; index--) {
				if (data.otherEmails[index]?.other_email.trim() === '') {
					emailGroup.remove(index)
				}
			}

			const validEmails = data.otherEmails
				.map(el => el.other_email)
				.filter(email => email.trim() !== '')
			if (validEmails.length > 0) {
				request.otherEmails = validEmails
			} else {
				delete request.otherEmails
			}
		}

		if (data.otherPhones && data.otherPhones.length > 0) {
			for (let index = data.phoneGroup.length - 1; index >= 0; index--) {
				if (data.phoneGroup[index]?.other_phone.trim() === '') {
					phoneGroup.remove(index)
				}
			}

			const validPhones = data.otherPhones
				.map(el => el.other_phone)
				.filter(phone => phone.trim() !== '')
			if (validPhones.length > 0) {
				request.otherPhones = validPhones
			} else {
				delete request.otherPhones
			}
		}

		dispatch(
			profileUpdate({
				request,
				callback: {
					success: () => {
						setLoading(false)
						setVisible(false)
						NOTIFICATION.success(t('Data updated successfully')) //
					},
					error: () => {
						setLoading(false)
					},
				},
			})
		)
	}

	const ConfirmPhoneComponents = ({}: any) => (
		<span className='confirm-phone' onClick={() => setOpenConfirmPhoneModal(true)}>
			{t('Confirm')}
		</span>
	)

	const inputRef = useRef(null)

	return (
		<>
			{openSocialModal && (
				<AddSocialModal open={openSocialModal} setOpen={setOpenSocialModal} />
			)}
			{openConfirmPhoneModal && (
				<PhoneProfileModal
					open={openConfirmPhoneModal}
					setOpen={setOpenConfirmPhoneModal}
					phone={getValues('phone')}
				/>
			)}
			<form onSubmit={handleSubmit(onSubmit)} className='form edit-profile-form'>
				<div className='edit-profile-form__body'>
					<div className='edit-profile-form__container'>
						<div className='edit-profile-form__container-label'>{t('User')}</div>
						<div className='edit-profile-form__row'>
							<InputDefault
								name={'lname'}
								label={t('Last name')}
								placeholder={t('Last name')}
								error={errors.lname?.message}
								register={register}
								control={control}
								required
							/>
							<InputDefault
								name={'name'}
								label={t('Name')}
								placeholder={t('Name')}
								error={errors.name?.message}
								register={register}
								control={control}
								required
							/>
							<InputDefault
								name={'sname'}
								label={t('Patronymic')}
								placeholder={t('Patronymic')}
								error={errors.sname?.message}
								register={register}
								control={control}
							/>
						</div>
						<div className='edit-profile-form__row'>
							<InputDatePicker
								name={'birth_date'}
								label={t('Date of birth')}
								placeholder={'10/08/1983'}
								error={errors.birth_date?.message}
								register={register}
								control={control}
								required
							/>
							<InputSelectDefault
								name={'gender_id'}
								optionKey={'gender'}
								label={t('Gender')}
								placeholder={t('Choose gender')}
								error={errors.gender_id?.message}
								register={register}
								control={control}
								options={options?.gender ? options?.gender : []}
								required
								allowClear={false}
							/>
							<div className='input-default empty' />
						</div>
						<div className='edit-profile-form__row'>
							<div className='edit-profile-form__block'>
								<InputMaskDefault
									name={'phone'}
									label={t('Phone')}
									placeholder={t('Phone')}
									error={errors.phone?.message}
									register={register}
									control={control}
									disabled={info?.is_confirm_phone ? true : 0}
									plus
									actionPlus={() => {
										phoneGroup.fields.length < 3 &&
											phoneGroup.append({ other_phone: '' })
									}}
								/>

								{watchPhone
									.replace(/[^\d]/g, '')
									.slice(watchPhone[0] === '+' ? 2 : 0).length === 10 &&
									!is_confirm_phone && <ConfirmPhoneComponents />}
								<div>
									{phoneGroup.fields.map((field, index) => (
										<InputMaskDefault
											key={field.id}
											name={`otherPhones[${index}].other_phone`}
											placeholder={t('Phone')}
											error={errors.otherPhones}
											register={register}
											control={control}
										>
											<RemovePhoneModal
												component={IconRemoveInput}
												action={() => phoneGroup.remove(index)}
											/>
										</InputMaskDefault>
									))}
								</div>
							</div>
							<div className='edit-profile-form__block'>
								<InputDefault
									name={'email'}
									label={t('E-mail')}
									placeholder={t('E-mail address')}
									error={errors.email?.message}
									register={register}
									control={control}
									disabled
									plus
									actionPlus={() => {
										emailGroup.fields.length < 3 &&
											emailGroup.append({ other_email: '' })
									}}
								/>
								{emailGroup.fields.map((field, index) => (
									<InputDefault
										key={field.id}
										name={`otherEmails[${index}].other_email`}
										placeholder={t('E-mail address')}
										error={errors.otherEmails}
										register={register}
										control={control}
									>
										<RemoveEmailModal
											component={IconRemoveInput}
											action={() => {
												emailGroup.remove(index)
											}}
										/>
									</InputDefault>
								))}
							</div>
							<div className='edit-profile-form__block'>
								{socialsGroup.fields.map((field: any, index) => (
									<InputDropdownPrefix
										label={index === 0 ? t('Messenger') : false}
										plus={socialsGroup.fields.length <= 2}
										actionPlus={() => {
											socialsGroup.fields.length < 4 &&
												socialsGroup.append({ type: 1, name: '' })
										}}
										key={index}
										index={index}
										classes={'edit-profile-form__social-select'}
										dropdownComponent={props => (
											<DropdownSocialsControlled
												optionKey={'messengers'}
												{...props}
											/>
										)}
										value={field ? field?.type : 1}
										register={register}
										control={control}
										options={
											options?.social_media
												? _.filter(options?.social_media)
												: []
										}
										reference={inputRef}
									>
										{index > 0 && (
											<RemoveSocialModal
												component={IconRemoveInput}
												action={() => {
													socialsGroup.remove(index)
												}}
											/>
										)}
									</InputDropdownPrefix>
								))}
							</div>
						</div>
					</div>
					{is_verifier === 1 ? (
						<div className='edit-profile-form__container'>
							<div className='edit-profile-form__container-label'>
								{t('Contractor')}{' '}
							</div>
							<div className='edit-profile-form__row'>
								<InputDefault
									name={'company'}
									label={t('Company')}
									defaultValue={`${t('LLC')} «ProfWorld»`}
									disabled
								/>
								<InputDefault
									name={'position'}
									label={t('Position')}
									defaultValue={t('Verifier')}
									disabled
								/>
								<InputDefault
									name={'country'}
									label={t('Region / City')}
									placeholder={t('Region / City')}
									register={register}
									control={control}
								/>
							</div>
						</div>
					) : (
						<div className='edit-profile-form__container'>
							<div className='edit-profile-form__container-label'>
								{t('Contractor')}{' '}
								<Tooltip
									trigger={'hover'}
									placement='bottom'
									title={<TooltipJobTitleBody />}
									className='default-tooltip'
									overlayClassName={
										'default-tooltip-overlay default-tooltip-overlay-size-294'
									}
								>
									<IconTooltipInfo />
								</Tooltip>
							</div>
							<div className='edit-profile-form__row'>
								<InputDefault
									name={'company'}
									label={t('Company')}
									defaultValue={info.company?.name}
									disabled
								/>
								<InputDefault
									name={'role'}
									label={t('Role')}
									defaultValue={info.company?.user?.role?.name}
									disabled
								/>
								<div className='input-default empty' />
							</div>
							<div className='edit-profile-form__row'>
								<InputDefault
									name={''}
									label={t('Division / Department')}
									placeholder={t('Division / Department')}
									disabled
								/>
								<InputDefault
									name={''}
									label={t('Position')}
									placeholder={t('Position')}
									disabled
								/>
								<InputDefault
									name={''}
									label={t('Region / City')}
									placeholder={t('Region / City')}
									disabled
								/>
							</div>
						</div>
					)}
				</div>

				<div className='edit-profile-form__footer'>
					<span className='edit-profile-form__btn-text' onClick={() => setVisible(false)}>
						{t('Cancellation')}
					</span>
					<ButtonDefault
						name={t('Save')}
						type={'submit'}
						loading={loading}
						disabled={loading}
					/>
				</div>
			</form>
		</>
	)
}

export default EditProfile
