import { Dropdown } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

import type { stateType } from '@types'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import { IconArrowDropdown } from '@assets/icons/btn/arrow'
import {
	IconFacebook,
	IconTelegram,
	IconWhatsapp,
	IconTwitter,
	IconLinkedin,
	IconYouTube,
	IconFB,
	IconPinterest,
} from '@assets/icons/nav/profileHeader'

import '../style.scss'
/* eslint-disable react/prop-types */
const DropdownSocialsControlled: FC<any> = ({
	value,
	control,
	name,
	rules,
	optionKey /*options*/,
}: any) => {
	const [open, setOpen] = useState<boolean>(false)
	const [id, setId] = useState<any>(value ? value : 1)

	const { options } = useSelector((state: stateType) => state.app)

	useEffect(() => {
		if (value && value !== id) {
			setId(value)
		}
	}, [value])

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field }: { field: { onChange } }) => {
				const action = (id: any) => {
					field.onChange(id)
					setId(id)
					setOpen(false)
				}
				const items: MenuProps['items'] = []

				if (options[optionKey]) {
					items.push(
						...options[optionKey].map(({ id, name }: { id: number; name: string }) => ({
							key: id,
							label: (
								<div key={id} className='dropdown-overlay__item'>
									{initIcon(name, optionKey)} {name}
								</div>
							),
							onClick: () => action(id),
						}))
					)
				}

				//options[optionKey].filter(item => item.id === id).name

				return (
					<Dropdown
						overlayClassName={'dropdown-social'}
						className={'dropdown'}
						menu={{ items }}
						trigger={['click']}
						placement='bottomRight'
						open={open}
						onOpenChange={(data: any) => setOpen(data)}
					>
						<div
							className='dropdown__triger'
							onClick={e => {
								e.preventDefault()
								setOpen(true)
							}}
						>
							<span className='dropdown__name'>
								{options[optionKey] &&
									initIcon(
										options[optionKey].filter(item => item.id === id)[0][
											'name'
										],
										optionKey
									)}
							</span>
							<div
								className={classNames(
									'dropdown__icon',
									{ ['animation-dropdown-up']: open },
									{ ['animation-dropdown-back']: !open }
								)}
							>
								<div className='dropdown__icon-arrow'>
									<IconArrowDropdown />
								</div>
							</div>
						</div>
					</Dropdown>
				)
			}}
		/>
	)
}

export default DropdownSocialsControlled

const initIcon = (type: string, optionKey?: string) => {
	switch (type.trim()) {
		case 'Telegram':
			return <IconTelegram />
		case 'Whatsapp':
			return <IconWhatsapp />
		case 'Facebook':
			return optionKey === 'messengers' ? <IconFacebook /> : <IconFB />
		case 'LinkedIn':
			return <IconLinkedin />
		case 'YouTube':
			return <IconYouTube />
		case 'Twitter':
			return <IconTwitter />
		case 'Pinterest':
			return <IconPinterest />

		default:
			break
	}
}
