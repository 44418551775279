import { Table, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import ButtonDefault from '@atoms/buttons/button'
import LcVerificationCompany from '@pages/lcVerification/company'
import { getOptions } from '@redux/reducers/appSlice'
import { setSessionProperties } from '@redux/reducers/userSlice'
import useCalculatePageSize from '@tools/hooks/useCalculatePageSize'
import useCompaniesPopup from '@tools/hooks/useCompaniesPopup'
import useTablePagination from '@tools/hooks/useTablePagination'
import './style.scss'

interface IProps {
	setActiveTab: (_value: string) => void
}

const { Text } = Typography

const CompaniesAdvanceSearchTable: FC<IProps> = ({ setActiveTab }) => {
	const tableRef = useRef<HTMLTableElement | null>(null)
	const { t } = useTranslation()
	const { options } = useSelector((state: stateType) => state.app)
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	const { companiesAdvanceSearch, loading }: any = useSelector(
		(state: stateType) => state.lcVerificator
	)
	const [modalVisible, setModalVisible] = useState(false)
	const dispatch = useDispatch()

	const defaultPageSize = useCalculatePageSize()
	const defaultTotal = companiesAdvanceSearch.length
	const keyId = null

	const { pageIndex, loadingPages, handleChangePage, handleTableRender } = useTablePagination({
		defaultPageSize,
		defaultTotal,
		tableRef,
		keyId,
	})

	const handleModalClose = () => {
		setModalVisible(false)
		const updatedSessionProperties = {
			...sessionProperties,
			companyId: null,
			tableKey: null,
			photoKeyActive: null,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
	}

	const handleModalOpen = (id, tabKey) => {
		const updatedSessionProperties = {
			...sessionProperties,
			companyId: id,
			tabKey: tabKey,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
		setModalVisible(true)
	}

	const { columnsPopup } = useCompaniesPopup(handleModalClose, handleModalOpen)

	useEffect(() => {
		!options?.filter_companies &&
			dispatch(
				getOptions({
					request: {
						source: ['filter_companies'],
					},
				})
			)
	}, [])

	return (
		<div className='lc-verification-companies-table auto'>
			{modalVisible && (
				<LcVerificationCompany open={modalVisible} onClose={handleModalClose} />
			)}
			{companiesAdvanceSearch ? (
				<div ref={tableRef as React.MutableRefObject<any>}>
					<Table
						onRow={handleTableRender}
						loading={loadingPages || loading}
						showSorterTooltip={false}
						locale={{
							emptyText: (
								<div className='lc-verification-companies-table__empty'>
									{t('No data')}.
								</div>
							),
						}}
						pagination={
							defaultTotal > defaultPageSize && {
								className: 'table-default__pagination right',
								defaultPageSize: defaultPageSize,
								pageSize: defaultPageSize,
								current: pageIndex,
								total: defaultTotal,
								showSizeChanger: false,
								onChange: handleChangePage,
							}
						}
						scroll={
							defaultTotal > defaultPageSize
								? { y: 460, scrollToFirstRowOnChange: true }
								: {}
						}
						className='lc-verification-companies-table__container'
						size='middle'
						columns={columnsPopup}
						rowKey={'id'}
						dataSource={companiesAdvanceSearch}
						sortDirections={['ascend', 'descend']}
					/>
				</div>
			) : (
				<Text className='lc-verification-companies-table__empty'>{t('No data')}</Text>
			)}
			<div className='form__container center'>
				<ButtonDefault
					name={t('Advanced Search')}
					action={() => setActiveTab('Filters')}
					loading={loading}
					disabled={loading}
				/>
			</div>
		</div>
	)
}

export default CompaniesAdvanceSearchTable
