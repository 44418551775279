import React from 'react'

const V2 = () => (
	<svg
		width='734'
		height='734'
		viewBox='0 0 734 734'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M718.7 367C718.7 561.2 561.2 718.7 367 718.7C172.8 718.7 15.3 561.2 15.3 367C15.3 172.8 172.8 15.3 367 15.3C561.2 15.3 718.7 172.8 718.7 367Z'
			fill='#6266D9'
		/>
		<path
			opacity='0.3'
			d='M617 367C617 505.1 505.1 617 367 617C228.9 617 117 505.1 117 367C117 228.9 228.9 117 367 117C505.1 117 617 228.9 617 367Z'
			fill='url(#paint0_radial_3743_4)'
		/>
		<path
			d='M594.7 367C594.7 492.7 492.7 594.7 367 594.7C241.3 594.7 139.3 492.7 139.3 367C139.3 241.3 241.3 139.3 367 139.3C492.7 139.3 594.7 241.3 594.7 367Z'
			fill='#5863D1'
		/>
		<defs>
			<radialGradient
				id='paint0_radial_3743_4'
				cx='0'
				cy='0'
				r='1'
				gradientUnits='userSpaceOnUse'
				gradientTransform='translate(187617 125117) scale(125000)'
			>
				<stop offset='0.9' stopColor='#0A0B0B' />
				<stop offset='1' stopColor='#101213' stopOpacity='0' />
			</radialGradient>
		</defs>
	</svg>
)

export default V2
