import { Layout } from 'antd'
import React, { useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'
import AppSider from '@atoms/siders/AppSider'
import AppText from '@atoms/text/AppText'
import AppTitle from '@atoms/text/AppTitle'
import DynamicSteps from '@organisms/dynamicSteps'

import { appPage } from '@redux/reducers/appSlice'
import useAddVacancySteps from '@tools/hooks/steps/useAddVacancySteps'

const AddVacancy: FC = () => {
	const { t } = useTranslation()
	const { currentVacancyStep, progressStep, vacancySteps, setCurrentProfileStep } =
		useAddVacancySteps()

	const dispatch = useDispatch()
	useEffect(() => {
		localStorage.getItem('company_step') &&
			setCurrentProfileStep(Number(localStorage.getItem('company_step')))
		dispatch(appPage('add-vacancy'))
	}, [])

	return (
		<Layout>
			<AppSider>
				<AppTitle level={3} title={t('New vacancy')} style={{ marginBottom: 56 }} />
				{vacancySteps
					.filter((vacancy: any) => vacancy.id === currentVacancyStep)
					.map((vacancy: any) => (
						<AppText
							key={vacancy.id}
							text={vacancy.text}
							style={{ fontWeight: 500, color: '#EDF2FF', margin: 0, fontSize: 16 }}
						/>
					))}
				{
					<ul className='vacancy-layout'>
						{vacancySteps.map(v => (
							<li
								key={v.id}
								className={`vacancy-layout__item ${
									v.id === currentVacancyStep
										? 'progress'
										: v.id < currentVacancyStep
										  ? 'done'
										  : ''
								} `}
							>
								<span className='vacancy-layout__step'>{v.id}</span>
								<span className='vacancy-layout__name'>{v.name}</span>
							</li>
						))}
					</ul>
				}
			</AppSider>

			<Layout
				style={{
					backgroundColor: '#fff',
					padding: '56px 24px',
					overflow: 'auto',
					height: '100vh',
				}}
			>
				<div style={{ maxWidth: 576 }}>
					{vacancySteps
						.filter((vacancy: any) => vacancy.id === currentVacancyStep)
						.map((vacancy: any) => (
							<Fragment key={vacancy.id}>
								<DynamicSteps steps={vacancy.steps} current={progressStep} />
							</Fragment>
						))}
				</div>
			</Layout>
		</Layout>
	)
}

export default AddVacancy
