import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'

const useLangGet: any = () => {
	const { info } = useSelector((state: stateType) => state.user)
	const [lang, setLang] = useState(info.lang || localStorage.getItem('lang') || 'ru')

	useEffect(() => {
		if (info.lang) {
			if (info.lang !== localStorage.getItem('lang')) localStorage.setItem('lang', lang)

			if (info.lang !== lang) setLang(info.lang)
		}
	}, [info.lang, lang])

	return lang
}

export default useLangGet
