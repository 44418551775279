/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import dayjs from 'dayjs'
import React from 'react'
import 'dayjs/locale/uk'
import 'dayjs/locale/ru'

import { Link } from 'react-router-dom'

import { IconLink } from '@assets/icons/statsInfo'
import { IconFile } from '@assets/icons/uploaders'
import { StatusCompany, Titlte } from '@molecules/tables/users'

const id = {
	title: <Titlte name={'№'} classes={'lc-verification-companies-table__title'} />,
	dataIndex: 'id',
	className: 'lc-verification-companies-table__column lc-verification-companies-table__column-id',
	render: text => <div className='lc-verification-companies-table__column-container'>{text}</div>,
	sorter: (a, b) => a.id - b.id,
	width: 50,
}

const name = {
	title: () => <Titlte name={'Company'} classes={'lc-verification-companies-table__title'} />,
	dataIndex: 'name',
	className:
		'lc-verification-companies-table__column lc-verification-companies-table__column-name',
	render: (text, record) => (
		<div>
			<Link to={`/lc-companies/company/${record.id}`}>{text}</Link>
		</div>
	),
	sorter: (a, b) => a.name.localeCompare(b.name),
	width: 245,
}

const activity = {
	title: () => (
		<Titlte name={'Field of activity'} classes={'lc-verification-companies-table__title'} />
	),
	dataIndex: 'activity',
	className:
		'lc-verification-companies-table__column lc-verification-companies-table__column-activity',
	render: (text, record) => <div>{record.activity?.name}</div>,
	sorter: (a, b) => a.activity?.id - b.activity?.id,
	width: 345,
}

const code = {
	title: () => (
		<Titlte name={'Code'} name2={'EDRPOU'} classes={'lc-verification-companies-table__title'} />
	),
	dataIndex: 'code',
	className:
		'lc-verification-companies-table__column lc-verification-companies-table__column-code',
	sorter: (a, b) => a.code - b.code,
	width: 125,
}

const updateDate = {
	title: () => (
		<Titlte
			name={'Date'}
			name2={'creations'}
			classes={'lc-verification-companies-table__title'}
		/>
	),
	dataIndex: 'update_date',
	className:
		'lc-verification-companies-table__column lc-verification-companies-table__column-date',
	render: (text, record) => (
		<div className='lc-verification-companies-table__column-date-block'>
			<span className='table-date'>
				{record?.update_date && dayjs(record.update_date).format('MM/DD/YYYY')}
			</span>
			<span className='table-time'>
				{record?.update_date && dayjs(record.update_date).format('HH:mm:ss')}
			</span>
		</div>
	),
	sorter: (a, b) => new Date(a.update_date).getTime() - new Date(b.update_date).getTime(),
	width: 125,
}

const deletedDate = {
	title: () => (
		<Titlte
			name={'Date'}
			name2={'removal'}
			classes={'lc-verification-companies-table__title'}
		/>
	),
	dataIndex: 'del_date',
	className:
		'lc-verification-companies-table__column lc-verification-companies-table__column-date',
	render: (text, record) => (
		<div className='lc-verification-companies-table__column-date-block'>
			<span className='table-date'>
				{record?.del_date && dayjs(record.del_date).format('MM/DD/YYYY')}
			</span>
			<span className='table-time'>
				{record?.del_date && dayjs(record.del_date).format('HH:mm:ss')}
			</span>
		</div>
	),
	sorter: (a, b) => new Date(a.del_date).getTime() - new Date(b.del_date).getTime(),
	width: 125,
}

const file = {
	title: () => <Titlte name={'File'} classes={'lc-verification-companies-table__title'} />,
	dataIndex: 'file',
	className:
		'lc-verification-companies-table__column lc-verification-companies-table__column-files',
	render: (text, record) => (
		<div className='lc-verification-companies-table__column-container'>
			<Link to={`/lc-companies/company/${record.id}/files`}>
				<IconFile />
			</Link>
		</div>
	),
	width: 125,
}

const status = {
	title: () => <Titlte name={'Status'} classes={'lc-verification-companies-table__title'} />,
	dataIndex: 'status',
	className:
		'lc-verification-companies-table__column lc-verification-companies-table__column-status',
	render: (text, record) => (
		<StatusCompany
			status={record.status}
			isDel={record.is_del}
			classes={'lc-verification-companies-table__status'}
		/>
	),
	sorter: (a, b) => {
		const order = { 4: 1, 2: 2, 3: 3 }
		const aIsDel = a?.is_del === 1
		const bIsDel = b?.is_del === 1

		return aIsDel === bIsDel ? order[a.status.id] - order[b.status.id] : aIsDel ? -1 : 1
	},
	width: 155,
}

const iconLink = {
	title: () => <Titlte name={'Details'} classes={'lc-verification-companies-table__title'} />,
	dataIndex: 'iconLink',
	className:
		'lc-verification-companies-table__column lc-verification-companies-table__column-link',
	render: (text, record) => (
		<div className='lc-verification-companies-table__column-container'>
			<Link to={`/lc-companies/company/${record.id}`}>
				<IconLink />
			</Link>
		</div>
	),
	width: 100,
}

export const columns = [id, name, activity, code, updateDate, file, status, iconLink]

export const columnsDelete = [id, name, activity, code, deletedDate, file, status, iconLink]
