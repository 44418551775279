import { Button, Form, Input, message, Switch } from 'antd'
import { func, number } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import { setNewPositionsInfo } from '@redux/actions/positionsActions'
import { checkFormValues, getCurrentValues } from '@tools/helpers'
import { useAddItemToDropDownList } from '@tools/hooks/useAddItemToDropDownList'

FormSetPositionSpecificity.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetPositionSpecificity({ changeStep, step }) {
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const {
		lists,
		positions: { info },
	} = useSelector(state => ({
		positions: state.positions,
		lists: state.dropDownList.lists,
	}))
	const addItemToDropDownList = useAddItemToDropDownList()

	const [isSwitch, setIsSwitch] = useState(true)
	const handleOnChangeSwitch = () => setIsSwitch(!isSwitch)

	const onFinish = async () => {
		const values = checkFormValues(form.getFieldsValue())
		values.compensation_package = getCurrentValues(
			values.compensation_package,
			lists.s_compensation_package
		)
		values.dress_code = getCurrentValues(values.dress_code, lists.s_dress)
		values.work_process = getCurrentValues(values.work_process, lists.s_work_specifics)
		values.work_place = getCurrentValues(values.work_place, lists.s_specificity_workplace)
		values.customer_communications = getCurrentValues(
			values.customer_communications,
			lists.s_specificity_communication
		)
		values.supervisor_communications = getCurrentValues(
			values.supervisor_communications,
			lists.s_manager_specificity_communication
		)
		try {
			dispatch(setNewPositionsInfo(values))
			changeStep(step + 1)
		} catch (err) {
			message.error(err.message)
			throw err
		}
	}
	useEffect(() => {
		if (info) {
			const {
				compensation_package,
				customer_communications,
				dress_code,
				opportunities,
				supervisor_communications,
				work_place,
				work_process,
				missions,
			} = info
			form.setFields([
				{
					name: 'compensation_package',
					value: compensation_package?.length
						? compensation_package.map(({ name }) => name)
						: [],
				},
				{ name: 'opportunities', value: opportunities ? opportunities : null },
				{
					name: 'customer_communications',
					value: customer_communications?.name ? customer_communications.name : null,
				},
				{ name: 'dress_code', value: dress_code?.name ? dress_code.name : null },
				{
					name: 'supervisor_communications',
					value: supervisor_communications?.name ? supervisor_communications.name : null,
				},
				{ name: 'work_place', value: work_place?.name ? work_place.name : null },
				{ name: 'work_process', value: work_process?.name ? work_process.name : null },
				{ name: 'missions', value: missions ? missions : null },
			])
		}
	}, [info])
	return (
		<Form
			name='set-position-specificity'
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
			className='form'
		>
			<AppText
				text={t('Specifics of the position')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Form.Item
				className='form__container'
				label={t('Compensation package')}
				name='compensation_package'
			>
				<AppSelect
					options={lists?.s_compensation_package}
					placeholder={t('Write or select from the list')}
					mode='multiple'
					isHaveFooter
					onSubmit={async name =>
						await addItemToDropDownList('s_compensation_package', name)
					}
				/>
			</Form.Item>

			<Form.Item label={t('Business trips')}>
				<div className='form__row'>
					<Switch
						defaultChecked
						style={{ width: 80 }}
						onChange={handleOnChangeSwitch}
						checkedChildren={t('Yes')}
						unCheckedChildren={t('No')}
					/>
					<Form.Item name='missions' noStyle>
						<Input
							disabled={!isSwitch}
							className='form__input'
							placeholder={t('Notes')}
						/>
					</Form.Item>
				</div>
			</Form.Item>

			<Form.Item className='form__container' label={t('Dress code')} name='dress_code'>
				<AppSelect
					options={lists?.s_dress}
					placeholder={t('Write or select from the list')}
				/>
			</Form.Item>

			<Form.Item
				className='form__container'
				label={t('Work process specifics')}
				name='work_process'
			>
				{t('Write or select from the list')}
				<AppSelect
					options={lists?.s_work_specifics}
					placeholder={t('Write or select from the list')}
					isHaveFooter
					onSubmit={async name => await addItemToDropDownList('s_work_specifics', name)}
				/>
			</Form.Item>

			<Form.Item
				className='form__container'
				label={t('Specifics of the workplace')}
				name='work_place'
			>
				<AppSelect
					options={lists?.s_specificity_workplace}
					placeholder={t('Write or select from the list')}
					isHaveFooter
					onSubmit={async name =>
						await addItemToDropDownList('s_specificity_workplace', name)
					}
				/>
			</Form.Item>

			<Form.Item
				className='form__container'
				label={t('Specificity of communication with clients')}
				name='customer_communications'
			>
				<AppSelect
					options={lists?.s_specificity_communication}
					placeholder={t('Write or select from the list')}
					isHaveFooter
					onSubmit={async name =>
						await addItemToDropDownList('s_specificity_communication', name)
					}
				/>
			</Form.Item>

			<Form.Item
				className='form__container'
				label={t('The specifics of communication with the head')}
				name='supervisor_communications'
			>
				<AppSelect
					options={lists?.s_manager_specificity_communication}
					placeholder={t('Write or select from the list')}
					isHaveFooter
					onSubmit={async name =>
						await addItemToDropDownList('s_manager_specificity_communication', name)
					}
				/>
			</Form.Item>

			<Form.Item className='form__container'>
				<div className='form__row _between'>
					<Button
						type='default'
						htmlType='button'
						shape='round'
						onClick={() => changeStep(step - 1)}
					>
						{t('Back')}
					</Button>
					<Button type='primary' shape='round' htmlType='submit'>
						{t('Save and continue')}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default FormSetPositionSpecificity
