/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { t } from 'i18next'
import * as yup from 'yup'

const validateAddressNotRequired = watchAddress => {
	if (watchAddress.length === 0) {
		return true
	}

	const isHTTP = watchAddress.startsWith('http://')
	const isHTTPS = watchAddress.startsWith('https://')

	if (isHTTP || isHTTPS) {
		return true
	}

	return false
}

const validatePhone = watchPhone => {
	if (watchPhone.replace(/[^\d]/g, '').slice(watchPhone[0] === '+' ? 2 : 0).length === 10) {
		return true
	}
	return false
}

const validateCode = watchCode => {
	const numericCode = watchCode.replace(/[^\d]/g, '')
	const slicedCode = numericCode.slice(0, 10)

	return /^[0-9]{8,10}$/.test(slicedCode)
}

const validatePhoneNotRequired = watchPhone => {
	const numericPhone = watchPhone.replace(/[^\d]/g, '')
	const slicedPhone = numericPhone.slice(watchPhone[0] === '+' ? 2 : 0)
	return watchPhone.length === 0 || (slicedPhone.length >= 10 && slicedPhone.length <= 13)
}

export const loginSchema = () =>
	yup.object().shape({
		email: yup
			.string()
			.required(t('Required field'))
			.matches(
				/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/i,
				t('Invalid field format')
			)
			.max(100, t('Field length exceeded')),
		password: yup
			.string()
			.required(t('Required field'))
			.min(8, t('Password length must be at least 8 characters'))
			.max(50, t('Field length exceeded')),
	})

export const regSchema = () =>
	yup.object().shape({
		email: yup
			.string()
			.required(t('Required field'))
			.matches(/^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}\s*$/i, t('Invalid field format'))
			.max(100, t('Field length exceeded')),
		password: yup
			.string()
			.required(t('Required field'))
			.min(8, t('Password length must be at least 8 characters'))
			.max(50, t('Field length exceeded')),
		password_repeat: yup
			.string()
			.oneOf([yup.ref('password'), null], t('Passwords do not match'))
			.required(t('Required field')),
	})

export const forgotSchema = () =>
	yup.object().shape({
		email: yup
			.string()
			.required(t('Required field'))
			.matches(
				/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/i,
				t('Invalid field format')
			)
			.max(100, t('Field length exceeded')),
	})

export const resetSchema = () =>
	yup.object().shape({
		password: yup
			.string()
			.required(t('Required field'))
			.min(8, t('Password length must be at least 8 characters'))
			.max(50, t('Field length exceeded')),
		password_repeat: yup
			.string()
			.oneOf([yup.ref('password'), null], t('Passwords do not match'))
			.required(t('Required field')),
	})

export const updatePasswordSchema = () =>
	yup.object().shape({
		currentPassword: yup
			.string()
			.required(t('Required field'))
			.min(8, t('Password length must be at least 8 characters'))
			.max(50, t('Field length exceeded')),
		password: yup
			.string()
			.required(t('Required field'))
			.min(8, t('Password length must be at least 8 characters'))
			.max(50, t('Field length exceeded')),
		password_repeat: yup
			.string()
			.oneOf([yup.ref('password'), null], t('Passwords do not match'))
			.required(t('Required field')),
	})
export const advanceSearch = () =>
	yup.object().shape({
		companyName: yup.string(),
		product: yup.string(),
		site: yup.string(),
		edrCode: yup.string(),
		dateFrom: yup.string().nullable(),
		//.transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
		dateTo: yup.string().nullable(),
		//.transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
		employees: yup.number().nullable(true).integer(),
		activity: yup.number().nullable(true).integer(),
	})

export const regUserSchema = () =>
	yup.object().shape({
		birth_date: yup.string().required(t('Required field')),
		lname: yup.string().required(t('Required field')),
		name: yup.string().required(t('Required field')),
		sname: yup.string(),
		gender_id: yup.number().required(t('Required field')),
		phone: yup
			.string()
			.required(t('Required field'))
			.test('phone', t('Not correct phone'), value => validatePhone(value)),
	})

export const editUserSchema = () =>
	yup.object().shape({
		email: yup.string().required(t('Required field')),
		birth_date: yup.string().required(t('Required field')),
		lname: yup.string().required(t('Required field')),
		name: yup.string().required(t('Required field')),
		sname: yup.string(),
		gender_id: yup.number().required(t('Required field')),
		country: yup.string(),
		phone: yup
			.string()
			.required(t('Required field'))
			.test('phone', t('Not correct phone'), value => validatePhone(value)),
		socials: yup.array(),
		otherEmails: yup.array(),
		otherPhones: yup.array(),
	})

export const codeSchema = () =>
	yup.object().shape({
		code: yup.number().required(t('Required field')),
	})

export const regCompanySchema = () =>
	yup.object().shape({
		name: yup.string().required(t('Required field')),
		employees: yup.number().nullable(true).integer(),
		code: yup
			.string()
			.nullable(true)
			.typeError(t('Required field'))
			.required(t('Required field'))
			// .min(8, t('Field length minimum 8 digits'))
			// .max(10, t('Field length exceeded'))
			//.matches(/^[0-9]{8}$|^[0-9]{10}$/, t('Invalid field format number')),
			.test('code', t('Invalid code'), value => validateCode(value)),
		activity: yup.number().nullable(true).integer(),
		services: yup.string(),
		website: yup
			.string()
			.test('website', t('Not correct web address'), value =>
				validateAddressNotRequired(value)
			),
	})

export const updateCompanySchema = () =>
	yup.object().shape({
		name: yup.string().required(t('Required field')),
		employees: yup.number().nullable(true).integer(),
		code: yup
			.string()
			.nullable(true)
			.typeError(t('Required field'))
			.required(t('Required field'))
			.test('code', t('Invalid code'), value => validateCode(value)),
		activity: yup.number().nullable(true).integer().required(t('Required field')),
		services: yup.string().required(t('Required field')),
		website: yup
			.string()
			.test('website', t('Not correct web address'), value =>
				validateAddressNotRequired(value)
			),
		tm_name: yup.string(),
	})

export const setCompanyContactsSchema = () =>
	yup.object().shape({
		legal_address_country: yup.string().required(t('Required field')),
		legal_address_city: yup.string().required(t('Required field')),
		legal_address_street: yup.string().required(t('Required field')),
		legal_address_house: yup.string().required(t('Required field')),
		actual_address_country: yup.string().required(t('Required field')),
		actual_address_city: yup.string().required(t('Required field')),
		actual_address_street: yup.string().required(t('Required field')),
		actual_address_house: yup.string().required(t('Required field')),
		website: yup
			.string()
			.test('website', t('Not correct web address'), value =>
				validateAddressNotRequired(value)
			),
		tm_name: yup.string(),
		actual_is_legal_address: yup.boolean().nullable(true),
		corporate_phone: yup
			.string()
			.nullable(true)
			.test('corporate_phone', t('Not correct phone'), value =>
				validatePhoneNotRequired(value)
			),
		email: yup
			.string()
			.nullable()
			.matches(
				/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/i,
				{
					message: t('Invalid field format'),
					excludeEmptyString: true,
				}
			)
			.max(100, t('Field length exceeded')),
		socials: yup.array(),
	})

export const setCompanyStructureSchema = () =>
	yup.object().shape({
		manager_lname: yup.string().required(t('Required field')),
		manager_name: yup.string().required(t('Required field')),
		manager_sname: yup.string().required(t('Required field')),
		employees: yup.number().nullable(true).required(t('Required field')),
		organizational_structure: yup.number().nullable(true).required(t('Required field')),
		//.test('phone', t('Not correct phone'), value => validatePhone(value)),
		departments: yup.array().nullable(true),
	})

export const setCompanyDepartmentSchema = () =>
	yup.object().shape({
		name: yup.string().required(t('Required field')),
		manager_position: yup.string().required(t('Required field')),
		manager_sname: yup.string(),
		manager_lname: yup.string().required(t('Required field')),
		manager_name: yup.string().required(t('Required field')),
		address_id: yup.number().nullable(true).required(t('Required field')),
	})

export const setCompanyAddressSchema = () =>
	yup.object().shape({
		country: yup.string().required(t('Required field')),
		city: yup.string().required(t('Required field')),
		street: yup.string().required(t('Required field')),
		house: yup.string().required(t('Required field')),
	})

export const addUserToCompanySchema = () =>
	yup.object().shape({
		email: yup
			.string()
			.required(t('Required field'))
			.matches(/^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i, t('Invalid field format'))
			.max(100, t('Field length exceeded')),
		lname: yup.string().required(t('Required field')),
		name: yup.string().required(t('Required field')),
		sname: yup.string(),
	})

export const setCompanyManagementSchema = () =>
	yup.object().shape({
		fileList: yup.array().nullable(true),
		is_training: yup.boolean().nullable(true),
		corporate_training: yup.array().nullable(true),
		selection_stages: yup.array().nullable(true),
	})

export const setCompanyMissionSchema = () =>
	yup.object().shape({
		mission: yup.string().nullable(true),
		competencies: yup.array().nullable(true),
		valuations: yup.array().nullable(true),
	})

/* example array object
yup.array().of(
    yup.object().shape({
      firsName: yup.string().required("First Name is required"),
      lastName: yup.string().required("Last Name is required")
    })
*/
