import React from 'react'
import type { FC } from 'react'

const IconRotate: FC<any> = ({ onClick }: any) => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		onClick={onClick}
	>
		<path
			d='M2.26611 14.0601C3.11216 15.7706 4.48431 17.1649 6.18111 18.0381C7.86576 18.9031 9.7865 19.1952 11.6521 18.8701C13.5235 18.541 15.238 17.6148 16.5391 16.2301C17.8522 14.8323 18.6883 13.054 18.9271 11.1511C19.1696 9.24383 18.8043 7.30868 17.8831 5.6211C16.9706 3.94662 15.5447 2.60933 13.8151 1.8061C12.0968 1.01026 10.1658 0.796795 8.31511 1.1981C6.46511 1.5991 4.94811 2.5111 3.69511 3.9531C3.20599 4.48586 2.7952 5.08554 2.47511 5.7341'
			stroke='#D2D8E8'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M6.931 5.81309L1.891 6.72009L1 1.59009'
			stroke='#D2D8E8'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default IconRotate
