import React, { useEffect } from 'react'
import LcVerificationCompaniesContainer from '@containers/lcVerification/companies'

const LcVerificationCompanies: React.FC = () => {
	useEffect(() => {
		console.log('LcVerificationCompanies PAGE')
	}, [])

	return <LcVerificationCompaniesContainer />
}

export default LcVerificationCompanies
