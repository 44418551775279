import classNames from 'classnames'
import React from 'react'
import type { FC } from 'react'
import BtnLogout from '@atoms/buttons/logoutButton'
import AsideFormBlock from '@containers/app/aside/form'
import CompaniesNavigationList from '@molecules/navigations/companies/sortList'
import LogoutFromProgramModal from '@organisms/modals/confirms/logoutFromProgram'

import useLogout from '@tools/hooks/useLogout'

import './style.scss'

interface AsideTemplateType {
	children: React.ReactNode
	center?: boolean
	page?: string
	classes?: string
}

const CompaniesTemplate: FC<AsideTemplateType> = ({
	children,
	center,
	page,
	classes,
}: AsideTemplateType) => {
	const { logout } = useLogout()

	return (
		//
		<div className={classNames('form-template', classes)}>
			<AsideFormBlock page={page}>
				<CompaniesNavigationList />
				<div className='form-template__box'>
					<LogoutFromProgramModal
						component={BtnLogout}
						color={'#A09EF2'}
						action={() => {
							logout()
						}}
					/>
				</div>
			</AsideFormBlock>
			<div
				className={classNames('form-template__body', {
					'form-template__body-center': center,
				})}
			>
				{children}
			</div>
		</div>
	)
}

export default CompaniesTemplate
