import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import { stepsTexts } from '@configs/texts'
import AsideFormBlock from '@containers/app/aside/form'
import helper from '@image/icons/helper.svg'
import StepList from '@molecules/navigations/companies/stepList'
import InformationHelp from '@organisms/modals/informationHelp'
import { getOptions } from '@redux/reducers/appSlice'
import './style.scss'

const list = [
	{ index: 1, id: 'company-create', name: 'Registration', link: '/company-create' },
	{
		index: 2,
		id: 'company-verification',
		name: 'Verification',
		link: '/company-create/verification',
	},
]

interface AsideTemplateType {
	children: React.ReactNode
	center?: boolean
	classes?: string
}

const CompaniesCreate: FC<AsideTemplateType> = ({
	children,
	center,
	classes,
}: AsideTemplateType) => {
	const { status } = useSelector((state: stateType) => state.app)
	const dispatch = useDispatch()
	const { tab } = useParams()
	const [keyStep, setKeyStep] = useState(tab ? tab : 'company-create')

	useEffect(() => {
		dispatch(
			getOptions({
				request: {
					source: ['employees', 'activities'],
				},
			})
		)
	}, [])

	useEffect(() => {
		tab ? setKeyStep(tab) : setKeyStep('company-create')
	}, [tab])

	return (
		<div className={classNames('form-template', classes)}>
			<AsideFormBlock page={status}>
				<StepList page={status} list={list} />
				<InformationHelp keyStep={keyStep} stepsTexts={stepsTexts} helper={helper} />
			</AsideFormBlock>
			<div
				className={classNames('form-template__body', {
					'form-template__body-center': center,
				})}
			>
				{children}
			</div>
		</div>
	)
}

export default CompaniesCreate
