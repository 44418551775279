import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { UseFormRegister, FieldValues } from 'react-hook-form'
import { IconRemoveInput } from '@assets/icons/modals/addUsers'
import InputDefault, {
	InputSelectDefault,
	InputSelectMultiple,
	InputDropdownPrefix,
	InputSwitchDefault,
	InputCheckboxDefault,
	InputSelectDragDrop,
	InputMaskDefault,
} from '@atoms/inputs'
import DropdownSocialsControlled from '@molecules/app/dropdwons/socials'
import RemoveSocialModal from '@organisms/modals/confirms/removeSocial'

interface FormFieldProps {
	item?: any
	items?: any // Замените any на тип вашего объекта поля формы
	//nestedField: any // Замените any на тип вашего объекта вложенного поля формы
	socialsGroup?: any // Замените any на тип вашей группы социальных полей
	inputRef?: any // Замените any на тип вашей ссылки
	register_?: UseFormRegister<FieldValues>
	register: any
	control: any
	errors: any // Замените any на тип вашего объекта ошибок формы
	options?: any // Замените any на тип вашего объекта опций
}

const RenderFields: FC<FormFieldProps> = ({
	item,
	items,
	//nestedField,
	socialsGroup,
	//inputRef,
	register,
	control,
	errors,
	options,
}) => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const { t } = useTranslation()

	const inputRef = useRef(null)

	const renderInput = field => {
		if (field.fieldType === 'InputMaskDefault') {
			return (
				<InputMaskDefault
					name={field.name}
					label={t(field.label)}
					placeholder={t(field.placeholder)}
					register={register}
					control={control}
					error={errors[field.name]?.message || false}
					required={field.required}
					mask={field.mask}
					tooltip={field?.tooltip}
				/>
			)
		}

		if (field.fieldType === 'switch') {
			return (
				<InputSwitchDefault
					name={field.name}
					label={t(field.label)}
					placeholder={t(field.placeholder)}
					register={register}
					control={control}
					error={errors[field.name]?.message || false}
					required={field.required}
				/>
			)
		}

		if (field.fieldType === 'checkbox') {
			return (
				<InputCheckboxDefault
					name={field.name}
					label={t(field.label)}
					placeholder={t(field.placeholder)}
					register={register}
					control={control}
					error={errors[field.name]?.message || false}
					required={field.required}
				/>
			)
		}

		if (field.fieldType === 'selectMultiple') {
			return (
				<InputSelectMultiple
					name={field.name}
					label={t(field.label)}
					placeholder={t(field.placeholder)}
					register={register}
					control={control}
					error={errors[field.name]?.message || false}
					options={options[field.optionKey] || []}
					required={field.required}
					addButton={field.addButton}
					removeButton={field.removeButton}
					companyId={userInfo?.company?.id}
				/>
			)
		}

		if (field.fieldType === 'selectDefault') {
			return (
				<InputSelectDefault
					name={field.name}
					label={t(field.label)}
					placeholder={t(field.placeholder)}
					register={register}
					control={control}
					error={errors[field.name]?.message || false}
					options={options[field.optionKey] || []}
					required={field.required}
					addButton={field.addButton}
					removeButton={field.removeButton}
					companyId={userInfo?.company?.id}
					tooltip={field?.tooltip}
				/>
			)
		}

		if (field.fieldType === 'selectDragDrop') {
			return (
				<InputSelectDragDrop
					name={field.name}
					label={t(field.label)}
					placeholder={t(field.placeholder)}
					register={register}
					control={control}
					error={errors[field.name]?.message || false}
					options={options[field.optionKey] || []}
					required={field.required}
					addButton={field.addButton}
				/>
			)
		}

		if (field.fieldType === 'dropdownPrefix' && socialsGroup) {
			return socialsGroup.fields.map((item: any, index: number) => (
				<InputDropdownPrefix
					label={index === 0 ? t('Company social media profile') : false}
					plus={field.plusAll || socialsGroup.fields.length <= 2}
					actionPlus={() => {
						socialsGroup.fields.length < 4 && socialsGroup.append({ type: 1, name: '' })
					}}
					key={index}
					index={index}
					classes={'edit-profile-form__social-select'}
					dropdownComponent={props => (
						<DropdownSocialsControlled optionKey={field.optionKey} {...props} />
					)}
					value={item ? item?.type : 1}
					register={register}
					control={control}
					options={
						options?.social_media
							? options[field.optionKey].filter((option: any) => option)
							: []
					}
					reference={inputRef}
				>
					{index > 0 && (
						<RemoveSocialModal
							component={IconRemoveInput}
							action={() => {
								socialsGroup.remove(index)
							}}
						/>
					)}
				</InputDropdownPrefix>
			))
		}

		return (
			<InputDefault
				name={field.name}
				label={t(field.label)}
				placeholder={t(field.placeholder)}
				register={register}
				control={control}
				error={errors[field.name]?.message || false}
				required={field.required}
				tooltip={field?.tooltip}
			/>
		)
	}

	return (
		<>
			{items ? (
				items.map(field => (
					<div key={field.name} className={`form__container ${field.className || ''}`}>
						{renderInput(field)}
					</div>
				))
			) : (
				<div key={item.name} className={`form__container ${item.className || ''}`}>
					{renderInput(item)}
				</div>
			)}
		</>
	)
}

export default RenderFields
