import { ConfigProvider } from 'antd'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './components/app/App'
import './i18n'
import { store } from './redux/store'
import { config } from '@assets/theme'
import ErrorFallbackPage from '@pages/app/ERROR'

// serviceWorker disable by default
// import * as serviceWorker from './serviceWorker';

// React.StrictMode add this for find errors app

const rootElement = document.getElementById('root') as HTMLElement
const root = createRoot(rootElement)

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<ErrorBoundary FallbackComponent={ErrorFallbackPage}>
				<ConfigProvider theme={config}>
					<App />
				</ConfigProvider>
			</ErrorBoundary>
		</Provider>
	</BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
