import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import './style.scss'
import IconClose from '@assets/icons/modals/close'
interface InformationHelpProps {
	keyStep: string
	stepsTexts: Record<string, { title: string; description: string }>
	helper: string
}

const InformationHelp: FC<InformationHelpProps> = ({ keyStep, stepsTexts, helper }) => {
	const { t } = useTranslation()
	const [isHelpVisible, setHelpVisible] = useState(true)

	const handleClose = () => {
		setHelpVisible(false)
	}

	useEffect(() => {
		setHelpVisible(true)
	}, [keyStep])

	return (
		<>
			{isHelpVisible && (
				<div className='information-help__wrap'>
					<span className='close' onClick={handleClose}>
						<IconClose />
					</span>
					<div className='information-help__image-wrap'>
						<img src={helper} alt='helper' />
					</div>
					<div className='information-help__text'>
						{keyStep && t(stepsTexts[keyStep]?.title)}
					</div>
					<div className='information-help__description'>
						{keyStep && t(stepsTexts[keyStep]?.description)}
					</div>
				</div>
			)}
		</>
	)
}

export default InformationHelp
