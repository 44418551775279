import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { IconFile } from '@assets/icons/uploaders'
import { columns } from '@configs/tables/files'
const useFilesPopup = (handleModalClose, handleModalOpen, companyId) => {
	let renderNameColumn

	if (companyId) {
		renderNameColumn = (text, record) => (
			<div className='images-name' onClick={() => handleModalOpen(record.index)}>
				{text.indexOf('.pdf') !== -1 ? (
					<IconFile />
				) : (
					<img
						src={record.url}
						alt='file'
						style={{ height: 25, width: 25, borderRadius: 4, marginRight: 8 }}
					/>
				)}
				<div className='link'>{text}</div>
			</div>
		)
	} else {
		renderNameColumn = (text, record) => (
			<div className='images-name'>
				<img
					src={record.url}
					alt='file'
					style={{ height: 25, width: 25, borderRadius: 4, marginRight: 8 }}
				/>
				<Link to={`/lc-companies/company/${companyId}/files/view`}>{text}</Link>
			</div>
		)
	}

	const columnsPopup = useMemo(
		() =>
			columns.map(column => {
				if (column.dataIndex === 'name') {
					return {
						...column,
						render: renderNameColumn,
					}
				}
				return column
			}),
		[]
	)

	return {
		columnsPopup,
	}
}

export default useFilesPopup
