import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import FormName from '@atoms/text/formName'
import FormUserRegistration from '@organisms/forms/userRegistration'
import { appPage, getOptions } from '@redux/reducers/appSlice'
import FormTemplate from '@templates/formTemplate'

const UserRegistration: FC = () => {
	const { page } = useSelector((state: stateType) => state.app)

	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(appPage('user-registration'))
		dispatch(
			getOptions({
				request: {
					source: ['gender'],
				},
			})
		)
	}, [])
	return (
		<>
			<Helmet title={page} />
			<FormTemplate page={page}>
				<FormName label={t('User Profile')} />
				<FormUserRegistration />
			</FormTemplate>
		</>
	)
}

export default UserRegistration
