/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import parse from 'html-react-parser'
import { t } from 'i18next'
import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
// api
// mapping
import { NOTIFICATION } from './notification'
import type { ApiResponse } from '@src/types/api'
import { api } from '@api/lcVerification'
import {
	setCompanies,
	setCompaniesAdvanceSearch,
	setCompany,
	setLoading,
} from '@redux/reducers/lcVerificatorSlice'

export function* sagaGetVerificationCompanyInfo(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.getVerificationCompanyInfo, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			yield put(setCompany(data.company_info))
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaGetVerificationCompaniesList(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.getVerificationCompaniesList, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			yield put(setCompanies(data))
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaFindListVerificationCompanies(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.getVerificationCompanyAdvanceSearch, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		yield put(setCompaniesAdvanceSearch(data.find_result))

		if (data.find_result.length === 0) {
			return NOTIFICATION.error(t('At your search query there are no results. Try again.'))
		}

		if (data.find_result.length > 0) {
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSetVerificationCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.setVerificationCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			yield put(setCompany(data.company_info))
			callback && callback.success()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRemoveVerificationCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.removeVerificationCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			callback && callback.success()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRestoreVerificationCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.restoreVerificationCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			callback && callback.success()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSearchVerificationCompany(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.searchVerificationCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			callback && callback.success(data.search_result)
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSendVerificationCompanyMessage(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.sendVerificationCompanyMessage, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			callback && callback.success()
			yield put(setCompany(data.company_info))
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaEditVerificationCompanyMessage(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.editVerificationCompanyMessage, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			callback && callback.success()
			yield put(setCompany(data.company_info))
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRemoveVerificationCompanyMessage(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.removeVerificationCompanyMessage, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			callback && callback.success()
			yield put(setCompany(data.company_info))
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}
