import React from 'react'
import type { FC } from 'react'

const IconKey: FC<any> = ({ color }: any) => (
	<svg width='24' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7 6.51541C7.00019 5.70721 7.24046 4.91387 7.69577 4.21813C8.15107 3.5224 8.80472 2.94977 9.58853 2.55998C10.3723 2.1702 11.2576 1.97753 12.1519 2.00209C13.0462 2.02664 13.9169 2.26752 14.673 2.69959C15.4292 3.13165 16.0431 3.73909 16.4508 4.45851C16.8585 5.17794 17.045 5.983 16.9908 6.78972C16.9367 7.59643 16.6438 8.37524 16.1429 9.04489C15.642 9.71455 14.9513 10.2505 14.1429 10.5969L14.1429 20.0644L12 22L9.85714 20.0644L11.2857 18.774L9.85714 17.4837L11.2857 16.1933L9.85714 14.9029L11.2857 13.6125L9.85714 12.3221L9.85714 10.5969C9.0021 10.2305 8.27972 9.65253 7.77395 8.93003C7.26819 8.20753 6.99981 7.37022 7 6.51541ZM10.5714 5.22502C10.5714 5.56726 10.7219 5.89547 10.9898 6.13746C11.2578 6.37946 11.6211 6.51541 12 6.51541C12.3789 6.51541 12.7422 6.37946 13.0102 6.13746C13.2781 5.89547 13.4286 5.56726 13.4286 5.22502C13.4286 4.88279 13.2781 4.55458 13.0102 4.31259C12.7422 4.07059 12.3789 3.93464 12 3.93464C11.6211 3.93464 11.2578 4.07059 10.9898 4.31259C10.7219 4.55458 10.5714 4.88279 10.5714 5.22502Z'
			fill={color}
		/>
	</svg>
)

export default IconKey
