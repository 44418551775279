import { Typography } from 'antd'
import React from 'react'
import type { FC } from 'react'

const { Text } = Typography

interface AppTextType {
	text: string
	style: React.CSSProperties
}

const AppText: FC<AppTextType> = ({ text, style }: AppTextType) => (
	<Text style={{ color: '#ffffff', fontWeight: 400, ...style }}>{text}</Text>
)

export default AppText
