/* eslint-disable react/prop-types */
import { Upload, message } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import type { stateType } from '@types'
import type { FC } from 'react'

import type { fileItemType, fileType } from 'types/api'
import IconXError from '@assets/icons/inputs/error'
import { IconUploader } from '@assets/icons/uploaders'
import IconPlus from '@assets/icons/uploaders/plus'

import Attachment from '@molecules/attachment'

import { removeFileDefaultCompany, addFileDefaultCompany } from '@redux/reducers/companySlice'

import { getFileFormat, getFileName, sizeConvertor } from '@tools/helpers'

interface FormVerifyCompanyProps {
	hideButtons?: boolean
	removeProtection?: string
	onResult?: (_id: number) => void
	required?: boolean
}

const FileUpload: FC<FormVerifyCompanyProps> = ({ removeProtection, onResult, required }) => {
	const [fileList, setFileList] = useState<Array<fileItemType>>([])
	const [error, setError] = useState<boolean>(false)
	const { info } = useSelector((state: stateType) => state.user)

	const dir = 'personnel_policy'

	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { handleSubmit } = useForm()

	useEffect(() => {
		if (info?.company?.fileList?.[dir]?.length > 0) {
			setFileList(
				info?.company?.fileList?.[dir].map((item: fileItemType) => ({
					id: item.id,
					name: getFileName(item),
					format: item.format,
					size: sizeConvertor(item.size),
					href: item.url,
					uuid: item.url.split('/')[4],
					status: 'done',
					progress: 100,
					//active_verifer: item?.active_verifer,
				}))
			)
		}
	}, [info?.company?.fileList?.[dir]])

	const handleOnRemoveFile = (id: number) => {
		if (removeProtection) {
			onResult && onResult(id)
			remove(id)
			return
		}
		dispatch(removeFileDefaultCompany({ request: { file_id: id }, callback: () => remove(id) }))
	}

	const remove = (id: number) => {
		const files = fileList.filter((item: any) => item.id !== id)
		setFileList(files)
	}

	const props: any = {
		name: 'some_files',
		accept: '.png,.jpg,.jpeg,.PNG,.JPG,.JPEG,.doc,.docx,.pdf',
		multiple: true,
		showUploadList: false,
		beforeUpload: (file: fileType) => {
			setError(false)
			const format = getFileFormat(file).toLocaleLowerCase()
			const formats = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']

			if (!formats.includes(format)) {
				message.error(t('File with type format is not valid!', { format: format }))
				return false
			}

			if (Math.floor(file.size / 1024 / 1024) > 10) {
				message.error(t('The file cannot be larger than 10MB!'))
				return false
			}

			return file
		},

		onSuccess(files: any) {
			setFileList((prev: any) =>
				prev.map((item: any) => {
					const itemFullName = `${item.name}.${item.format}`
					files &&
						files.forEach((file: any) => {
							if (
								file.name.toLocaleLowerCase() === itemFullName.toLocaleLowerCase()
							) {
								item.href = file.url
								item.status = 'done'
								item.progress = 100
							}
						})
					return item
				})
			)
		},
		onError(err: any) {
			console.error(err)
			message.error(`${t('Something went wrong')}: ${err}`)
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 3,
			format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
		},
		customRequest: async (props: any) => {
			const { file } = props

			const formData = new FormData()
			formData.append('file', file)
			formData.append('company_id', `${info.company.id}`)
			formData.append('type', dir)

			dispatch(
				addFileDefaultCompany({
					request: formData,
					dir: dir,
				})
			)
		},
	}

	const onSubmit = (_data: any) => {
		fileList.length === 0 && setError(true)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form form-company-registration'>
			<div className='form__body'>
				<div className='form__container'>
					<div className='form__container-title'>
						<span>
							{t('Personnel policy')}
							{required && fileList.length === 0 && (
								<span style={{ color: '#FF776F' }}>*</span>
							)}
						</span>
						{fileList.length > 0 && (
							<Upload.Dragger {...props} name='files' className='uploader-icon-zone'>
								<IconPlus />
							</Upload.Dragger>
						)}
					</div>
					{fileList.length === 0 && (
						<>
							<Upload.Dragger
								{...props}
								name='files'
								className={classNames('uploader-zone', {
									['uploader-zone-error']: error,
								})}
							>
								<div className='uploader-zone__text'>
									<IconUploader /> <span>{t('Upload file')}</span>
								</div>
							</Upload.Dragger>
							{error && (
								<div className='uploader-zone__error-text'>
									<IconXError />
									<span>{t('Please upload supporting document')}</span>
								</div>
							)}
						</>
					)}
				</div>
				<div className='form__container'>
					{fileList &&
						fileList.map((file: fileItemType) => (
							<Attachment
								isVerified={file.active_verifer}
								status={removeProtection}
								key={file.id}
								file={file}
								isEditable
								isDownload
								showProgress
								onRemove={() => handleOnRemoveFile(file.id)}
							/>
						))}
				</div>
			</div>
		</form>
	)
}

export default FileUpload
