import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormForgotPasswordData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault from '@atoms/inputs'
import { appPage, setStatus, setSuccess } from '@redux/reducers/appSlice'
import { forgot } from '@redux/reducers/authSlice'
import i18n from '@src/i18n'
import { consts } from '@tools/consts'
import { forgotSchema } from '@tools/shemas'
import './style.scss'

const FormForgotPassword: FC = () => {
	const { loading } = useSelector((state: stateType) => state.auth)
	const schema = useMemo(() => forgotSchema(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormForgotPasswordData>({
		defaultValues: {
			email: '',
		},
		resolver: yupResolver(schema),
	})

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onSubmit = (data: any) => {
		const { email } = data

		let key = 'email'
		let request

		Number(email) ? (key = 'phone') : (key = 'email')

		if (key === 'email') {
			request = {
				[key]: email,
				ref: consts.domain,
			}
		}

		if (key === 'phone') {
			request = {
				[key]: email,
			}
		}

		dispatch(
			forgot({
				request,
				callback: () => {
					if (key === 'email') {
						dispatch(setStatus('success'))
						dispatch(
							setSuccess({
								type: 'forgot',
							})
						)
					}

					if (key === 'phone') {
						dispatch(setStatus('forgot-code'))
						localStorage.setItem('forgot-phone', email)
						navigate('/forgot-by-phone')
					}
				},
			})
		)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form forgot-form'>
			<div className='form__container'>
				<InputDefault
					name={'email'}
					label={t('E-mail or Phone')}
					placeholder={t('E-mail or Phone 2')}
					error={errors.email?.message}
					register={register}
					control={control}
				/>
			</div>
			<div className='form__container'>
				<ButtonDefault
					name={t('Retrieve password')}
					type={'submit'}
					loading={loading}
					disabled={loading}
				/>
				<div className='form__container-links'>
					<Link to='/auth' onClick={() => dispatch(appPage('login'))}>
						{t('Login')}
					</Link>
					<Link to='/auth' onClick={() => dispatch(appPage('registration'))}>
						{t('Registration')}
					</Link>
				</div>
			</div>
		</form>
	)
}

export default FormForgotPassword
