import classNames from 'classnames'
import React from 'react'
import type { FC } from 'react'
import AppBenefits from '@atoms/text/AppBenefits'
import AsideBlock from '@containers/app/aside'
import DropdownLang from '@molecules/app/dropdwons/lang'

import './style.scss'

interface AsideTemplateType {
	children: React.ReactNode
	center?: boolean
	showBenefits?: boolean
	page?: string
}

const AsideTemplate: FC<AsideTemplateType> = ({
	children,
	center,
	showBenefits,
	page,
}: AsideTemplateType) => (
	<div className='aside-template'>
		<DropdownLang />
		<AsideBlock page={page}>{showBenefits && <AppBenefits />}</AsideBlock>
		<div
			className={classNames('aside-template__body', {
				'aside-template__body-center': center,
			})}
		>
			{children}
		</div>
	</div>
)

export default AsideTemplate
