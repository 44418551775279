import { takeEvery } from 'redux-saga/effects'
// sagas
import { sagaGetOptions, sagaSetFile } from './app'
import {
	sagaForgot,
	sagaLogin,
	sagaLogout,
	sagaRegistration,
	sagaReset,
	sagaResetByPhone,
	sagaResetPasswordInside,
	sagaUpdatePassword,
} from './auth'
import {
	sagaAddCompanyDeputy,
	sagaAddCompanyToArchive,
	sagaAddUserToCompany,
	sagaCreateCompany,
	sagaGetAddressesList,
	sagaGetCompanies,
	sagaGetCompany,
	sagaGetCompanyInfo,
	sagaLoginToCompany,
	sagaLogoutFromCompany,
	sagaRecoveryCompany,
	sagaRemoveCompany,
	sagaRestoreCompany,
	sagaRemoveFileCompany,
	sagaRemoveUserFromCompany,
	sagaSearchUserInCompany,
	sagaSetCompanyAdmin,
	sagaSetCompanyStatus,
	sagaSetCompanyInfoContacts,
	sagaSetFileToCompany,
	sagaTransferCompany,
	sagaUpdateCompany,
	sagaUpdateUserToCompany,
	sagaGetCompanyDepartmentAddressList,
	sagaSetCompanyDepartmentAddress,
	sagaAddCompanyDepartment,
	sagaGetCompanyDepartmentList,
	sagaAddFileDefaultCompany,
	sagaRemoveFileDefaultCompany,
	sagaRemoveCompanyDepartment,
	sagaRemoveCompanyDepartmentAddress,
} from './company'
import { sagaCreateDepartment, sagaGetDepartmentInfo, sagaGetDepartments } from './departments'
import {
	sagaEditVerificationCompanyMessage,
	sagaFindListVerificationCompanies,
	sagaGetVerificationCompaniesList,
	sagaGetVerificationCompanyInfo,
	sagaRemoveVerificationCompany,
	sagaRemoveVerificationCompanyMessage,
	sagaRestoreVerificationCompany,
	sagaSearchVerificationCompany,
	sagaSendVerificationCompanyMessage,
	sagaSetVerificationCompany,
} from './lcVerification'
import {
	sagaGetNotificationsUserList,
	sagaUpdateStatusView,
	sagaGetNotificationsUserNew,
} from './notificationsUser'
import {
	sagaUserInfo,
	sagaUserProfileCreate,
	sagaUserProfileUpdate,
	sagaUserSendSms,
	sagaUserConfirmPhone,
} from './user'

export function* sagaWatcher(): Generator {
	// APP
	yield takeEvery('app/getOptions', sagaGetOptions)
	yield takeEvery('app/setFile', sagaSetFile)
	// AUTH
	yield takeEvery('auth/login', sagaLogin)
	yield takeEvery('auth/setLogout', sagaLogout)
	yield takeEvery('auth/registration', sagaRegistration)
	yield takeEvery('auth/updatePassword', sagaUpdatePassword)
	yield takeEvery('auth/reset', sagaReset)
	yield takeEvery('auth/resetPasswordInside', sagaResetPasswordInside)
	yield takeEvery('auth/forgot', sagaForgot)
	yield takeEvery('auth/forgotByPhone', sagaResetByPhone)
	// USER
	yield takeEvery('user/getUserInfo', sagaUserInfo)
	yield takeEvery('user/profileCreate', sagaUserProfileCreate)
	yield takeEvery('user/profileUpdate', sagaUserProfileUpdate)
	yield takeEvery('user/profileConfirmPhone', sagaUserConfirmPhone)
	yield takeEvery('user/sendSmsOnPhone', sagaUserSendSms)
	// yield takeEvery('user/changeStep', sagaUserChangeStep);
	// COMPANY
	yield takeEvery('company/getCompanyInfo', sagaGetCompanyInfo)
	yield takeEvery('company/createCompany', sagaCreateCompany)
	yield takeEvery('company/updateCompany', sagaUpdateCompany)
	yield takeEvery('company/getCompanies', sagaGetCompanies)
	yield takeEvery('company/addCompanyToArchive', sagaAddCompanyToArchive)
	yield takeEvery('company/recoveryCompany', sagaRecoveryCompany)
	yield takeEvery('company/loginToCompany', sagaLoginToCompany)
	yield takeEvery('company/logoutFromCompany', sagaLogoutFromCompany)
	yield takeEvery('company/setFileToCompany', sagaSetFileToCompany)
	yield takeEvery('company/removeFileCompany', sagaRemoveFileCompany)
	yield takeEvery('company/setCompanyStatus', sagaSetCompanyStatus)
	yield takeEvery('company/setCompanyInfoContacts', sagaSetCompanyInfoContacts)
	yield takeEvery('company/removeCompany', sagaRemoveCompany)
	yield takeEvery('company/restoreCompany', sagaRestoreCompany)
	yield takeEvery('company/searchUserInCompany', sagaSearchUserInCompany)
	yield takeEvery('company/addUserToCompany', sagaAddUserToCompany)
	yield takeEvery('company/updateUserToCompany', sagaUpdateUserToCompany)
	yield takeEvery('company/removeUserFromCompany', sagaRemoveUserFromCompany)
	yield takeEvery('company/transferCompany', sagaTransferCompany)

	yield takeEvery('company/getCompany', sagaGetCompany)
	yield takeEvery('company/setCompanyAdmin', sagaSetCompanyAdmin)
	yield takeEvery('company/addCompanyDeputy', sagaAddCompanyDeputy)
	yield takeEvery('addresses/getAddressesList', sagaGetAddressesList)

	yield takeEvery('company/getCompanyDepartmentAddressList', sagaGetCompanyDepartmentAddressList)
	yield takeEvery('company/setCompanyDepartmentAddress', sagaSetCompanyDepartmentAddress)
	yield takeEvery('company/addCompanyDepartment', sagaAddCompanyDepartment)
	yield takeEvery('company/getCompanyDepartmentList', sagaGetCompanyDepartmentList)

	yield takeEvery('company/addFileDefaultCompany', sagaAddFileDefaultCompany)
	yield takeEvery('company/removeFileDefaultCompany', sagaRemoveFileDefaultCompany)

	yield takeEvery('company/removeCompanyDepartment', sagaRemoveCompanyDepartment)
	yield takeEvery('company/removeCompanyDepartmentAddress', sagaRemoveCompanyDepartmentAddress)
	// LC Verificator
	yield takeEvery('lcVerificator/getCompanies', sagaGetVerificationCompaniesList)
	yield takeEvery('lcVerificator/getCompaniesAdvanceSearch', sagaFindListVerificationCompanies)
	yield takeEvery('lcVerificator/getCompany', sagaGetVerificationCompanyInfo)
	yield takeEvery('lcVerificator/setVerification', sagaSetVerificationCompany)
	yield takeEvery('lcVerificator/removeCompany', sagaRemoveVerificationCompany)
	yield takeEvery('lcVerificator/restoreCompany', sagaRestoreVerificationCompany)
	yield takeEvery('lcVerificator/searchCompanies', sagaSearchVerificationCompany)

	yield takeEvery('lcVerificator/sendMessage', sagaSendVerificationCompanyMessage)
	yield takeEvery('lcVerificator/editMessage', sagaEditVerificationCompanyMessage)
	yield takeEvery('lcVerificator/removeMessage', sagaRemoveVerificationCompanyMessage)

	// DEPARTMENT
	yield takeEvery('departments/createDepartment', sagaCreateDepartment)
	yield takeEvery('departments/getDepartments', sagaGetDepartments)
	yield takeEvery('departments/getDepartmentInfo', sagaGetDepartmentInfo)

	// NOTIFICATIONS USER
	yield takeEvery('notificationsUser/getNotificationsUserNew', sagaGetNotificationsUserNew)
	yield takeEvery('notificationsUser/getNotificationsUserList', sagaGetNotificationsUserList)
	yield takeEvery('notificationsUser/updateStatusView', sagaUpdateStatusView)
}
