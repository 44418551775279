// import { getDropDownList } from '@redux/actions/dropDownList-actions';
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { api } from '@api/api'
import { getOptions } from '@redux/reducers/appSlice'
// api

export const useAddItemToDropDownList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { token } = useSelector(state => state.auth)

	return async (listName, name) => {
		if (listName && name) {
			try {
				await api.addDropDownList({ token, listName, itemName: name })
				await dispatch(getOptions(token))
			} catch (e) {
				console.error(e)
				message.error(e.message)
			}
		} else {
			message.warning(t('Error'))
		}
	}
}
