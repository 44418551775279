import { Button, Form, Input, message } from 'antd'
import { func, number } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanyInfo } from '@api/asyncHelpers'
import AppText from '@atoms/text/AppText'
import table_1 from '@image/table-1.svg'
import AppSelect from '@molecules/app/select'
import FormAddNewDepartment from '@organisms/forms/setDepartment/FormSetDepartment'
import StaticFormModal from '@organisms/modals/staticForm'
import { getCompanyInfoAction } from '@redux/actions/companyActions'
import { addNewDepartment } from '@redux/actions/departmentsActions'
import { getDropDownList } from '@redux/actions/dropDownListActions'
import { rules } from '@tools/messages'

FormSetCompanyStructure.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetCompanyStructure({ changeStep, step }) {
	const dispatch = useDispatch()

	const { lists, company } = useSelector(state => ({
		lists: state.dropDownList.lists,
		company: state.company.info,
	}))
	const [form] = Form.useForm()
	const [visible, setVisible] = useState(false)

	const onFinish = async () => {
		const key = 'set-company-structure'
		const formValues = form.getFieldsValue()
		message.loading({ content: t('Loading'), key })
		try {
			const json = await setCompanyInfo(formValues)
			if (json.ok) {
				message.success({ content: t('Company structure updated'), key })
				await dispatch(getCompanyInfoAction())
				changeStep(step + 1)
			} else {
				message.error({ content: t('Error'), key })
			}
		} catch (e) {
			message.error({ content: e.message, key })
		}
	}
	const handleOnAddDepartments = async values => {
		try {
			await addNewDepartment(values)
			await dispatch(getDropDownList())
			const formValues = form.getFieldsValue()
			const departments = Array.isArray(formValues?.tl_lists)
				? [...formValues.tl_lists, values.name]
				: [values.name]
			form.setFields([{ name: 'tl_lists', value: departments }])
		} catch (err) {
			console.error(err)
			message.error(err)
		}
	}

	useEffect(() => {
		if (company) {
			form.setFields([
				{ name: 's_workers', value: company?.s_workers?.name },
				{
					name: 's_organizational_structure',
					value: company?.s_organizational_structure?.name,
				},
				{ name: 'manager_surname', value: company?.manager_surname },
				{ name: 'manager_first_name', value: company?.manager_first_name },
				{ name: 'manager_middle_name', value: company?.manager_middle_name },
				{ name: 'tl_lists', value: company?.tl_lists?.map(item => item.name) },
			])
		}
	}, [company])

	return (
		<>
			<StaticFormModal
				visible={visible}
				changeVisible={setVisible}
				title={t('New department')}
				text={t('Fill in department information')}
				component={
					<FormAddNewDepartment
						changeVisible={() => setVisible(!visible)}
						onSubmit={handleOnAddDepartments}
					/>
				}
				img={table_1}
				onSubmit={() => setVisible(false)}
			/>
			<Form
				name='set-company-structure'
				layout='vertical'
				size='large'
				onFinish={onFinish}
				form={form}
				className='form'
			>
				<AppText
					text={t('Structure')}
					style={{
						color: '#20272E',
						fontSize: 18,
						fontWeight: 700,
						marginBottom: 24,
						lineHeight: 1.5715,
						display: 'block',
					}}
				/>

				<Form.Item noStyle>
					<div className='form__row'>
						<Form.Item
							label={t('Directors name')}
							name='manager_surname'
							rules={[{ required: true, message: t('Enter Last Name!') }]}
						>
							<Input className='form__input' placeholder={t('Last name')} />
						</Form.Item>

						<Form.Item
							label={t('Name of Director')}
							name='manager_first_name'
							rules={[{ required: true, message: t('Enter your name!') }]}
						>
							<Input className='form__input' placeholder={t('Name')} />
						</Form.Item>

						<Form.Item label={t('Directors patronymic')} name='manager_middle_name'>
							<Input className='form__input' placeholder={t('Patronymic')} />
						</Form.Item>
					</div>
				</Form.Item>

				<Form.Item
					rules={[{ required: true, message: rules.required }]}
					name='s_workers'
					label={t('Number of employees in the company')}
				>
					<AppSelect
						options={lists?.s_workers}
						placeholder={t('The number of employees')}
					/>
				</Form.Item>

				<Form.Item
					rules={[{ required: true, message: rules.required }]}
					name='tl_lists'
					label={t('Names of departments / Divisions / Directions')}
				>
					<AppSelect
						mode='multiple'
						placeholder={t('Financial / Front-End / Marketing and Advertising')}
						options={lists?.tl_lists}
						isHaveFooter
						isHaveModal
						modalBtnTxt={t('Add department')}
						changeVisible={() => setVisible(prev => !prev)}
					/>
				</Form.Item>

				<Form.Item
					rules={[{ required: true, message: rules.required }]}
					name='s_organizational_structure'
					label={t('Organizational structure')}
				>
					<AppSelect
						options={lists?.s_organizational_structure}
						placeholder={t('Holacracy')}
					/>
				</Form.Item>

				<Form.Item>
					<div className='form__row _between'>
						<Button
							type='default'
							htmlType='button'
							shape='round'
							onClick={() => changeStep(step - 1)}
						>
							{t('Back')}
						</Button>
						<Button type='primary' shape='round' htmlType='submit'>
							{t('Save and continue')}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</>
	)
}

export default FormSetCompanyStructure
