import { locale } from 'dayjs'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Routes, Route } from 'react-router-dom'

// import { ErrorBoundary } from 'react-error-boundary';
// import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

import AuthCompanyWrapper from './AuthCompanyWrapper'
import AuthWrapper from './AuthWrapper'
import CreateCompanyWrapper from './CreateCompanyWrapper'
import LcVerificationWrapper from './LcVerificationWrapper'
import PageWrapper from './PageWrapper'
import SettingCompanyWrapper from './SettingCompanyWrapper'
import UserRegistrationWrapper from './UserRegistrationWrapper'
import type { FC } from 'react'
import LazyComponent from '@app/LazyComponent'
import ViewFilesModal from '@organisms/modals/viewFile/ViewFilesModal'
import Page404 from '@pages/app/404'
import Authentication from '@pages/authentication'

import PageConfirmPhone from '@pages/authentication/confirmPhone'
import Forgot from '@pages/authentication/forgot'
import PageForgotByPhone from '@pages/authentication/forgotByPhone'
import RecoveryPass from '@pages/authentication/recovery'
import CompaniesProfile from '@pages/companies'
import CompanyCreate from '@pages/companies/create'
import CompanySetting from '@pages/companies/setting'
import CompanyVerification from '@pages/companies/verification'
import CompanyRegistration from '@pages/companyRegisrtration'
import CompanyRegistrationOther from '@pages/companyRegisrtration/other'
import VerifyCompany from '@pages/companyVerify'
import AddDeputy from '@pages/deputy/add'
import LcVerificationCompanies from '@pages/lcVerification/companies'
import LcVerificationCompany from '@pages/lcVerification/company'
import MyCompany from '@pages/myCompany'
import Settings from '@pages/settings'
import UserRegistration from '@pages/user/registration'
import AddVacancy from '@pages/vacancy/add'

// import 'antd/dist/antd.min.css';
// import 'antd/dist/antd.less';
import '@assets/main.scss'
import 'dayjs/locale/uk'
import 'dayjs/locale/ru'

import { setAppLang } from '@redux/reducers/appSlice'
import i18n from '@src/i18n'
import useLangGet from '@tools/hooks/useLangSet'
import useServiceWebSocket from '@tools/hooks/useServiceWebSocket'
import { menuLcVerificationLists, menuLists } from '@tools/menu'
import { getPageComponent } from '@tools/utils/getPages'
// lazy
// const Authentication = React.lazy(() => import('@pages/authentication'));
// const Forgot = React.lazy(() => import('@pages/authentication/forgot'));

const App: FC = () => {
	useServiceWebSocket()

	const dispatch = useDispatch()
	const lang = useLangGet()

	// TODO example connect to ES
	//const url = `http://api.prof.world/v2.0/sse/userOnline/?userToken=${localStorage.getItem('token')}`;
	//const es = new EventSource(url, {});

	useEffect(() => {
		i18n.changeLanguage(lang)
		locale(lang)
		dispatch(setAppLang(lang))
	}, [])

	return (
		<Routes>
			<Route
				path='/auth'
				element={
					<AuthWrapper>
						<Authentication />
					</AuthWrapper>
				}
			/>
			<Route
				path='/forgot'
				element={
					<AuthWrapper>
						<Forgot />
					</AuthWrapper>
				}
			/>
			<Route
				path='/forgot-by-phone'
				element={
					<AuthWrapper>
						<PageForgotByPhone />
					</AuthWrapper>
				}
			/>
			<Route path='/confirm-phone' element={<PageConfirmPhone />} />
			<Route
				path='/recovery-password'
				element={
					<AuthWrapper>
						<RecoveryPass />
					</AuthWrapper>
				}
			/>
			<Route
				path='/companies'
				element={
					<AuthCompanyWrapper>
						<CompaniesProfile />
					</AuthCompanyWrapper>
				}
			/>
			<Route
				path='/companies/:sort'
				element={
					<AuthCompanyWrapper>
						<CompaniesProfile />
					</AuthCompanyWrapper>
				}
			/>
			<Route
				path='/company-create'
				element={
					<CreateCompanyWrapper showTemplate>
						<CompanyCreate />
					</CreateCompanyWrapper>
				}
			/>
			<Route
				path='/company-create/:tab'
				element={
					<CreateCompanyWrapper showTemplate>
						<CompanyVerification />
					</CreateCompanyWrapper>
				}
			/>
			<Route
				path='/company-registration-main'
				element={
					<PageWrapper>
						<CompanyRegistration />
					</PageWrapper>
				}
			/>
			<Route
				path='/company-setting'
				element={
					<SettingCompanyWrapper showTemplate>
						<CompanySetting />
					</SettingCompanyWrapper>
				}
			/>
			<Route
				path='/company-setting/:tab'
				element={
					<SettingCompanyWrapper showTemplate>
						<CompanySetting />
					</SettingCompanyWrapper>
				}
			/>
			<Route
				path='/profile-create'
				element={
					<UserRegistrationWrapper>
						<UserRegistration />
					</UserRegistrationWrapper>
				}
			/>
			<Route
				path='/company-verify'
				element={
					<PageWrapper>
						<VerifyCompany />
					</PageWrapper>
				}
			/>
			<Route
				path='/add-vacancy'
				element={
					<PageWrapper>
						<AddVacancy />
					</PageWrapper>
				}
			/>
			<Route
				path='/add-deputy'
				element={
					<PageWrapper>
						<AddDeputy />
					</PageWrapper>
				}
			/>
			<Route
				path='/company-registration'
				element={
					<PageWrapper>
						<CompanyRegistrationOther />
					</PageWrapper>
				}
			/>

			{RouterMenuList()}
			{RouterLcVerificationMenuList()}

			<Route
				path={'/my-company/:tab'}
				element={
					<PageWrapper showTemplate>
						<MyCompany />
					</PageWrapper>
				}
			/>

			<Route
				path={'/settings/:tab'}
				element={
					<PageWrapper showTemplate>
						<Settings />
					</PageWrapper>
				}
			/>

			<Route
				path={'/lc-companies'}
				element={
					<LcVerificationWrapper showTemplate showHeader>
						<LcVerificationCompanies />
					</LcVerificationWrapper>
				}
			/>

			<Route
				path={'/lc-companies/:tab'}
				element={
					<LcVerificationWrapper showTemplate showHeader>
						<LcVerificationCompanies />
					</LcVerificationWrapper>
				}
			/>

			<Route
				path={'/lc-companies/company/:id'}
				element={
					<LcVerificationWrapper showTemplate>
						<LcVerificationCompany />
					</LcVerificationWrapper>
				}
			/>

			<Route path={'/lc-companies/company/:id/files/view'} element={<ViewFilesModal />} />

			<Route
				path={'/lc-companies/company/:id/:tab_company'}
				element={
					<LcVerificationWrapper showTemplate>
						<LcVerificationCompany />
					</LcVerificationWrapper>
				}
			/>

			<Route path='*' element={<Page404 />} />
		</Routes>
	)
}

export default App

const RouterMenuList = () =>
	menuLists.map(({ key, link, component }) => (
		<Route
			key={key}
			path={link}
			element={
				<PageWrapper showTemplate>
					<LazyComponent
						key={component}
						fallback={null}
						getComponent={() => getPageComponent(component)}
					/>
				</PageWrapper>
			}
		/>
	))

const RouterLcVerificationMenuList = () =>
	menuLcVerificationLists.map(({ key, link, component }) => (
		<Route
			key={key}
			path={link}
			element={
				<LcVerificationWrapper showTemplate showHeader>
					<LazyComponent
						key={component}
						fallback={null}
						getComponent={() => getPageComponent(component)}
					/>
				</LcVerificationWrapper>
			}
		/>
	))
