import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import { stepsTexts } from '@configs/texts'
import AsideFormBlock from '@containers/app/aside/form'
import helper from '@image/icons/helper.svg'
import StepList from '@molecules/navigations/companies/stepList'
import InformationHelp from '@organisms/modals/informationHelp'

import { getOptions } from '@redux/reducers/appSlice'

import './style.scss'

const list = [
	{
		index: 1,
		id: 'general',
		name: 'General',
		link: '/company-setting/general',
	},
	{
		index: 2,
		id: 'contacts',
		name: 'Contacts',
		link: '/company-setting/contacts',
	},
	{
		index: 3,
		id: 'structure',
		name: 'Structure',
		link: '/company-setting/structure',
	},
	{
		index: 4,
		id: 'management',
		name: 'Personnel Management',
		link: '/company-setting/management',
	},
	{
		index: 5,
		id: 'mission',
		name: 'Mission and values',
		link: '/company-setting/mission',
	},
]

interface AsideTemplateType {
	children: React.ReactNode
	center?: boolean
	classes?: string
}

const CompaniesSetting: FC<AsideTemplateType> = ({
	children,
	center,
	classes,
}: AsideTemplateType) => {
	const { tab } = useParams()
	const { status } = useSelector((state: stateType) => state.app)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			getOptions({
				request: {
					source: ['employees', 'activities'],
				},
			})
		)
	}, [])

	return (
		<div className={classNames('form-template', classes)}>
			<AsideFormBlock page={status}>
				<StepList page={tab} list={list} />
				{tab && <InformationHelp keyStep={tab} stepsTexts={stepsTexts} helper={helper} />}
			</AsideFormBlock>
			<div
				className={classNames('form-template__body', {
					'form-template__body-center': center,
				})}
			>
				{children}
			</div>
		</div>
	)
}

export default CompaniesSetting
