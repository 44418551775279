import React from 'react'
import { useSelector } from 'react-redux'
import type { FC } from 'react'
import { IconUsersSetting } from '@assets/icons/nav/usersTable'
import TabsProfileContainer from '@containers/settings/tabsProfileContainer'
import PageBreadcrumb from '@organisms/breadcrumb'
import HeaderProfile from '@organisms/profile/header'

import './style.scss'

interface SettingsContainerType {
	children?: React.ReactNode
}

const SettingsContainer: FC<SettingsContainerType> = ({} /*children */ : SettingsContainerType) => {
	const { info } = useSelector((state: any) => state.user)
	return (
		<div className='settings-container'>
			{!info?.is_verifier && <PageBreadcrumb icon={IconUsersSetting} />}
			<HeaderProfile />
			<TabsProfileContainer />
		</div>
	)
}

export default SettingsContainer
