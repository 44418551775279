import type { AxiosResponse } from 'axios'
import { get, post } from '@api/api_helper'

export const api = {
	getOptions: async (token: string): Promise<AxiosResponse> => {
		try {
			return await get(`/get_lists_create_company/?token=${token}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getTestFormOptions: async (token: string): Promise<AxiosResponse> => {
		try {
			return await get(`/get_list_items/?token=${token}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getCiti: async (request: any): Promise<AxiosResponse> => {
		const { token, name } = request
		try {
			return await get(`/get_list_cities/?token=${token}&citi=${name}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getStreets: async (request: any): Promise<AxiosResponse> => {
		const { token, citiId, name } = request
		try {
			return await get(`/get_list_street/?token=${token}&id_city=${citiId}&street=${name}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	addDropDownList: async (request: any): Promise<AxiosResponse> => {
		const { token, listName, itemName } = request
		try {
			return await post(`/add_field_table/?token=${token}&name=${itemName}&list=${listName}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},
}
