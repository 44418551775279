import React from 'react'
import type { FC } from 'react'

const IconSpinner: FC = () => (
	<svg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M3 12C3 6.6 6.951 3 11.8254 3C15.7872 3 18.984 5.1015 20.1 8.4M21 12C21 17.4 17.049 21 12.1746 21C8.2119 21 5.016 18.8985 3.9 15.6'
			stroke='#8D97B0'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M16.5 7.5002L20.1 8.4002L21 4.8002M7.5 16.5002L3.9 15.6002L3 19.2002'
			stroke='#8D97B0'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default IconSpinner
