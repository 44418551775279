import React from 'react'
import type { FC } from 'react'
import closeModal from '@image/icons/close_modal.svg'
interface CloseModalIconType {
	onClick?: () => void
	srcImg?: string
}

const CloseModalIcon: FC<CloseModalIconType> = ({ onClick, srcImg }: CloseModalIconType) => (
	<div className='close_modal' onClick={() => onClick && onClick()}>
		<img className='close_modal__icon' src={srcImg ? srcImg : closeModal} alt='close_modal' />
	</div>
)

export default CloseModalIcon
