import { Button, Form } from 'antd'
import { func, number } from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import { setNewPositionsInfo } from '@redux/actions/positionsActions'
import { checkFormValues, getCurrentValues } from '@tools/helpers'
import { useAddItemToDropDownList } from '@tools/hooks/useAddItemToDropDownList'

FormSetPositionPersonalInformation.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetPositionPersonalInformation({ changeStep, step }) {
	const [form] = Form.useForm()

	const dispatch = useDispatch()
	const {
		lists,
		positions: { info },
	} = useSelector(state => ({
		lists: state.dropDownList.lists,
		positions: state.positions,
	}))

	const addItemToDropDownList = useAddItemToDropDownList()

	const selectOption = [
		{ id: 0, name: 'Нет' },
		{ id: 1, name: 'Да' },
	]

	const onFinish = async () => {
		const values = checkFormValues(form.getFieldsValue())
		values.hobbies = getCurrentValues(values.hobbies, lists.s_hobbies)
		values.personal_qualities = getCurrentValues(
			values.personal_qualities,
			lists.s_personal_qualities
		)
		try {
			dispatch(setNewPositionsInfo(values))
			changeStep(step + 1)
		} catch (err) {
			console.error(err)
			throw err
		}
	}
	useEffect(() => {
		if (info) {
			const { hobbies, military_service, personal_qualities } = info
			form.setFields([
				{ name: 'military_service', value: military_service ? military_service : null },
				{ name: 'hobbies', value: hobbies?.length ? hobbies.map(({ name }) => name) : [] },
				{
					name: 'personal_qualities',
					value: personal_qualities?.length
						? personal_qualities.map(({ name }) => name)
						: [],
				},
			])
		}
	}, [info])

	return (
		<Form
			name='set-position-personal_info'
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
			className='form'
		>
			<AppText
				text={t('Personal information')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Form.Item label={t('Military service')} name='military_service'>
				<AppSelect options={selectOption} placeholder={t('Select from list')} />
			</Form.Item>

			<Form.Item label={t('Interests, hobbies')} name='hobbies'>
				<AppSelect
					options={lists?.s_hobbies}
					placeholder={t('Write or select from the list')}
					isHaveFooter
					mode='multiple'
					onSubmit={async name => await addItemToDropDownList('s_hobbies', name)}
				/>
			</Form.Item>

			<Form.Item label={t('Personal qualities')} name='personal_qualities'>
				<AppSelect
					options={lists?.s_personal_qualities}
					placeholder={t('Write or select from the list')}
					mode='multiple'
					isHaveFooter
					onSubmit={async name =>
						await addItemToDropDownList('s_personal_qualities', name)
					}
				/>
			</Form.Item>

			<Form.Item>
				<div className='form__row _between'>
					<Button
						type='default'
						htmlType='button'
						shape='round'
						onClick={() => changeStep(step - 1)}
					>
						{t('Back')}
					</Button>
					<Button type='primary' shape='round' htmlType='submit'>
						{t('Save and continue')}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default FormSetPositionPersonalInformation
