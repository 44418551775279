import React, { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'

interface LazyComponentProps {
	key: string
	fallback: React.ReactNode | null
	getComponent: () => Promise<React.FC> | null
}

const LazyComponent: React.FC<LazyComponentProps> = ({ fallback, getComponent }) => {
	const { t } = useTranslation()
	const ComponentPromise = getComponent()

	if (!ComponentPromise) {
		return <>{t('Component not found')}</>
	}

	const Lazy = lazy(() => ComponentPromise.then(module => ({ default: module })))

	return (
		<Suspense fallback={fallback}>
			<Lazy />
		</Suspense>
	)
}

export default LazyComponent
