import React from 'react'
import type { FC } from 'react'
import BtnGoBack from '@atoms/buttons/goBackButton'
import './style.scss'

const FormFooter: FC = () => (
	<div className='form-footer'>
		<BtnGoBack />
	</div>
)

export default FormFooter
