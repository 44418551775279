import { Col, Modal, Row } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import CloseModalIcon from '@assets/icons/CloseModalIcon'
import deleteIcon from '@image/icons/remove.svg'

const DetailsModal: FC<any> = ({ data, removeItem, visible, changeVisible, title }: any) => {
	const { t } = useTranslation()
	useEffect(() => {
		if (!data.length) {
			changeVisible()
		}
	}, [data])

	return (
		<Modal
			open={visible}
			title={title}
			width='80vw'
			destroyOnClose
			okText={t('Apply')}
			onOk={() => changeVisible(!visible)}
			cancelButtonProps={{ style: { display: 'none' } }}
			styles={{ body: { height: '75vh', overflow: 'auto' } }}
			closable
			centered
			closeIcon={<CloseModalIcon onClick={() => changeVisible(!visible)} />}
		>
			<Row className='position-details' gutter={[0, 8]}>
				{data.map((el: any) => (
					<Col span={24} key={el.id}>
						<Row wrap={false} gutter={[8, 0]}>
							<Col className={'position-details__item '} flex={1}>
								<span className={'position-details__name'}>{el.name}</span>
								<img
									className='position-details__remove'
									src={deleteIcon}
									alt='delete'
									onClick={() => removeItem(el.id)}
								/>
							</Col>
						</Row>
					</Col>
				))}
			</Row>
		</Modal>
	)
}

export default DetailsModal
