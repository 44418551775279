import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'

import './style.scss'

import type { FormCodeData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault from '@atoms/inputs'
import FormFooter from '@atoms/text/formFooter'
import TimeCountdown from '@molecules/timeCountdown'
import { forgotByPhone } from '@redux/reducers/authSlice'
import i18n from '@src/i18n'
import { codeSchema } from '@tools/shemas'

const FormCode: FC = () => {
	const { loading } = useSelector((state: stateType) => state.auth)
	const schema = useMemo(() => codeSchema(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			code: undefined,
		},
		resolver: yupResolver(schema),
	})

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onSubmit = async (data: FormCodeData) => {
		const { code } = data
		const request = {
			phone: localStorage.getItem('forgot-phone'),
			confirm_phone_code: Number(code),
		}

		dispatch(
			forgotByPhone({
				request,
				callback: () => {
					navigate('/recovery-password')
				},
			})
		)
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className='form code-form'>
				<div className='form__container'>
					<InputDefault
						name={'code'}
						label={t('SMS code')}
						placeholder={t('Enter code')}
						error={errors.code?.message}
						register={register}
						control={control}
						type={'number'}
					/>
					<div className='form__container-links'>
						<TimeCountdown />
					</div>
				</div>
				<div className='form__container'>
					<ButtonDefault
						name={t('Confirm')}
						type={'submit'}
						loading={loading}
						disabled={loading}
					/>
				</div>
			</form>
			<FormFooter />
		</>
	)
}

export default FormCode
