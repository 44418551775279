import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import CompanyListItem from './item'

import type { stateType } from '@types'
import type { FC } from 'react'
import SkeletonCompanies from '@atoms/skeletons/companies'
import EmptySuccessCompanyModal from '@organisms/modals/confirms/emptySuccessCompany'

import './style.scss'

export interface CompanyType {
	id: number
	name: string
	role: {
		id: number
		name: string
	}
	status: {
		id: number
		name: string
	}
	is_del?: number
}

const CompaniesList: FC = () => {
	const { list, loading, loadingLogin } = useSelector((state: stateType) => state.company)
	const { info } = useSelector((state: stateType) => state.user)

	const [success, setSuccess] = useState<false | { title: string; desc: string }>(false)
	const [initID, setInitID] = useState<false | number>(false)

	return (
		<div className='companies-list'>
			{success && (
				<EmptySuccessCompanyModal
					title={success.title}
					desc={success.desc}
					setSuccess={setSuccess}
				/>
			)}
			{loading && <SkeletonCompanies />}
			{list &&
				list.map((item: CompanyType, index: number) => (
					<CompanyListItem
						key={index}
						item={item}
						loadingLogin={loadingLogin}
						initID={initID}
						setInitID={setInitID}
						index={index}
						setSuccess={setSuccess}
						info={info}
					/>
				))}
		</div>
	)
}

export default CompaniesList
