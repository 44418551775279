import type React from 'react'

export function getIconComponent(key: string): Promise<React.FC> | null {
	switch (key) {
		case 'CalendarIcon':
			return import('@assets/icons/menu/CalendarIcon').then(module => module.default)
		case 'GameIcon':
			return import('@assets/icons/menu/GameIcon').then(module => module.default)
		case 'HomeIcon':
			return import('@assets/icons/menu/HomeIcon').then(module => module.default)
		case 'HrAdministrationIcon':
			return import('@assets/icons/menu/HrAdministrationIcon').then(module => module.default)
		case 'HrAnalyticsIcon':
			return import('@assets/icons/menu/HrAnalyticsIcon').then(module => module.default)
		case 'MyCompanyIcon':
			return import('@assets/icons/menu/MyCompanyIcon').then(module => module.default)
		case 'PerformersEvaluationIcon':
			return import('@assets/icons/menu/PerformersEvaluationIcon').then(
				module => module.default
			)
		case 'PersonnelEvaluationIcon':
			return import('@assets/icons/menu/PersonnelEvaluationIcon').then(
				module => module.default
			)
		case 'PersonnelSelectionIcon':
			return import('@assets/icons/menu/PersonnelSelectionIcon').then(
				module => module.default
			)
		case 'SettingsIcon':
			return import('@assets/icons/menu/SettingsIcon').then(module => module.default)
		case 'StaffAdaptationIcon':
			return import('@assets/icons/menu/StaffAdaptationIcon').then(module => module.default)
		case 'StaffTrainingIcon':
			return import('@assets/icons/menu/StaffTrainingIcon').then(module => module.default)
		case 'SurveysIcon':
			return import('@assets/icons/menu/SurveysIcon').then(module => module.default)
		case 'TasksIcon':
			return import('@assets/icons/menu/TasksIcon').then(module => module.default)
		case 'TestIcon':
			return import('@assets/icons/menu/TestIcon').then(module => module.default)
		default:
			return null
	}
}
