/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { notification } from 'antd'
import parse from 'html-react-parser'
import { t } from 'i18next'
import _ from 'lodash'
import { call, put, select } from 'redux-saga/effects'

import { NOTIFICATION } from './notification'
import type { ApiResponse } from '@src/types/api'
import { api } from '@api/auth/index'
import { setStatus, setSuccess } from '@redux/reducers/appSlice'
import { setError, setLoading } from '@redux/reducers/authSlice'

export function* sagaLogin(action) {
	const { payload } = action
	const { request, callback } = payload
	const getAuth = state => state.auth
	const { session } = yield select(getAuth)
	yield put(setLoading(true))
	yield put(setError(null))
	try {
		const response = yield call(api.login, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			notification.destroy('login-error')
			return NOTIFICATION.error(data.msg, '', 'login-error')
		}

		if (!_.isEmpty(data.user_data)) {
			if (!data.user_data.is_confirm_email) {
				yield put(setStatus('success'))
				yield put(
					setSuccess({
						type: 'registration',
					})
				)
			}

			if (data.user_data.token) {
				!session && sessionStorage.setItem('token', `${data.user_data.token}`)
				session && localStorage.setItem('token', `${data.user_data.token}`)
				callback && callback()
			}
			localStorage.setItem('token', `${data.user_data.token}`)
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaLogout(action) {
	const { payload } = action
	const { callback } = payload
	const getUserInfo = state => state.user.info
	const { token } = yield select(getUserInfo)
	yield put(setLoading(true))
	try {
		if (!token) {
			return callback()
		}

		if (token) {
			const response = yield call(api.logout, token)
			const data: ApiResponse = response.data

			if (!_.isObject(data)) {
				return NOTIFICATION.error(parse(data).toString())
			}

			if (data.error) {
				notification.destroy('login-error')
				return NOTIFICATION.error(data.msg, '', 'login-error')
			}

			if (data.status === 'success') {
				callback && callback()
			}
		}
	} catch (e) {
		yield NOTIFICATION.error(e && parse(e))
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRegistration(action) {
	const { payload } = action
	const {
		/*callback*/
	} = payload
	yield put(setLoading(true))
	try {
		const response = yield call(api.registration, payload)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(setStatus('success'))
			yield put(
				setSuccess({
					type: 'registration',
				})
			)
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaUpdatePassword(action) {
	const { payload } = action
	const {
		/* callback */
	} = payload
	yield put(setLoading(true))
	try {
		const response = yield call(api.updateVerifierPassword, payload)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		yield NOTIFICATION.success(t('The password is successfully updated'))
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaReset(action) {
	const { payload } = action
	const { request, callback } = payload
	yield put(setLoading(true))
	try {
		const response = yield call(api.reset, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(setStatus('success'))
			yield put(
				setSuccess({
					type: 'reset',
				})
			)
			callback && callback()
			localStorage.removeItem('recovery-token')
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaResetPasswordInside(action) {
	const { payload } = action
	const { request, callback } = payload
	yield put(setLoading(true))
	try {
		const response = yield call(api.reset, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			callback && callback()
			localStorage.removeItem('recovery-token')
		}
		yield NOTIFICATION.success(t('The password is successfully updated'))
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaResetByPhone(action) {
	const { payload } = action
	const { request, callback } = payload
	yield put(setLoading(true))
	try {
		const response = yield call(api.resetByPhone, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			localStorage.setItem('recovery-token', data.user_data.reset_password_token)
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaForgot(action) {
	const { payload } = action
	const { request, callback } = payload
	yield put(setLoading(true))
	try {
		const response = yield call(api.forgot, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}
