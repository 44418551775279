export function getPageComponent(key: string): Promise<React.FC> | null {
	switch (key) {
		case 'Culture':
			return import('@pages/culture').then(module => module.default)
		case 'Home':
			return import('@pages/home').then(module => module.default)
		case 'HrAdministration':
			return import('@pages/hrAdministration').then(module => module.default)
		case 'HrAnalytics':
			return import('@pages/hrAnalytics').then(module => module.default)
		case 'LcVerificationCompanies':
			return import('@pages/lcVerification/companies').then(module => module.default)
		case 'LcVerificationSettings':
			return import('@pages/lcVerification/settings').then(module => module.default)
		case 'MyCalendar':
			return import('@pages/myCalendar').then(module => module.default)
		case 'MyCompany':
			return import('@pages/myCompany').then(module => module.default)
		case 'Performens':
			return import('@pages/performens').then(module => module.default)
		case 'Personne':
			return import('@pages/personne').then(module => module.default)
		case 'Settings':
			return import('@pages/settings').then(module => module.default)
		case 'StaffAdaptation':
			return import('@pages/staffAdaptation').then(module => module.default)
		case 'StaffAssessment':
			return import('@pages/staffAssessment').then(module => module.default)
		case 'StaffTraning':
			return import('@pages/staffTraning').then(module => module.default)
		case 'Surveys':
			return import('@pages/surveys').then(module => module.default)
		case 'Tasks':
			return import('@pages/tasks').then(module => module.default)
		case 'Testing':
			return import('@pages/testing').then(module => module.default)
		default:
			return null
	}
}
