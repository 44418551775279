import { Dropdown } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import logo from '@image/logo.svg'
import useLogout from '@tools/hooks/useLogout'

import '../style.scss'

const DropdwonProfile: FC = () => {
	const [open, setOpen] = useState<boolean>(false)

	const { logout } = useLogout()
	const { t } = useTranslation()

	const items: MenuProps['items'] = [
		{
			key: '1',
			label: <div className='dropdown-overlay__item'>{t('Personal account')}</div>,
		},
		{
			key: '2',
			label: <div className='dropdown-overlay__item'>{t('My Companies')}</div>,
		},
		{
			key: '3',
			label: (
				<div className='dropdown-overlay__item' onClick={() => logout()}>
					{t('Exit')}
				</div>
			),
			onClick: () => logout(),
		},
	]

	return (
		<Dropdown
			menu={{ items }}
			className={'dropdown'}
			trigger={['click']}
			open={open}
			onOpenChange={(data: any) => setOpen(data)}
		>
			<div
				className='dropdown__triger'
				onClick={e => {
					e.preventDefault()
					setOpen(prev => !!prev)
				}}
			>
				<img className='header-logo' src={logo} alt='logo' />
			</div>
		</Dropdown>
	)
}

export default DropdwonProfile
