import { Dropdown } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { stateType } from '@types'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import { IconArrowDropdown } from '@assets/icons/btn/arrow'
import { IconFilter } from '@assets/icons/statsInfo'
import { getCompanies, setFilter } from '@redux/reducers/lcVerificatorSlice'

import '../style.scss'

const DropdownDateFilter: FC<{ rootClassName?: string }> = ({ rootClassName }) => {
	const { filter } = useSelector((state: stateType) => state.lcVerificator)
	const [open, setOpen] = useState<boolean>(false)
	const [value, setValue] = useState<{ id?: number; name?: string }>({})

	const { page, options } = useSelector((state: stateType) => state.app)
	const dispatch = useDispatch()

	const action = (item: any) => {
		dispatch(setFilter(item.id))
		setValue(item)
		setOpen(false)

		dispatch(
			getCompanies({
				request: {
					type: `companies${page.charAt(0).toUpperCase() + page.slice(1)}`,
					filter: item.id,
				},
			})
		)
	}

	useEffect(() => {
		if (options?.filter_companies) {
			const result = _.find(options?.filter_companies, { id: filter })
			setValue(result)
		}
	}, [filter, options?.filter_companies])

	const items: MenuProps['items'] = []

	if (options?.filter_companies) {
		items.push(
			...options?.filter_companies.map(item => ({
				key: item.id,
				label: (
					<div key={item.id} className='dropdown-overlay__item'>
						{item.name}
					</div>
				),
				onClick: () => action(item),
			}))
		)
	}

	return (
		<Dropdown
			rootClassName={rootClassName}
			menu={{ items }}
			className={'dropdown'}
			trigger={['click']}
			placement='bottomRight'
			open={open}
			onOpenChange={(data: any) => setOpen(data)}
		>
			<div
				className='dropdown__triger'
				onClick={e => {
					e.preventDefault()
					setOpen(true)
				}}
			>
				<div className='dropdown__prefix-icon'>
					<IconFilter />
				</div>
				<span className='dropdown__name'>{value?.name}</span>
				<div
					className={classNames(
						'dropdown__icon',
						{ ['animation-dropdown-up']: open },
						{ ['animation-dropdown-back']: !open }
					)}
				>
					<div className='dropdown__icon-arrow'>
						<IconArrowDropdown />
					</div>
				</div>
			</div>
		</Dropdown>
	)
}

export default DropdownDateFilter
