import { Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import {
	IconEditProfile,
	IconEmptyAvatar,
	IconProfileCompany,
	IconProfileConfirmPhone,
	IconProfileLocale,
	IconProfileMessage,
	IconProfileNotConfirmPhone,
	IconTooltipInfo,
} from '@assets/icons/nav/profileHeader'
import DropdownDefault from '@molecules/app/dropdwons/default'
import SocialList from '@molecules/lists/social'
import TooltipConfirmPhoneBody from '@molecules/tooltips/confirmPhone'
import TooltipJobTitleBody from '@molecules/tooltips/jobTitle'
import PhoneProfileModal from '@organisms/modals/confirms/phoneProfile'
import AddStatusModal from '@organisms/modals/settings/addStatus'
import EditProfileModal from '@organisms/modals/settings/editProfile'
import { setFile } from '@redux/reducers/appSlice'

import { getAvatarUrl } from '@tools/helpFunctions'
import { createPhoneMask, getAge } from '@tools/helpers'

import './style.scss'

const HeaderProfile: FC = () => {
	const [modalPhone, setModalPhone] = useState(false)
	const { info } = useSelector((state: stateType) => state.user)
	const {
		country,
		name,
		birth_date,
		lname,
		email,
		phone,
		company,
		office_presence_status,
		otherPhones,
		otherEmails,
		is_confirm_phone,
		files,
		socials,
		is_verifier,
	} = info
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isVerifier = is_verifier === 1
	const StatusButton = ({ onClick }: any) => (
		<div className='header-profile__status' onClick={onClick}>
			<Tooltip
				trigger={'hover'}
				title={t('Office presence status')}
				placement='bottom'
				className='default-tooltip'
				overlayClassName={'default-tooltip-overlay'}
			>
				<span
					className={classNames('header-profile__status-text', {
						[`header-profile__status-text-${office_presence_status?.id}`]:
							office_presence_status,
					})}
				>
					{office_presence_status ? office_presence_status?.name : t('Select status')}
				</span>
			</Tooltip>
		</div>
	)

	const uploadAvatar = (event: any) => {
		const file = event.target.files[0]

		if (file) {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('type', 'avatar')

			dispatch(
				setFile({
					type: 'user',
					request: formData,
					callback: {
						success: () => console.log('success'),
						error: () => console.log('error'),
					},
				})
			)
		}
	}

	return (
		<>
			{modalPhone && (
				<PhoneProfileModal phone={phone} open={true} setModalPhone={setModalPhone} />
			)}
			<div className='header-profile'>
				<div className='header-profile__avatar'>
					<input
						type='file'
						id='file'
						name='file'
						onChange={uploadAvatar}
						className='header-profile__avatar-uploader'
					/>
					{files?.avatar && (
						<img
							className='header-profile__avatar'
							src={getAvatarUrl(files?.avatar, '110')}
							alt={'avatar'}
						/>
					)}
					<IconEmptyAvatar />
				</div>
				<div className='header-profile__body'>
					<div className='header-profile__row'>
						<div className='header-profile__column'>
							<div className='header-profile__name'>
								<span className='header-profile__name-title'>
									{name} {lname}
								</span>
								<span className='header-profile__name-desc'>
									{birth_date && `(${getAge(birth_date)} ${t('years')})`}
								</span>
								<EditProfileModal
									component={IconEditProfile}
									action={() => {
										//dispatch(addCompanyToArchive({ request: { company_id: id }, callback: () => callback(false) }));
									}}
								/>
							</div>
							<div className='header-profile__role'>
								{isVerifier ? (
									t('Verifier')
								) : (
									<>
										<span>{t('Position not assigned')} </span>
										<div className='header-profile__role-icon'>
											<Tooltip
												trigger={'hover'}
												placement='bottom'
												title={<TooltipJobTitleBody />}
												className='default-tooltip'
												overlayClassName={
													'default-tooltip-overlay default-tooltip-overlay-size-294'
												}
											>
												<></>
												<IconTooltipInfo />
											</Tooltip>
										</div>
									</>
								)}
							</div>
						</div>
						{!isVerifier && (
							<div className='header-profile__column'>
								<AddStatusModal
									component={StatusButton}
									action={() => {
										//dispatch(addCompanyToArchive({ request: { company_id: id }, callback: () => callback(false) }));
									}}
								/>
							</div>
						)}
					</div>
					<div className='header-profile__row'>
						<div>
							<DropdownDefault
								icon={<IconProfileCompany />}
								title={isVerifier ? `${t('LLC')} «ProfWorld»` : company?.name}
								list={[]}
							/>
						</div>
						<div>
							<DropdownDefault
								icon={<IconProfileMessage />}
								title={email}
								list={otherEmails ? otherEmails : []}
							/>
						</div>
						<div>
							{phone && (
								<DropdownDefault
									icon={
										is_confirm_phone ? (
											<IconProfileConfirmPhone />
										) : (
											<Tooltip
												trigger={'hover'}
												title={
													<TooltipConfirmPhoneBody
														onClick={() => setModalPhone(true)}
													/>
												}
												placement='bottom'
												className='default-tooltip'
												overlayClassName={'default-tooltip-overlay'}
											>
												<IconProfileNotConfirmPhone />
											</Tooltip>
										)
									}
									title={phone && `+38 ${createPhoneMask(phone)}`}
									list={otherPhones ? otherPhones : []}
								/>
							)}
						</div>
						<div>
							<DropdownDefault
								icon={<IconProfileLocale />}
								title={country}
								list={[]}
							/>
						</div>
						<div>
							<SocialList socials={socials} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default HeaderProfile
