import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'

import './style.scss'
import AdvancedSearch from '@organisms/forms/advancedSearch'
import CompaniesAdvanceSearchTable from '@organisms/tables/lcVerificationCompanies/companiesAdvanceSearchTable'

interface SearchModalType {
	component?: any
	action?: any
	active?: boolean
	open: boolean
	setOpen: any
	defaultValue?: string
	defaultResult?: any
}

const SearchModal: FC<SearchModalType> = ({
	component: Component,
	open,
	setOpen,
	defaultValue,
}: SearchModalType) => {
	const [visible, setVisible] = useState(false)

	const [activeTab, setActiveTab] = useState('Filters')
	const { t } = useTranslation()

	useEffect(() => {
		setVisible(open)
	}, [open])

	const onCancel = () => {
		setVisible(false)
		setOpen(false)
		setActiveTab('Filters')
	}

	return (
		<>
			{Component && (
				<Component
					onClick={() => {
						!open && setVisible(true)
					}}
				/>
			)}

			<Modal
				rootClassName='modal-search__wrap'
				className='modal-search'
				open={visible}
				footer={null}
				closable={true}
				styles={{ body: { padding: 0 } }}
				onCancel={onCancel}
				destroyOnClose={true}
			>
				<div className='modal-search__body'>
					<div
						className={
							activeTab === 'Filters'
								? 'modal-search__wrap'
								: 'none modal-search__wrap'
						}
					>
						<div className='modal-search__title'>{t('Advanced Search')}</div>
						<AdvancedSearch setActiveTab={setActiveTab} defaultValue={defaultValue} />
					</div>
					<div
						className={
							activeTab === 'Result'
								? 'modal-search__wrap'
								: 'none modal-search__wrap'
						}
					>
						<div className='modal-search__title'>{t('Searching results')}</div>
						<CompaniesAdvanceSearchTable setActiveTab={setActiveTab} />
					</div>
				</div>
			</Modal>
		</>
	)
}

export default SearchModal
