import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from './Loading'
import type { stateType } from '@types'
import type { FC } from 'react'
import Success from '@pages/success'
import UserRegistration from '@pages/user/registration'
import { setStatus } from '@redux/reducers/appSlice'
import { getUserInfo } from '@redux/reducers/userSlice'
import CompaniesCreate from '@templates/formTemplate/companiesCreate'
import { getStoreToken } from '@tools/helpFunctions'

interface PageWrapperType {
	children: React.ReactNode
	showTemplate?: boolean
}

const CreateCompanyWrapper: FC<PageWrapperType> = ({ children, showTemplate }: PageWrapperType) => {
	const { status, success } = useSelector((state: stateType) => state.app)
	const { info } = useSelector((state: stateType) => state.user)
	const token = getStoreToken()

	const dispatch: any = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		token && _.isEmpty(info) && dispatch(getUserInfo('2'))
		!token && dispatch(setStatus('auth'))
	}, [])

	useEffect(() => {
		switch (status) {
			case 'main':
				return navigate('/')

			case 'auth':
				return navigate('/auth')

			case 'companies-profile':
				return navigate('/companies')

			case 'company-create':
				return navigate('/company-create')
		}
	}, [status])

	const controller = () => {
		switch (status) {
			case 'main':
				return navigate('/')

			case 'auth':
				return navigate('/auth')

			case 'success':
				return <Success type={success.type} />

			case 'loading':
				return <Loading />

			case 'profile-create':
				return <UserRegistration />

			case 'companies-profile':
			case 'company-create':
			case 'company-verification':
				return showTemplate ? (
					<CompaniesCreate>{children}</CompaniesCreate>
				) : (
					<>{children}</>
				)
		}
	}
	return <>{controller()}</>
}

export default CreateCompanyWrapper
