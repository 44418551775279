import classNames from 'classnames'
import './style.scss'
import React from 'react'
import type { FC } from 'react'

import AppText from '@atoms/text/AppText'
import AppTitle from '@atoms/text/AppTitle'

import useAsideHook from '@tools/hooks/useAsideHook'

interface AuthenticationAsideType {
	page?: string
	background?: boolean
	children?: React.ReactNode
}

const AsideFormBlock: FC<AuthenticationAsideType> = ({
	page,
	background,
	children,
}: AuthenticationAsideType) => {
	const titles = useAsideHook(page)
	const { title, subTitle } = titles

	return (
		<div className={classNames('form-sider', { background })}>
			<AppTitle
				title={title}
				level={2}
				style={{
					paddingBottom: 12,
					fontSize: 24,
					lineHeight: 1,
					marginBottom: 0,
					letterSpacing: '0.02em',
				}}
			/>
			<AppText text={subTitle} style={{ fontSize: 16, paddingBottom: 48 }} />
			{children}
		</div>
	)
}

export default AsideFormBlock
