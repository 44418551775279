import { message } from 'antd'
import _ from 'lodash'
import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { AddSocialData } from 'types/api'
import IconDelete from '@assets/icons/btn/delete'
import { IconPlus } from '@assets/icons/modals/addUsers'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault, { InputSelectDefault } from '@atoms/inputs'
import { profileUpdate } from '@redux/reducers/userSlice'
import './style.scss'

const AddSocialForm: FC<any> = ({ setVisible }: any) => {
	const [loading, setLoading] = useState<any>(false)
	const { info } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const { socials } = info

	//const schema = useMemo(() => editUserSchema(), [i18n.language]);

	const {
		register,
		control,
		handleSubmit,
		formState: {
			/*errors*/
		},
	} = useForm<AddSocialData>({
		defaultValues: {
			socials: socials ? socials : [],
		},
	})

	const socialsGroup = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'socials', // unique name for your Field Array
	})

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const onSubmit = async (data: any) => {
		dispatch(
			profileUpdate({
				request: data,
				callback: {
					success: () => {
						setLoading(false)
						setVisible(false)
						message.success({ content: t('Data updated successfully'), duration: 4 })
					},
					error: () => {
						setLoading(false)
					},
				},
			})
		)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form add-social-form'>
			<div className='add-social-form__body'>
				<div className='add-social-form__container'>
					<div className='add-social-form__container-label'>
						{t('Social Network')}{' '}
						<IconPlus onClick={() => socialsGroup.append({ type: '', name: '' })} />
					</div>
					{socialsGroup.fields.map((field, index) => (
						<div className='add-social-form__row' key={field.id}>
							<InputSelectDefault
								classes={'add-social-form__select'}
								name={`socials[${index}].type`}
								optionKey={'social_media'}
								label={t('Social network')}
								placeholder={t('Social network')}
								register={register}
								control={control}
								options={
									options?.social_media ? _.filter(options?.social_media) : []
								}
								required
							/>
							<div className='add-social-form__link'>
								<InputDefault
									name={`socials[${index}].name`}
									label={t('Link')}
									placeholder={t('Link')}
									register={register}
									control={control}
									required
								/>
								<div
									className='add-social-form__remove'
									onClick={() => socialsGroup.remove(index)}
								>
									<IconDelete />
									<span>{t('Delete')}</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className='add-social-form__footer'>
				<span className='add-social-form__btn-text' onClick={() => setVisible(false)}>
					{t('Cancellation')}
				</span>
				<ButtonDefault
					name={t('Save')}
					type={'submit'}
					loading={loading}
					disabled={loading}
				/>
			</div>
		</form>
	)
}

export default AddSocialForm
