/* eslint-disable react/no-find-dom-node */
import { DatePicker, Select, Switch, Tooltip, Checkbox } from 'antd'
import localeEN from 'antd/es/date-picker/locale/en_US'
import localeRu from 'antd/es/date-picker/locale/ru_RU'
import localeUA from 'antd/es/date-picker/locale/uk_UA'
import classNames from 'classnames'
import dayjs from 'dayjs'
import IMask from 'imask'
import { isObject } from 'lodash'
import React, { useState, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactInputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'
import IconXError from '@assets/icons/inputs/error'
import CalendarIcon from '@assets/icons/menu/CalendarIcon'
import { IconPlus } from '@assets/icons/modals/addUsers'
import { IconTooltipInfo } from '@assets/icons/nav/profileHeader'
import SelectRemoveIconMini from '@assets/icons/select/SelectRemoveIconMini'
import IconDropDownArrow from '@assets/icons/select/dropDownArrow'
import { IconDelete } from '@assets/icons/uploaders'
import TagsSelect from '@atoms/tags'
import TagsSelectMultiple from '@atoms/tags/selectMultiple'
import TooltipStructureInfoBody from '@molecules/tooltips/structureInfo'
import AddAddress from '@organisms/modals/confirms/addAddress'
import AddDepartment from '@organisms/modals/confirms/addDepartment'
import RemoveFileConfirmation from '@organisms/modals/confirms/removeFileConfirmation'
import {
	getCompanyDepartmentList,
	getCompanyDepartmentAddressList,
	removeCompanyDepartment,
	removeCompanyDepartmentAddress,
} from '@redux/reducers/companySlice'
import { NOTIFICATION } from '@redux/sagas/notification'
import './styles/style.scss'

import 'dayjs/locale/uk'
import 'dayjs/locale/ru'

interface InputDefaultType {
	reference?: any
	name?: string
	label?: any
	classes?: string
	error?: any
	type?: string
	placeholder?: any
	register?: any
	control?: any
	rules?: any
	required?: boolean
	defaultValue?: string
	disabled?: boolean
	children?: React.ReactNode
	maxLength?: number
	actionPlus?: any
	plus?: any
	onChange?: any
	value?: any
	allowClear?: any
	field?: any
	index?: any
	options?: any
	dropdownComponent?: any
	addButton?: string
	removeButton?: string
	tooltip?: string
}

const InputDefault: FC<InputDefaultType> = ({
	reference,
	name,
	label,
	classes,
	error,
	type,
	placeholder,
	//register,
	rules,
	control,
	defaultValue,
	disabled,
	required,
	children,
	maxLength,
	actionPlus,
	plus,
	onChange,
	value,
	tooltip,
}: InputDefaultType) => {
	const { t } = useTranslation()
	const switchController = () => {
		const onChangeFnc = (e: any, onChange: any) => {
			if (maxLength) {
				maxLength >= e.target.value.length &&
					onChange(parseInt(e.target.value.toString().replace('+', '').replace('-', '')))
				return
			}
			onChange(e.target.value)
		}
		if (control) {
			return (
				<Controller
					control={control}
					name={name ? name : ''}
					rules={rules}
					render={({
						field: { onChange, /*onBlur , ref*/ value, name },
						fieldState: {
							/* invalid, isTouched, isDirty */
						},
						//formState,
					}) => (
						<div className={classNames('input-default', classes)}>
							{label && (
								<label>
									{label}
									{required && <div className='input-default__required'>*</div>}
									{tooltip && (
										<Tooltip
											trigger={'hover'}
											placement='top'
											title={t(tooltip)}
											className='default-tooltip'
											overlayClassName={
												'default-tooltip-overlay default-tooltip-overlay-size-576'
											}
										>
											<></>
											<IconTooltipInfo />
										</Tooltip>
									)}
									{plus && (
										<div
											className='input-default__plus'
											onClick={() => actionPlus()}
										>
											<IconPlus />
										</div>
									)}
								</label>
							)}
							<div className='input-default__input'>
								<input
									ref={reference}
									className={classNames('', { 'input-default__error': error })}
									onChange={(e: any) => onChangeFnc(e, onChange)}
									name={name}
									type={type}
									placeholder={placeholder}
									value={value || ''}
									disabled={disabled ? disabled : false}
								/>
								<div className='input-default__icons'>{children}</div>

								{error && (
									<div
										className={classNames('input-default__message', {
											['animation-opacity']: error,
										})}
									>
										<div className='input-default__message-icon'>
											<IconXError />
										</div>
										<span>{error}</span>
									</div>
								)}
							</div>
						</div>
					)}
				/>
			)
		}

		return (
			<div className={classNames('input-default', classes)}>
				<label>{label}</label>
				<div className='input-default__input'>
					<input
						ref={reference}
						className={classNames('', { 'input-default__error': error })}
						name={name}
						type={type}
						onChange={onChange}
						value={value}
						placeholder={placeholder}
						defaultValue={defaultValue}
						disabled={disabled ? disabled : false}
					/>
					<div className='input-default__icons'>{children}</div>

					{error && (
						<div
							className={classNames('input-default__message', {
								['animation-opacity']: error,
							})}
						>
							<div className='input-default__message-icon'>
								<IconXError />
							</div>
							<span>{error}</span>
						</div>
					)}
				</div>
			</div>
		)
	}
	return <>{switchController()}</>
}

export default InputDefault

export const InputDatePicker: FC<any> = ({
	name,
	label,
	classes,
	error,
	//	type,
	placeholder,
	//register,
	rules,
	control,
	// defaultValue,
	// disabled,
	required,
	children,
	allowClear,
}: any) => {
	const lang = localStorage.getItem('lang')
	const DATE_FORMAT = 'DD/MM/YYYY'
	const MASKED = IMask.createMask({
		mask: Date,
		pattern: DATE_FORMAT,
		blocks: {
			DD: { mask: IMask.MaskedRange, from: 1, to: 31, maxLength: 2 },
			MM: { mask: IMask.MaskedRange, from: 1, to: 12, maxLength: 2 },
			YYYY: { mask: IMask.MaskedRange, from: 1900, to: Number.MAX_VALUE },
		},
		format: (date: Date) => dayjs(date).format(DATE_FORMAT),
		parse: (date: string) => dayjs(date, DATE_FORMAT).toDate(),
		// optional interval options
		// min: new Date(2000, 0, 1),
		// max: new Date(2020, 0, 1),
		autofix: false,
		lazy: false,
		overwrite: true,
	})

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({
				field: { onChange, value /* onBlur,  name, ref */ },
				fieldState: {
					/* invalid, isTouched, isDirty */
				},
				//formState,
			}) => (
				<div className={classNames('input-default', classes)}>
					<label>
						{label}
						{required && <div className='input-default__required'>*</div>}
					</label>
					<div className='input-default__input'>
						<DatePicker
							locale={lang === 'ru' ? localeRu : lang === 'ua' ? localeUA : localeEN}
							allowClear={allowClear}
							className={classNames('', { 'input-default__error': error })}
							placeholder={placeholder}
							format={DATE_FORMAT}
							defaultValue={value ? dayjs(value, 'YYYY-MM-DD') : undefined}
							onChange={onChange}
							suffixIcon={<CalendarIcon />}
							onKeyDown={(event: any) => {
								// event.target.value = datePickerformat(event.target.value);
								const input = event.target as HTMLInputElement
								MASKED.resolve(input.value)
							}}
						/>
						<div className='input-default__icons'>{children}</div>

						{error && (
							<div
								className={classNames('input-default__message', {
									['animation-opacity']: error,
								})}
							>
								<div className='input-default__message-icon'>
									<IconXError />
								</div>
								<span>{error}</span>
							</div>
						)}
					</div>
				</div>
			)}
		/>
	)
}

export const InputMaskDefault: FC<any> = ({
	name,
	label,
	classes,
	error,
	// type,
	// register,
	rules,
	control,

	disabled,
	required,
	children,
	plus,
	actionPlus,
	mask,
	placeholder,
	tooltip,
}: any) => {
	const { t } = useTranslation()
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({
				field: { onChange, value /*onBlur,  name, ref */ },
				fieldState: {
					/* invalid, isTouched, isDirty */
				},
				//formState,
			}) => (
				<div className={classNames('input-default', classes)}>
					<label>
						{label}
						{required && <div className='input-default__required'>*</div>}
						{tooltip && (
							<Tooltip
								trigger={'hover'}
								placement='top'
								title={t(tooltip)}
								className='default-tooltip'
								overlayClassName={
									'default-tooltip-overlay default-tooltip-overlay-size-576'
								}
							>
								<></>
								<IconTooltipInfo />
							</Tooltip>
						)}
						{plus && (
							<div className='input-default__plus' onClick={() => actionPlus()}>
								<IconPlus />
							</div>
						)}
					</label>
					<div className='input-default__input'>
						<ReactInputMask
							mask={mask ? mask : '+38 (099) 999 99 99'}
							className={classNames('', { 'input-default__error': error })}
							placeholder={placeholder ? placeholder : '+38 (097) 777 77 77'}
							maskChar={' '}
							value={value || ''}
							onChange={onChange}
							disabled={disabled}
						/>
						<div className='input-default__icons'>{children}</div>

						{error && (
							<div
								className={classNames('input-default__message', {
									['animation-opacity']: error,
								})}
							>
								<div className='input-default__message-icon'>
									<IconXError />
								</div>
								<span>{error}</span>
							</div>
						)}
					</div>
				</div>
			)}
		/>
	)
}

export const InputSelectDragDrop: FC<any> = ({
	name,
	label,
	classes,
	error,
	placeholder,
	rules,
	control,
	children,
	options,
	required,
	allowClear,
}: any) => {
	const { t } = useTranslation()
	const [dataTags, setDataTags] = useState([])
	const selectRef = useRef<any>(null)
	const handleButtonClick = () => {
		if (selectRef.current) selectRef.current.blur()
	}

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: {} }) => (
				<div
					className={classNames(
						'input-default input-select-dropdown input-select-multiple',
						classes
					)}
				>
					<label>
						{label}
						{required && <div className='input-default__required'>*</div>}
					</label>
					<div className='input-default__input'>
						<Select
							ref={selectRef}
							virtual={false}
							id={`id_${name}`}
							mode='multiple'
							className={classNames('form-select', {
								'form-select__error': error,
							})}
							popupClassName='form-dropdown select-custom-dropdown'
							//placeholder={placeholder}
							bordered={false}
							style={{ width: '100%', height: '40px' }}
							onChange={(value: any) => {
								onChange(value)
								setDataTags(value)
							}}
							options={options.map(({ id, name }: any) => ({
								label: name,
								value: id,
								key: id,
							}))}
							tagRender={() => <></>}
							optionRender={option => (
								<div className='ant-checkbox-group'>
									<div className='checkbox-content'>
										<span className='ant-checkbox-inner' />
										<span className='ant-checkbox-label'>
											{option.data.label}
										</span>
									</div>
								</div>
							)}
							dropdownRender={content => (
								<div className='select-custom'>
									<div className='select-custom__content'>{content}</div>
									<div className='select-custom__button-bottom'>
										<span
											className='select-custom__cancel'
											onClick={() => onChange([])}
										>
											{t('Reset everything')}
										</span>
										<span
											className='select-custom__submit'
											onClick={handleButtonClick}
										>
											{t('Apply')}
										</span>
									</div>
								</div>
							)}
							allowClear={allowClear}
							suffixIcon={<IconDropDownArrow />}
							value={value}
							removeIcon={<SelectRemoveIconMini />}
						/>
						<div className='placeholder-select'>{placeholder}</div>
						{value || (dataTags && dataTags.length >= 1) ? (
							<TagsSelect
								dataTags={value || dataTags}
								options={options}
								onOrderChange={value => onChange(value)}
							/>
						) : (
							<></>
						)}
						<div className='input-default__icons'>{children}</div>
						{error && (
							<div
								className={classNames('input-default__message', {
									['animation-opacity']: error,
								})}
							>
								<div className='input-default__message-icon'>
									<IconXError />
								</div>
								<span>{error}</span>
							</div>
						)}
					</div>
				</div>
			)}
		/>
	)
}

export const InputSelectDefault: FC<any> = ({
	name,
	label,
	classes,
	error,
	// type,
	placeholder,
	// register,
	rules,
	control,
	// defaultValue,
	// disabled,
	children,
	options,
	required,
	allowClear,
	addButton,
	removeButton,
	companyId,
	tooltip,
}: any) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const handleRemoveClick = id => {
		if (!companyId) return
		if (removeButton === 'address') {
			dispatch(
				removeCompanyDepartmentAddress({
					request: {
						address_id: id,
					},
					callback: () => {
						NOTIFICATION.success(t('Address successfully deleted'))
						dispatch(
							getCompanyDepartmentAddressList({
								request: { company_id: companyId },
								callback: {},
							})
						)
					},
				})
			)
		}
		if (removeButton === 'department') {
			dispatch(
				removeCompanyDepartment({
					request: {
						department_id: id,
					},
					callback: () => {
						NOTIFICATION.success(t('Department successfully deleted'))
						dispatch(
							getCompanyDepartmentList({
								request: { company_id: companyId },
								callback: {},
							})
						)
					},
				})
			)
		}
	}
	if (control) {
		return (
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({
					field: { onChange, value /* onBlur, name, ref*/ },
					fieldState: {
						/*invalid, isTouched, isDirty */
					},
					//formState,
				}) => (
					<div className={classNames('input-default', classes)}>
						<label>
							{label}
							{required && <div className='input-default__required'>*</div>}
							{tooltip && tooltip === 'tooltip structure' && (
								<Tooltip
									trigger={'hover'}
									placement='bottom'
									title={<TooltipStructureInfoBody />}
									className='default-tooltip'
									overlayClassName={
										'default-tooltip-overlay default-tooltip-overlay-size-576'
									}
								>
									<></>
									<IconTooltipInfo />
								</Tooltip>
							)}
						</label>
						<div className='input-default__input'>
							<Select
								className={classNames('form-select', {
									'form-select__error': error,
								})}
								virtual={false}
								popupClassName='form-dropdown'
								placeholder={placeholder}
								bordered={false}
								style={{ width: '100%', height: '40px' }}
								onChange={(value: any) => {
									onChange(value)
								}}
								optionRender={item => (
									<div className='option-content'>
										{item.label}
										{removeButton && (
											<span
												className='remove-icon'
												onClick={e => {
													e.stopPropagation()
												}}
											>
												<RemoveFileConfirmation
													component={IconDelete}
													action={() => {
														handleRemoveClick(item.value)
														item.value === value && onChange(undefined)
													}}
													status={removeButton}
													submit={true}
												/>
											</span>
										)}
									</div>
								)}
								options={options.map(({ id, name }: any) => ({
									key: id,
									label: name,
									value: id,
								}))}
								allowClear={allowClear}
								suffixIcon={<IconDropDownArrow />}
								value={value}
								dropdownRender={content => (
									<div className='select-custom'>
										<div className='select-custom__content'>{content}</div>
										{addButton === 'address' ? <AddAddress /> : ''}
									</div>
								)}
							/>
							<div className='input-default__icons'>{children}</div>

							{error && (
								<div
									className={classNames('input-default__message', {
										['animation-opacity']: error,
									})}
								>
									<div className='input-default__message-icon'>
										<IconXError />
									</div>
									<span>{error}</span>
								</div>
							)}
						</div>
					</div>
				)}
			/>
		)
	}

	return (
		<div className={classNames('input-default', classes)}>
			<label>{label}</label>
			<div className='input-default__input'>
				<Select
					className={classNames('form-select', { 'form-select__error': error })}
					popupClassName='form-dropdown'
					placeholder={placeholder}
					bordered={false}
					style={{ width: '100%', height: '40px' }}
					options={options.map(({ id, name }: any) => ({
						key: id,
						label: name,
						value: id,
					}))}
					allowClear
					suffixIcon={<IconDropDownArrow />}
				/>
				<div className='input-default__icons'>{children}</div>

				{error && (
					<div
						className={classNames('input-default__message', {
							['animation-opacity']: error,
						})}
					>
						<div className='input-default__message-icon'>
							<IconXError />
						</div>
						<span>{error}</span>
					</div>
				)}
			</div>
		</div>
	)
}

export const InputSwitchDefault: FC<any> = ({
	name,
	label,
	classes,
	error,
	// type,
	// register,
	rules,
	control,
	// defaultValue,
	// disabled,
	children,
	required,
}: any) => {
	const { t } = useTranslation()

	if (control) {
		return (
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({
					field: { onChange, value /* onBlur, name, ref*/ },
					fieldState: {
						/*invalid, isTouched, isDirty */
					},
					//formState,
				}) => (
					<div
						className={classNames('input-default input-default__switch-wrap', classes)}
					>
						<label>
							{label}
							{required && <div className='input-default__required'>*</div>}
						</label>
						<div className='input-default__switch'>
							<div className='ant-switch-wrap'>
								<div className='ant-switch-label'>{value ? t('Yes') : t('No')}</div>
								<Switch
									checked={value ? true : false}
									onChange={value => {
										onChange(value)
									}}
								/>
							</div>
							<div className='input-default__icons'>{children}</div>

							{error && (
								<div
									className={classNames('input-default__message', {
										['animation-opacity']: error,
									})}
								>
									<div className='input-default__message-icon'>
										<IconXError />
									</div>
									<span>{error}</span>
								</div>
							)}
						</div>
					</div>
				)}
			/>
		)
	}

	return <></>
}

export const InputCheckboxDefault: FC<any> = ({
	name,
	label,
	classes,
	error,
	rules,
	control,
	children,
	required,
}: any) => {
	const {} = useTranslation()

	if (control) {
		return (
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({ field: { onChange, value }, fieldState: {} }) => (
					<div
						className={classNames(
							'input-default input-default__checkbox-wrap',
							classes
						)}
					>
						{/* <label>
							{label}
							{required && <div className='input-default__required'>*</div>}
						</label> */}
						<div className='input-default__checkbox'>
							<div className='ant-checkbox-wrap'>
								<Checkbox
									checked={value ? true : false}
									onChange={value => {
										onChange(value)
									}}
								>
									{label}
									{required && <div className='input-default__required'>*</div>}
								</Checkbox>
							</div>
							<div className='input-default__icons'>{children}</div>
							{error && (
								<div
									className={classNames('input-default__message', {
										['animation-opacity']: error,
									})}
								>
									<div className='input-default__message-icon'>
										<IconXError />
									</div>
									<span>{error}</span>
								</div>
							)}
						</div>
					</div>
				)}
			/>
		)
	}

	return <></>
}

export const InputSelectMultiple: FC<any> = ({
	name,
	label,
	classes,
	error,
	// type,
	placeholder,
	// register,
	rules,
	control,
	// defaultValue,
	// disabled,
	children,
	options,
	required,
	allowClear,
	addButton,
	removeButton,
	companyId,
}: any) => {
	const { t } = useTranslation()
	const [dataTags, setDataTags] = useState([])
	const selectRef = useRef<any>(null)
	const dispatch = useDispatch()
	const handleRemoveClick = id => {
		if (!companyId) return
		if (removeButton === 'address') {
			dispatch(
				removeCompanyDepartmentAddress({
					request: {
						address_id: id,
					},
					callback: () => {
						NOTIFICATION.success(t('Address successfully deleted'))
						dispatch(
							getCompanyDepartmentAddressList({
								request: { company_id: companyId },
								callback: {},
							})
						)
					},
				})
			)
		}
		if (removeButton === 'department') {
			dispatch(
				removeCompanyDepartment({
					request: {
						department_id: id,
					},
					callback: () => {
						NOTIFICATION.success(t('Department successfully deleted'))
						dispatch(
							getCompanyDepartmentList({
								request: { company_id: companyId },
								callback: {},
							})
						)
					},
				})
			)
		}
	}

	if (control) {
		const handleButtonClick = () => {
			if (selectRef.current) selectRef.current.blur()
		}
		return (
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({
					field: { onChange, value /* onBlur, name, ref*/ },
					fieldState: {
						/*invalid, isTouched, isDirty */
					},
					//formState,
				}) => (
					<div
						className={classNames(
							'input-default input-select-multiple input-select-custom',
							classes
						)}
					>
						<label>
							{label}
							{required && <div className='input-default__required'>*</div>}
						</label>
						<div className='input-default__input'>
							<Select
								ref={selectRef}
								virtual={false}
								id={`id_${name}`}
								mode='multiple'
								className={classNames('form-select', {
									'form-select__error': error,
								})}
								popupClassName='form-dropdown select-custom-dropdown'
								//placeholder={placeholder}
								bordered={false}
								style={{ width: '100%', height: '40px' }}
								onChange={(value: any) => {
									onChange(value)
									setDataTags(value)
								}}
								options={options.map(({ id, name }: any) => ({
									key: id,
									label: name,
									value: id,
								}))}
								tagRender={() => <></>}
								optionRender={option => (
									<div className='ant-checkbox-group'>
										<div className='checkbox-content'>
											<span className='ant-checkbox-inner' />
											<span className='ant-checkbox-label'>
												{option.data.label}
											</span>
										</div>
										{removeButton && option?.data?.value.startsWith('p_') && (
											<span
												className='remove-icon'
												onClick={e => {
													e.stopPropagation()
												}}
											>
												<RemoveFileConfirmation
													component={IconDelete}
													action={() => {
														handleRemoveClick(option.data.value)
														if (value.includes(option.data.value)) {
															onChange(
																[...value].filter(
																	item =>
																		item !== option.data.value
																)
															)
														}
													}}
													status={removeButton}
													submit={true}
												/>
											</span>
										)}
									</div>
								)}
								dropdownRender={content => (
									<div className='select-custom'>
										<div className='select-custom__content'>{content}</div>
										{addButton === 'department' ? <AddDepartment /> : ''}
										<div className='select-custom__button-bottom'>
											<span
												className='select-custom__cancel'
												onClick={() => onChange([])}
											>
												{t('Reset everything')}
											</span>
											<span
												className='select-custom__submit'
												onClick={handleButtonClick}
											>
												{t('Apply')}
											</span>
										</div>
									</div>
								)}
								allowClear={allowClear}
								suffixIcon={<IconDropDownArrow />}
								value={value}
								removeIcon={<SelectRemoveIconMini />}
							/>
							<div className='placeholder-select'>{placeholder}</div>
							{value || (dataTags && dataTags.length >= 1) ? (
								<TagsSelectMultiple
									dataTags={value || dataTags}
									options={options}
									onOrderChange={value => onChange(value)}
								/>
							) : (
								<></>
							)}
							<div className='input-default__icons'>{children}</div>
							{error && (
								<div
									className={classNames('input-default__message', {
										['animation-opacity']: error,
									})}
								>
									<div className='input-default__message-icon'>
										<IconXError />
									</div>
									<span>{error}</span>
								</div>
							)}
						</div>
					</div>
				)}
			/>
		)
	}

	return (
		<div className={classNames('input-default', classes)}>
			<label>{label}</label>
			<div className='input-default__input'>
				<Select
					className={classNames('form-select', { 'form-select__error': error })}
					popupClassName='form-dropdown'
					placeholder={placeholder}
					bordered={false}
					style={{ width: '100%', height: '40px' }}
					options={options.map(({ id, name }: any) => ({
						key: id,
						label: name,
						value: id,
					}))}
					allowClear
					suffixIcon={<IconDropDownArrow />}
				/>
				<div className='input-default__icons'>{children}</div>

				{error && (
					<div
						className={classNames('input-default__message', {
							['animation-opacity']: error,
						})}
					>
						<div className='input-default__message-icon'>
							<IconXError />
						</div>
						<span>{error}</span>
					</div>
				)}
			</div>
		</div>
	)
}

export const InputDropdownPrefix: FC<InputDefaultType> = ({
	reference,
	// name,
	label,
	classes,
	error,
	type,
	placeholder,
	register,
	rules,
	control,
	// defaultValue,
	disabled,
	required,
	children,
	// maxLength,
	actionPlus,
	plus,
	// onChange,
	value,
	// field,
	index,
	// options,
	dropdownComponent: DropdownComponent,
}: InputDefaultType) => {
	const isEmpty = isObject(children)

	return (
		<div className={classNames('input-default', classes)}>
			{label && (
				<label>
					{label}
					{required && <div className='input-default__required'>*</div>}
					{plus && (
						<div className='input-default__plus' onClick={() => actionPlus()}>
							<IconPlus />
						</div>
					)}
				</label>
			)}

			<div className={`${classes}__body`}>
				<Controller
					control={control}
					name={`socials[${index}].type`}
					rules={rules}
					render={({
						field: {
							/*value, onChange, onBlur,  name, ref */
						},
						fieldState: {
							/* invalid, isTouched, isDirty */
						},
						// formState,
					}) => (
						<DropdownComponent
							value={value && value} // error TIME FIX!!!
							name={`socials[${index}].type`}
							register={register}
							control={control}
						/>
					)}
				/>
				<Controller
					control={control}
					name={`socials[${index}].name`}
					rules={rules}
					render={({
						field: { value, onChange /* onBlur,  name, ref */ },
						fieldState: {
							/* invalid, isTouched, isDirty */
						},
						// formState,
					}) => (
						<div className='input-default__input'>
							<input
								ref={reference}
								className={classNames('', {
									'input-default__error': error,
									isIcon: isEmpty,
								})}
								onChange={onChange}
								name={`socials[${index}].name`}
								type={type}
								placeholder={placeholder}
								value={value || ''}
								disabled={disabled ? disabled : false}
							/>
							<div className='input-default__icons'>{children}</div>

							{error && (
								<div
									className={classNames('input-default__message', {
										['animation-opacity']: error,
									})}
								>
									<div className='input-default__message-icon'>
										<IconXError />
									</div>
									<span>{error}</span>
								</div>
							)}
						</div>
					)}
				/>
			</div>
		</div>
	)
}
