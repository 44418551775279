import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { appPage, setStatus } from '@redux/reducers/appSlice'
import { setLogout } from '@redux/reducers/authSlice'

const useLogout: any = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const logout = () => {
		dispatch(
			setLogout({
				callback: () => {
					dispatch(setStatus('auth'))
					dispatch(appPage('login'))
					navigate('/auth')

					if (sessionStorage.getItem('token')) sessionStorage.removeItem('token')

					if (localStorage.getItem('token')) localStorage.removeItem('token')

					return
				},
			})
		)
	}

	return { logout }
}

export default useLogout
