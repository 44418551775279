import qs from 'querystringify'

import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import type { stateType } from '@types'

const usePageStatusController: any = () => {
	const { status /*success*/ } = useSelector((state: stateType) => state.app)
	const navigate = useNavigate()

	const { pathname, search } = useLocation()
	const params: any = qs.parse(search)

	const redirectController = () => {
		if (params?.reset_password_token) {
			localStorage.setItem('recovery-token', params.reset_password_token)
			return navigate('/recovery-password')
		}

		return navigate('/auth')
	}

	const navigatePageController = () => {
		switch (status) {
			case 'auth':
				return redirectController()

			case 'company-registration':
				return navigate('/company-registration-main')

			case 'company-verification':
				return navigate('/company-create/verification')

			case 'profile-create':
				return navigate('/profile-create')

			case 'companies-profile':
				return navigate('/companies')

			case 'verifier':
				if (pathname.split('/')[1] !== 'lc-companies') {
					return navigate('/lc-companies/all')
				}

			case 'main':
				pathname === '/company-registration-main' && navigate('/')
				pathname === '/company-verify' && navigate('/')
				return

			default:
				//console.log('ping');
				//navigate('/');
				return
		}
	}

	const navigateAuthController = () => {
		if (status === 'main' || status === 'verification') {
			navigate('/')
		}

		if (status === 'profile-create') {
			navigate('/profile-create')
		}

		if (status === 'companies-profile') {
			navigate('/companies')
		}

		if (status === 'company-verification') {
			navigate('/company-create/verification')
		}
	}

	return { navigatePageController, navigateAuthController }
}

export default usePageStatusController
