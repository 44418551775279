import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import MainCompaniesContainer from '@containers/companies/main'
import { setStatus } from '@redux/reducers/appSlice'
import CompaniesTemplate from '@templates/formTemplate/companiesTemplate'

const CompaniesProfile: FC = () => {
	const { status: page } = useSelector((state: stateType) => state.app)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setStatus('companies-profile'))
	}, [])
	return (
		<>
			<Helmet title={page} />
			<CompaniesTemplate page={page}>
				<MainCompaniesContainer />
			</CompaniesTemplate>
		</>
	)
}

export default CompaniesProfile
