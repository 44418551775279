import React from 'react'
import type { FC } from 'react'

export const IconMoveToArchive: FC<any> = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clipPath='url(#clip0_4415_2212)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V7.5C22 6.96957 21.7893 6.46086 21.4142 6.08579C21.0391 5.71071 20.5304 5.5 20 5.5H12.481L11.081 3.75C10.8936 3.51599 10.6561 3.3271 10.3859 3.19728C10.1157 3.06746 9.81977 3.00004 9.52 3H4C3.46957 3 2.96086 3.21071 2.58579 3.58579ZM8.00008 11.4692V14.5305H12.0819V17L16.082 12.9998L12.0819 8.99969V11.4692H8.00008Z'
				fill='#8D97B0'
			/>
		</g>
		<defs>
			<clipPath id='clip0_4415_2212'>
				<rect width='24' height='24' fill='white' />
			</clipPath>
		</defs>
	</svg>
)

export const IconMoveToRemove: FC<any> = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49698L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.1341C19.1102 16.2971 19.6432 9.55715 19.6432 9.48915C19.6626 9.28315 19.5955 9.08815 19.4623 8.93115C19.3193 8.78415 19.1384 8.69715 18.9391 8.69715H5.06852C4.86817 8.69715 4.67755 8.78415 4.54528 8.93115C4.41107 9.08815 4.34494 9.28315 4.35466 9.48915C4.35645 9.50164 4.37557 9.73905 4.40754 10.136C4.54958 11.8992 4.94516 16.8102 5.20078 19.1341C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38762 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.1341Z'
			fill='#8D97B0'
		/>
	</svg>
)
