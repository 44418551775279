import { Select } from 'antd'
import ct from 'countries-and-timezones'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import type { stateType } from '@types'
import IconDropDownArrow from '@assets/icons/select/dropDownArrow'

import { profileUpdate } from '@redux/reducers/userSlice'

import './styles.scss'

const languageOptions = [
	{
		value: 'ru',
		label: 'Русский',
	},
	{
		value: 'ua',
		label: 'Українська',
	},
	{
		value: 'en',
		label: 'English',
	},
]

const getCountries = t => {
	const countries = ct.getAllCountries()
	return countries
		? Object.values(countries).map(country => ({
				value: country.id,
				label: t(country.name),
		  }))
		: []
}

const getTimezoneOptions = (selectedCountry, t) => {
	const timezones = ct.getTimezonesForCountry(selectedCountry)
	return timezones
		? timezones.map(timezone => ({
				value: `${timezone.name} ${timezone.utcOffsetStr}`,
				label: t(`${timezone.name} ${timezone.utcOffsetStr}`),
		  }))
		: []
}

const LanguageAndRegion = () => {
	const [loading, setLoading] = useState<any>(false)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { info } = useSelector((state: stateType) => state.user)

	const getCountriesOptions = getCountries(t)

	const [language, setLanguage] = useState(
		info.lang || localStorage.getItem('lang') || languageOptions[0].value
	)
	const [country, setCountry] = useState(
		info.country || localStorage.getItem('country') || languageOptions[1].value.toUpperCase()
	)
	const [timezone, setTimezone] = useState(
		info.timezone || localStorage.getItem('timezone') || getTimezoneOptions(country, t)[0].value
	)

	const handleLanguageChange = (value: string) => {
		setLoading(true)
		localStorage.setItem('lang', value)
		setLanguage(value)
	}

	const handleCountryChange = (value: string) => {
		setLoading(true)
		localStorage.setItem('country', value)
		setCountry(value)
	}

	const handleTimezoneChange = (value: string) => {
		setLoading(true)
		localStorage.setItem('timezone', value)
		setTimezone(value)
	}

	useEffect(() => {
		const defaultTimezone = getTimezoneOptions(country, t)[0]?.value || ''
		localStorage.setItem('timezone', defaultTimezone)
		handleTimezoneChange(defaultTimezone)
	}, [country])

	useEffect(() => {
		if (language !== (info.lang || localStorage.getItem('lang'))) {
			const request = {
				lang: language,
			}
			dispatch(
				profileUpdate({
					request,
					callback: { success: () => {} },
				})
			)
		}
		setLoading(false)
	}, [language])

	useEffect(() => {
		const request = {
			country: country,
			timezone: timezone,
		}
		dispatch(
			profileUpdate({
				request,
				callback: { success: () => {} },
			})
		)
		setLoading(false)
	}, [country, timezone])

	const renderSelect = (
		label: string,
		value: string,
		options: any[],
		onChange: (_value: string) => void,
		loading: boolean
	) => (
		<div className='input-default'>
			<label>{t(label)}</label>
			<div className='input-default__input'>
				<Select
					disabled={loading}
					className='form-select'
					popupClassName='form-dropdown'
					bordered={false}
					onChange={onChange}
					style={{ width: '100%', height: '40px' }}
					value={value}
					options={options}
					allowClear={false}
					suffixIcon={<IconDropDownArrow />}
				/>
			</div>
		</div>
	)

	return (
		<div className='languageForm'>
			{renderSelect('Language', language, languageOptions, handleLanguageChange, loading)}
			{renderSelect('Country', country, getCountriesOptions, handleCountryChange, loading)}
			{renderSelect(
				'Timezone',
				timezone,
				getTimezoneOptions(country, t),
				handleTimezoneChange,
				loading
			)}
		</div>
	)
}

export default LanguageAndRegion
