import React from 'react'
import type { FC } from 'react'

const IconTime: FC<any> = ({ _onClick }: any) => (
	<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7.00016 0.333496C10.6822 0.333496 13.6668 3.31816 13.6668 7.00016C13.6668 10.6822 10.6822 13.6668 7.00016 13.6668C3.31816 13.6668 0.333496 10.6822 0.333496 7.00016C0.333496 3.31816 3.31816 0.333496 7.00016 0.333496ZM7.00016 1.66683C5.58567 1.66683 4.22912 2.22873 3.22893 3.22893C2.22873 4.22912 1.66683 5.58567 1.66683 7.00016C1.66683 8.41465 2.22873 9.7712 3.22893 10.7714C4.22912 11.7716 5.58567 12.3335 7.00016 12.3335C8.41465 12.3335 9.7712 11.7716 10.7714 10.7714C11.7716 9.7712 12.3335 8.41465 12.3335 7.00016C12.3335 5.58567 11.7716 4.22912 10.7714 3.22893C9.7712 2.22873 8.41465 1.66683 7.00016 1.66683V1.66683ZM7.00016 3.00016C7.16345 3.00018 7.32105 3.06013 7.44308 3.16864C7.5651 3.27715 7.64306 3.42666 7.66216 3.58883L7.66683 3.66683V6.72416L9.4715 8.52883C9.59106 8.6488 9.66048 8.80979 9.66565 8.97908C9.67082 9.14838 9.61135 9.3133 9.49933 9.44035C9.38731 9.56739 9.23113 9.64703 9.06251 9.66309C8.8939 9.67916 8.72549 9.63044 8.5915 9.52683L8.52883 9.4715L6.52883 7.4715C6.42522 7.36779 6.35867 7.23283 6.3395 7.0875L6.3335 7.00016V3.66683C6.3335 3.49002 6.40373 3.32045 6.52876 3.19542C6.65378 3.0704 6.82335 3.00016 7.00016 3.00016V3.00016Z'
			fill='#8D97B0'
		/>
	</svg>
)

export default IconTime
