import { yupResolver } from '@hookform/resolvers/yup'

import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'

import type { FormCompanyManagementData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import BtnGoBack from '@atoms/buttons/goBackButton'
import { managementFields } from '@configs/formFields'
import FileUpload from '@organisms/forms/fileUpload'
import RenderFields from '@organisms/forms/renderFields'
import { getOptions } from '@redux/reducers/appSlice'
import { updateCompany, removeFileDefaultCompany } from '@redux/reducers/companySlice'
import i18n from '@src/i18n'
import { setCompanyManagementSchema } from '@tools/shemas'

import './style.scss'

const FormCompanyManagement: FC = () => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const schema = useMemo(() => setCompanyManagementSchema(), [i18n.language])
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			selection_stages: undefined,
			is_training: false,
			corporate_training: undefined,
			fileList: undefined,
		},
		resolver: yupResolver(schema),
	})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const isTraining = watch('is_training')
	const [removeFiles, setRemoveFiles] = useState<number[]>([])
	const [fields, setFields] = useState(managementFields)

	const handleOnRemoveFile = (id: number) => {
		if (!removeFiles.includes(id)) {
			setRemoveFiles(prevFiles => [...prevFiles, id])
		}
	}

	useEffect(() => {
		setFields(
			!isTraining
				? [...managementFields].filter(field => field.name !== 'corporate_training')
				: managementFields
		)
	}, [isTraining])

	useEffect(() => {
		if (_.isEmpty(userInfo.company)) {
			navigate('/my-company/profileCompany')
		}
		dispatch(
			getOptions({
				request: {
					source: ['selection_stages', 'corporate_training'],
				},
			})
		)
	}, [])

	useEffect(() => {
		if (!_.isEmpty(userInfo.company)) {
			const { is_training, corporate_training, selection_stages } = userInfo.company
			is_training && setValue('is_training', is_training)
			corporate_training && setValue('corporate_training', corporate_training)
			selection_stages && setValue('selection_stages', selection_stages)
			corporate_training && setValue('corporate_training', corporate_training)
		}
	}, [userInfo])

	const onSubmit = (data: FormCompanyManagementData) => {
		const request = {
			...data,
			selection_stages: data.selection_stages,
			corporate_training: data.corporate_training,
		}

		if (userInfo.company) {
			if (removeFiles.length > 0) {
				dispatch(
					removeFileDefaultCompany({
						request: { file_id: removeFiles },
						callback: () => {
							navigate('/company-setting/mission')
						},
					})
				)
			}
			dispatch(
				updateCompany({
					request: {
						company_id: userInfo.company.id,
						fields: request,
					},
					callback: {
						success: () => {
							navigate('/company-setting/mission')
						},
					},
				})
			)

			return
		}
	}

	const goBack = () => {
		if (userInfo?.company?.id) {
			navigate('/company-setting/structure')
			return
		}
	}

	return (
		<div className='form form-company-contacts'>
			<div className='form__body'>
				<form>
					<RenderFields
						items={fields}
						register={register}
						control={control}
						errors={errors}
						options={options}
					/>
				</form>
				<div className='form__container '>
					<div className='input-default input-file'>
						<FileUpload
							hideButtons={true}
							removeProtection={'default'}
							onResult={id => handleOnRemoveFile(id)}
						/>
					</div>
				</div>
			</div>
			<div className='form-footer form-footer_submit'>
				<BtnGoBack onClick={goBack} />
				<ButtonDefault name={t('Next')} type={'submit'} action={handleSubmit(onSubmit)} />
			</div>
		</div>
	)
}

export default FormCompanyManagement
