import { Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import IconWarning from '@assets/icons/modals/warning'

import ButtonDefault from '@atoms/buttons/button'

import './style.scss'

interface LogoutFromSystemModalType {
	component: any
	action?: any
	color?: any
}

const LogoutFromProgramModal: FC<LogoutFromSystemModalType> = ({
	component: Component,
	action,
	color,
}: LogoutFromSystemModalType) => {
	const [visible, setVisible] = useState(false)
	const [loader, setLoader] = useState(false)
	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
	}

	const call = () => {
		setLoader(true)
		action(onCancel)
	}

	return (
		<>
			<Component
				color={color}
				onClick={() => {
					setVisible(true)
				}}
			/>
			<Modal
				className='modal-confirm'
				open={visible}
				width={504}
				footer={null}
				closable={true}
				styles={{ body: { padding: '64px' } }}
				centered
				onCancel={onCancel}
			>
				<div className='modal-confirm__header'>
					<IconWarning />
				</div>
				<div className='modal-confirm__body'>
					<div className='modal-confirm__title'>{t('Exit the program')}</div>
					<div className='modal-confirm__desc'>
						{t('Are you sure you want to leave the program?')}
					</div>
				</div>
				<div className='modal-confirm__btns'>
					<ButtonDefault
						name={t('Leaving')}
						type={'submit'}
						loading={loader}
						disabled={loader}
						action={call}
					/>
					<button className='btn-default btn-gray' onClick={onCancel}>
						{t('Cancel')}
					</button>
				</div>
			</Modal>
		</>
	)
}

export default LogoutFromProgramModal
