import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
// import { exampleValue } from '../../types/app-types';

/*
 *
 * App reducer
 *
 */
const initialState = {
	companies: {},
	companiesAdvanceSearch: [],
	company: {},
	loading: false,
	filter: 4,
}

export type lcVerificatorInitStateType = typeof initialState

const lcVerificatorSlice = createSlice({
	name: 'lcVerificator',
	initialState,
	reducers: {
		getCompanies: () => console.log('getCompanies'),
		getCompaniesAdvanceSearch: () => console.log('getCompaniesAdvanceSearch'),
		setCompaniesAdvanceSearch: (state, action: PayloadAction<any>) => {
			state.companiesAdvanceSearch = action.payload
		},
		getCompany: () => console.log('getCompany'),
		setVerification: () => console.log('setVerification'),
		removeCompany: () => console.log('removeCompany'),
		restoreCompany: () => console.log('restoreCompany'),
		searchCompanies: () => console.log('searchCompanies'),
		sendMessage: () => console.log('sendMessage'),
		editMessage: () => console.log('editMessage'),
		removeMessage: () => console.log('removeMessage'),
		setCompany: (state, action: PayloadAction<any>) => {
			state.company = action.payload
		},
		setCompanies: (state, action: PayloadAction<any>) => {
			state.companies = action.payload
		},
		setLoading: (state, action: PayloadAction<any>) => {
			state.loading = action.payload
		},
		setFilter: (state, action: PayloadAction<any>) => {
			state.filter = action.payload
		},
	},
})

export default lcVerificatorSlice.reducer
export const {
	getCompanies,
	getCompaniesAdvanceSearch,
	getCompany,
	setVerification,
	searchCompanies,
	removeCompany,
	restoreCompany,
	setCompanies,
	setCompaniesAdvanceSearch,
	setCompany,
	setLoading,
	setFilter,
	sendMessage,
	editMessage,
	removeMessage,
}: any = lcVerificatorSlice.actions
