import { yupResolver } from '@hookform/resolvers/yup'

import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormCompanyMissionData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import BtnGoBack from '@atoms/buttons/goBackButton'
import { missionFields } from '@configs/formFields'
import RenderFields from '@organisms/forms/renderFields'
import { getOptions, setStatus, setSuccess } from '@redux/reducers/appSlice'
import { updateCompany } from '@redux/reducers/companySlice'
import i18n from '@src/i18n'

import { setCompanyMissionSchema } from '@tools/shemas'

import './style.scss'

const FormCompanyMission: FC = () => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const schema = useMemo(() => setCompanyMissionSchema(), [i18n.language])
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			mission: '',
			competencies: undefined,
			valuations: undefined,
		},
		resolver: yupResolver(schema),
	})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		if (_.isEmpty(userInfo.company)) {
			navigate('/my-company/profileCompany')
		}
		dispatch(
			getOptions({
				request: {
					source: ['competencies', 'valuation'],
				},
			})
		)
	}, [])

	useEffect(() => {
		if (!_.isEmpty(userInfo.company)) {
			const { competencies, valuations, mission } = userInfo.company
			mission && setValue('mission', mission)
			competencies && setValue('competencies', competencies)
			valuations && setValue('valuations', valuations)
		}
	}, [userInfo])

	const onSubmit = (data: FormCompanyMissionData) => {
		const request = {
			...data,
			competencies: data.competencies,
			valuations: data.valuations,
		}

		if (userInfo.company) {
			dispatch(
				updateCompany({
					request: {
						company_id: userInfo.company.id,
						fields: request,
					},
					callback: {
						success: () => {
							dispatch(setStatus('success'))
							dispatch(setSuccess({ type: 'company-updated' }))
						},
					},
				})
			)
			return
		}
	}

	const goBack = () => {
		if (userInfo?.company?.id) {
			navigate('/company-setting/management')
			return
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form form-company-contacts'>
			<div className='form__body'>
				<RenderFields
					items={missionFields}
					register={register}
					control={control}
					errors={errors}
					options={options}
				/>
			</div>
			<div className='form-footer form-footer_submit'>
				<BtnGoBack onClick={goBack} />
				<ButtonDefault name={t('Next')} type={'submit'} />
			</div>
		</form>
	)
}

export default FormCompanyMission
