import React, { useEffect, useState } from 'react'
import './style.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import { useParams } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import { IconAppFile, IconAppPrint } from '@assets/icons/app'
import IconArrowLeft, { IconArrowLeftCircle, IconArrowRightCircle } from '@assets/icons/btn/arrow'
import { IconZoomMinus } from '@assets/icons/btn/zoomMinus'
import { IconZoomPlus } from '@assets/icons/btn/zoomPlus'
import { IconFile } from '@assets/icons/uploaders'
import { getCompany } from '@redux/reducers/lcVerificatorSlice'

type ViewFilesModalProps = {
	onClose?: () => void
}

const ViewFilesModal: FC<ViewFilesModalProps> = ({ onClose }) => {
	const { id } = useParams()
	const dispatch = useDispatch()
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	const companyId = id || sessionProperties?.companyId
	const defaultIndex = sessionProperties?.photoKeyActive || 0

	const { company }: any = useSelector((state: stateType) => state.lcVerificator)

	const [activeImage, setActiveImage] = useState(company?.files?.edr[defaultIndex])
	const [scale, setScale] = useState(1.0)
	const [zoomInButtonEnabled, setZoomInButtonEnabled] = useState(false)
	const [zoomOutButtonEnabled, setZoomOutButtonEnabled] = useState(false)

	useEffect(() => {
		setActiveImage(company?.files?.edr[defaultIndex])
	}, [company])

	useEffect(() => {
		dispatch(
			getCompany({
				request: {
					company_id: Number(companyId),
				},
			})
		)
	}, [])

	const handleScaleChange = (factor: number) => {
		if (factor > 0 && zoomInButtonEnabled) {
			setScale(prevScale => prevScale + factor)
		} else if (factor < 0 && zoomOutButtonEnabled) {
			setScale(prevScale => prevScale + factor)
		}
	}

	useEffect(() => {
		if (scale < 2.0) {
			setZoomInButtonEnabled(true)
		} else {
			setZoomInButtonEnabled(false)
		}

		if (scale > 0.1) {
			setZoomOutButtonEnabled(true)
		} else {
			setZoomOutButtonEnabled(false)
		}
	}, [scale])

	const downloadImage = () => {
		window.open(activeImage?.url)
	}

	const printImage = () => {
		const fileName = activeImage?.name
		const fileExtension = fileName.split('.').pop().toLowerCase()
		const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileExtension)

		if (isImage) {
			const image = new Image()
			image.src = activeImage?.url
			image.onload = () => {
				const printWindow = window.open('', '', 'width=600,height=600')
				if (printWindow) {
					printWindow.document.open()
					printWindow.document.write('<html><body style="margin:0;padding:0;">')
					printWindow.document.write(
						`<img src="${image.src}" style="max-width:100%;height:auto">`
					)
					printWindow.document.write('</body></html>')
					setTimeout(() => {
						printWindow.document.close()
						printWindow.print()
						printWindow.close()
					}, 2000)
				}
			}
		} else {
			const url = `https://docs.google.com/viewer?url=${encodeURIComponent(
				activeImage?.url
			)}&embedded=true`
			window.open(url, '_blank')
		}
	}

	const handleClose = () => {
		if (onClose) {
			onClose()
		}
	}

	const scalePercent = Math.round(scale * 100)

	return (
		<div className='view-file-container'>
			<div className='headerModal'>
				<div onClick={() => handleClose()} className='headerModal_start'>
					<IconArrowLeft />
					<IconFile />
					<span className='title'>{activeImage?.name}</span>
				</div>

				<div className='headerModal_end'>
					<div onClick={printImage}>
						<IconAppPrint />
					</div>
					<div onClick={downloadImage}>
						<IconAppFile />
					</div>
				</div>
			</div>

			<Carousel
				selectedItem={defaultIndex}
				axis='horizontal'
				showStatus={false}
				className='carusel'
				showThumbs={false}
				infiniteLoop={false}
				showIndicators={false}
				emulateTouch
				useKeyboardArrows
				transitionTime={1000}
				onChange={index => {
					setActiveImage(company?.files?.edr[index])
				}}
				renderArrowNext={(clickHandler, hasNext) =>
					hasNext && (
						<div onClick={clickHandler}>
							<IconArrowRightCircle />
						</div>
					)
				}
				renderArrowPrev={(clickHandler, hasPrev) =>
					hasPrev && (
						<div onClick={clickHandler}>
							<IconArrowLeftCircle />
						</div>
					)
				}
			>
				{company?.files?.edr.map(item => {
					const fileName = item.name
					const fileExtension = fileName.split('.').pop().toLowerCase()
					const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileExtension)

					if (isImage) {
						return (
							<div key={fileName} className='img-zoom-wrapper'>
								<img
									key={item}
									src={item.url}
									style={{ width: '50%', transform: `scale(${scale})` }}
									alt='image'
									loading='lazy'
								/>
								<div className='btn-wrap-zoom'>
									<button
										onClick={() => handleScaleChange(-0.1)}
										className='btn-zoom'
										disabled={!zoomOutButtonEnabled}
									>
										<IconZoomMinus />
									</button>
									<span className='percent'>{scalePercent} %</span>
									<button
										onClick={() => handleScaleChange(0.1)}
										className='btn-zoom'
										disabled={!zoomInButtonEnabled}
									>
										<IconZoomPlus />
									</button>
								</div>
							</div>
						)
					} else {
						return (
							<div
								key={fileName}
								id={item.id}
								style={{ width: '100%', height: '70vh' }}
							>
								<iframe
									src={`https://docs.google.com/viewer?url=${encodeURIComponent(
										item.url
									)}&embedded=true`}
									width='100%'
									height='100%'
									title='PDF Viewer'
									className='iframeClass'
									style={{ background: '#dcdefc' }}
								/>
							</div>
						)
					}
				})}
			</Carousel>
		</div>
	)
}

export default ViewFilesModal
