import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormCompanyRegistrationData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import BtnGoBack from '@atoms/buttons/goBackButton'

import { createFields } from '@configs/formFields'
import RenderFields from '@organisms/forms/renderFields'
import OutFromCreateCompanyModal from '@organisms/modals/confirms/outFromCreateCompany'

import { setStatus, setSuccess } from '@redux/reducers/appSlice'
import { createCompany, logoutFromCompany, updateCompany } from '@redux/reducers/companySlice'
import { setUser } from '@redux/reducers/userSlice'
import i18n from '@src/i18n'

import { regCompanySchema } from '@tools/shemas'

import './style.scss'

// interface FormData {
// 	name: string
// 	employees: number
// 	code: number
// 	activity: number
// 	services: string
// 	website: string
// }

const FormCompanyRegistration: FC = () => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const schema = useMemo(() => regCompanySchema(), [i18n.language])
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: '',
			employees: null,
			code: undefined,
			activity: null,
			services: '',
			website: '',
		},
		resolver: yupResolver(schema),
	})

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		if (!_.isEmpty(userInfo.company)) {
			const { name, employees, code, activity, services, website } = userInfo.company

			name && setValue('name', name)
			employees && setValue('employees', employees.id)
			code && setValue('code', code)
			activity && setValue('activity', activity.id)
			services && setValue('services', services)
			website && setValue('website', website)
		}
	}, [userInfo])

	const onSubmit = (data: FormCompanyRegistrationData) => {
		const request = {
			...data,
			code: Number(data.code),
			employees: data.employees ? data.employees : 0,
			activity: data.activity ? data.activity : 0,
		}

		if (userInfo.company) {
			dispatch(
				updateCompany({
					request: {
						company_id: userInfo.company.id,
						fields: request,
					},
					callback: {
						success: () => {
							navigate('/company-create/verification')
						},
					},
				})
			)
			return
		}

		dispatch(
			createCompany({
				request: { ...request },
				callback: () => {
					dispatch(setStatus('success'))
					dispatch(setSuccess({ type: 'company-create' }))
					navigate('/company-create/verification')
				},
			})
		)
	}

	const goBack = () => {
		if (userInfo?.company?.id) {
			dispatch(
				logoutFromCompany({
					request: { company_id: userInfo.company.id },
					callback: () => {
						dispatch(setUser({ ...userInfo, company: false }))
						dispatch(setStatus('companies-profile'))
						navigate('/companies')
					},
				})
			)
			return
		}

		dispatch(setStatus('companies-profile'))
		navigate('/companies')
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form form-company-registration'>
			<div className='form__body'>
				<RenderFields
					items={createFields}
					register={register}
					control={control}
					errors={errors}
					options={options}
				/>
			</div>
			<div className='form-footer form-footer_submit'>
				<OutFromCreateCompanyModal component={BtnGoBack} action={goBack} />
				<ButtonDefault name={t('Next')} type={'submit'} />
			</div>
		</form>
	)
}

export default FormCompanyRegistration
