export const missionFields = [
	{
		name: 'mission',
		label: 'Vision / Mission',
		placeholder: 'Enter Name',
		required: false,
	},
	{
		name: 'valuations',
		label: 'Values',
		fieldType: 'selectMultiple',
		placeholder: 'Enter a name or select from the list',
		required: false,
		optionKey: 'valuation',
	},
	{
		name: 'competencies',
		label: 'Competencies',
		fieldType: 'selectMultiple',
		placeholder: 'Enter a name or select from the list',
		required: false,
		optionKey: 'competencies',
	},
]
