import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import { IconLink } from '@assets/icons/statsInfo'
import LcVerificationCompany from '@pages/lcVerification/company'
import { updateStatusView } from '@redux/reducers/notificationsUserSlice'
import { setSessionProperties } from '@redux/reducers/userSlice'
import './style.scss'

interface InfoBlockType {
	isWarning: boolean
	classes?: string
	onClick?: () => void
	title: string
	subtitle: string
	date?: string
	counter?: boolean
	type?: string
	companyId?: number | string
	changeStatus?: boolean
	notificationId?: number | string
}

const InfoBlock: FC<InfoBlockType> = ({
	changeStatus,
	companyId,
	isWarning,
	onClick,
	title,
	subtitle,
	counter,
	type,
	classes,
	notificationId,
}: InfoBlockType) => {
	const dispatch = useDispatch()
	const { info } = useSelector((state: any) => state.user)
	const [modalVisible, setModalVisible] = useState(false)
	const [isHovered, setIsHovered] = useState(false)
	const [isViewed, setIsViewed] = useState('')
	const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | undefined>(undefined)
	const { sessionProperties } = useSelector((state: stateType) => state.user)

	const handleModalClose = () => {
		setModalVisible(false)
		const updatedSessionProperties = {
			...sessionProperties,
			companyId: null,
			tableKey: null,
			photoKeyActive: null,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
	}

	const handleModalOpen = (id: number | string, tabKey: string) => {
		const updatedSessionProperties = {
			...sessionProperties,
			companyId: id,
			tabKey: tabKey,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
		setModalVisible(true)
	}

	const handleMouse = val => {
		if (changeStatus) setIsHovered(val)
	}

	useEffect(() => {
		if (isHovered && isViewed === '') {
			const timeoutId = setTimeout(() => {
				notificationId &&
					dispatch(
						updateStatusView({
							request: { notificationId: notificationId },
							callback: {},
						})
					)
				setIsViewed('viewed')
				hoverTimeout && clearTimeout(hoverTimeout)
			}, 10)

			setHoverTimeout(timeoutId)
		} else {
			if (hoverTimeout) {
				clearTimeout(hoverTimeout)
			}
		}

		return () => {
			if (hoverTimeout) {
				clearTimeout(hoverTimeout)
			}
		}
	}, [isHovered, isViewed])

	return (
		<div className={classNames('info-block', classes, isViewed)}>
			{companyId && modalVisible && (
				<LcVerificationCompany open={modalVisible} onClose={handleModalClose} />
			)}
			<div
				className={`info-block__item ${isWarning ? '_warning' : ''}`}
				onClick={onClick}
				onMouseEnter={() => handleMouse(true)}
				onMouseLeave={() => handleMouse(false)}
			>
				<div
					className={classNames('info-block__type', {
						[`info-block__type-${type}`]: type ? type : false,
					})}
				/>

				<div className='info-block__message'>
					{companyId && (
						<a
							className='info-block__link'
							onClick={() =>
								info?.is_verifier && handleModalOpen(companyId, 'history')
							}
						>
							<span className='info-block__title'>{title}</span>
							{info?.is_verifier && <IconLink />}
						</a>
					)}
					{!companyId && <span className='info-block__title'>{title}</span>}
					<span className='info-block__subtitle'>{subtitle}</span>
				</div>
			</div>
			{counter && <div className='info-block__counter'>+1</div>}
		</div>
	)
}
export default InfoBlock
