import React from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import TabsAuthContent from '@containers/auth/tabsAuthConteiner'
import AsideTemplate from '@templates/asideTemplate'

const Authentication: FC = () => {
	const { page } = useSelector((state: stateType) => state.app)
	return (
		<>
			<Helmet title={page} />
			<AsideTemplate showBenefits center page={page}>
				<TabsAuthContent />
			</AsideTemplate>
		</>
	)
}

export default Authentication
