/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */

import dayjs from 'dayjs'
import React from 'react'
import { StatusCompany, Titlte } from '@molecules/tables/users'
import 'dayjs/locale/ru'
import 'dayjs/locale/uk'

/* TODO need parser for avatar */

export const columns = [
	{
		title: <Titlte name={'№'} classes={'lc-verification-companies-table__title'} />,
		dataIndex: 'id',
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-id',
		render: (text, record) => (
			<div className='lc-verification-companies-table__column-container'>{record.index}</div>
		),
		width: 50,
	},
	{
		title: () => (
			<Titlte name={'Field of activity'} classes={'lc-verification-companies-table__title'} />
		),
		dataIndex: 'activity',
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-activity w-230',
		render: (text, record) => <div>{record?.activity?.name}</div>,
		width: 254,
	},
	{
		title: () => (
			<Titlte
				name={'Code'}
				name2={'EDRPOU'}
				classes={'lc-verification-companies-table__title'}
			/>
		),
		dataIndex: 'code',
		className: 'lc-verification-companies-table__column w-125',
		width: 145,
	},
	{
		title: () => (
			<Titlte
				name={'Date'}
				name2={'creations'}
				classes={'lc-verification-companies-table__title'}
			/>
		),
		dataIndex: 'update_date',
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-date w-125',
		render: (text, record) => (
			<div className='lc-verification-companies-table__column-date-block'>
				<span className='table-date'>{dayjs(record.update_date).format('MM/DD/YYYY')}</span>
				<span className='table-time'>{dayjs(record.update_date).format('HH:mm:ss')}</span>
			</div>
		),
		width: 145,
	},
	{
		title: () => <Titlte name={'Status'} classes={'lc-verification-companies-table__title '} />,
		dataIndex: 'status',
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-status',
		render: (text, record) => (
			<StatusCompany
				status={record.status}
				isDel={record?.is_del}
				classes={'lc-verification-companies-table__status'}
			/>
		),
	},
]
