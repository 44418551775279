import { Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import LazyComponent from '@app/LazyComponent'
import { setActive } from '@redux/reducers/menuSlice'
import { findMenuKey } from '@tools/helpFunctions'
import './style.scss'
import { getIconComponent } from '@tools/utils/getIcons'

interface MenuType {
	isCollapsed: boolean
}

const AppMenu: FC<MenuType> = ({ isCollapsed }: MenuType) => {
	const { list /*active */ } = useSelector((state: stateType) => state.menu)

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { pathname } = useLocation()

	const { t } = useTranslation()

	useEffect(() => {
		dispatch(setActive(findMenuKey(pathname)))
	}, [])

	const onClick = (key: number, link: string) => {
		dispatch(setActive(key))
		navigate(`${link}`)
	}

	return (
		<div className={classNames('menu-list', { ['menu-list__is-collapsed']: isCollapsed })}>
			{list.map(({ key, label, link, icon, disabled }) => (
				<Tooltip
					key={key}
					trigger={'hover'}
					placement='right'
					title={t(label)}
					className={'default-tooltip'}
					overlayClassName={classNames('default-tooltip-overlay', {
						['default-tooltip-overlay-hide']: !isCollapsed,
					})}
				>
					<div
						key={key.toString()}
						className={classNames('menu-list__item', {
							['menu-list__item-active']:
								`/${pathname.split('/')[1]}` === link ? true : false,
							'menu-list__item-disabled': disabled,
						})}
						onClick={() => !disabled && onClick(key, link)}
					>
						<LazyComponent
							key={icon}
							fallback={<div style={{ width: 20 }} />}
							getComponent={() => getIconComponent(icon)}
						/>
						<div className='menu-list__item-name'>{t(label)}</div>
					</div>
				</Tooltip>
			))}
		</div>
	)
}

export default AppMenu
