import React from 'react'
import type { FC } from 'react'

const IconXError: FC = () => (
	<svg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7.47821 8.87929C7.29818 9.05931 7.05401 9.16044 6.79942 9.16044C6.54482 9.16044 6.30065 9.05931 6.12062 8.87929L3.99981 6.45529L1.87901 8.87849C1.79014 8.96882 1.68426 9.04067 1.56747 9.08988C1.45069 9.13909 1.32532 9.16469 1.1986 9.16521C1.07187 9.16572 0.946296 9.14114 0.829117 9.09289C0.711938 9.04463 0.605474 8.97365 0.515864 8.88404C0.426255 8.79443 0.355274 8.68797 0.307016 8.57079C0.258759 8.45361 0.234179 8.32803 0.234695 8.20131C0.235211 8.07458 0.260812 7.94921 0.310023 7.83243C0.359233 7.71565 0.431079 7.60977 0.521415 7.52089L2.72781 5.00089L0.520615 2.47929C0.430279 2.39041 0.358433 2.28453 0.309223 2.16775C0.260012 2.05097 0.234411 1.9256 0.233895 1.79887C0.233379 1.67214 0.257959 1.54657 0.306216 1.42939C0.354474 1.31221 0.425455 1.20575 0.515064 1.11614C0.604674 1.02653 0.711138 0.955548 0.828317 0.907291C0.945496 0.859033 1.07107 0.834453 1.1978 0.834969C1.32452 0.835485 1.44989 0.861086 1.56667 0.910297C1.68346 0.959507 1.78934 1.03135 1.87822 1.12169L3.99981 3.54649L6.12062 1.12169C6.20949 1.03135 6.31537 0.959507 6.43216 0.910297C6.54894 0.861086 6.67431 0.835485 6.80103 0.834969C6.92776 0.834453 7.05333 0.859033 7.17051 0.907291C7.28769 0.955548 7.39416 1.02653 7.48377 1.11614C7.57337 1.20575 7.64436 1.31221 7.69261 1.42939C7.74087 1.54657 7.76545 1.67214 7.76493 1.79887C7.76442 1.9256 7.73882 2.05097 7.68961 2.16775C7.6404 2.28453 7.56855 2.39041 7.47821 2.47929L5.27182 5.00089L7.47821 7.52089C7.56747 7.61005 7.63828 7.71592 7.68659 7.83247C7.7349 7.94901 7.75977 8.07393 7.75977 8.20009C7.75977 8.32625 7.7349 8.45117 7.68659 8.56771C7.63828 8.68425 7.56747 8.79013 7.47821 8.87929Z'
			fill='#FF776F'
		/>
	</svg>
)

export default IconXError
