import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'

import './style.scss'

const TooltipStructureInfoBody: FC = () => {
	const { t } = useTranslation()
	return (
		<div className='tooltip-structure-title'>
			<div className='tooltip-structure-title__title'>{t('Organizational structures')} :</div>
			<li value={'1'} className='tooltip-structure-title__li'>
				<p>
					<b>{t('Linear title')}</b>
					{t('Linear description')}
				</p>
			</li>
			<li value={'2'} className='tooltip-structure-title__li'>
				<p>
					<b>{t('Functional title')}</b>
					{t('Functional description')}
				</p>
			</li>
			<li value={'3'} className='tooltip-structure-title__li'>
				<p>
					<b>{t('Linear-Functional title')}</b>
					{t('Linear-Functional description')}
				</p>
			</li>
			<li value={'4'} className='tooltip-structure-title__li'>
				<p>
					<b>{t('Divisional title')}</b>
					{t('Divisional description')}
				</p>
			</li>
			<li value={'4'} className='tooltip-structure-title__li'>
				<p>
					<b>{t('Create Structure')}</b>
					{t('Divisional description')}
				</p>
			</li>
		</div>
	)
}

export default TooltipStructureInfoBody
