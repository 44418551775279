import React from 'react'
import type { FC } from 'react'

export const IconZoomPlus: FC = () => (
	<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.66667 5.33333H5.33333V8.66667C5.33333 8.84348 5.26309 9.01305 5.13807 9.13807C5.01305 9.2631 4.84348 9.33333 4.66667 9.33333C4.48986 9.33333 4.32029 9.2631 4.19526 9.13807C4.07024 9.01305 4 8.84348 4 8.66667V5.33333H0.666667C0.489856 5.33333 0.320287 5.2631 0.195262 5.13807C0.070238 5.01305 0 4.84348 0 4.66667C0 4.48986 0.070238 4.32029 0.195262 4.19526C0.320287 4.07024 0.489856 4 0.666667 4H4V0.666667C4 0.489856 4.07024 0.320286 4.19526 0.195262C4.32029 0.0702377 4.48986 0 4.66667 0C4.84348 0 5.01305 0.0702377 5.13807 0.195262C5.26309 0.320286 5.33333 0.489856 5.33333 0.666667V4H8.66667C8.84348 4 9.01305 4.07024 9.13807 4.19526C9.2631 4.32029 9.33333 4.48986 9.33333 4.66667C9.33333 4.84348 9.2631 5.01305 9.13807 5.13807C9.01305 5.2631 8.84348 5.33333 8.66667 5.33333Z'
			fill='#8D8BED'
		/>
	</svg>
)
