import { Typography } from 'antd'
import React from 'react'
import type { FC } from 'react'

const { Title } = Typography

interface AppTitleType {
	title: string
	level: any
	style?: React.CSSProperties
}

const AppTitle: FC<AppTitleType> = ({ title, level, style }: AppTitleType) => (
	<Title level={level} style={{ color: '#ffffff', fontWeight: 700, ...style }}>
		{title}
	</Title>
)

export default AppTitle
