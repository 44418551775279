import classNames from 'classnames'
import React, { useEffect } from 'react'

import './style.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import type { FC } from 'react'
import { getCompanies } from '@redux/reducers/companySlice'

const CompaniesNavigationList: FC = () => {
	const { sort } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(getCompanies({ request: { type: sort ? sort : 'my' } }))
	}, [sort])

	return (
		<div className='sort-companies'>
			<div
				className={classNames('sort-companies__item', {
					['sort-companies__item-active']: !sort,
				})}
				onClick={() => navigate('/companies')}
			>
				{t('My Companies')}
			</div>
			<div
				className={classNames('sort-companies__item', {
					['sort-companies__item-active']: sort === 'transfer',
				})}
				onClick={() => navigate('/companies/transfer')}
			>
				{t('Transferred Companies')}
			</div>
			<div
				className={classNames('sort-companies__item', {
					['sort-companies__item-active']: sort === 'archive',
				})}
				onClick={() => navigate('/companies/archive')}
			>
				{t('Archive of Companies')}
			</div>
		</div>
	)
}

export default CompaniesNavigationList
