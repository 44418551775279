import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import type { FC } from 'react'
import plus from '@image/icons/plus.svg'
import { appPage } from '@redux/reducers/appSlice'
//import './style.scss'

const TabsProfileContainer: FC = () => {
	const { page } = useSelector((state: any) => state.app)
	const {
		/*info */
	} = useSelector((state: any) => state.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { info } = useSelector((state: any) => state.user)
	const { tab } = useParams()

	useEffect(() => {
		dispatch(appPage(tab))
	}, [tab])

	useEffect(() => {
		if (!tab) navigate('/my-company/profileCompany')
	}, [])

	const onChange = (value: string) => {
		dispatch(appPage(value))

		switch (value) {
			case 'profileCompany':
				navigate(`/my-company/${value}`)
				break

			case 'organizationalStructure':
				navigate(`/my-company/${value}`)
				break

			case 'departments':
				navigate(`/my-company/${value}`)
				break

			case 'employees':
				navigate(`/my-company/${value}`)
				break

			default:
				navigate(`/my-company/${value}`)
				break
		}
	}

	return (
		<Tabs
			onChange={onChange}
			activeKey={page ? page : 'profileCompany'}
			centered
			className={`settings-tabs ${tab === 'profileCompany' ? 'settings-empty' : ''}`}
			items={[
				{
					label: t('Profile company'),
					key: 'profileCompany',
					children:
						info.company.status.id === 4 ? (
							<div className='tab_pane_title'>
								<div className='company_empty'>
									<div className='company_empty__title'>
										{t('Information is not filled in')}
									</div>
									<div
										className='company_empty__button'
										onClick={() => navigate('/company-setting')}
									>
										<div className='company_empty__button__icon'>
											<img src={plus} alt='plus' />
										</div>
										<span className='company_empty__button__text'>
											{t('Fill in information')}
										</span>
									</div>
								</div>
							</div>
						) : (
							<div className='company_no-verifier'>
								{t('No data. Waiting for verification confirmation')}
							</div>
						),
				},
				{
					label: t('Organizational structure'),
					key: 'organizationalStructure',
					children: (
						<>
							<div className='tab_pane_title'>{t('Organizational structure')}</div>
						</>
					),
				},
				{
					label: t('Departments'),
					key: 'departments',
					children: (
						<>
							<div className='tab_pane_title'>{t('Departments')}</div>
						</>
					),
				},
				{
					label: t('Employees'),
					key: 'employees',
					children: (
						<>
							<div className='tab_pane_title'>{t('Employees')}</div>
						</>
					),
				},
			]}
		/>
	)
}

export default TabsProfileContainer
