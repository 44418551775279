import React, { useState, useEffect } from 'react'
import SelectRemoveIconMini from '@assets/icons/select/SelectRemoveIconMini'
import './styles/style.scss'
import '@atoms/inputs/styles/style.scss'

interface TagsSelectProps {
	dataTags: number[]
	options: any
	onOrderChange?: (_newOrder: number[]) => void
}

const TagsSelectMultiple: React.FC<TagsSelectProps> = ({ dataTags, options, onOrderChange }) => {
	const [dataSource, setDataSource] = useState(
		options
			.filter(option => dataTags.includes(option.id))
			.map(item => ({ id: item.id, label: item.name }))
	)

	useEffect(() => {
		setDataSource(
			options
				.filter(option => dataTags.includes(option.id))
				.map(item => ({ id: item.id, label: item.name }))
		)
	}, [dataTags, options])

	const removeItem = (id: number) => {
		const updatedDataSource = [...dataSource].filter(item => item.id !== id)

		if (onOrderChange) {
			const newOrder = updatedDataSource.map(item => item.id)
			onOrderChange(newOrder)
		}
	}

	if (dataSource.length < 1) return <></>

	return (
		<div className='ant-select ant-select-multiple ant-select-empty'>
			<div className='ant-select-selector'>
				<div className='ant-select-selection-overflow'>
					{dataSource.map((props: any) => (
						<div
							key={props.id}
							className='ant-select-selection-overflow-item'
							style={{ opacity: 1 }}
						>
							<div className='ant-select-selection-item' title={props.label}>
								<span className='ant-select-selection-item-content'>
									{props.label}
								</span>
								<span
									className='ant-select-selection-item-remove'
									aria-hidden='true'
									style={{ userSelect: 'none' }}
									onClick={() => removeItem(props.id)}
								>
									<SelectRemoveIconMini />
								</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default TagsSelectMultiple
