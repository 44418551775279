import React from 'react'
import type { FC } from 'react'
import image from '@image/loaders/companies.png'
import './style.scss'

const SkeletonCompanies: FC = () => (
	<div className='skeleton-companies'>
		<div className='skeleton-companies__mask' />
		<img className='skeleton-companies__img' src={image} alt='skeletonCompanies' />
	</div>
)

export default SkeletonCompanies
