import { Table, Typography } from 'antd'
import './style.scss'

import 'dayjs/locale/ru'
import 'dayjs/locale/uk'

import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import ViewFiles from '@pages/view/files'
import { setSessionProperties } from '@redux/reducers/userSlice'
import useFilesPopup from '@tools/hooks/useFilesPopup'
import useTablePagination from '@tools/hooks/useTablePagination'

const { Text } = Typography

const LcVerificationFilesTable: FC = () => {
	const dispatch = useDispatch()
	const tableRef = useRef<HTMLTableElement | null>(null)
	const { t } = useTranslation()
	const { company }: any = useSelector((state: stateType) => state.lcVerificator)
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	const [modalVisible, setModalVisible] = useState(false)

	const excludeFiles = company?.files?.edr.map((item, index) => ({
		...item,
		index: index + 1,
	}))

	const defaultPageSize = 5
	const defaultTotal = excludeFiles?.length
	const keyId = null

	const { pageIndex, loadingPages, handleChangePage, handleTableRender } = useTablePagination({
		defaultPageSize,
		defaultTotal,
		tableRef,
		keyId,
	})

	const handleModalClose = () => {
		const updatedSessionProperties = {
			...sessionProperties,
			photoKeyActive: null,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
		setModalVisible(false)
	}

	const handleModalOpen = index => {
		const updatedSessionProperties = {
			...sessionProperties,
			photoKeyActive: index - 1,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
		setModalVisible(true)
	}

	const { columnsPopup } = useFilesPopup(
		handleModalClose,
		handleModalOpen,
		sessionProperties?.companyId
	)

	return (
		<div className='lc-verification-companies-table'>
			{modalVisible && <ViewFiles open={modalVisible} onClose={handleModalClose} />}
			{defaultTotal ? (
				<Table
					onRow={handleTableRender}
					rowKey={'date_upload'}
					showSorterTooltip={false}
					locale={{
						emptyText: (
							<div className='lc-verification-companies-table__empty'>
								{t('No data')}.
							</div>
						),
					}}
					loading={loadingPages}
					pagination={
						defaultTotal > defaultPageSize && {
							className: 'table-default__pagination',
							defaultPageSize: defaultPageSize,
							pageSize: defaultPageSize,
							current: pageIndex,
							showSizeChanger: false,
							total: defaultTotal,
							onChange: handleChangePage,
						}
					}
					className='lc-verification-companies-table__container'
					size='middle'
					columns={columnsPopup}
					dataSource={excludeFiles}
					sortDirections={['ascend', 'descend']}
				/>
			) : (
				<Text className='lc-verification-companies-table__empty'>{t('No data')}</Text>
			)}
		</div>
	)
}

export default LcVerificationFilesTable
