import { combineReducers } from 'redux'

import app from './appSlice'
import auth from './authSlice'
import company from './companySlice'
import lcVerificator from './lcVerificatorSlice'
import menu from './menuSlice'
// old

import notificationsUser from './notificationsUserSlice'
import { addressesReducer } from './old/reducers/addresses-reducer'
import { candidatesReducer } from './old/reducers/candidates-reducer'
import { departmentsReducer } from './old/reducers/departments-reducer'
import { deputyReducer } from './old/reducers/deputy-reducer'
import { functionalityReducer } from './old/reducers/functionality-reducer'
import { globalReducer } from './old/reducers/global-reducer'
import { KPIReducer } from './old/reducers/kpi-reducer'
import { positionsListReducer } from './old/reducers/positions-reducer'
import { resultReducer } from './old/reducers/result-reducer'
import { targetReducer } from './old/reducers/target-reducer'
import { tasksReducer } from './old/reducers/tasks-reducer'
import { vacanciesReducer } from './old/reducers/vacancies-reducer'
import { zunReducer } from './old/reducers/zun-reducer'
import user from './userSlice'

import { dropDownListReducer } from '@redux/reducers/old/reducers/dropDownLists-reducer'

export default combineReducers({
	app,
	auth,
	user,
	notificationsUser,
	menu,
	company,
	dropDownList: dropDownListReducer,
	global: globalReducer,
	deputy: deputyReducer,
	vacancies: vacanciesReducer,
	addresses: addressesReducer,
	departments: departmentsReducer,
	positions: positionsListReducer,
	functionality: functionalityReducer,
	kpi: KPIReducer,
	result: resultReducer,
	zun: zunReducer,
	target: targetReducer,
	candidates: candidatesReducer,
	tasks: tasksReducer,
	lcVerificator: lcVerificator,
})
