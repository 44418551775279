import axios from 'axios'
import { getStoreLang, getStoreToken } from '@tools/helpFunctions'

const API_URL = process.env.REACT_APP_API_URL
	? process.env.REACT_APP_API_URL
	: 'https://api.prof.world/v2.0'

export const axiosApi = axios.create({
	baseURL: API_URL,
	headers: {
		//'Content-Type': 'application/x-www-form-urlencoded',
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
})

axiosApi.interceptors.request.use(config => {
	if (getStoreToken()) {
		config.headers.userToken = getStoreToken()
	}
	if (getStoreLang) {
		config.headers.lang = getStoreLang()
	}
	return config
})

axiosApi.interceptors.response.use(
	response => response,
	error => {
		if (error.response.status === 401) {
		}

		if (error.response.status === 422) {
			return Promise
				.reject
				// toast.error(error.response.data.message)
				()
		}

		if (error.response.status === 400) {
			return Promise.reject(error.response.data.message)
		}
	}
)

export async function get(url, config = {}) {
	return await axiosApi.get(url, { ...config }).then(response => response)
}

export async function post(url, data, config = {}) {
	return await axiosApi.post(url, { ...data }, { ...config }).then(response => response)
}

export async function formDataPost(url, data, config = {}) {
	return axiosApi.post(url, data, { ...config }).then(response => response)
}

export async function put(url, data, config = {}) {
	return axiosApi.put(url, { ...data }, { ...config }).then(response => response)
}

export async function del(url, config = {}) {
	return await axiosApi.delete(url, { ...config }).then(response => response)
}
