import { Table, Typography } from 'antd'
import React, { useEffect, useRef, useState, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import type { stateType } from '@types'
import type { FC } from 'react'

import DropdownDateFilter from '@molecules/app/dropdwons/dateFilter'
import LcVerificationCompany from '@pages/lcVerification/company'
import { getOptions } from '@redux/reducers/appSlice'
import { setSessionProperties } from '@redux/reducers/userSlice'

import useCompaniesPopup from '@tools/hooks/useCompaniesPopup'
import useTablePagination from '@tools/hooks/useTablePagination'

import './style.scss'

interface LcVerificationCompaniesTableType {
	headerTitle?: string
	list?: boolean
	keyId?: string
	filter?: any
}

const { Text } = Typography

const LcVerificationCompaniesTable: FC<LcVerificationCompaniesTableType> = ({
	headerTitle,
	keyId = 'all',
}: LcVerificationCompaniesTableType) => {
	const tableRef = useRef<HTMLTableElement | null>(null)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [componentKey] = useState(keyId)
	const [modalVisible, setModalVisible] = useState(false)

	const { options } = useSelector((state: stateType) => state.app)
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	const { companies, loading, filter }: any = useSelector(
		(state: stateType) => state.lcVerificator
	)

	const defaultPageSize = 9
	const defaultTotal = companies?.list?.length

	const { pageIndex, loadingPages, handleChangePage, handleTableRender } = useTablePagination({
		defaultPageSize,
		defaultTotal,
		tableRef,
		keyId,
	})

	const handleModalClose = () => {
		setModalVisible(false)
		const updatedSessionProperties = {
			...sessionProperties,
			companyId: null,
			tableKey: null,
			photoKeyActive: null,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
	}

	const handleModalOpen = (id, tabKey) => {
		const updatedSessionProperties = {
			...sessionProperties,
			companyId: id,
			tabKey: tabKey,
		}
		dispatch(setSessionProperties(updatedSessionProperties))
		setModalVisible(true)
	}

	const { columnsPopup } = useCompaniesPopup(handleModalClose, handleModalOpen)

	useEffect(() => {
		!options?.filter_companies &&
			dispatch(
				getOptions({
					request: {
						source: ['filter_companies'],
					},
				})
			)
	}, [])

	return (
		<div
			className='lc-verification-companies-table'
			ref={tableRef as React.MutableRefObject<any>}
		>
			{modalVisible && (
				<LcVerificationCompany open={modalVisible} onClose={handleModalClose} />
			)}
			<HeaderTable headerTitle={headerTitle} list={!!defaultTotal} filter={filter} />
			{(defaultTotal === 0 || !defaultTotal) && filter === 4 ? (
				<Text className='lc-verification-companies-table__empty'>{t('No data')}</Text>
			) : (
				<div ref={tableRef as React.MutableRefObject<any>}>
					<Table
						key={componentKey}
						onRow={handleTableRender}
						loading={loadingPages || loading}
						showSorterTooltip={false}
						locale={{
							emptyText: (
								<div className='lc-verification-companies-table__empty'>
									{t('No data')}.
								</div>
							),
						}}
						pagination={
							defaultTotal > defaultPageSize && {
								className: 'table-default__pagination',
								defaultPageSize: defaultPageSize,
								pageSize: defaultPageSize,
								current: pageIndex,
								showSizeChanger: false,
								total: defaultTotal,
								onChange: handleChangePage,
							}
						}
						scroll={
							defaultTotal > defaultPageSize
								? { y: 460, scrollToFirstRowOnChange: true }
								: {}
						}
						className='lc-verification-companies-table__container'
						size='middle'
						columns={columnsPopup}
						rowKey='id'
						dataSource={companies?.list}
						sortDirections={['ascend', 'descend']}
					/>
				</div>
			)}
		</div>
	)
}

export default LcVerificationCompaniesTable

const HeaderTable: FC<LcVerificationCompaniesTableType> = ({
	headerTitle,
	list,
	filter,
}: LcVerificationCompaniesTableType) => {
	const { t } = useTranslation()

	return useMemo(
		() => (
			<div
				className={`verification-table-header ${
					!list && filter === 4 && 'verification-table-header__empty'
				}`}
			>
				<div className='verification-table-header__title'>
					{headerTitle && t(headerTitle)}
				</div>
				{!list && filter === 4 ? null : (
					<div className='verification-table-header__filters'>
						<DropdownDateFilter rootClassName='search-dropdown' />
					</div>
				)}
			</div>
		),
		[headerTitle, list, t, filter]
	)
}
