import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormCompanyUpdateData } from 'types/api'
import IconRecovry from '@assets/icons/btn/recovery'
import ButtonDefault from '@atoms/buttons/button'
import BtnGoBack from '@atoms/buttons/goBackButton'

import { updateFields } from '@configs/formFields'
import RenderFields from '@organisms/forms/renderFields'
import FormVerifyCompany from '@organisms/forms/verifyCompany/index'
import ReVerificationCompanyModal from '@organisms/modals/confirms/reVerificationCompany'
import { setStatus, setSuccess } from '@redux/reducers/appSlice'
import { removeFileCompany, updateCompany } from '@redux/reducers/companySlice'
import i18n from '@src/i18n'
import { updateCompanySchema } from '@tools/shemas'
import './style.scss'

const FormCompanyUpdate: FC = () => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { options } = useSelector((state: stateType) => state.app)
	const schema = useMemo(() => updateCompanySchema(), [i18n.language])
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: '',
			tm_name: '',
			employees: null,
			code: undefined,
			activity: undefined,
			services: '',
			website: '',
		},
		resolver: yupResolver(schema),
	})

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [removeFiles, setRemoveFiles] = useState<number[]>([])
	const [confirm, setConfirm] = useState(false)
	const [request, setRequest] = useState({})

	useEffect(() => {
		if (!_.isEmpty(userInfo.company)) {
			const { name, employees, code, activity, services, website, tm_name } = userInfo.company
			tm_name && setValue('tm_name', tm_name)
			name && setValue('name', name)
			employees && setValue('employees', employees.id)
			code && setValue('code', code)
			activity && setValue('activity', activity.id)
			services && setValue('services', services)
			website && setValue('website', website)
		}
	}, [userInfo])

	const handleOnRemoveFile = (id: number) => {
		if (!removeFiles.includes(id)) {
			setRemoveFiles(prevFiles => [...prevFiles, id])
		}
	}

	const closeModal = () => {
		setConfirm(false)
	}

	useEffect(() => {
		if (_.isEmpty(userInfo.company)) {
			navigate('/my-company/profileCompany')
		}
	}, [])

	const goBack = () => {
		if (userInfo?.company?.id) {
			navigate('/my-company/profileCompany')
			return
		}
	}

	const onSubmit = (data: FormCompanyUpdateData) => {
		if (confirm || isSubmitting) return

		setIsSubmitting(true)

		const request = {
			...data,
			code: Number(data.code),
			employees: data.employees ? data.employees : 0,
			activity: data.activity ? data.activity : 0,
		}

		if (userInfo.company) {
			try {
				dispatch(
					updateCompany({
						request: {
							sms_confirm: removeFiles.length > 0 ? true : false,
							company_id: userInfo.company.id,
							fields: request,
						},
						callback: {
							confirm: () => {
								setRequest(request)
								setConfirm(true)
							},
							success: () => {
								navigate('/company-setting/contacts')
							},
						},
					})
				)
			} catch (error) {
			} finally {
				setIsSubmitting(false)
			}

			return
		}
	}

	const handleReVerificationAction = codeSms => {
		const requestFields = {
			...request,
		}

		codeSms &&
			dispatch(
				updateCompany({
					request: {
						confirm_phone_code: codeSms,
						company_id: userInfo.company.id,
						fields: requestFields,
					},
					callback: {
						success: () => {
							if (removeFiles.length > 0) {
								dispatch(
									removeFileCompany({
										request: { file_id: removeFiles },
										callback: () => {},
									})
								)
							}
							dispatch(setStatus('success'))
							dispatch(setSuccess({ type: 'company-verification-again' }))
						},
					},
				})
			)
	}

	return (
		<div className='form form-company-update'>
			<div className='form__body'>
				<form>
					<div className='form__body__text'>
						{t('When making changes to the')}
						<b>{t('Company Name or TIN')}</b>
						{t('it will be necessary to undergo re-verification of the Company')}
					</div>
					{confirm && userInfo?.phone && (
						<ReVerificationCompanyModal
							close={closeModal}
							visibleDefault={confirm}
							component={IconRecovry}
							phone={userInfo?.phone}
							action={handleReVerificationAction}
						/>
					)}
					<RenderFields
						items={updateFields}
						register={register}
						control={control}
						errors={errors}
						options={options}
					/>
				</form>
				<div className='form__container '>
					<div className='input-default input-file'>
						<FormVerifyCompany
							hideButtons={true}
							removeProtection={true}
							onResult={id => handleOnRemoveFile(id)}
						/>
					</div>
				</div>

				<div className='form__container '>
					<div className='input-default'>
						<label>{t('Status')}</label>
						<div
							className={classNames(
								'companies-list__status',
								`companies-list__status-${userInfo?.company?.status?.id}`
							)}
						>
							{userInfo?.company?.status?.name}
						</div>
					</div>
				</div>
			</div>
			<div className='form-footer form-footer_submit'>
				<BtnGoBack onClick={goBack} />
				<ButtonDefault name={t('Next')} type={'submit'} action={handleSubmit(onSubmit)} />
			</div>
		</div>
	)
}

export default FormCompanyUpdate
