import './style.scss'

import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import type { stateType } from '@types'
import type { FC } from 'react'

import type { AdvancedSearchData, AdvancedSearchIProps } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault, { InputDatePicker, InputSelectDefault } from '@atoms/inputs'
import { getOptions } from '@redux/reducers/appSlice'
import { getCompaniesAdvanceSearch } from '@redux/reducers/lcVerificatorSlice'
import i18n from '@src/i18n'
import { advanceSearch } from '@tools/shemas'

const AdvancedSearch: FC<AdvancedSearchIProps> = ({ setActiveTab, defaultValue }) => {
	const { loading } = useSelector((state: stateType) => state.auth)
	const { options } = useSelector((state: stateType) => state.app)
	const {} /*companiesAdvanceSearch */ : any = useSelector(
		(state: stateType) => state.lcVerificator
	)

	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		if (!options?.employees || !options?.activities) {
			dispatch(
				getOptions({
					request: {
						source: ['employees', 'activities'],
					},
				})
			)
		}
	}, [])

	const schema = useMemo(() => advanceSearch(), [i18n.language])

	const {
		register,
		control,
		handleSubmit,
		// getValues,
		// setValue,
		formState: { errors },
		// reset,
	} = useForm({
		defaultValues: {
			companyName: defaultValue,
			product: '',
			site: '',
			edrCode: '',
			dateFrom: '',
			dateTo: '',
			employees: undefined,
			activity: undefined,
		},
		resolver: yupResolver(schema),
	})

	const onSubmit = async (data: AdvancedSearchData) => {
		const { dateFrom, dateTo } = data

		const processedData = Object.fromEntries(
			Object.entries(data).filter(([_key, value]) => value !== '' && value !== null)
		)

		const formatDate = date => {
			if (!date) return
			return dayjs(date).format('YYYY-MM-DD').toString()
		}

		if (!isEmpty(processedData)) {
			const request = {
				...processedData,
				dateFrom: formatDate(dateFrom),
				dateTo: formatDate(dateTo),
			}
			dispatch(
				getCompaniesAdvanceSearch({
					request,
					callback: () => {
						setActiveTab?.('Result')
					},
				})
			)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='advance-search-form'>
			<div className='forms'>
				<div className='column-form'>
					<div className='form__container'>
						<InputDefault
							name={'companyName'}
							label={t('Company Name')}
							placeholder={t('Enter Company Name')}
							error={errors.companyName?.message}
							register={register}
							control={control}
						/>
					</div>
					<div className='form__container'>
						<InputSelectDefault
							allowClear={true}
							name={'employees'}
							optionKey={'employees'}
							label={t('Number of employees in the company')}
							placeholder={t('Select from list')}
							error={errors.employees?.message}
							register={register}
							control={control}
							options={options?.employees ? options?.employees : []}
						/>
					</div>
					<div className='form__container'>
						<InputDefault
							name={'edrCode'}
							label={t('EDRPOU code / VAT number PE')}
							placeholder={t('Enter EDRPOU code')}
							error={errors.edrCode?.message}
							register={register}
							control={control}
						/>
					</div>
					<div className='form__container'>
						<InputSelectDefault
							allowClear={true}
							name={'activity'}
							optionKey={'activities'}
							label={t('Field of activity')}
							placeholder={t('Select from list')}
							error={errors.activity?.message}
							register={register}
							control={control}
							options={options?.activities ? options?.activities : []}
						/>
					</div>
				</div>
				<div className='column-form'>
					<div className='form__container'>
						<InputDefault
							name={'product'}
							label={t('Services / Product')}
							placeholder={t('Enter the name of the main product of the Company')}
							error={errors.product?.message}
							register={register}
							control={control}
						/>
					</div>
					<div className='form__container'>
						<InputDefault
							name={'site'}
							label={t('Website')}
							placeholder={t('Add link to website')}
							error={errors.site?.message}
							register={register}
							control={control}
						/>
					</div>
					<div className='date-range'>
						<InputDatePicker
							classes={'date-input'}
							name={'dateFrom'}
							label={t('Date range')}
							placeholder={'10/08/1983'}
							error={errors.dateFrom?.message}
							register={register}
							control={control}
						/>
						<InputDatePicker
							classes={'date-input'}
							name={'dateTo'}
							placeholder={'10/08/1983'}
							error={errors.dateTo?.message}
							register={register}
							control={control}
						/>
					</div>
				</div>
			</div>
			<div className='form__container'>
				<ButtonDefault
					name={t('Advanced Search')}
					type={'submit'}
					loading={loading}
					disabled={loading}
				/>
			</div>
		</form>
	)
}

export default AdvancedSearch
