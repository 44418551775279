export const addressFields = [
	{
		name: 'country',
		label: 'Country',
		placeholder: 'Ukraine',
		required: true,
		className: 'form__container-mini w-276',
	},
	{
		name: 'city',
		label: 'City',
		placeholder: 'Kyiv',
		required: true,
		className: 'form__container-mini w-276',
	},
	{
		name: 'street',
		label: 'Street',
		placeholder: 'Gagarin',
		required: true,
		className: 'form__container-mini w-456',
	},
	{
		name: 'house',
		label: 'House',
		placeholder: '456',
		required: true,
		className: 'form__container-mini w-96',
	},
]
