import React, { useEffect } from 'react'
import SettingsContainer from '@containers/settings'

const Settings: React.FC = () => {
	useEffect(() => {
		console.log('Settings PAGE')
	}, [])

	return <SettingsContainer />
}

export default Settings
