import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import mainEN from '../src/locales/en/texts.json'
import timezoneEN from '../src/locales/en/timezone.json'
import mainRU from '../src/locales/ru/texts.json'
import timezoneRU from '../src/locales/ru/timezone.json'
import mainUA from '../src/locales/ua/texts.json'
import timezoneUA from '../src/locales/ua/timezone.json'
import homeEN from '@src/locales/en/home.json'
import settingsEN from '@src/locales/en/settings.json'
import homeRU from '@src/locales/ru/home.json'
import settingsRU from '@src/locales/ru/settings.json'
import homeUA from '@src/locales/ua/home.json'
import settingsUA from '@src/locales/ua/settings.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const resources = {
	ru: {
		translation: {
			'Welcome to React': 'Welcome to React and react-i18next',
			...mainRU,
			...homeRU,
			...settingsRU,
			...timezoneRU,
		},
	},
	en: {
		translation: {
			'Welcome to React': 'Bienvenue à React et react-i18next',
			...mainEN,
			...homeEN,
			...settingsEN,
			...timezoneEN,
		},
	},
	ua: {
		translation: {
			'Welcome to React': 'Bienvenue à React et react-i18next',
			...mainUA,
			...homeUA,
			...settingsUA,
			...timezoneUA,
		},
	},
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'ru', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	})

export default i18n
