import { yupResolver } from '@hookform/resolvers/yup'

import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormCompanyStructureData } from 'types/api'

import ButtonDefault from '@atoms/buttons/button'
import BtnGoBack from '@atoms/buttons/goBackButton'
import { structureFields } from '@configs/formFields'
import RenderFields from '@organisms/forms/renderFields'
import { getOptions } from '@redux/reducers/appSlice'
import {
	updateCompany,
	getCompanyDepartmentList,
	updateDataCreated,
} from '@redux/reducers/companySlice'

import i18n from '@src/i18n'
import { setCompanyStructureSchema } from '@tools/shemas'
import './style.scss'

const FormCompanyStructure: FC = () => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const { departmentList, dataCreated } = useSelector((state: stateType) => state.company)
	const schema = useMemo(() => setCompanyStructureSchema(), [i18n.language])
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			manager_lname: '',
			manager_name: '',
			manager_sname: '',
			employees: undefined,
			organizational_structure: undefined,
			departments: [],
		},
		resolver: yupResolver(schema),
	})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const watchDepartments = watch('departments')

	useEffect(() => {
		if (_.isEmpty(userInfo.company)) {
			navigate('/my-company/profileCompany')
		}
		dispatch(
			getOptions({
				request: {
					source: ['employees', 'organizational_structure', 'company_departments'],
				},
			})
		)
		dispatch(
			updateDataCreated({
				request: {},
				callback: {},
			})
		)
	}, [])

	useEffect(() => {
		if (!dataCreated?.department) return
		const { departments } = userInfo.company
		const newDepartments = !watchDepartments ? [...departments] : [...watchDepartments]

		if (!newDepartments.includes(dataCreated?.department)) {
			newDepartments.push('p_' + dataCreated?.department)
		}

		departmentList?.length > 0 &&
			dataCreated?.department &&
			setValue('departments', newDepartments)
	}, [dataCreated?.department])

	useEffect(() => {
		if (!_.isEmpty(userInfo.company)) {
			dispatch(
				getCompanyDepartmentList({
					request: { company_id: userInfo.company.id },
					callback: {},
				})
			)

			const {
				manager_lname,
				manager_name,
				manager_sname,
				employees,
				organizational_structure,
				departments,
			} = userInfo.company

			manager_name && setValue('manager_name', manager_name)
			manager_sname && setValue('manager_sname', manager_sname)
			manager_lname && setValue('manager_lname', manager_lname)
			employees && setValue('employees', employees.id)
			organizational_structure &&
				setValue('organizational_structure', organizational_structure.id)
			departments &&
				setValue(
					'departments',
					[...departments].map(item => {
						if (item.is_standard) {
							return `s_${item.id}`
						} else {
							return `p_${item.id}`
						}
					})
				)
		}
	}, [userInfo])

	const onSubmit = (data: FormCompanyStructureData) => {
		const request = {
			//...data,
			manager_name: data.manager_name,
			manager_sname: data.manager_sname,
			manager_lname: data.manager_lname,
			employees: data.employees,
			organizational_structure: data.organizational_structure,
			departments:
				data.departments &&
				[...data.departments].map(item => ({
					id: item,
				})),
		}

		if (userInfo.company) {
			dispatch(
				updateCompany({
					request: {
						company_id: userInfo.company.id,
						fields: request,
					},
					callback: {
						success: () => {
							navigate('/company-setting/management')
						},
					},
				})
			)
			return
		}
	}

	const goBack = () => {
		if (userInfo?.company?.id) {
			navigate('/company-setting/contacts')
			return
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form form-company-contacts'>
			<div className='form__body'>
				<div className='form__sub-title'>{t('Director of the Company')}</div>
				<div className='form__sub-content'>
					<RenderFields
						items={structureFields}
						register={register}
						control={control}
						errors={errors}
						options={{ ...options, departments: departmentList }}
					/>
				</div>
			</div>
			<div className='form-footer form-footer_submit'>
				<BtnGoBack onClick={goBack} />
				<ButtonDefault name={t('Next')} type={'submit'} />
			</div>
		</form>
	)
}

export default FormCompanyStructure
