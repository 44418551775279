import { Dropdown } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import type { stateType } from '@types'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import { IconArrowDropdown } from '@assets/icons/btn/arrow'
import { profileUpdate } from '@redux/reducers/userSlice'

import '../style.scss'

const DropdownLang: FC = () => {
	const { info } = useSelector((state: stateType) => state.user)
	const dispatch = useDispatch()
	const [open, setOpen] = useState<boolean>(false)
	const lang = info.lang || localStorage.getItem('lang') || 'ru'

	const action = (lang: string) => {
		setOpen(false)
		localStorage.setItem('lang', lang)
		const request = {
			lang: lang,
		}
		dispatch(
			profileUpdate({
				request,
				callback: {
					success: {},
					//error: () => {},
				},
			})
		)
		//window.dispatchEvent(new Event('storage'));
	}

	const items: MenuProps['items'] = [
		{
			key: 'en',
			label: <div className='dropdown-overlay__item'>English</div>,
			onClick: () => action('en'),
		},
		{
			key: 'ru',
			label: <div className='dropdown-overlay__item'>Русский</div>,
			onClick: () => action('ru'),
		},
		{
			key: 'ua',
			label: <div className='dropdown-overlay__item'>Українська</div>,
			onClick: () => action('ua'),
		},
	]

	return (
		<Dropdown
			className={'dropdown'}
			menu={{ items }}
			trigger={['click']}
			open={open}
			onOpenChange={(data: any) => setOpen(data)}
		>
			<div
				className='dropdown__triger'
				onClick={e => {
					e.preventDefault()
					setOpen(prev => !!prev)
				}}
			>
				<span className='dropdown__lang-name'>{lang}</span>
				<div
					className={classNames(
						'dropdown__icon',
						{ ['animation-dropdown-up']: open },
						{ ['animation-dropdown-back']: !open }
					)}
				>
					<div className='dropdown__icon-arrow'>
						<IconArrowDropdown />
					</div>
				</div>
			</div>
		</Dropdown>
	)
}

export default DropdownLang
