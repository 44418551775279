import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from './Loading'
import type { stateType } from '@types'
import type { FC } from 'react'
import Success from '@pages/success'
import { setStatus } from '@redux/reducers/appSlice'
import { getUserInfo } from '@redux/reducers/userSlice'
import { getStoreToken } from '@tools/helpFunctions'

interface AuthCompanyWrapperType {
	children: React.ReactNode
}

const AuthCompanyWrapper: FC<AuthCompanyWrapperType> = ({ children }: AuthCompanyWrapperType) => {
	const token = getStoreToken()
	const { status, success } = useSelector((state: stateType) => state.app)
	const { info } = useSelector((state: stateType) => state.user)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		token && _.isEmpty(info) && dispatch(getUserInfo('3'))
		!token && dispatch(setStatus('auth'))
	}, [])

	useEffect(() => {
		if (status === 'company-verification' && _.isEmpty(info.company)) {
			dispatch(setStatus('companies-profile'))
			return navigate('/companies')
		}

		switch (status) {
			case 'auth':
				return navigate('/auth')

			case 'main':
				return navigate('/')

			case 'company-verification':
				return navigate('/company-create/verification')

			case 'companies-profile':
				return navigate('/companies')

			case 'company-create':
				return navigate('/company-create')

			case 'profile-create':
				return navigate('/profile-create')
		}
	}, [status])

	const controller = () => {
		switch (status) {
			case 'success':
				return <Success type={success.type} />

			case 'loading':
				return <Loading />

			case 'companies-profile':
				return <>{children}</>
		}
	}
	return <>{controller()}</>
}

export default AuthCompanyWrapper
