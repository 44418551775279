import { Button, Col, Form, Input, message, Row, Switch } from 'antd'
import { func, number } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import { setNewPositionsInfo } from '@redux/actions/positionsActions'
import { checkFormValues, getCurrentValues } from '@tools/helpers'

FormSetPositionFamilyStatus.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetPositionFamilyStatus({ changeStep, step }) {
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const {
		lists,
		positions: { info },
	} = useSelector(state => ({
		positions: state.positions,
		lists: state.dropDownList.lists,
	}))

	const [isSwitch, setIsSwitch] = useState(true)
	const handleOnChangeSwitch = open => {
		if (!open) {
			form.resetFields(['age_from', 'age_to'])
		}
		setIsSwitch(open)
	}

	const onFinish = async () => {
		const values = checkFormValues(form.getFieldsValue())
		values.family_status = getCurrentValues(values.family_status, lists.s_family_status)
		values.gender = getCurrentValues(values.gender, lists.tools_sex)
		if (values.age_from || values.age_to) {
			values.age = { from: values.age_from, to: values.age_to }
		}
		delete values.age_from
		delete values.age_to
		try {
			dispatch(setNewPositionsInfo(values))
			changeStep(step + 1)
		} catch (err) {
			message.error(err.message)
			throw err
		}
	}
	useEffect(() => {
		if (info) {
			const { age, family_status, gender, kids } = info
			const time = {
				from: age?.from ? age.from : null,
				to: age?.to ? age.to : null,
			}
			form.setFields([
				{ name: 'age_from', value: time.from },
				{ name: 'age_to', value: time.to },
				{ name: 'family_status', value: family_status?.name ? family_status.name : null },
				{ name: 'gender', value: gender?.name ? gender.name : null },
				{ name: 'kids', value: kids ? kids : null },
			])
		}
	}, [info])
	return (
		<Form
			name='set-position-marital-status'
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
			className='form'
		>
			<AppText
				text={t('Age and marital status')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Form.Item label={t('Age')}>
				<Row align='middle' gutter={12} wrap={false}>
					<Col span={8}>
						<Switch
							style={{ width: '100%' }}
							defaultChecked
							onChange={handleOnChangeSwitch}
							checkedChildren={t('Has the meaning')}
							unCheckedChildren={t('Doesnt matter')}
						/>
					</Col>
					<Col flex={1}>
						<Row gutter={6}>
							<Col span={12}>
								<Form.Item name='age_from' noStyle>
									<Input
										type='number'
										disabled={!isSwitch}
										className='form__input'
										placeholder={t('From')}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name='age_to' noStyle>
									<Input
										type='number'
										disabled={!isSwitch}
										className='form__input'
										placeholder={t('Before')}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form.Item>

			<Form.Item className='form__container' label={t('Gender')} name='gender'>
				<AppSelect
					options={lists?.tools_sex}
					placeholder={t('Write or select from the list')}
				/>
			</Form.Item>

			<Form.Item className='form__container' label={t('Family status')} name='family_status'>
				<AppSelect
					options={lists?.s_family_status}
					placeholder={t('Write or select from the list')}
				/>
			</Form.Item>

			<Form.Item className='form__container' label={t('Children')} name='kids'>
				<Input type='number' className='form__input' placeholder={t('Select quantity')} />
			</Form.Item>
			<Form.Item className='form__container'>
				<div className='form__row _between'>
					<Button
						type='default'
						htmlType='button'
						shape='round'
						onClick={() => changeStep(step - 1)}
					>
						{t('Back')}
					</Button>
					<Button type='primary' shape='round' htmlType='submit'>
						{t('Save and continue')}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default FormSetPositionFamilyStatus
