// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// @btn-disable-color: @disabled-color;
// @btn-disable-bg: @disabled-bg;
// @btn-disable-border: @border-color-base;
// @disabled-bg
import type { ThemeConfig } from 'antd'

const vars = {
	colors: {
		primary: {
			main: '#4E5AF2',
			dark: '#3843ED',
			light: '#5E6BF6',
			extraLight: '#DCDEFC',
		},
		success: {
			main: '#71D575',
			dark: '#58C65C',
			light: '#84DF88',
		},
		grey: {
			main: '#8D97B0',
			dark: '#6D7895',
			light: '#D2D8E8',
			extraLight: '#EDF2FF',
		},

		black: '#191927',
		white: '#FFFFFF',
		main: '#3843ED',
		text: {
			dark: '#20272E',
		},
		secondary: {
			dark: '#6D6BE5',
			extraLight: '#EDF2FF',
		},
		form: {
			stroke: '#CBD5E2',
		},
	},
}

const { colors } = vars

export const modifyVars = {
	// primary
	'primary-color': colors.primary.main,

	// link
	'link-color': colors.primary.main,

	// Border
	'border-radius-base': '3px',

	// Buttons
	'btn-font-size-lg': '12px',
	'btn-font-weight': '600',
	'btn-height-base': '40px',

	// disabled
	'disabled-bg': colors.primary.extraLight,
	'disabled-color': colors.grey.main,
	// table
	'table-row-hover-bg': '#EDF2FF70',
	'table-selected-row-hover-bg': '#EDF2FF70',
	'dropdown-font-size': '12px',
	// checkbox

	// 'checkbox-size': '12px',
	// 'checkbox-color': colors.success.main,
	// 'btn-padding-horizontal-base': '24px',

	// switch-color
	'switch-color': colors.success.main,
	// switch-color
	'zindex-dropdown': 999,
	'zindex-modal': 998,
	'zindex-modal-mask': 998,
}

export const config: ThemeConfig = {
	hashed: false,
	token: {
		colorBgLayout: 'transparent',
		colorPrimaryText: colors.text.dark,
		fontSize: 12,
		fontSizeHeading1: 36,
		fontSizeHeading2: 24,
		fontSizeHeading3: 18,
		paddingLG: 24,
		padding: 12,
		colorPrimary: colors.primary.main,
		colorLinkHover: colors.secondary.dark,
		colorLinkActive: colors.main,
		colorIconHover: colors.secondary.dark,
	},
	components: {
		Dropdown: {
			borderRadius: 16,
		},
		Button: {
			borderRadius: 20,
			paddingContentHorizontal: 24,
			colorPrimary: colors.primary.main,
			colorPrimaryBg: colors.white,
			colorPrimaryBgHover: colors.main,
			colorPrimaryBorder: colors.primary.main,
			colorPrimaryActive: colors.primary.dark,
			colorPrimaryTextActive: colors.white,
			colorPrimaryBorderHover: colors.main,
			colorText: colors.primary.main,
			colorPrimaryHover: colors.main,
			colorBgContainer: 'transparent',
		},
		Layout: {
			headerBg: 'transparent',
			bodyBg: 'transparent',
		},
		Divider: {
			colorTextHeading: colors.grey.light,
		},
		List: {
			colorText: colors.white,
		},
		Modal: {},
		Form: {
			colorTextLabel: colors.text.dark,
		},
		Input: {
			colorBgContainerDisabled: colors.primary.extraLight,
			borderRadius: 4,
			colorSuccessBorder: colors.primary.main,
			colorBorder: colors.form.stroke,
			padding: 8,
			colorTextPlaceholder: colors.grey.main,
		},
		Table: {
			borderRadius: 0,
			borderRadiusLG: 0,
			borderRadiusSM: 0,
			borderRadiusXS: 0,
			headerBorderRadius: 0,
			rowHoverBg: 'transparent',
		},
		Pagination: {
			colorText: colors.grey.dark,
			colorPrimaryTextActive: colors.main,
			colorPrimaryTextHover: colors.main,
			colorBgTextHover: 'transparent',
			colorBgTextActive: 'transparent',
		},
	},
}
