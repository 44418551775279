import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { FC } from 'react'
import FormHeader from '@atoms/text/formHeader'
import FormResetPass from '@organisms/forms/reset/index'
import { appPage } from '@redux/reducers/appSlice'
import AsideTemplate from '@templates/asideTemplate'

const RecoveryPass: FC = () => {
	const { page } = useSelector((state: any) => state.app)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if (localStorage.getItem('recovery-token')) {
			dispatch(appPage('recovery'))
			return
		}

		navigate('/')
	}, [])

	return (
		<>
			<Helmet title={page} />
			<AsideTemplate showBenefits center page={page}>
				<FormHeader type={'reset'} />
				<FormResetPass />
			</AsideTemplate>
		</>
	)
}

export default RecoveryPass
