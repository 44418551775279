import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
/*
 *
 * User reducer
 *
 */
const initialState = {
	loading: false,
	info: {} as any,
	list: [],
	choice: {} as any,
	sessionProperties: {} as any,
}

export type userInitStateType = typeof initialState

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		getUserInfo: () => console.log('getUserInfo'),
		profileCreate: () => console.log('profileCreate'),
		profileUpdate: () => console.log('profileUpdate'),
		profileConfirmPhone: () => console.log('profileConfirmPhone'),
		changeStep: () => console.log('changeStep'),
		sendSmsOnPhone: () => console.log('sendSmsOnPhone'),
		setUser: (state, action: PayloadAction<any>) => {
			state.info = action.payload
		},
		setChoice: (state, action: PayloadAction<any>) => {
			state.choice = action.payload
		},
		updateChoiceUser: (state, action: PayloadAction<any>) => {
			state.choice = { ...state.choice, ...action.payload }
		},
		updateUser: (state, action: PayloadAction<any>) => {
			state.info = { ...state.info, ...action.payload }
		},
		setLoading: (state, action: PayloadAction<any>) => {
			state.loading = action.payload
		},
		setSessionProperties: (state, action: PayloadAction<any>) => {
			state.sessionProperties = action.payload
		},
	},
})

export default userSlice.reducer
export const {
	getUserInfo,
	profileConfirmPhone,
	profileCreate,
	profileUpdate,
	changeStep,
	sendSmsOnPhone,
	setUser,
	setChoice,
	updateChoiceUser,
	updateUser,
	setLoading,
	setSessionProperties,
}: any = userSlice.actions
