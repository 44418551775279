import { Layout } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'
import AppSider from '@atoms/siders/AppSider'
import AppText from '@atoms/text/AppText'
import AppTitle from '@atoms/text/AppTitle'
import DynamicSteps from '@organisms/dynamicSteps'
import { appPage } from '@redux/reducers/appSlice'

import useProfileSteps from '@tools/hooks/steps/useProfileSteps'

const CompanyRegistrationOther: FC = () => {
	const { profileSteps, currentProfileStep, setCurrentProfileStep } = useProfileSteps()

	const { t } = useTranslation()
	const dispatch = useDispatch()
	useEffect(() => {
		localStorage.getItem('company_step') &&
			setCurrentProfileStep(Number(localStorage.getItem('company_step')))
		dispatch(appPage('company-reg'))
	}, [])

	return (
		<Layout>
			<AppSider>
				<AppTitle level={3} title={t('Company')} />
				<AppText
					text={t(
						'Fill in and confirm information about the company to use all the functionality of the platform.'
					)}
					style={{ fontWeight: 500, color: '#EDF2FF', margin: 0, fontSize: 16 }}
				/>
			</AppSider>

			<Layout>
				<div style={{ maxWidth: 576 }}>
					<DynamicSteps steps={profileSteps} current={currentProfileStep} />
				</div>
			</Layout>
		</Layout>
	)
}

export default CompanyRegistrationOther
