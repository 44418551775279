import './style.scss'
import { Modal } from 'antd'
import classNames from 'classnames'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import ButtonDefault from '@atoms/buttons/button'
import { getOptions } from '@redux/reducers/appSlice'
import { profileUpdate } from '@redux/reducers/userSlice'

interface EditProfileModalModalType {
	component: any
	action?: any
}

const AddStatusModal: FC<EditProfileModalModalType> = ({
	component: Component, //action,
}: EditProfileModalModalType) => {
	const { info } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const [visible, setVisible] = useState(false)
	const [active, setActive] = useState(null)
	const [loader, setLoader] = useState(false)

	const { office_presence_status } = info

	const dispatch = useDispatch()

	useEffect(() => {
		if (visible) {
			dispatch(
				getOptions({
					request: {
						source: ['office_presence_status'],
					},
				})
			)
		}
	}, [visible])

	useEffect(() => {
		setActive(office_presence_status?.id)
	}, [office_presence_status])

	const onCancel = () => {
		setVisible(false)
	}

	// const call = () => {
	// 	setLoader(true)
	// 	action(onCancel)
	// }

	const setStatus = () => {
		const request = {
			office_presence_status_id: active,
		}
		dispatch(
			profileUpdate({
				request,
				callback: {
					success: () => {
						setLoader(false)
						onCancel()
					},
					error: () => {
						setLoader(false)
					},
				},
			})
		)
	}

	return (
		<>
			<>
				<Component
					onClick={() => {
						setVisible(true)
					}}
				/>
				<Modal
					className='modal-status'
					open={visible}
					width={504}
					footer={null}
					closable={true}
					styles={{ body: { padding: '64px' } }}
					centered
					onCancel={onCancel}
				>
					<div className='modal-status__container'>
						<div className='modal-status__header'>
							<div className='modal-status__title'>{t('Office presence status')}</div>
							<div className='modal-status__desc'>
								{t(
									'The desired status will help the team understand how quickly you can answer messages and when you are available'
								)}
							</div>
						</div>
						<div className='modal-status__body'>
							{options?.office_presence_status &&
								options?.office_presence_status.map((item: any) => {
									const { id, name } = item
									return (
										<div
											className={classNames(
												`modal-status__item modal-status__item-${id}`,
												{
													[`modal-status__item-active-${active}`]:
														active === id ? true : false,
												}
											)}
											key={id}
											onClick={() => setActive(id)}
										>
											{name}
										</div>
									)
								})}
						</div>
						<div className='modal-status__footer'>
							<ButtonDefault name={t('Select')} action={setStatus} loading={loader} />
						</div>
					</div>
				</Modal>
			</>
		</>
	)
}

export default AddStatusModal
