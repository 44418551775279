import React, { useState } from 'react'

import FormSetAdmin from '@organisms/forms/registrationCompany/setAdmin'
import FormSetCompanyMain from '@organisms/forms/registrationCompany/setCompany'

const useRegCompanySteps = () => {
	const [current, setCurrent] = useState(1)

	const steps = [
		{
			id: 1,
			content: <FormSetCompanyMain setCurrent={setCurrent} />,
		},
		{
			id: 2,
			content: <FormSetAdmin setCurrent={setCurrent} />,
		},
	]

	return {
		current,
		setCurrent,
		steps,
	}
}

export default useRegCompanySteps
