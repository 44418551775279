import { Modal } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'

import EditProfile from '@organisms/forms/editProfile'

import './style.scss'
import { getOptions } from '@redux/reducers/appSlice'

interface EditProfileModalModalType {
	component: any
	action?: any
}

const EditProfileModal: FC<EditProfileModalModalType> = ({
	component: Component, //action,
}: EditProfileModalModalType) => {
	const [visible, setVisible] = useState(false)
	const [_loader, _setLoader] = useState(false)

	const dispatch = useDispatch()

	useEffect(
		() => () => {
			console.log('unmount', visible)
		},
		[]
	)

	useEffect(() => {
		if (visible) {
			dispatch(
				getOptions({
					request: {
						source: ['gender', 'social_media', 'messengers'],
					},
				})
			)
		}
	}, [visible])

	const onCancel = () => {
		setVisible(false)
	}

	// const call = () => {
	// 	setLoader(true)
	// 	action(onCancel)
	// }

	return (
		<>
			<>
				<Component
					onClick={() => {
						setVisible(true)
					}}
				/>
				<Modal
					className='modal-edit-profile'
					open={visible}
					width={1200}
					footer={null}
					closable={true}
					styles={{
						body: { padding: '64px' },
						mask: { backgroundColor: 'rgba(220, 222, 252, 0.6)' },
					}}
					centered
					onCancel={onCancel}
					maskClosable={false}
				>
					<div className='modal-edit-profile__body'>
						<div className='modal-edit-profile__header'>
							<div className='modal-edit-profile__title'>
								{t('Information Editing')}
							</div>
							<div className='modal-edit-profile__desc'>
								{t('Change your personal details')}
							</div>
						</div>
						<div className='modal-edit-profile__form'>
							<EditProfile setVisible={setVisible} />
						</div>
					</div>
				</Modal>
			</>
		</>
	)
}

export default EditProfileModal
