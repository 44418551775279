import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createPhoneMask } from '@tools/helpers'

const useFormHeader: any = (type: any, data: any) => {
	const [titles, setTitles] = useState<any>({ title: '', desc: '' })

	const { t } = useTranslation()
	useEffect(() => {
		switch (type) {
			case 'forgot':
				setTitles({
					title: t('Forgot password?'),
					desc: t('You will be sent instructions to reset your password'),
				})
				break

			case 'reset':
				setTitles({ title: t('Password recovery'), desc: '' })
				break

			case 'code':
				setTitles({
					title: t('Confirmation of the entrance'),
					desc: t('We sent an SMS with a 6-digit code', { phone: '+38 (050) 725 60 09' }),
				})
				break

			case 'confirmPhone':
				setTitles({
					title: t('Phone Verification'),
					desc: t('We sent an SMS with a 6-digit code', {
						phone: data?.phone && createPhoneMask(data?.phone),
					}),
				})
				break

			case 'forgotByPhone':
				setTitles({
					title: t('Phone Verification'),
					desc: t('We sent an SMS with a 6-digit code', {
						phone: data?.phone && createPhoneMask(data?.phone),
					}),
				})
				break
			case 'resetPasswordByPhone':
				setTitles({
					title: t('Password recovery'),
					desc: t('We sent an SMS with a 6-digit code', {
						phone: data?.phone && createPhoneMask(data?.phone),
					}),
				})
				break

			default:
				setTitles({ title: '', desc: '' })
				break
		}
	}, [type, t])

	return { titles }
}

export default useFormHeader
