import { Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import IconWrong from '@assets/icons/modals/wrong'
import ButtonDefault from '@atoms/buttons/button'

import './style.scss'

interface RemoveEmailModalType {
	component: any
	action?: any
}

const RemoveEmailModal: FC<RemoveEmailModalType> = ({
	component: Component,
	action,
}: RemoveEmailModalType) => {
	const [visible, setVisible] = useState(false)

	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
	}

	// const call = () => {
	// 	action(onCancel)
	// }

	return (
		<>
			<Component
				onClick={() => {
					setVisible(true)
				}}
			/>
			<Modal
				className='modal-confirm'
				open={visible}
				width={504}
				footer={null}
				closable={true}
				styles={{ body: { padding: '64px' } }}
				centered
				onCancel={onCancel}
			>
				<div className='modal-confirm__header'>
					<IconWrong />
				</div>
				<div className='modal-confirm__body'>
					<div className='modal-confirm__title'>{t('E-mail removal')}</div>
					<div className='modal-confirm__desc'>
						{t('Are you sure you want to delete an e-mail?')}
					</div>
					<div className='modal-confirm__btns'>
						<ButtonDefault action={action} name={t('Delete')} />
						<ButtonDefault action={onCancel} name={t('Cancel')} classes={'btn-gray'} />
					</div>
				</div>
			</Modal>
		</>
	)
}

export default RemoveEmailModal
