import { Modal } from 'antd'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import IconWrong from '@assets/icons/modals/wrong'
import ButtonDefault from '@atoms/buttons/button'

import './style.scss'
import { setStatus, setSuccess } from '@redux/reducers/appSlice'
import { setVerification } from '@redux/reducers/lcVerificatorSlice'

interface RemoveLcCompanyModalType {
	component: any
}

const DeclineVerificationCompany: FC<RemoveLcCompanyModalType> = ({
	component: Component,
}: RemoveLcCompanyModalType) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { id } = useParams()
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	const [visible, setVisible] = useState(false)
	const [_loader, setLoader] = useState(false)
	const [comment, setComment] = useState('')

	const companyId = id || sessionProperties?.companyId

	const onCancel = () => {
		setVisible(false)
	}

	const onChange = (e: any) => {
		setComment(e.target.value)
	}

	const actionVerification = () => {
		dispatch(
			setVerification({
				request: {
					company_id: companyId,
					verification: 0,
					comment,
				},
				callback: {
					success: () => {
						dispatch(setStatus('success'))
						dispatch(setSuccess({ type: 'lc-not-verification' }))
						setLoader(true)
						onCancel()
					},
				},
			})
		)
	}

	return (
		<>
			<Component
				onClick={() => {
					setVisible(true)
				}}
			/>
			<Modal
				className='modal-confirm'
				open={visible}
				width={504}
				footer={null}
				closable={true}
				styles={{ body: { padding: '32px 64px' } }}
				centered
				onCancel={onCancel}
			>
				<div className='modal-confirm__header'>
					<IconWrong />
				</div>
				<div className='modal-confirm__body'>
					<div className='modal-confirm__title decline'>
						{t('Reject the verification')}
					</div>
					<div className='modal-confirm__desc decline-desc'>
						{parse(t('Indicate the reason for the refusal'))}
					</div>
					<div>
						<textarea
							className='send-message__textarea verification'
							placeholder={t(
								'Incorrect data for verification.Download the current confirming document.'
							)}
							value={comment}
							onChange={onChange}
						/>
					</div>
					<div className='modal-confirm__btns-decline'>
						<span className='edit-profile-form__btn-text' onClick={onCancel}>
							{t('Cancellation')}
						</span>
						<ButtonDefault action={actionVerification} name={t('Reject')} />
					</div>
				</div>
			</Modal>
		</>
	)
}

export default DeclineVerificationCompany
