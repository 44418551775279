import React from 'react'

const RocketContainer = () => (
	<svg
		width='348'
		height='215'
		viewBox='0 0 265 215'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g opacity='0.28'>
			<g opacity='0.28'>
				<g opacity='0.28'>
					<g opacity='0.28'>
						<path
							opacity='0.28'
							d='M112.432 124.004C110.458 128.006 108.383 131.956 106.308 135.907C103.929 140.364 101.499 144.821 98.9688 149.228C102.512 158.7 105.346 168.475 107.472 178.403L131.362 178.352C131.311 178.352 124.023 151.963 112.432 124.004Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M96.083 142.036C96.8422 143.809 97.5508 145.581 98.2594 147.405C102.916 139.25 107.319 130.943 111.52 122.535C111.571 122.434 111.672 122.333 111.723 122.282C111.065 120.763 110.458 119.243 109.749 117.724C104.941 125.625 100.436 133.729 96.1842 141.985C96.1336 141.934 96.1336 141.985 96.083 142.036Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M69.1055 151.558C71.4844 155.914 73.9139 160.219 75.8372 164.829C77.5075 168.83 79.026 173.186 78.9753 177.592C81.6073 173.946 84.138 170.248 86.5675 166.5C90.313 160.777 93.856 155.002 97.2472 149.076C92.287 135.907 85.9095 123.295 78.368 111.393C73.8127 104.251 68.8018 97.413 63.3861 90.8792C63.3355 90.8792 63.3355 90.8792 63.2848 90.8792C59.6406 91.2844 55.9964 91.7402 52.4027 92.348C49.5683 92.8039 46.7339 93.361 43.8995 93.9182C44.6587 96.4507 45.7216 98.7806 47.1388 100.857C56.9074 115.141 43.4946 109.822 35.8518 113.621C35.3962 113.874 34.8901 114.178 34.4346 114.533C40.154 116.812 44.9118 120.662 49.2646 124.916C57.2111 132.818 63.7404 141.783 69.1055 151.558Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M100.689 158.902C99.8289 156.218 98.9178 153.584 97.9561 150.9C92.4392 160.321 86.5679 169.59 80.1904 178.453L105.903 178.403C104.485 171.818 102.765 165.335 100.689 158.902Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M62.2223 89.5115C56.8571 83.1802 51.0871 77.1528 44.9121 71.48C42.2801 77.8113 41.8246 85.662 43.4949 92.4492C49.6699 91.1829 55.9461 90.2206 62.2223 89.5115Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M56.6543 60.1849C56.4012 62.3629 58.3245 64.6421 60.6528 64.3382C62.728 64.0343 64.0946 61.6538 63.5378 59.6784C63.5378 59.6278 63.4872 59.5265 63.4872 59.4758C61.0071 59.1719 58.7295 59.3745 56.6543 59.9823C56.6543 60.033 56.6543 60.1343 56.6543 60.1849Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M73.5093 163.309C71.5353 159.054 69.2071 154.951 66.9294 150.798C64.2975 145.986 61.2606 141.428 57.92 137.072C54.6301 132.767 50.9858 128.765 47.0885 125.017C43.1912 121.32 38.889 117.926 33.8275 115.951C33.5744 115.849 33.4226 115.647 33.3214 115.394C27.9562 120.408 23.4009 132.513 35.8015 142.947C50.0748 155.002 47.0885 156.471 44.8109 165.487C42.5332 174.502 46.1268 178.453 46.1268 178.453L77.356 178.402C77.7103 173.236 75.6857 168.019 73.5093 163.309Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M45.6202 70.0618C52.9086 76.7476 59.7416 83.94 65.9672 91.6388C77.1024 105.365 86.4661 120.509 93.7545 136.616C94.3113 137.781 94.8175 138.997 95.3236 140.212C99.474 132.26 103.827 124.46 108.483 116.812C108.635 116.508 108.939 116.407 109.192 116.457C97.2976 89.4608 81.7083 63.1734 65.0561 59.7798C65.5623 62.5149 63.6389 65.4526 60.8045 65.8578C57.7676 66.3137 54.9332 63.5279 55.0851 60.5395C50.8334 62.211 47.6953 65.7059 45.6202 70.0618C45.5695 70.0111 45.6202 70.0618 45.6202 70.0618Z'
							fill='#4F6AFF'
						/>
					</g>
					<g opacity='0.28'>
						<path
							opacity='0.28'
							d='M91.2743 178.858C91.2743 178.858 80.5441 162.65 69.004 148.215C65.7646 152.115 62.6265 156.116 59.5897 160.219L59.5391 160.269C64.3474 165.942 68.9534 171.818 73.205 177.896C73.3568 178.098 73.3062 178.301 73.1544 178.453L91.2743 178.858Z'
							fill='white'
						/>
						<path
							opacity='0.28'
							d='M58.9316 159.51C61.9685 155.407 65.1572 151.406 68.3965 147.455C66.6756 145.328 64.9041 143.2 63.1832 141.225C60.5007 146.239 58.0206 151.355 56.5527 156.876C57.4132 157.737 58.1724 158.598 58.9316 159.51Z'
							fill='white'
						/>
						<path
							opacity='0.28'
							d='M72.3954 178.403C70.6239 175.87 68.8018 173.338 66.8785 170.906C66.3217 170.197 65.7649 169.488 65.2082 168.728C65.1576 168.728 65.1576 168.728 65.107 168.728C57.7172 168.728 50.2263 168.577 42.8872 169.995C42.2798 170.096 42.0267 169.184 42.6341 169.083C49.8214 167.715 57.1605 167.766 64.449 167.766C59.1344 161.03 53.4656 154.597 47.4425 148.519C47.3413 149.228 47.0376 149.836 46.3796 150.19C40.6601 153.533 40.154 141.58 30.1323 146.392C20.1106 151.153 49.7201 177.947 49.7201 177.947L72.3954 178.403Z'
							fill='white'
						/>
						<path
							opacity='0.28'
							d='M52.4529 152.267C53.6171 153.483 54.7306 154.698 55.8441 155.964C57.3625 150.494 59.8426 145.379 62.5252 140.415C54.9836 131.804 47.8976 125.422 44.0003 126.283C35.0415 128.259 46.1261 140.719 47.3915 147.05C49.1124 148.772 50.7826 150.494 52.4529 152.267Z'
							fill='white'
						/>
					</g>
				</g>
			</g>
		</g>
		<g opacity='0.28'>
			<g opacity='0.28'>
				<g opacity='0.28'>
					<g opacity='0.28'>
						<path
							opacity='0.28'
							d='M180.153 124.004C182.127 128.006 184.203 131.956 186.278 135.907C188.657 140.364 191.086 144.821 193.617 149.228C190.074 158.7 187.239 168.475 185.114 178.403L161.224 178.352C161.274 178.352 168.563 151.963 180.153 124.004Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M196.451 142.036C195.692 143.809 194.984 145.581 194.275 147.405C189.618 139.25 185.215 130.943 181.014 122.535C180.963 122.434 180.862 122.333 180.812 122.282C181.47 120.763 182.077 119.243 182.785 117.724C187.594 125.625 192.099 133.729 196.35 141.985C196.401 141.934 196.451 141.985 196.451 142.036Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M223.48 151.558C221.101 155.914 218.671 160.219 216.748 164.829C215.078 168.83 213.559 173.186 213.61 177.592C210.978 173.946 208.447 170.248 206.018 166.5C202.272 160.777 198.729 155.002 195.338 149.076C200.298 135.907 206.676 123.295 214.217 111.393C218.772 104.251 223.783 97.413 229.199 90.8792C229.25 90.8792 229.25 90.8792 229.3 90.8792C232.944 91.2844 236.589 91.7402 240.182 92.348C243.017 92.8039 245.851 93.361 248.686 93.9182C247.926 96.4507 246.863 98.7806 245.446 100.857C235.678 115.141 249.091 109.822 256.733 113.621C257.189 113.874 257.695 114.178 258.151 114.533C252.431 116.812 247.673 120.662 243.32 124.916C235.374 132.818 228.845 141.783 223.48 151.558Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M191.845 158.902C192.706 156.218 193.617 153.584 194.578 150.9C200.095 160.321 205.967 169.59 212.344 178.453L186.632 178.403C188.1 171.818 189.821 165.335 191.845 158.902Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M230.363 89.5115C235.728 83.1802 241.498 77.1528 247.673 71.48C250.305 77.8113 250.761 85.662 249.091 92.4492C242.865 91.1829 236.639 90.2206 230.363 89.5115Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M235.93 60.1849C236.183 62.3629 234.26 64.6421 231.932 64.3382C229.857 64.0343 228.49 61.6538 229.047 59.6784C229.047 59.6278 229.097 59.5265 229.097 59.4758C231.577 59.1719 233.855 59.3745 235.93 59.9823C235.93 60.033 235.93 60.1343 235.93 60.1849Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M219.026 163.309C220.999 159.054 223.328 154.951 225.605 150.798C228.237 145.986 231.274 141.428 234.615 137.072C237.905 132.767 241.549 128.765 245.446 125.017C249.344 121.32 253.646 117.926 258.707 115.951C258.96 115.849 259.112 115.647 259.213 115.394C264.579 120.408 269.134 132.513 256.733 142.947C242.46 155.002 245.446 156.471 247.724 165.487C250.002 174.502 246.408 178.453 246.408 178.453L215.179 178.402C214.875 173.236 216.9 168.019 219.026 163.309Z'
							fill='#4F6AFF'
						/>
						<path
							opacity='0.28'
							d='M246.964 70.0618C239.676 76.7476 232.843 83.94 226.617 91.6388C215.482 105.365 206.119 120.509 198.83 136.616C198.273 137.781 197.767 138.997 197.261 140.212C193.111 132.26 188.758 124.46 184.101 116.812C183.949 116.508 183.646 116.407 183.393 116.457C195.287 89.4608 210.876 63.1734 227.528 59.7798C227.022 62.5149 228.946 65.4526 231.78 65.8578C234.817 66.3137 237.651 63.5279 237.5 60.5395C241.701 62.211 244.889 65.7059 246.964 70.0618C246.964 70.0111 246.964 70.0618 246.964 70.0618Z'
							fill='#4F6AFF'
						/>
					</g>
					<g opacity='0.28'>
						<path
							opacity='0.28'
							d='M201.26 178.858C201.26 178.858 211.99 162.65 223.53 148.215C226.769 152.115 229.908 156.116 232.944 160.219L232.995 160.269C228.187 165.942 223.581 171.818 219.329 177.896C219.177 178.098 219.228 178.301 219.38 178.453L201.26 178.858Z'
							fill='white'
						/>
						<path
							opacity='0.28'
							d='M233.653 159.51C230.617 155.407 227.428 151.406 224.188 147.455C225.909 145.328 227.681 143.2 229.402 141.225C232.084 146.239 234.564 151.355 236.032 156.876C235.172 157.737 234.413 158.598 233.653 159.51Z'
							fill='white'
						/>
						<path
							opacity='0.28'
							d='M220.189 178.403C221.961 175.87 223.783 173.338 225.706 170.906C226.263 170.197 226.82 169.488 227.377 168.728C227.427 168.728 227.427 168.728 227.478 168.728C234.868 168.728 242.358 168.577 249.698 169.995C250.305 170.096 250.558 169.184 249.951 169.083C242.763 167.715 235.424 167.766 228.136 167.766C233.45 161.03 239.119 154.597 245.142 148.519C245.244 149.228 245.547 149.836 246.205 150.19C251.925 153.533 252.431 141.58 262.452 146.392C272.474 151.153 242.865 177.947 242.865 177.947L220.189 178.403C220.139 178.403 220.139 178.403 220.189 178.403Z'
							fill='white'
						/>
						<path
							opacity='0.28'
							d='M245.193 147.05C246.408 140.719 257.543 128.259 248.584 126.283C244.687 125.422 237.601 131.804 230.06 140.415C232.742 145.379 235.273 150.494 236.741 155.964C237.854 154.698 238.968 153.483 240.132 152.267C241.752 150.494 243.472 148.772 245.193 147.05Z'
							fill='white'
						/>
					</g>
				</g>
			</g>
		</g>
		<path
			d='M159.705 171.034V160.397H128.78V171.034V170.983C124.731 173.617 122.757 178.226 122.757 183.393H165.678C165.729 178.176 163.755 173.668 159.705 171.034Z'
			fill='#18004C'
		/>
		<path
			d='M138.902 162.93C145.685 165.716 159.756 170.983 159.756 170.983V160.347H132.677C134.701 161.258 136.827 162.069 138.902 162.93Z'
			fill='#0A0A0A'
		/>
		<path
			d='M172.409 120.637L184.101 134.312C186.936 137.655 188.251 142.011 187.695 146.367L184.506 171.084C184.304 172.806 182.633 174.022 180.912 173.718C179.394 173.465 178.28 172.097 178.382 170.527L178.989 159.384C179.343 152.546 174.636 146.418 167.955 145.05L172.409 120.637Z'
			fill='#FF3040'
		/>
		<path
			d='M116.835 120.637L105.143 134.312C102.308 137.655 100.992 142.011 101.549 146.367L104.738 171.084C104.94 172.806 106.611 174.022 108.332 173.718C109.85 173.465 110.963 172.097 110.862 170.527L110.255 159.384C109.901 152.546 114.608 146.418 121.289 145.05L116.835 120.637Z'
			fill='#FF3040'
		/>
		<path
			d='M179.9 129.399L172.409 120.637L167.955 145.05C169.271 145.303 170.536 145.759 171.65 146.367C174.282 140.694 177.015 134.971 179.9 129.399Z'
			fill='#CF0019'
		/>
		<path
			d='M110.609 136.288C113.14 139.58 115.771 142.822 118.353 146.063C119.315 145.607 120.276 145.303 121.339 145.05L116.885 120.637L107.471 131.678C108.584 133.299 109.647 135.021 110.609 136.288Z'
			fill='#CF0019'
		/>
		<path
			d='M122.605 156.75C122.605 156.75 115.367 138.162 115.722 118.611C116.076 99.0598 111.267 55.6525 124.984 27.4403C133.184 10.6244 138.346 3.73598 141.13 0.899566C142.395 -0.366691 144.521 -0.214739 145.584 1.20347C149.886 6.9776 161.022 22.2233 163.805 28.8079C167.247 37.0639 175.143 52.8668 172.41 120.586C172.41 120.586 171.043 145.709 166.235 156.7H122.605V156.75Z'
			fill='white'
		/>
		<path
			opacity='0.37'
			d='M123.01 49.7772C125.034 36.3042 128.274 22.3754 136.372 11.131C136.726 10.6752 137.131 10.3206 137.536 10.0674C139.611 6.72447 141.687 3.4322 143.762 0.0892831C142.851 -0.0626677 141.889 0.190583 141.13 0.899687C138.295 3.7361 133.183 10.6245 124.984 27.4404C111.217 55.6526 116.076 98.9586 115.721 118.56C115.367 138.162 122.605 156.7 122.605 156.7L166.589 156.497C123.769 156.092 120.277 117.598 119.72 96.3248C119.315 80.7752 120.732 65.1242 123.01 49.7772Z'
			fill='#95A1E6'
		/>
		<path
			d='M158.288 27.8458C160.161 28.1497 161.983 28.5042 163.856 28.8588C161.123 22.2236 149.987 7.02851 145.635 1.25438C144.572 -0.214482 142.446 -0.366432 141.18 0.950476C138.346 3.78689 133.234 10.6753 125.034 27.4912C124.883 27.8458 124.68 28.2003 124.528 28.6055C135.461 25.8198 147.102 26.073 158.288 27.8458Z'
			fill='#FF3040'
		/>
		<path
			d='M136.676 15.0818C139.915 10.7765 142.952 6.31927 146.191 2.014C145.989 1.7101 145.786 1.45685 145.584 1.2036C144.521 -0.265262 142.395 -0.417214 141.13 0.899693C138.295 3.73611 133.183 10.6245 124.984 27.4404C124.832 27.795 124.629 28.1495 124.478 28.5547C126.047 28.1495 127.616 27.8456 129.185 27.5924C130.906 23.0339 133.74 18.9818 136.676 15.0818Z'
			fill='#CF0019'
		/>
		<path
			d='M164.716 162.373H124.427C122.605 162.373 121.137 160.904 121.137 159.081C121.137 157.257 122.605 155.788 124.427 155.788H164.716C166.538 155.788 168.006 157.257 168.006 159.081C168.006 160.904 166.538 162.373 164.716 162.373Z'
			fill='#18004C'
		/>
		<path
			d='M143.661 42.8887C134.095 42.8887 126.351 50.6382 126.351 60.2111C126.351 69.784 134.095 77.5335 143.661 77.5335C153.227 77.5335 160.971 69.784 160.971 60.2111C160.971 50.6888 153.227 42.8887 143.661 42.8887ZM143.661 72.5191C136.878 72.5191 131.361 66.9982 131.361 60.2111C131.361 53.4239 136.878 47.9031 143.661 47.9031C150.443 47.9031 155.96 53.4239 155.96 60.2111C155.909 67.0489 150.443 72.5191 143.661 72.5191Z'
			fill='#18004C'
		/>
		<path
			opacity='0.37'
			d='M143.661 72.5699C150.453 72.5699 155.96 67.0594 155.96 60.2619C155.96 53.4643 150.453 47.9539 143.661 47.9539C136.868 47.9539 131.361 53.4643 131.361 60.2619C131.361 67.0594 136.868 72.5699 143.661 72.5699Z'
			fill='#95A1E6'
		/>
		<g opacity='0.6'>
			<path
				opacity='0.6'
				d='M131.412 61.123C131.868 67.505 137.132 72.57 143.661 72.57C144.673 72.57 145.635 72.4181 146.596 72.2155C141.484 68.5686 136.423 64.8712 131.412 61.123Z'
				fill='#FCFAF5'
			/>
			<path
				opacity='0.6'
				d='M153.024 68.1633C154.847 66.036 155.91 63.2502 155.91 60.2619C155.91 53.4747 150.393 47.9539 143.61 47.9539C139.207 47.9539 135.36 50.2838 133.184 53.728C139.763 58.6411 146.343 63.4528 153.024 68.1633Z'
				fill='#FCFAF5'
			/>
		</g>
		<path
			d='M143.66 46.637C144.303 46.637 144.824 46.1155 144.824 45.4721C144.824 44.8287 144.303 44.3071 143.66 44.3071C143.017 44.3071 142.496 44.8287 142.496 45.4721C142.496 46.1155 143.017 46.637 143.66 46.637Z'
			stroke='#4F6AFF'
			strokeWidth='0.3478'
			strokeMiterlimit='10'
		/>
		<path
			d='M143.66 76.1659C144.303 76.1659 144.824 75.6443 144.824 75.0009C144.824 74.3575 144.303 73.8359 143.66 73.8359C143.017 73.8359 142.496 74.3575 142.496 75.0009C142.496 75.6443 143.017 76.1659 143.66 76.1659Z'
			stroke='#4F6AFF'
			strokeWidth='0.3478'
			strokeMiterlimit='10'
		/>
		<path
			d='M154.923 50.6418C155.377 50.1869 155.377 49.4493 154.923 48.9944C154.468 48.5394 153.731 48.5394 153.276 48.9944C152.822 49.4493 152.822 50.1869 153.276 50.6419C153.731 51.0968 154.468 51.0968 154.923 50.6418Z'
			stroke='#4F6AFF'
			strokeWidth='0.3478'
			strokeMiterlimit='10'
		/>
		<path
			d='M134.062 71.5061C134.517 71.0512 134.517 70.3136 134.062 69.8586C133.608 69.4037 132.871 69.4037 132.416 69.8586C131.961 70.3136 131.961 71.0512 132.416 71.5061C132.871 71.9611 133.608 71.9611 134.062 71.5061Z'
			stroke='#4F6AFF'
			strokeWidth='0.3478'
			strokeMiterlimit='10'
		/>
		<path
			d='M158.389 61.4266C159.032 61.4266 159.553 60.905 159.553 60.2616C159.553 59.6182 159.032 59.0967 158.389 59.0967C157.746 59.0967 157.225 59.6182 157.225 60.2616C157.225 60.905 157.746 61.4266 158.389 61.4266Z'
			stroke='#4F6AFF'
			strokeWidth='0.3478'
			strokeMiterlimit='10'
		/>
		<path
			d='M128.881 61.4266C129.524 61.4266 130.045 60.905 130.045 60.2616C130.045 59.6182 129.524 59.0967 128.881 59.0967C128.238 59.0967 127.717 59.6182 127.717 60.2616C127.717 60.905 128.238 61.4266 128.881 61.4266Z'
			stroke='#4F6AFF'
			strokeWidth='0.3478'
			strokeMiterlimit='10'
		/>
		<path
			d='M154.917 71.5081C155.371 71.0531 155.371 70.3155 154.917 69.8606C154.462 69.4056 153.725 69.4056 153.27 69.8606C152.816 70.3155 152.816 71.0531 153.27 71.5081C153.725 71.963 154.462 71.963 154.917 71.5081Z'
			stroke='#4F6AFF'
			strokeWidth='0.3478'
			strokeMiterlimit='10'
		/>
		<path
			d='M134.069 50.6389C134.524 50.184 134.524 49.4464 134.069 48.9914C133.614 48.5365 132.877 48.5365 132.423 48.9915C131.968 49.4464 131.968 50.184 132.423 50.6389C132.877 51.0939 133.614 51.0939 134.069 50.6389Z'
			stroke='#4F6AFF'
			strokeWidth='0.3478'
			strokeMiterlimit='10'
		/>
		<path
			d='M115.569 85.0806C115.569 85.0806 144.622 75.2545 171.751 84.6248'
			stroke='#4F6AFF'
			strokeWidth='0.6955'
			strokeMiterlimit='10'
			strokeDasharray='5.56 5.56'
		/>
		<path
			d='M119.112 42.7874C127.211 40.6601 148.064 36.4054 168.512 42.3315'
			stroke='#4F6AFF'
			strokeWidth='0.6955'
			strokeMiterlimit='10'
			strokeDasharray='5.56 5.56'
		/>
		<path
			d='M115.569 92.1207C115.569 92.1207 144.622 82.2945 171.751 91.6648'
			stroke='#4F6AFF'
			strokeWidth='0.6955'
			strokeMiterlimit='10'
			strokeDasharray='5.56 5.56'
		/>
		<g opacity='0.79'>
			<path
				opacity='0.79'
				d='M155.149 190.99C155.807 193.371 156.82 195.65 158.085 197.676C159.35 199.753 160.919 201.627 162.742 203.197C164.564 204.767 166.639 206.084 168.866 207.047C171.093 208.009 173.472 208.667 175.901 209.022C173.421 209.123 170.891 208.667 168.562 207.806C166.183 206.945 164.007 205.628 162.084 204.008C160.16 202.336 158.591 200.361 157.376 198.132C156.212 195.904 155.402 193.472 155.149 190.99Z'
				fill='white'
			/>
		</g>
		<g opacity='0.79'>
			<path
				opacity='0.79'
				d='M133.841 193.371C133.588 195.599 132.93 197.727 132.019 199.803C131.108 201.829 129.893 203.754 128.476 205.527C127.059 207.249 125.388 208.819 123.566 210.085C121.744 211.402 119.72 212.415 117.594 213.074C119.568 212.01 121.39 210.795 123.06 209.427C124.73 208.059 126.299 206.54 127.666 204.818C129.033 203.146 130.247 201.323 131.31 199.398C132.373 197.524 133.183 195.498 133.841 193.371Z'
				fill='white'
			/>
		</g>
		<g opacity='0.79'>
			<path
				opacity='0.79'
				d='M135.866 205.173C135.714 205.932 135.461 206.641 135.208 207.351C134.954 208.06 134.651 208.769 134.297 209.427C133.942 210.086 133.588 210.744 133.183 211.403C132.778 212.061 132.323 212.669 131.816 213.226C131.968 212.466 132.221 211.757 132.474 211.048C132.727 210.339 133.031 209.63 133.385 208.971C133.74 208.313 134.094 207.654 134.499 206.996C134.904 206.388 135.309 205.78 135.866 205.173Z'
				fill='white'
			/>
		</g>
		<g opacity='0.79'>
			<path
				opacity='0.79'
				d='M144.926 210.237C145.179 210.541 145.33 210.845 145.482 211.199C145.634 211.503 145.685 211.858 145.735 212.212C145.786 212.567 145.837 212.871 145.786 213.225C145.786 213.58 145.735 213.935 145.584 214.289C145.33 213.985 145.179 213.681 145.027 213.327C144.875 213.023 144.824 212.668 144.774 212.314C144.723 211.959 144.672 211.655 144.723 211.301C144.723 210.946 144.774 210.592 144.926 210.237Z'
				fill='white'
			/>
		</g>
		<g opacity='0.79'>
			<path
				opacity='0.79'
				d='M151.708 204.818L153.075 206.996C153.53 207.705 153.986 208.364 154.492 209.073C154.998 209.731 155.504 210.39 156.01 211.048L157.681 212.973C156.871 212.619 156.111 212.163 155.453 211.606C154.795 211.048 154.188 210.39 153.631 209.681C153.125 208.972 152.67 208.212 152.315 207.402C152.012 206.54 151.809 205.679 151.708 204.818Z'
				fill='white'
			/>
		</g>
	</svg>
)

export default RocketContainer
