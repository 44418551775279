import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// helpers

import type { stateType } from '@types'

import type { FC } from 'react'

import './style.scss'

import type { FormResetPassData, FormResetPassInsideIProps } from 'types/api'
import IconEye from '@assets/icons/inputs/eye'
import IconEyeClose from '@assets/icons/inputs/eyeClose'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault from '@atoms/inputs'
import GeneratePassword from '@molecules/generatePassword'
import { resetPasswordInside } from '@redux/reducers/authSlice'
import i18n from '@src/i18n'
import usePasswordHook from '@tools/hooks/forms/usePasswordHook'
import { resetSchema } from '@tools/shemas'

const FormResetPassInside: FC<FormResetPassInsideIProps> = ({ setActiveTab, setModalActive }) => {
	const { loading } = useSelector((state: stateType) => state.auth)
	const schema = useMemo(() => resetSchema(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		//getValues,
		setValue,
		formState: { errors },
		reset,
	} = useForm<FormResetPassData>({
		defaultValues: {
			password: '',
			password_repeat: '',
		},
		resolver: yupResolver(schema),
	})

	const { passwordType: passwordType, changePasswordType: changePasswordType } = usePasswordHook()
	const { passwordType: passwordRepeatType, changePasswordType: changePasswordRepeatType } =
		usePasswordHook()

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onSubmit = async (data: FormResetPassData) => {
		const { password } = data

		const request = {
			new_password: password,
			reset_password_token: localStorage.getItem('recovery-token'),
		}

		dispatch(
			resetPasswordInside({
				request,
				callback: () => {
					setModalActive(false)
					setActiveTab('reset')
					reset()
				},
			})
		)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form reset-form-inside'>
			<div className='form__container'>
				<InputDefault
					name={'password'}
					label={t('Create a password')}
					placeholder={t('Enter your password')}
					error={errors.password?.message}
					register={register}
					control={control}
					type={passwordType}
				/>
				<div className='form__container-icons'>
					<GeneratePassword setValue={setValue} />
					<div className='form__container-icon' onClick={changePasswordType}>
						{passwordType === 'input' ? <IconEye /> : <IconEyeClose />}
					</div>
				</div>
			</div>

			<div className='form__container'>
				<InputDefault
					name={'password_repeat'}
					label={t('Repeat password')}
					placeholder={t('Repeat your password')}
					error={errors.password_repeat?.message}
					register={register}
					control={control}
					type={passwordRepeatType}
				/>
				<div className='form__container-icons'>
					<div className='form__container-icon' onClick={changePasswordRepeatType}>
						{passwordRepeatType === 'input' ? <IconEye /> : <IconEyeClose />}
					</div>
				</div>
			</div>

			<div className='form__container'>
				<ButtonDefault
					name={t('Save')}
					type={'submit'}
					loading={loading}
					disabled={loading}
				/>
				<ButtonDefault action={() => {}} name={t('Cancel')} classes={'btn-gray cancel'} />
			</div>
		</form>
	)
}

export default FormResetPassInside
