import { Checkbox, Col, Row, Typography } from 'antd'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import PositionFunctionalityModalPositionItems from './PositionFunctionalityModalPositionItems'
import PositionFunctionalityModalPositionNames from './PositionFunctionalityModalPositionNames'
import PositionFunctionalityModalPositionSelected from './PositionFunctionalityModalPositionSelected'
import type { FC } from 'react'

const PositionFunctionalityModalBody: FC<any> = ({
	title,
	positionsId,
	setPositionId,
	positionData,
	addItem,
	removeItem,
}: any) => {
	const { t } = useTranslation()
	const { indeterminateSelected, checkAllSelected, onChangeAllCheckListFunctionality } =
		positionData
	return (
		<div className='functionality-modal__body'>
			<Row className='functionality-modal__row' wrap={false}>
				<Fragment>
					<Col className='functionality-modal__column functionality-modal__column_left'>
						<Typography.Text className='functionality-modal__text'>
							{t('Positions')}
						</Typography.Text>
						<PositionFunctionalityModalPositionNames
							positionsId={positionsId}
							setPositionId={setPositionId}
							positionData={positionData}
						/>
					</Col>

					<Col className='functionality-modal__column functionality-modal__column_center'>
						<Typography.Text className='functionality-modal__text'>
							{title}
						</Typography.Text>

						<PositionFunctionalityModalPositionItems
							positionData={positionData}
							addItem={addItem}
							removeItem={removeItem}
						/>
					</Col>

					<Col className='functionality-modal__column functionality-modal__column_right'>
						<Checkbox
							className='functionality-list__item'
							checked={checkAllSelected}
							indeterminate={indeterminateSelected}
							onChange={onChangeAllCheckListFunctionality}
						>
							<Typography.Text className='functionality-modal__text'>
								{t('Selected')}
							</Typography.Text>
						</Checkbox>
						<PositionFunctionalityModalPositionSelected positionData={positionData} />
					</Col>
				</Fragment>
			</Row>
		</div>
	)
}

export default PositionFunctionalityModalBody
