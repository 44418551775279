import { UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Divider, Row, Tooltip } from 'antd'
import React, { useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import PreviewVacancyPrint from './PreviewVacancyPrint'

import type { stateType } from '@types'
import type { FC } from 'react'
import FormAddUser from '@organisms/forms/addUser/index-old'
import StaticFormModal from '@organisms/modals/staticForm'
import { getDescriptions, getPriorityName, getDescriptionPreviewVacancy } from '@tools/helpers'

// import { createFile } from '@api/asyncHelpers';

const PreviewVacancy: FC<any> = ({ onSubmit }: any) => {
	const { t } = useTranslation()
	const {
		positions,
		user: { user },
		company: { company },
		dropDownList: { lists },
	} = useSelector((state: stateType) => ({
		user: state.user.info,
		company: state.company.info,
		dropDownList: state.app.options,
		positions: { info: {} },
	}))
	const { info }: any = positions

	const [visible, setVisible] = useState(false)

	const description = getDescriptions({
		salary: getDescriptionPreviewVacancy(info?.salary)
			? `${getDescriptionPreviewVacancy(info?.salary)} ${t('UAH')}`
			: null,
		employment_type: info?.employment_type?.name,
		level: info?.level?.name,
	})

	const [users, setUsers] = useState([])
	const handleOnAddUser = (options: any) => {
		setUsers(options)
		setVisible(!visible)
	}

	const handlePrint = useReactToPrint({
		removeAfterPrint: true,
		content: () => {
			const print_section = document.createElement('section')
			print_section.innerHTML = ReactDOMServer.renderToStaticMarkup(
				<PreviewVacancyPrint
					store={{ positions, user, company, lists }}
					users={users}
					subTitle={description}
				/>
			)
			return print_section
		},
	})
	const handleOnCreateFile = async () => {
		// const HTML = ReactDOMServer.renderToStaticMarkup(
		// 	<PreviewVacancyPrint
		// 		store={{ positions, user, company, lists }}
		// 		users={users}
		// 		subTitle={description}
		// 	/>
		// )
		/*
      try {
      const buffer = await createFile(HTML);
      const file = new Blob([buffer], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${info.name}.pdf`;
      link.click();
    } catch (e) {
      message.error(e.message);
    }
    */
	}

	return (
		<>
			<StaticFormModal
				visible={visible}
				changeVisible={() => setVisible(!visible)}
				text={t('Select the users who must approve the vacancy')}
				title={t('Job approval')}
				component={<FormAddUser onSubmitForm={handleOnAddUser} info={info} />}
			/>

			<div className='preview'>
				<div className='preview__body'>
					<Row className='preview__row' style={{ height: '100%' }}>
						<Col span={16} className='preview__col preview__col-left'>
							<div className='preview__header'>
								<span className='preview__title'>{info.name}</span>
								<span className='preview__subtitle'>{description}</span>
							</div>
							<div className='preview__description'>
								<span className='preview__description-title'>
									{t('Functional')}
								</span>
								<Row className='preview__description-list'>
									{info?.functionality?.map((func: any) => (
										<Col
											span={24}
											className='preview__description-list-item'
											key={func.id}
										>
											{func.name}
										</Col>
									))}
								</Row>
							</div>
							<div className='preview__description'>
								<span className='preview__description-title'>{t('Result')}</span>
								<Row className='preview__description-list' align='stretch'>
									{info?.result?.map((el: any) => (
										<Col
											span={24}
											className='preview__description-list-item'
											key={el.id}
										>
											{el.name}
										</Col>
									))}
								</Row>
							</div>
							<div className='preview__description'>
								<span className='preview__description-title'>{t('KPI')}</span>
								<Row className='preview__description-list'>
									{info?.kpi?.map((el: any) => (
										<Col
											span={24}
											className='preview__description-list-item'
											key={el.id}
										>
											{el.name}
										</Col>
									))}
								</Row>
							</div>

							<Divider style={{ background: '#CBD5E2' }} />

							<div className='preview__specifics specifics'>
								<Row gutter={[18, 12]} className='specifics__row'>
									{/*Languages*/}
									<Col span={12} className='specifics__col'>
										<span className='specifics__name'>{t('Languages')}:</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											{info?.languages?.length > 0 ? (
												info?.languages.map((language: any) => (
													<Col
														className='specifics__list-item'
														key={language.id}
													>
														{language.name} {language.levels}
													</Col>
												))
											) : (
												<Col>-</Col>
											)}
										</Row>
									</Col>
									{/*Programs*/}
									<Col span={12} className='specifics__col'>
										<span className='specifics__name'>{t('Programs')}:</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											{info?.programs?.length > 0 ? (
												info?.programs.map((program: any) => (
													<Col
														className='specifics__list-item'
														key={program.id}
													>
														{program.name}
													</Col>
												))
											) : (
												<Col>-</Col>
											)}
										</Row>
									</Col>
									{/*Position specifics*/}
									<Col span={12} className='specifics__col'>
										<span className='specifics__name'>
											{t('Position specifics')}:
										</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											<Col className='specifics__list-item'>
												{info?.dress_code?.name ? (
													<Tooltip
														overlayInnerStyle={{
															fontSize: 12,
															width: 300,
															textAlign: 'center',
														}}
														placement='topLeft'
														color='#4E5AF2'
														title={info?.dress_code?.name}
													>
														{t('Dress code')}: {info?.dress_code?.name}
													</Tooltip>
												) : (
													`${t('Dress code')}: -`
												)}
											</Col>

											<Col className='specifics__list-item'>
												{info?.work_process?.name ? (
													<Tooltip
														overlayInnerStyle={{
															fontSize: 12,
															width: 300,
															textAlign: 'center',
														}}
														placement='topLeft'
														color='#4E5AF2'
														title={info?.work_process?.name}
													>
														{t('Working process')}:{' '}
														{info?.work_process?.name}
													</Tooltip>
												) : (
													`${t('Working process')}: -`
												)}
											</Col>
											<Col className='specifics__list-item'>
												{info?.work_place?.name ? (
													<Tooltip
														overlayInnerStyle={{
															fontSize: 12,
															width: 300,
															textAlign: 'center',
														}}
														placement='topLeft'
														color='#4E5AF2'
														title={info?.work_place?.name}
													>
														{t('Workplace')}: {info?.work_place?.name}
													</Tooltip>
												) : (
													`${t('Workplace')}: -`
												)}
											</Col>
											<Col className='specifics__list-item'>
												{info?.customer_communications?.name ? (
													<Tooltip
														overlayInnerStyle={{
															fontSize: 12,
															width: 300,
															textAlign: 'center',
														}}
														placement='topLeft'
														color='#4E5AF2'
														title={info?.customer_communications?.name}
													>
														{t('Communication with clients')}:{' '}
														{info?.customer_communications?.name}
													</Tooltip>
												) : (
													`${t('Communication with clients')}: -`
												)}
											</Col>
											<Col className='specifics__list-item'>
												{info?.supervisor_communications?.name ? (
													<Tooltip
														overlayInnerStyle={{
															fontSize: 12,
															width: 300,
															textAlign: 'center',
														}}
														placement='topLeft'
														color='#4E5AF2'
														title={
															info?.supervisor_communications?.name
														}
													>
														{t('Communication with the manager')}:{' '}
														{info?.supervisor_communications?.name}
													</Tooltip>
												) : (
													`${t('Communication with the manager')}: -`
												)}
											</Col>
											<Col className='specifics__list-item'>
												{info?.credentials ? (
													<Tooltip
														overlayInnerStyle={{
															fontSize: 12,
															width: 300,
															textAlign: 'center',
														}}
														placement='topLeft'
														color='#4E5AF2'
														title={info?.credentials}
													>
														{t('Powers')}: {info?.credentials}
													</Tooltip>
												) : (
													`${t('Powers')}: -`
												)}
											</Col>
											<Col className='specifics__list-item'>
												{info?.missions ? (
													<Tooltip
														overlayInnerStyle={{
															fontSize: 12,
															width: 300,
															textAlign: 'center',
														}}
														placement='topLeft'
														color='#4E5AF2'
														title={info?.missions}
													>
														{t('Business trips')}: {info?.missions}
													</Tooltip>
												) : (
													`${t('Business trips')}: -`
												)}
											</Col>
											<Col className='specifics__list-item'>
												{info?.opportunities ? (
													<Tooltip
														overlayInnerStyle={{
															fontSize: 12,
															width: 300,
															textAlign: 'center',
														}}
														placement='topLeft'
														color='#4E5AF2'
														title={info?.opportunities}
													>
														{t('Opportunity for career growth')}:{' '}
														{info?.opportunities}
													</Tooltip>
												) : (
													`${t('Opportunity for career growth')}: -`
												)}
											</Col>
										</Row>
									</Col>
									{/*Education*/}
									<Col span={12} className='specifics__col'>
										<span className='specifics__name'>{t('Education')}:</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											<Col className='specifics__list-item'>
												{t('Education')}:{' '}
												{info?.education?.name
													? info?.education?.name
													: '-'}
											</Col>
										</Row>
									</Col>
									{/*Rights and Auto*/}
									<Col span={12} className='specifics__col'>
										<span className='specifics__name'>
											{t('Rights and Auto')}:
										</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											<Col className='specifics__list-item'>
												{t('Rights required')} -{' '}
												{info?.driver_license
													? info?.driver_license
													: t('No')}
											</Col>
											<Col className='specifics__list-item'>
												{t('Need your car')} -{' '}
												{info.car_available ? info.car_available : t('No')}
											</Col>
											{info?.driver_categories?.map((category: any) => (
												<Col
													className='specifics__list-item'
													key={category.idItem}
												>
													{t('Category')} {category.name}
												</Col>
											))}
										</Row>
									</Col>
									{/*Competencies*/}
									<Col span={12} className='specifics__col'>
										<span className='specifics__name'>
											{t('Competencies')}:
										</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											{info?.competencies?.length > 0 ? (
												info?.competencies?.map((el: any) => (
													<Col
														className='specifics__list-item'
														key={el.idItem}
													>
														{el.name}
													</Col>
												))
											) : (
												<Col>-</Col>
											)}
										</Row>
									</Col>
									{/*Personal qualities*/}
									<Col span={12} className='specifics__col'>
										<span className='specifics__name'>
											{t('Personal qualities')}:
										</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											{info?.personal_qualities?.length > 0 ? (
												info?.personal_qualities?.map((el: any) => (
													<Col
														className='specifics__list-item'
														key={el.idItem}
													>
														{el.name}
													</Col>
												))
											) : (
												<Col>-</Col>
											)}
										</Row>
									</Col>
									{/*Compensation package*/}
									<Col span={12} className='specifics__col'>
										<span className='specifics__name'>
											{t('Compensation package')}:
										</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											{info?.compensation_package?.length > 0 ? (
												info?.compensation_package?.map((el: any) => (
													<Col
														className='specifics__list-item'
														key={el.idItem}
													>
														{el.name}
													</Col>
												))
											) : (
												<Col>-</Col>
											)}
										</Row>
									</Col>
								</Row>
							</div>
						</Col>
						<Col
							span={8}
							className='preview__col preview__col-right'
							style={{ backgroundColor: '#EDF2FF' }}
						>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<Button block shape='round' type='primary' onClick={onSubmit}>
										{users.length
											? t('Send for approval')
											: t('Accept and publish')}
									</Button>
								</Col>
								<Col span={24}>
									<Row gutter={16}>
										<Col span={12}>
											<Button
												block
												shape='round'
												type='default'
												onClick={handlePrint}
											>
												{t('Print')}
											</Button>
										</Col>
										<Col span={12}>
											<Button
												block
												shape='round'
												type='default'
												onClick={handleOnCreateFile}
											>
												{t('Download')}
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>

							{/* Author */}
							<Divider style={{ background: '#CBD5E2' }} />
							<div className='preview__specifics specifics'>
								<Row gutter={[18, 12]} className='specifics__row'>
									<Col span={24} className='specifics__col'>
										<span className='specifics__name'>{t('Author')}:</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											<Col className='specifics__list-item specifics__list-item-avatar'>
												<Avatar
													size={18}
													style={{
														backgroundColor: '#8D8BED',
														marginRight: 5,
													}}
													icon={<UserOutlined />}
												/>
												{user?.name} {user?.sname}
											</Col>
										</Row>
									</Col>

									<Col span={24} className='specifics__col'>
										<span className='specifics__name'>{t('Customer')}:</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											<Col className='specifics__list-item specifics__list-item-avatar'>
												<Avatar
													size={18}
													style={{
														backgroundColor: '#8D8BED',
														marginRight: 5,
													}}
													icon={<UserOutlined />}
												/>
												{t('Name and surname of the Customer')}
											</Col>
										</Row>
									</Col>

									<Col span={24} className='specifics__col'>
										<span className='specifics__name'>
											{t('Send for approval')}:
										</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											<Col span={24}>
												<Button
													onClick={() => setVisible(!visible)}
													style={{ padding: 0 }}
													type='link'
												>
													{t('Choose a colleague')}
												</Button>
											</Col>
											{users.map((user: any) => (
												<Col span={24} key={user.id}>
													<Row gutter={5} wrap={false}>
														<Col>
															<div className='specifics__list-item specifics__list-item-avatar'>
																<Avatar
																	size={18}
																	style={{
																		backgroundColor: '#8D8BED',
																		marginRight: 5,
																	}}
																	icon={<UserOutlined />}
																/>
																{user.name}
															</div>
														</Col>
													</Row>
												</Col>
											))}
										</Row>
									</Col>
								</Row>
							</div>

							{/* Resume Selection Criteria */}
							<Divider style={{ background: '#CBD5E2' }} />
							<div className='preview__specifics specifics'>
								<Row gutter={[18, 12]} className='specifics__row'>
									<Col span={24} className='specifics__col'>
										<span className='specifics__name'>
											{t('Resume Selection Criteria')}:
										</span>
										<Row gutter={[6, 6]}>
											{info?.criteria?.length > 0 ? (
												info.criteria.map((el: any) => (
													<Col span={24} key={el.id}>
														<Row gutter={5}>
															<Col>
																<p className='specifics__list-item'>
																	{el.name}
																</p>
															</Col>
															<Col>
																<p className='specifics__list-item'>
																	{getPriorityName(
																		lists?.s_priority,
																		el.priority
																	)}
																</p>
															</Col>
														</Row>
													</Col>
												))
											) : (
												<Col span={24}>-</Col>
											)}
										</Row>
									</Col>
								</Row>
							</div>

							{/*Company*/}
							<Divider style={{ background: '#CBD5E2' }} />
							<div className='preview__specifics specifics'>
								<Row gutter={[18, 12]} className='specifics__row'>
									<Col span={24} className='specifics__col'>
										<span className='specifics__name'>Company:</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											{company && (
												<>
													<Col className='specifics__list-item'>
														{company.companyName}
													</Col>
													<Col className='specifics__list-item'>
														{company.kod}
													</Col>
													{company.s_activity?.name && (
														<Col className='specifics__list-item'>
															Field of activity:{' '}
															{company.s_activity.name}
														</Col>
													)}
													{company.s_organizational_structure?.name && (
														<Col className='specifics__list-item'>
															{'Structure'}:{' '}
															{
																company.s_organizational_structure
																	.name
															}
														</Col>
													)}
													{company.s_workers?.name && (
														<Col className='specifics__list-item'>
															{t('The number of employees')}:{' '}
															{company.s_workers.name}
														</Col>
													)}
												</>
											)}
										</Row>
									</Col>
									<Col span={24} className='specifics__col'>
										<span className='specifics__name'>{t('Director')}:</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											<Col className='specifics__list-item specifics__list-item-avatar'>
												<Avatar
													size={18}
													style={{
														backgroundColor: '#8D8BED',
														marginRight: 5,
													}}
													icon={<UserOutlined />}
												/>
												{t('Name and surname of the Director')}
											</Col>
										</Row>
									</Col>
									<Col span={24} className='specifics__col'>
										<span className='specifics__name'>
											{t('Department name')}:
										</span>
										<Row gutter={[5, 5]} className='specifics__list'>
											<Col className='specifics__list-item'>
												{info.department_name}
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default PreviewVacancy
