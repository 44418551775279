import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import facebook from '@image/icons/socials/facebook.svg'
import google from '@image/icons/socials/google.svg'
import linkedin from '@image/icons/socials/linkedin.svg'

import './style.scss'

const LoginOptions: FC = () => {
	const { t } = useTranslation()

	return (
		<div className='login-options'>
			<p className='login-options__title'>{t('Or sign in with')}</p>
			<div className='login-options__list'>
				<div className='login-options__card'>
					<img className='login-options__icon' src={google} alt='google' />
				</div>
				<div className='login-options__card'>
					<img className='login-options__icon' src={facebook} alt='facebook' />
				</div>
				<div className='login-options__card'>
					<img className='login-options__icon' src={linkedin} alt='linkedin' />
				</div>
			</div>
		</div>
	)
}

export default LoginOptions
