import { Col, Form, Input, Row } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppSelect from '@molecules/app/select'

const AddressesFields = (props: any) => {
	const { t } = useTranslation()

	const getNotFoundDescription = (list: any, name: any) =>
		!list?.length && name?.length < 3
			? t('Enter more than number characters', { number: 2 })
			: t('No data')

	const {
		formName,
		label,
		disabled,
		required,
		office,
		onClearCity,
		onSelectCity,
		onSearchCity,
		onSelectStreet,
		onClearStreet,
		onSearchStreet,
		onChangeStreetNumber,
	} = props
	const { city_id, city_name, city_list, street_id, street_name, street_list } = office[formName]

	const [citiesLoading, setCitiesLoading] = useState(false)
	const [streetsLoading, setStreetsLoading] = useState(false)

	const handleOnSearchCity = async (name: any) => {
		setCitiesLoading(true)
		await onSearchCity(formName, name)
		setCitiesLoading(false)
	}
	const handleOnSearchStreet = async (name: any) => {
		setStreetsLoading(true)
		await onSearchStreet(formName, name)
		setStreetsLoading(false)
	}
	return (
		<>
			{label && (
				<div className='form__label_control'>
					<p className={`form__label ${required ? 'form__label-required' : ''}`}>
						{label}
					</p>
				</div>
			)}

			<Row gutter={[12, 12]}>
				<Col span={8}>
					<Form.Item
						name={`${formName}_city`}
						rules={[{ required: required, message: t('Field required') }]}
					>
						<AppSelect
							value={city_name}
							placeholder={t('City')}
							autoComplete='off'
							disabled={disabled}
							dropdownMatchSelectWidth={350}
							notFoundDescription={getNotFoundDescription(city_list, city_name)}
							onClear={() => onClearCity(formName)}
							onSelect={(option: any) => onSelectCity(formName, option)}
							onSearch={handleOnSearchCity}
							options={city_list}
							isLoading={citiesLoading}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						name={`${formName}_street`}
						rules={[{ required: city_id && required, message: t('Field required') }]}
					>
						<AppSelect
							placeholder={t('Street')}
							autoComplete='off'
							disabled={!city_id}
							dropdownMatchSelectWidth={350}
							notFoundDescription={getNotFoundDescription(street_list, street_name)}
							onClear={() => onClearStreet(formName)}
							onSelect={(option: any) => onSelectStreet(formName, option)}
							onSearch={handleOnSearchStreet}
							options={street_list}
							isLoading={streetsLoading}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						name={`${formName}_street_number`}
						rules={[{ required: street_id && required, message: t('Field required') }]}
					>
						<Input
							onChange={e => onChangeStreetNumber(formName, e.target.value)}
							autoComplete='off'
							disabled={!street_id}
							className='form__input'
							placeholder={t('Street number')}
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	)
}

export default AddressesFields
