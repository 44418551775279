import { get, post } from '../api_helper'
import type { AxiosResponse } from 'axios'

export const api = {
	getUserInfo: async (_token: string): Promise<AxiosResponse> => {
		try {
			return await get('/profile/get_userInfo')
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	profileCreate: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post('/profile/profileCreate', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	updateProfile: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post('/profile/updateProfile', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	confirmPhone: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post('/profile/confirmPhone', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	sendSmsOnPhone: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post('/profile/confirmPhoneSendSms', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},
}
