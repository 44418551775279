import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { CompanyType } from './index'
import type { FC } from 'react'
import IconDelete from '@assets/icons/btn/delete'
import IconRecovry from '@assets/icons/btn/recovery'
import IconSpinner2 from '@assets/icons/btn/spinner2'
import DropdownCompaniesNav from '@molecules/app/dropdwons/companyNav'
import RecoveryCompanyModal from '@organisms/modals/confirms/recoveryCompany'
import RemoveCompanyModal from '@organisms/modals/confirms/removeCompany'
import {
	loginToCompany,
	recoveryCompany,
	removeCompanyFromList,
	restoreCompany,
} from '@redux/reducers/companySlice'
import { getUserInfo } from '@redux/reducers/userSlice'
import { NOTIFICATION } from '@redux/sagas/notification'

import './style.scss'

interface CompanyListItemType {
	item: CompanyType
	loadingLogin: boolean
	initID: false | number
	setInitID: React.Dispatch<React.SetStateAction<false | number>>
	index: number
	setSuccess: any
	info?: any
}

const CompanyListItem: FC<CompanyListItemType> = ({
	item,
	loadingLogin,
	initID,
	setInitID,
	index,
	setSuccess,
	info,
}: CompanyListItemType) => {
	const [opacity, setOpacity] = useState<boolean>(false)
	const dispatch = useDispatch()
	const { id, name, role, status, is_del } = item

	const { sort } = useParams()
	const { t } = useTranslation()

	useEffect(() => {
		setTimeout(() => {
			setOpacity(true)
		}, index * 100)
	}, [])
	//companyRestore
	const actionRestoreCompany = companyId => {
		dispatch(
			restoreCompany({
				request: {
					company_id: companyId,
				},
				callback: {
					success: () => {
						NOTIFICATION.success(t('Company successfully restored'))
						setTimeout(() => window.location.reload(), 1000)
					},
				},
			})
		)
	}

	const navigations = () => {
		switch (sort) {
			case 'archive':
				return (
					<RecoveryCompanyModal
						component={IconRecovry}
						phone={info?.phone}
						action={(callback: any) => {
							dispatch(
								recoveryCompany({
									request: { company_id: id },
									callback: () => {
										dispatch(removeCompanyFromList(id))
										callback()
										setSuccess({
											title: t('The company has been successfully restored!'),
											desc: t(
												'The company has been moved to the «My Companies»  section'
											),
										})
									},
								})
							)
						}}
					/>
				)
			case 'transfer':
				return (
					<RemoveCompanyModal
						component={IconDelete}
						phone={info?.phone}
						action={(_callback: any) => {
							//dispatch(addCompanyToArchive({ request: { company_id: id }, callback: () => callback(false) }));
						}}
					/>
				)

			default:
				return <DropdownCompaniesNav item={item} setSuccess={setSuccess} />
		}
	}

	return (
		<div
			key={id}
			className={classNames('companies-list__item', { ['animation-opacity']: opacity })}
		>
			{loadingLogin && initID === id && (
				<div className='companies-list__item-loader'>
					<div className='icon-spinner-left'>
						<IconSpinner2 />
					</div>
				</div>
			)}
			<div className='companies-list__name'>
				<div
					className='companies-list__title'
					onClick={() => {
						setInitID(id)
						!loadingLogin &&
							dispatch(
								loginToCompany({
									request: { company_id: id },
									callback: () => {
										dispatch(getUserInfo())
									},
								})
							)
					}}
				>
					{name}
				</div>
				<div className='companies-list__role'>{role.name}</div>
			</div>
			<div className='companies-list__nav'>
				{is_del === 1 ? (
					<>
						<span
							className='companies-list__restore link'
							onClick={() => actionRestoreCompany(id)}
						>
							{t('Retrieve')}
						</span>
						<div
							className={classNames(
								'companies-list__status',
								'companies-list__status-5'
							)}
						>
							{t('Remove')}
						</div>
					</>
				) : (
					<div
						className={classNames(
							'companies-list__status',
							`companies-list__status-${status.id}`
						)}
					>
						{status.name}
					</div>
				)}
				{navigations()}
			</div>
		</div>
	)
}

export default CompanyListItem
