import React from 'react'
import type { FC } from 'react'
import { IconUsersSetting } from '@assets/icons/nav/usersTable'
import TabsProfileContainer from '@containers/companies/my/tabsProfileContainer'
import PageBreadcrumb from '@organisms/breadcrumb'

import './style.scss'

interface SettingsContainerType {
	children?: React.ReactNode
}

const MyCompaniesContainer: FC<
	SettingsContainerType
> = ({} /*children */ : SettingsContainerType) => (
	<div className='settings-container'>
		<PageBreadcrumb icon={IconUsersSetting} />
		<TabsProfileContainer />
	</div>
)

export default MyCompaniesContainer
