import React from 'react'

const V8 = () => (
	<svg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M3.8999 0.200195C5.5999 0.200195 6.8999 1.5002 6.8999 3.2002C6.8999 4.9002 5.5999 6.2002 3.8999 6.2002C2.1999 6.2002 0.899902 4.9002 0.899902 3.2002C0.899902 1.5002 2.1999 0.200195 3.8999 0.200195Z'
			fill='#FAFAFA'
		/>
	</svg>
)

export default V8
