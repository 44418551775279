import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'

import './style.scss'

const TooltipJobTitleBody: FC = () => {
	const { t } = useTranslation()
	return (
		<div className='tooltip-job-title'>
			<div className='tooltip-job-title__title'>
				{t(
					'After passing the Verification and filling out a detailed profile of the Company, the functions will be available'
				)}
				:
			</div>
			<li value={'1'} className='tooltip-job-title__li'>
				{t('Division / Department')}
			</li>
			<li value={'2'} className='tooltip-job-title__li'>
				{t('Position')}
			</li>
			<li value={'3'} className='tooltip-job-title__li'>
				{t('Region / City')}
			</li>
		</div>
	)
}

export default TooltipJobTitleBody
