import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { FC } from 'react'
import IconArrowLeft from '@assets/icons/btn/arrow'

import './style.scss'

interface BtnGoBackType {
	onClick?: () => void
}

const BtnGoBack: FC<BtnGoBackType> = ({ onClick }: BtnGoBackType) => {
	const [hover, setHover] = useState<boolean>(false)
	const navigate = useNavigate()
	const { t } = useTranslation()

	const call = () => {
		if (onClick) {
			return onClick()
		}
		navigate(-1)
	}

	return (
		<div
			className='btn-back'
			onClick={call}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<div className='btn-back__icon'>
				<IconArrowLeft color={hover ? '#6d6be5' : false} />
			</div>
			<div className='btn-back__text'>
				<span>{t('Back')}</span>
			</div>
		</div>
	)
}

export default BtnGoBack
