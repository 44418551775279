import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from './Loading'
import type { stateType } from '@types'
import type { FC } from 'react'
import Success from '@pages/success'
import UserRegistration from '@pages/user/registration'
import { setStatus } from '@redux/reducers/appSlice'
import { getUserInfo } from '@redux/reducers/userSlice'
import LcVerificationTemplate from '@templates/lcVerificationTemplate'
import { getStoreToken } from '@tools/helpFunctions'
import usePageStatusController from '@tools/hooks/usePageStatusController'

interface LcVerificationWrapperType {
	children: React.ReactNode
	showTemplate?: boolean
	showHeader?: boolean
}

const LcVerificationWrapper: FC<LcVerificationWrapperType> = ({
	children,
	showTemplate,
	showHeader,
}: LcVerificationWrapperType) => {
	const { status, success } = useSelector((state: stateType) => state.app)
	const token = getStoreToken()
	const { navigatePageController } = usePageStatusController()
	const dispatch = useDispatch()

	useEffect(() => {
		token && dispatch(getUserInfo())
		!token && dispatch(setStatus('auth'))
	}, [])

	useEffect(() => {
		navigatePageController()
	}, [status])

	const controller = () => {
		switch (status) {
			case 'success':
				return <Success type={success.type} action={success.action} />

			case 'loading':
				return <Loading />

			case 'profile-create':
				return <UserRegistration />

			case 'verifier':
				return showTemplate ? (
					<LcVerificationTemplate showHeader={showHeader}>
						{children}
					</LcVerificationTemplate>
				) : (
					<>{children}</>
				)
		}
	}
	return <>{controller()}</>
}

export default LcVerificationWrapper
