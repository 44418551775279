import React from 'react'
import type { FC } from 'react'

import './style.scss'

interface FormNameType {
	label: string
}

const FormName: FC<FormNameType> = ({ label }: FormNameType) => (
	<div className='form-name'>{label}</div>
)

export default FormName
