import { yupResolver } from '@hookform/resolvers/yup'

import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormCompanyContactsData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import BtnGoBack from '@atoms/buttons/goBackButton'
import { contactsFields } from '@configs/formFields'
import RenderFields from '@organisms/forms/renderFields'
import { getOptions } from '@redux/reducers/appSlice'
import { setCompanyInfoContacts, updateCompany } from '@redux/reducers/companySlice'
import i18n from '@src/i18n'
import { setCompanyContactsSchema } from '@tools/shemas'

import './style.scss'

const FormCompanyUpdate: FC = () => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const schema = useMemo(() => setCompanyContactsSchema(), [i18n.language])
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			legal_address_country: '',
			legal_address_city: '',
			legal_address_street: '',
			legal_address_house: '',
			actual_address_country: '',
			actual_address_city: '',
			actual_address_street: '',
			actual_address_house: '',
			corporate_phone: '',
			actual_is_legal_address: false,
			website: '',
			socials: [],
		},
		resolver: yupResolver(schema),
	})
	const [fields, setFields] = useState(contactsFields)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const socialsGroup = useFieldArray({
		control,
		name: 'socials',
	})

	const actualIsLegalAddress = watch('actual_is_legal_address')

	useEffect(() => {
		if (actualIsLegalAddress) {
			setValue('actual_address_country', '-')
			setValue('actual_address_city', '-')
			setValue('actual_address_street', '-')
			setValue('actual_address_house', '-')
		} else {
			setValue('actual_address_country', '')
			setValue('actual_address_city', '')
			setValue('actual_address_street', '')
			setValue('actual_address_house', '')
		}

		setFields(
			actualIsLegalAddress
				? [...contactsFields].map(field =>
						field.group_id === 'actual_address' ? { ...field, hidden: true } : field
				  )
				: contactsFields
		)
	}, [actualIsLegalAddress])

	useEffect(() => {
		if (_.isEmpty(userInfo.company)) {
			navigate('/my-company/profileCompany')
		}
		dispatch(
			getOptions({
				request: {
					source: ['gender', 'social_media', 'messengers'],
				},
			})
		)
	}, [])

	useEffect(() => {
		if (!_.isEmpty(userInfo.company)) {
			const { addresses, corporate_phone, socials, website, actual_is_legal_address } =
				userInfo.company
			const { legal_address, actual_address } = addresses

			if (legal_address) {
				legal_address?.country && setValue('legal_address_country', legal_address?.country)
				legal_address?.city && setValue('legal_address_city', legal_address?.city)
				legal_address?.street && setValue('legal_address_street', legal_address?.street)
				legal_address?.house && setValue('legal_address_house', legal_address?.house)
			}

			if (actual_address) {
				actual_address?.country &&
					setValue('actual_address_country', actual_address?.country)
				actual_address?.city && setValue('actual_address_city', actual_address?.city)
				actual_address?.street && setValue('actual_address_street', actual_address?.street)
				actual_address?.house && setValue('actual_address_house', actual_address?.house)
			}

			actual_is_legal_address && setValue('actual_is_legal_address', actual_is_legal_address)
			socials && setValue('socials', socials)
			website && setValue('website', website)
			corporate_phone && setValue('corporate_phone', corporate_phone)

			if (socials === null || socials.length === 0) {
				socialsGroup.append({ type: 1, name: '' })
			}
		}
	}, [userInfo])

	const onSubmit = (data: FormCompanyContactsData) => {
		const request = {
			legal_address: {
				country: data.legal_address_country,
				city: data.legal_address_city,
				street: data.legal_address_street,
				house: data.legal_address_house,
			},
			actual_address: {
				country: data.actual_is_legal_address
					? data.legal_address_country
					: data.actual_address_country,
				city: data.actual_is_legal_address
					? data.legal_address_city
					: data.actual_address_city,
				street: data.actual_is_legal_address
					? data.legal_address_street
					: data.actual_address_street,
				house: data.actual_is_legal_address
					? data.legal_address_country
					: data.legal_address_house,
			},
			other: {
				actual_is_legal_address: data.actual_is_legal_address,
				corporate_phone: data.corporate_phone,
				website: data.website,
				socials: data.socials,
			},
		}

		if (userInfo.company) {
			dispatch(
				updateCompany({
					request: {
						company_id: userInfo.company.id,
						fields: request.other,
					},
					callback: {
						success: () => {},
					},
				})
			)
			dispatch(
				setCompanyInfoContacts({
					request: {
						company_id: userInfo.company.id,
						legal_address: request.legal_address,
						actual_address: request.actual_address,
					},
					callback: () => {
						navigate('/company-setting/structure')
					},
				})
			)
			return
		}
	}

	const goBack = () => {
		if (userInfo?.company?.id) {
			navigate('/company-setting/general')
			return
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form form-company-contacts'>
			<div className='form__body'>
				{fields.map(({ group, items, hidden, ...field }) => (
					<React.Fragment key={field.name}>
						{group && !hidden && <div className='form__sub-title'>{t(group)}</div>}
						{items ? (
							<div className={`form__sub-content ${hidden ? 'hidden' : ''}`}>
								<RenderFields
									options={options}
									items={items}
									register={register}
									control={control}
									errors={errors}
								/>
							</div>
						) : (
							<RenderFields
								options={options}
								item={field}
								register={register}
								control={control}
								errors={errors}
								socialsGroup={socialsGroup}
							/>
						)}
					</React.Fragment>
				))}
			</div>
			<div className='form-footer form-footer_submit'>
				<BtnGoBack onClick={goBack} />
				<ButtonDefault name={t('Next')} type={'submit'} />
			</div>
		</form>
	)
}

export default FormCompanyUpdate
