import React from 'react'
import type { FC } from 'react'
import V1 from '@assets/image/404/v1'
import V2 from '@assets/image/404/v2'
import V3 from '@assets/image/404/v3'
import V4 from '@assets/image/404/v4'
import V5 from '@assets/image/404/v5'
import V6 from '@assets/image/404/v6'
import V7 from '@assets/image/404/v7'
import V8 from '@assets/image/404/v8'
import V9 from '@assets/image/404/v9'

import './style.scss'

interface Template404Type {
	children: React.ReactNode
}

const Template404: FC<Template404Type> = ({ children }: Template404Type) => (
	<div className='page-404'>
		<div className='page-404__child'>{children}</div>
		<div className='page-404__body'>
			<div className='page-404__element page-404__rocket'>
				<V1 />
			</div>
			<div className='page-404__element page-404__background-1'>
				<V2 />
			</div>
			<div className='page-404__element page-404__background-2'>
				<V3 />
			</div>
			<div className='page-404__element page-404__planet-1'>
				<V4 />
			</div>
			<div className='page-404__element page-404__planet-2'>
				<V5 />
			</div>
			<div className='page-404__element page-404__large-star-1'>
				<V6 />
			</div>
			<div className='page-404__element page-404__large-star-2'>
				<V6 />
			</div>
			<div className='page-404__element page-404__large-star-3'>
				<V6 />
			</div>
			<div className='page-404__element page-404__large-star-4'>
				<V6 />
			</div>
			<div className='page-404__element page-404__small-star-1'>
				<V7 />
			</div>
			<div className='page-404__element page-404__small-star-2'>
				<V7 />
			</div>
			<div className='page-404__element page-404__small-star-3'>
				<V7 />
			</div>
			<div className='page-404__element page-404__small-star-4'>
				<V7 />
			</div>
			<div className='page-404__element page-404__large-dot-1'>
				<V8 />
			</div>
			<div className='page-404__element page-404__large-dot-2'>
				<V8 />
			</div>
			<div className='page-404__element  page-404__small-dot-1'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-2'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-3'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-4'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-5'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-6'>
				<V9 />
			</div>
		</div>
	</div>
)

export default Template404

export const TemplateERROR: FC<Template404Type> = ({ children }: Template404Type) => (
	<div className='page-404'>
		<div className='page-404__child'>{children}</div>
		<div className='page-404__body'>
			<div className='page-404__element page-404__rocket'>
				<V1 />
			</div>
			<div className='page-404__element page-404__background-1'>
				<V2 />
			</div>
			<div className='page-404__element page-404__planet-1'>
				<V4 />
			</div>
			<div className='page-404__element page-404__planet-2'>
				<V5 />
			</div>
			<div className='page-404__element page-404__large-star-1'>
				<V6 />
			</div>
			<div className='page-404__element page-404__large-star-2'>
				<V6 />
			</div>
			<div className='page-404__element page-404__large-star-3'>
				<V6 />
			</div>
			<div className='page-404__element page-404__large-star-4'>
				<V6 />
			</div>
			<div className='page-404__element page-404__small-star-1'>
				<V7 />
			</div>
			<div className='page-404__element page-404__small-star-2'>
				<V7 />
			</div>
			<div className='page-404__element page-404__small-star-3'>
				<V7 />
			</div>
			<div className='page-404__element page-404__small-star-4'>
				<V7 />
			</div>
			<div className='page-404__element page-404__large-dot-1'>
				<V8 />
			</div>
			<div className='page-404__element page-404__large-dot-2'>
				<V8 />
			</div>
			<div className='page-404__element  page-404__small-dot-1'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-2'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-3'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-4'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-5'>
				<V9 />
			</div>
			<div className='page-404__element  page-404__small-dot-6'>
				<V9 />
			</div>
		</div>
	</div>
)
