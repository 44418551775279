import React from 'react'
import type { FC } from 'react'
//types
interface IconDeleteType {
	onClick?: () => void
}

const IconDelete: FC<IconDeleteType> = ({ onClick }: IconDeleteType) => (
	<svg
		width='12'
		height='14'
		viewBox='0 0 12 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		onClick={onClick}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.5247 2.49547C11.7841 2.49547 12 2.71081 12 2.9848V3.23813C12 3.50546 11.7841 3.72746 11.5247 3.72746H0.475902C0.215907 3.72746 0 3.50546 0 3.23813V2.9848C0 2.71081 0.215907 2.49547 0.475902 2.49547H2.41971C2.81457 2.49547 3.1582 2.21481 3.24703 1.81881L3.34882 1.36415C3.50702 0.744825 4.02766 0.333496 4.62351 0.333496H7.37649C7.96585 0.333496 8.49233 0.744825 8.64469 1.33149L8.75362 1.81815C8.8418 2.21481 9.18543 2.49547 9.58094 2.49547H11.5247ZM10.5372 11.7562C10.7402 9.86488 11.0955 5.37159 11.0955 5.32626C11.1084 5.18893 11.0637 5.05893 10.9749 4.95426C10.8796 4.85627 10.759 4.79827 10.626 4.79827H1.37901C1.24545 4.79827 1.11837 4.85627 1.03019 4.95426C0.940714 5.05893 0.896625 5.18893 0.903108 5.32626C0.9043 5.33459 0.917049 5.49287 0.938364 5.75748C1.03305 6.93298 1.29678 10.207 1.46719 11.7562C1.58779 12.8975 2.33665 13.6148 3.42137 13.6408C4.25841 13.6602 5.12074 13.6668 6.00252 13.6668C6.83308 13.6668 7.67661 13.6602 8.53959 13.6408C9.66191 13.6215 10.4101 12.9169 10.5372 11.7562Z'
			fill='#D2D8E8'
		/>
	</svg>
)

export default IconDelete
