import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import 'dayjs/locale/uk'
import 'dayjs/locale/ru'

import { IconAppFile, IconAppPrint } from '@assets/icons/app'
import { Titlte } from '@molecules/tables/users'
import { getFileSize } from '@tools/helpers'

dayjs.extend(advancedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)

const getDate = date => {
	const formattedDate = dayjs(date)
	return {
		formatted: formattedDate.format('MM/DD/YYYY'),
		time: formattedDate.format('HH:mm:ss'),
	}
}

const handlePrint = record => {
	const image = new Image()
	image.src = record.url
	image.onload = () => {
		const printWindow = window.open('', '', 'width=600,height=600')
		if (printWindow) {
			printWindow.document.open()
			printWindow.document.write('<html><body style="margin:0;padding:0;">')
			printWindow.document.write(
				`<img src="${image.src}" style="max-width:100%;height:auto;">`
			)
			printWindow.document.write('</body></html>')
			setTimeout(() => {
				printWindow.document.close()
				printWindow.print()
				printWindow.close()
			}, 2000)
		}
	}
}

const printPdf = record => {
	const url = `https://docs.google.com/viewer?url=${encodeURIComponent(record.url)}&embedded=true`
	window.open(url, '_blank')
}

export const columns = [
	{
		title: <Titlte name={'№'} classes={'lc-verification-companies-table__title'} />,
		dataIndex: 'id',
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-id w-50',
		render: (text, record) => (
			<div className='lc-verification-companies-table__column-container'>{record.index}</div>
		),
		width: 50,
	},
	{
		title: () => <Titlte name={'Name'} classes={'lc-verification-companies-table__title'} />,
		dataIndex: 'name',
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-name w-254',
		render: () => {},
		width: 254,
	},
	{
		title: () => <Titlte name={'Size'} classes={'lc-verification-companies-table__title'} />,
		dataIndex: 'size',
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-activity w-110',
		render: (text, record) => <div>{getFileSize(record)}</div>,
		width: 110,
	},
	{
		title: () => <Titlte name={'Date'} classes={'lc-verification-companies-table__title'} />,
		dataIndex: 'date_upload',
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-date w-110',
		render: (text, record) => {
			const dateInfo = getDate(record.date_upload)
			return (
				<div className='lc-verification-companies-table__column-date-block'>
					<span className='table-date'>{dateInfo.formatted}</span>
					<span className='table-time'>{dateInfo.time}</span>
				</div>
			)
		},
		width: 110,
	},
	{
		title: () => (
			<Titlte name={'Download'} classes={'lc-verification-companies-table__title'} />
		),
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-link w-110',
		render: (text, record) => (
			<div
				className='lc-verification-companies-table__column-container'
				onClick={() => window.open(record.url)}
			>
				<IconAppFile />
			</div>
		),
		width: 110,
	},
	{
		title: () => <Titlte name={'Print'} classes={'lc-verification-companies-table__title'} />,
		className:
			'lc-verification-companies-table__column lc-verification-companies-table__column-link w-110',
		render: (text, record) => {
			const fileExtension = record?.name.split('.').pop().toLowerCase()
			const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileExtension)

			return (
				<div className='lc-verification-companies-table__column-container'>
					<a
						onClick={() => (isImage ? handlePrint(record) : printPdf(record))}
						target='_blank'
						rel='noreferrer'
					>
						<IconAppPrint />
					</a>
				</div>
			)
		},
		width: 110,
	},
]
