import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import '../style.scss'
import './styles.scss'

import { IconLink } from '@assets/icons/statsInfo'

const SortButtons: React.FC = () => (
	<span className='search-column-sorter'>
		<span className='search-sorter-inner' aria-hidden='true'>
			<span
				role='img'
				aria-label='caret-up'
				className='anticon anticon-caret-up ant-table-column-sorter-up'
			>
				<CaretUpOutlined />
			</span>
			<span
				role='img'
				aria-label='caret-down'
				className='anticon anticon-caret-down ant-table-column-sorter-down'
			>
				<CaretDownOutlined />
			</span>
		</span>
	</span>
)

const DropdownSearchCompanies: FC<any> = ({
	setModalOpen,
	setDropdownOpen,
	dropdownOpen,
	result: { all = [] } = [],
	searchValue,
}: any) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [dataSort, setDataSort] = useState([...all])
	const [sortVal, setSortVal] = useState(false)
	const [sortActive, setSortActive] = useState(false)

	const handleChangeSort = () => {
		const sortedData = [...all]

		sortedData.sort((a, b) => {
			if (sortVal) return a.name.localeCompare(b.name)

			return b.name.localeCompare(a.name)
		})

		setSortActive(true)
		setDataSort(sortedData)
		setSortVal(prev => !prev)
	}

	useEffect(() => {
		setSortActive(false)
	}, [all])

	const getHighlightedText = (text, highlight) => {
		const parts = text.split(new RegExp(`(${highlight})`, 'i'))
		return parts.map((part, index) => (
			<React.Fragment key={index}>
				{part.toLowerCase() === highlight.toLowerCase() ? (
					<span style={{ color: '#3843ED' }}>{part}</span>
				) : (
					part
				)}
			</React.Fragment>
		))
	}

	let result

	switch (true) {
		case all.length === 1:
			result = t('result')
			break
		case all.length >= 2 && all.length <= 4:
			result = t('results_plural_2')
			break
		case all.length >= 5:
			result = t('results')
			break
		default:
			result = t('results')
	}

	const items: MenuProps['items'] = []

	if (all.length > 0) {
		items.push({
			key: 'all.length',
			label: (
				<div className='dropdown-overlay__item' onClick={() => handleChangeSort()}>
					<span className='dropdown-overlay__text dropdown-overlay__text-length'>
						{all.length} {result} <SortButtons />
					</span>
				</div>
			),
			onClick: () => {},
		})

		const sortedArray = sortActive ? dataSort : all

		items.push(
			...sortedArray.map(item => ({
				key: item.id,
				label: (
					<div className='dropdown-overlay__item dropdown-overlay__item-content '>
						<span className='dropdown-overlay__text'>
							{item.name && getHighlightedText(item.name, searchValue)}
						</span>
						<span className='dropdown-overlay__text dropdown-overlay__text-secondary'>
							{item.code && getHighlightedText(String(item.code), searchValue)}
							{item.services && ', '}
							{item.services && getHighlightedText(item.services, searchValue)}
						</span>
					</div>
				),
				onClick: () => navigate(`/lc-companies/company/${item.id}`),
			}))
		)
	} else {
		items.push({
			key: 'none',
			label: (
				<div className='dropdown-overlay__item'>
					<span className='dropdown-overlay__text dropdown-overlay__text-length'>
						{t('No results')}
					</span>
				</div>
			),
		})
	}

	items.push({
		key: 'search-more',
		label: (
			<div>
				<span>{t('Advanced Search')}</span>
				<IconLink />
			</div>
		),
		onClick: () => {
			setDropdownOpen(false)
			setTimeout(() => {
				setModalOpen(true)
			}, 100)
		},
	})

	return (
		<div className='dropdown-search'>
			<Dropdown
				rootClassName='dropdown-companies-wrap'
				className={'dropdown companies'}
				menu={{ items }}
				trigger={['click']}
				open={dropdownOpen}
				onOpenChange={(data: any) => setDropdownOpen(data)}
			>
				<div
					className='dropdown__triger'
					onClick={e => {
						e.preventDefault()
						setDropdownOpen(true)
					}}
				/>
			</Dropdown>
		</div>
	)
}

export default DropdownSearchCompanies
