export const updateFields = [
	{
		name: 'name',
		label: 'Company Name',
		placeholder: 'Enter Company Name',
		required: true,
		className: '',
	},
	{
		name: 'tm_name',
		label: 'Brand name',
		placeholder: 'Enter Brand name',
		required: false,
	},
	// {
	// 	name: 'employees',
	// 	className: 'form__container-mini',
	// 	label: 'Number of employees in the company',
	// 	placeholder: 'Select from list',
	// 	optionKey: 'employees',
	// 	required: true,
	// },
	{
		name: 'code',
		label: 'EDRPOU code / VAT number PE',
		placeholder: 'Enter EDRPOU code',
		required: true,
		fieldType: 'InputMaskDefault',
		mask: '9999999999',
	},
	{
		name: 'activity',
		fieldType: 'selectDefault',
		optionKey: 'activities',
		label: 'Field of activity',
		placeholder: 'Select from list',
		required: true,
	},
	{
		name: 'services',
		label: 'Services / Product',
		placeholder: 'Enter the name of the main product of the Company',
		required: true,
	},
	// {
	// 	name: 'website',
	// 	label: 'Website',
	// 	placeholder: 'Add link to website',
	// 	required: false,
	// },
]
