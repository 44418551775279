import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import { FormHeaderCompanies } from '@atoms/text/formHeader'
import FormCompanyContacts from '@organisms/forms/companies/contacts'
import FormCompanyManagement from '@organisms/forms/companies/management'
import FormCompanyMission from '@organisms/forms/companies/mission'
import FormCompanyStructure from '@organisms/forms/companies/structure'
import FormCompanyUpdate from '@organisms/forms/companies/update'
import { setStatus } from '@redux/reducers/appSlice'

const tabComponents = {
	general: <FormCompanyUpdate />,
	contacts: <FormCompanyContacts />,
	structure: <FormCompanyStructure />,
	management: <FormCompanyManagement />,
	mission: <FormCompanyMission />,
}

const CompanySetting: FC = () => {
	const [title, setTitle] = useState('General')
	const { page } = useSelector((state: stateType) => state.app)
	const { tab } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const Component = tabComponents[tab || 'general']

	useEffect(() => {
		const titles = {
			general: 'General',
			contacts: 'Contacts',
			structure: 'Structure',
			management: 'Personnel Management',
			mission: 'Mission and values',
		}

		setTitle(titles[tab as keyof typeof titles] || titles['general'])
	}, [tab])

	useEffect(() => {
		dispatch(setStatus('company-setting'))
		if (!tab) navigate('/company-setting/general')
	}, [tab])

	return (
		<>
			<Helmet title={page} />
			<FormHeaderCompanies title={t(title)} iconClosed />
			{Component}
		</>
	)
}

export default CompanySetting
