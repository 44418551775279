import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import __ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import AuthenticationAside from '@containers/app/aside'
import DynamicSteps from '@organisms/dynamicSteps'
import useRegCompanySteps from '@tools/hooks/steps/useRegCompanySteps'

const CompanyRegistration: FC = () => {
	const { info } = useSelector((state: stateType) => state.user)
	const { page } = useSelector((state: stateType) => state.app)

	const { current, setCurrent, steps } = useRegCompanySteps()

	useEffect(() => {
		if (!__.isEmpty(info)) {
			setCurrent(Number(info.step))
		}
	}, [info])

	return (
		<Layout className='new-company'>
			<AuthenticationAside page={page} />

			<Layout className='new-company__layout layout'>
				<Content className='new-company__container'>
					<DynamicSteps steps={steps} current={current} />
				</Content>
			</Layout>
		</Layout>
	)
}

export default CompanyRegistration
