import { Button, Form, Input, message } from 'antd'
import { func, number } from 'prop-types'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { setCompanyInfo } from '@api/asyncHelpers'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import { checkAllDepartments } from '@redux/actions/departmentsActions'
import { useAddItemToDropDownList } from '@tools/hooks/useAddItemToDropDownList'

FormSetCompanyMission.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetCompanyMission({ changeStep, step }) {
	const { lists, company } = useSelector(state => ({
		lists: state.dropDownList.lists,
		company: state.company.info,
	}))
	const [form] = Form.useForm()
	const addItemToDropDownList = useAddItemToDropDownList()
	const onFinish = async () => {
		const formValue = form.getFieldsValue()
		try {
			const json = await setCompanyInfo(formValue)
			if (json.ok) {
				await checkAllDepartments()
				changeStep(step + 1)
			} else {
				message.error({ content: t('Error') })
			}
		} catch (e) {
			message.error({ content: e.message })
		}
	}

	useEffect(() => {
		if (company) {
			form.setFields([
				{ name: 'mission', value: company?.mission },
				{ name: 's_values', value: company?.s_values?.map(item => item.name) },
				{ name: 's_competence', value: company?.s_competence?.map(item => item.name) },
			])
		}
	}, [company])
	return (
		<Form
			name='set-company-mission'
			initialValues={{
				mission: null, // місія
				s_values: [], // цінності
				s_competence: [], // компетерція
			}}
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
			className='form'
		>
			<AppText
				text={t('Mission and values')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Form.Item className='form__container' name='mission' label={t('Vision / Mission')}>
				<Input className='form__input' placeholder={t('Vision / Mission')} />
			</Form.Item>

			<Form.Item name='s_values' label={t('Values')} className='form__container'>
				<AppSelect
					options={lists?.s_values}
					placeholder={t('Values')}
					mode='multiple'
					isHaveFooter
					onSubmit={async name => await addItemToDropDownList('s_values', name)}
				/>
			</Form.Item>

			<Form.Item name='s_competence' label={t('Competence')} className='form__container'>
				<AppSelect
					options={lists?.s_competence}
					placeholder={t('Competence')}
					mode='multiple'
					isHaveFooter
					onSubmit={async name => await addItemToDropDownList('s_competence', name)}
				/>
			</Form.Item>

			<Form.Item className='form__container'>
				<div className='form__row _between'>
					<Button
						type='default'
						htmlType='button'
						shape='round'
						onClick={() => changeStep(step - 1)}
					>
						{t('Back')}
					</Button>
					<Button type='primary' shape='round' htmlType='submit'>
						{t('Save and continue')}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default FormSetCompanyMission
