import { Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { FC } from 'react'
import IconSeccess from '@assets/icons/modals/success'
import ButtonDefault from '@atoms/buttons/button'
import './style.scss'

interface CompanyVerifiedModalType {
	component?: any
	action?: any
	color?: any
}

const CompanyVerifiedModal: FC<CompanyVerifiedModalType> = ({
	action,
}: CompanyVerifiedModalType) => {
	const [visible, setVisible] = useState(true)
	const [loader, setLoader] = useState(false)
	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
		action()
	}

	const call = () => {
		setLoader(true)
		onCancel()
	}

	return (
		<Modal
			className='modal-confirm'
			open={visible}
			width={504}
			footer={null}
			closable={true}
			styles={{ body: { padding: '44px 40px' } }}
			centered
			onCancel={onCancel}
		>
			<div className='modal-confirm__header'>
				<IconSeccess />
			</div>
			<div className='modal-confirm__body'>
				<div className='modal-confirm__title'>
					{t('The company has been successfully verified!')}
				</div>
				<div className='modal-confirm__desc'>
					{t('Fill in your Company information or transfer rights to continue')}
				</div>
			</div>
			<div className='modal-confirm__btns'>
				<ButtonDefault
					name={t('Fill in information')}
					type={'submit'}
					loading={loader}
					disabled={loader}
					action={call}
				/>
				<button className='btn-default btn-gray' onClick={onCancel}>
					{t('Transfer Rights')}
				</button>
			</div>
		</Modal>
	)
}

export default CompanyVerifiedModal
