import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import FormHeader from '@atoms/text/formHeader'
import FormCode from '@organisms/forms/code/confirmPhoneResetPassword'
import { appPage } from '@redux/reducers/appSlice'
import AsideTemplate from '@templates/asideTemplate'

const PageForgotByPhone: FC = () => {
	const { page } = useSelector((state: stateType) => state.app)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if (localStorage.getItem('forgot-phone')) {
			dispatch(appPage('forgot-phone'))
			return
		}

		navigate('/')
	}, [])

	return (
		<>
			<Helmet title={page} />
			<AsideTemplate showBenefits center page={page}>
				<FormHeader
					type={'forgotByPhone'}
					data={{ phone: localStorage.getItem('forgot-phone') }}
				/>
				<FormCode />
			</AsideTemplate>
		</>
	)
}

export default PageForgotByPhone
