import React from 'react'
import type { FC } from 'react'

const IconCloseBox: FC = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M14.3955 9.5949L9.60352 14.3869'
			stroke='white'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M14.3971 14.3898L9.60107 9.5928'
			stroke='white'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M16.3345 2.7502H7.66549C4.64449 2.7502 2.75049 4.8892 2.75049 7.9162V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.9162C21.2505 4.8892 19.3645 2.7502 16.3345 2.7502Z'
			stroke='white'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default IconCloseBox
