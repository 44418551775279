import { get, post } from '../api_helper'
import type { AxiosResponse } from 'axios'

export const api = {
	getNotificationsUserList: async (_token: string): Promise<AxiosResponse> => {
		try {
			return await get('/notification/get_notificationList')
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getNotificationsUserNew: async (_token: string): Promise<AxiosResponse> => {
		try {
			return await get('/notification/get_notificationNew')
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	updateStatusView: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post('/notification/update_notificationStatusView', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},
}
