import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/*
 *
 * App reducer
 *
 */
const initialState = {
	loading: false,
	session: false,
	isReg: false,
	token: null,
	error: null,
	isResetPass: false,
}

export type authInitStateType = typeof initialState

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		login: () => console.log('login'),
		updatePassword: () => console.log('updatePassword'),
		setLogout: () => console.log('setLogout'),
		setSessionAuth: (state, action: PayloadAction<any>) => {
			state.session = action.payload
		},
		registration: () => console.log('registration'),
		reset: () => console.log('reset'),
		forgot: () => console.log('forgot'),
		forgotByPhone: () => console.log('forgotByPhone'),
		resetPasswordInside: () => console.log('resetPasswordInside'),
		setToken: (state, action: PayloadAction<any>) => {
			state.token = action.payload
		},
		setError: (state, action: PayloadAction<any>) => {
			state.error = action.payload
		},
		setLoading: (state, action: PayloadAction<any>) => {
			state.loading = action.payload
		},
		setReset: (state, action: PayloadAction<any>) => {
			state.isResetPass = action.payload
		},
		setReg: (state, action: PayloadAction<any>) => {
			state.isReg = action.payload
		},
	},
})

export default authSlice.reducer
export const {
	login,
	setLogout,
	setSessionAuth,
	registration,
	updatePassword,
	reset,
	resetPasswordInside,
	forgot,
	forgotByPhone,
	setToken,
	setError,
	setLoading,
	setReset,
	setReg,
}: any = authSlice.actions
