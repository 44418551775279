import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { FC } from 'react'
import FormForgotPassword from '@organisms/forms/forgot/index'
import Login from '@organisms/forms/login/index'
import Registration from '@organisms/forms/registration/index'
import LoginOptions from '@organisms/socialBlock'

import { appPage } from '@redux/reducers/appSlice'

import './style.scss'

const TabsAuthContent: FC = () => {
	const { page } = useSelector((state: any) => state.app)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		localStorage.getItem('page') && dispatch(appPage(localStorage.getItem('page')))
	}, [])

	const onChange = (value: string) => {
		dispatch(appPage(value))
	}

	const controller = () => {
		switch (page) {
			case 'forgot':
				return <FormForgotPassword />

			default:
				return (
					<Tabs
						onChange={onChange}
						defaultActiveKey={
							page
								? page
								: localStorage.getItem('page')
								  ? localStorage.getItem('page')
								  : 'login'
						}
						centered
						className='auth-tabs'
						items={[
							{
								label: t('Login'),
								key: 'login',
								children: (
									<>
										<Login />
										<LoginOptions />
									</>
								),
							},
							{
								label: t('Registration'),
								key: 'registration',
								children: (
									<>
										<Registration />
										<LoginOptions />
									</>
								),
							},
						]}
					/>
				)
		}
	}
	return controller()
}

export default TabsAuthContent
