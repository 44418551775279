import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'
import { IconLink } from '@assets/icons/statsInfo'
import { IconFile } from '@assets/icons/uploaders'
import { columns, columnsDelete } from '@configs/tables/lcCompanies'

const useCompaniesPopup = (handleModalClose, handleModalOpen) => {
	const { page } = useSelector((state: stateType) => state.app)

	const updateColumns = page === 'deleted' ? columnsDelete : columns

	const renderNameColumn = (text, record) => (
		<div className='link' onClick={() => handleModalOpen(record.id, 'history')}>
			{text}
		</div>
	)

	const renderDetailsColumn = (text, record) => (
		<div className='lc-verification-companies-table__column-container'>
			<IconLink onClick={() => handleModalOpen(record.id, 'history')} />
		</div>
	)

	const renderFileColumn = (text, record) => (
		<div className='lc-verification-companies-table__column-container'>
			<div onClick={() => handleModalOpen(record.id, 'files')}>
				<IconFile />
			</div>
		</div>
	)

	const columnsPopup = useMemo(
		() =>
			updateColumns.map(column => {
				if (column.dataIndex === 'name') {
					return {
						...column,
						render: renderNameColumn,
					}
				} else if (column.dataIndex === 'details') {
					return {
						...column,
						render: renderDetailsColumn,
					}
				} else if (column.dataIndex === 'file') {
					return {
						...column,
						render: renderFileColumn,
					}
				}
				return column
			}),
		[]
	)

	return {
		columnsPopup,
	}
}

export default useCompaniesPopup
