import { Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import ButtonDefault from '@atoms/buttons/button'
import plus from '@image/icons/plus.svg'

import FormCompanyAddress from '@organisms/forms/companies/address'
import './style.scss'

interface OutFromCreateCompanyModalType {
	component?: any
	action?: any
	color?: any
	status?: string
	submit?: boolean
	isVerified?: any
}

const AddAddress: FC<OutFromCreateCompanyModalType> = ({
	action,
	submit,
}: OutFromCreateCompanyModalType) => {
	const [visible, setVisible] = useState(false)
	const [loader, setLoader] = useState(false)

	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
	}

	const call = () => {
		setLoader(true)
		action(onCancel)
	}

	return (
		<>
			<div
				className='company_empty__button'
				onClick={() => {
					setVisible(true)
				}}
			>
				<div className='company_empty__button__icon'>
					<img src={plus} alt='plus' />
				</div>
				<span className='company_empty__button__text'>{t('Add address')}</span>
			</div>

			<Modal
				className='modal-add-address'
				open={visible}
				width={982}
				footer={null}
				closable={true}
				centered
				onCancel={onCancel}
			>
				<div className='modal-add-address__left'>
					<h2>{t('New address')}</h2>
					<p>{t('Fill in the information about the new address')}</p>
				</div>
				<div>
					<FormCompanyAddress onCancel={onCancel} />
					<div className='modal-confirm__btns'>
						{submit && (
							<ButtonDefault
								name={t('Delete')}
								type={'submit'}
								loading={loader}
								disabled={loader}
								action={call}
							/>
						)}
					</div>
				</div>
			</Modal>
		</>
	)
}

export default AddAddress
