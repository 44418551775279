import React from 'react'

function SelectRemoveIcon() {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 14C11.3137 14 14 11.3138 14 8C14 4.68625 11.3137 2 8 2C4.68629 2 2 4.68625 2 8C2 11.3138 4.68629 14 8 14ZM4.97725 5.7728L7.2045 8L4.97725 10.2273L5.77275 11.0228L8 8.7955L10.2272 11.0228L11.0228 10.2273L8.7955 8L11.0228 5.7728L10.2272 4.97729L8 7.20459L5.77275 4.97729L4.97725 5.7728Z'
				fill='white'
			/>
		</svg>
	)
}

export default SelectRemoveIcon
