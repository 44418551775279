import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox } from 'antd'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import type { stateType } from '@types'

import type { FC } from 'react'

import './style.scss'

import type { LoginData } from 'types/api'
import IconEye from '@assets/icons/inputs/eye'
import IconEyeClose from '@assets/icons/inputs/eyeClose'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault from '@atoms/inputs'
import { appPage, setStatus } from '@redux/reducers/appSlice'
import { login, setSessionAuth } from '@redux/reducers/authSlice'
import i18n from '@src/i18n'
import usePasswordHook from '@tools/hooks/forms/usePasswordHook'
import { loginSchema } from '@tools/shemas'

const Login: FC = () => {
	const { loading } = useSelector((state: stateType) => state.auth)
	const schema = useMemo(() => loginSchema(), [i18n.language])

	const {
		register,
		control,
		handleSubmit,
		//getValues,
		formState: { errors },
	} = useForm<LoginData>({
		defaultValues: {
			email: '',
			password: '',
		},
		resolver: yupResolver(schema),
	})

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const setSession = (e: any) => {
		dispatch(setSessionAuth(e.target.checked))
	}

	const onSubmit = async (data: LoginData) => {
		const { email, password } = data

		let key
		let request
		Number(email) ? (key = 'phone') : (key = 'email')

		if (key === 'email') {
			request = {
				[key]: email,
				password: password,
			}
		}

		if (key === 'phone') {
			request = {
				[key]: email,
				password: password,
			}
		}

		dispatch(
			login({
				request,
				callback: () => {
					dispatch(setStatus(''))
					navigate('/')
				},
			})
		)
	}

	const { passwordType: passwordType, changePasswordType: changePasswordType } = usePasswordHook()

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form auth-form'>
			<div className='form__body'>
				<div className='form__container'>
					<InputDefault
						name={'email'}
						label={t('E-mail or Phone')}
						placeholder={t('E-mail or Phone 2')}
						error={errors.email?.message}
						register={register}
						control={control}
					/>
				</div>
				<div className='form__container'>
					<InputDefault
						name={'password'}
						label={t('Password')}
						classes={'margin-0'}
						placeholder={t('Enter your password')}
						error={errors.password?.message}
						register={register}
						control={control}
						type={passwordType}
					/>
					<div className='form__container-icons' onClick={changePasswordType}>
						{passwordType === 'input' ? <IconEye /> : <IconEyeClose />}
					</div>
				</div>
				<div className='form__container-footer'>
					<Checkbox className='form__container-checkbox' onChange={setSession}>
						{t('Remember')}
					</Checkbox>
					<Link to='/forgot' onClick={() => appPage('forgot')}>
						{t('Forgot password?')}
					</Link>
				</div>
			</div>

			<div className='form__container'>
				<ButtonDefault
					name={t('Sign in platform')}
					type={'submit'}
					loading={loading}
					disabled={loading}
				/>
			</div>
		</form>
	)
}

export default Login
