import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import IconPlus from '@assets/icons/btn/plus'
import './style.scss'

interface ButtonAddType {
	action: () => void
}

const ButtonAdd: FC<ButtonAddType> = ({ action }: ButtonAddType) => {
	const { t } = useTranslation()
	return (
		<div className='btn-add' onClick={() => action()}>
			<div className='btn-add__plus'>
				<IconPlus />
			</div>
			<div className='btn-add__text'>{t('Add company')}</div>
		</div>
	)
}

export default ButtonAdd
