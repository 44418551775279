import React from 'react'
import type { FC } from 'react'

const SelectRemoveIconMini: FC = () => (
	<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M6 12C9.31371 12 12 9.31375 12 6C12 2.68625 9.31371 0 6 0C2.68629 0 0 2.68625 0 6C0 9.31375 2.68629 12 6 12ZM2.97725 3.7728L5.2045 6L2.97725 8.22729L3.77275 9.0228L6 6.7955L8.22725 9.0228L9.02275 8.22729L6.7955 6L9.02275 3.7728L8.22725 2.97729L6 5.20459L3.77275 2.97729L2.97725 3.7728Z'
			fill='white'
		/>
	</svg>
)

export default SelectRemoveIconMini
