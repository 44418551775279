import { Button, Form, Input, message } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormSetCompanyMainType } from 'types/api'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import { createCompany } from '@redux/reducers/companySlice'
import { changeStep } from '@redux/reducers/userSlice'

const FormSetCompanyMain: FC<FormSetCompanyMainType> = ({ setCurrent }: FormSetCompanyMainType) => {
	const { t } = useTranslation()
	//const user = useSelector((state: stateType) => state.user)
	const { options }: any = useSelector((state: stateType) => state.app)
	const { token } = useSelector((state: stateType) => state.auth)
	const { info, loading } = useSelector((state: stateType) => state.company)

	const key = 'set-company'
	const dispatch = useDispatch()

	const [form] = Form.useForm()

	useEffect(() => {
		if (info) {
			for (const key in info) {
				form.setFieldValue(key, info[key])
			}
		}
	}, [info])

	const onFinish = async () => {
		message.loading({ content: t('Loading...'), key })

		dispatch(
			createCompany({
				data: form.getFieldsValue(),
				token: token,
				key: key,
				callback: () => {
					dispatch(changeStep({ token: token, step: '2' }))
					setCurrent(2)
					message.success({
						content: t('Company data successfully saved!'),
						key,
						duration: 4,
					})
				},
			})
		)
	}

	return (
		<Form
			name='set-company'
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
			className='form'
		>
			<AppText
				text={t('General')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Form.Item
				className='form__container'
				name='companyName'
				label='Company Name'
				rules={[{ required: false, message: t('Please enter your company name!') }]}
			>
				<Input className='form__input' placeholder={t('LLC Company')} />
			</Form.Item>
			<Form.Item
				name='s_workers'
				label={t('Number of employees in the company')}
				className='form__container'
			>
				<AppSelect
					options={options?.s_workers}
					placeholder={t('The number of employees')}
				/>
			</Form.Item>
			<Form.Item
				className='form__container'
				name='kod'
				label={t('EDRPOU code/TIN FOP')}
				rules={[
					{
						required: false,
						message: t('Please enter the EDRPOU code (ЄDRPOU)/TIN FOP!'),
					},
				]}
			>
				<Input type='number' className='form__input' placeholder='1234567' />
			</Form.Item>

			<Form.Item className='form__container' name='s_activity' label={t('Field of activity')}>
				<AppSelect options={options?.s_activity} placeholder={t('Field of activity')} />
			</Form.Item>

			<Form.Item className='form__container' name='services' label={t('Services / Product')}>
				<Input className='form__input' placeholder={t('Services')} />
			</Form.Item>
			<Form.Item className='form__container' name='website' label='Website'>
				<Input className='form__input' placeholder='https://mysite.com' />
			</Form.Item>

			<Form.Item className='form__container'>
				<div className='form__row _right'>
					<Button type='primary' shape='round' htmlType='submit' disabled={loading}>
						{t('Save and continue')}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default FormSetCompanyMain
