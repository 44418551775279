import React from 'react'

const V5 = () => (
	<svg
		width='105'
		height='71'
		viewBox='0 0 105 71'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M87.4 35.7999C87.4 54.9999 71.7999 70.5999 52.4999 70.5999C33.2999 70.5999 17.7 54.9999 17.7 35.7999C17.7 16.4999 33.2999 0.899902 52.4999 0.899902C71.7999 0.899902 87.4 16.4999 87.4 35.7999Z'
			fill='#F48625'
		/>
		<path
			d='M72.4999 64.2999C66.7999 68.2999 59.9999 70.5999 52.4999 70.5999C33.2999 70.5999 17.7 54.9999 17.7 35.7999C17.7 16.4999 33.2999 0.899902 52.4999 0.899902C59.9999 0.899902 66.7999 3.2999 72.4999 7.1999C71.7999 8.0999 70.6999 8.6999 69.4999 8.6999H65.7C63.5 8.6999 61.7999 10.3999 61.7999 12.4999C61.7999 14.6999 63.5 16.3999 65.7 16.3999H69.4999C71.5999 16.3999 73.2999 18.1999 73.2999 20.2999C73.2999 22.3999 71.5999 24.1999 69.4999 24.1999H65.7C63.5 24.1999 61.7999 25.8999 61.7999 27.9999C61.7999 30.1999 63.5 31.8999 65.7 31.8999H69.4999C71.5999 31.8999 73.2999 33.5999 73.2999 35.7999C73.2999 37.8999 71.5999 39.5999 69.4999 39.5999H65.7C63.5 39.5999 61.7999 41.3999 61.7999 43.4999C61.7999 45.6999 63.5 47.3999 65.7 47.3999H69.4999C71.5999 47.3999 73.2999 49.0999 73.2999 51.2999C73.2999 53.3999 71.5999 55.0999 69.4999 55.0999H65.7C63.5 55.0999 61.7999 56.8999 61.7999 58.9999C61.7999 61.0999 63.5 62.8999 65.7 62.8999H69.4999C70.6999 62.8999 71.7999 63.3999 72.4999 64.2999Z'
			fill='#F9A81B'
		/>
		<path
			d='M49.5999 70.5001C31.7999 69.0001 17.7 54.0001 17.7 35.8001C17.7 17.5001 31.7999 2.5001 49.5999 1.1001C51.2999 1.5001 52.4999 3.0001 52.4999 4.8001C52.4999 6.9001 50.7999 8.7001 48.7 8.7001H44.9C42.7 8.7001 40.9999 10.4001 40.9999 12.5001C40.9999 14.7001 42.7 16.4001 44.9 16.4001H48.7C50.7999 16.4001 52.4999 18.2001 52.4999 20.3001C52.4999 22.4001 50.7999 24.2001 48.7 24.2001H44.9C42.7 24.2001 40.9999 25.9001 40.9999 28.0001C40.9999 30.2001 42.7 31.9001 44.9 31.9001H48.7C50.7999 31.9001 52.4999 33.6001 52.4999 35.8001C52.4999 37.9001 50.7999 39.6001 48.7 39.6001H44.9C42.7 39.6001 40.9999 41.4001 40.9999 43.5001C40.9999 45.7001 42.7 47.4001 44.9 47.4001H48.7C50.7999 47.4001 52.4999 49.1001 52.4999 51.3001C52.4999 53.4001 50.7999 55.1001 48.7 55.1001H44.9C42.7 55.1001 40.9999 56.9001 40.9999 59.0001C40.9999 61.1001 42.7 62.9001 44.9 62.9001H48.7C50.7999 62.9001 52.4999 64.6001 52.4999 66.7001C52.4999 68.5001 51.2999 70.1001 49.5999 70.5001Z'
			fill='#F8CE8C'
		/>
		<path
			d='M104.5 13.2001C106.7 18.3001 85.2 32.5001 56.5 45.0001C27.8 57.4001 2.79994 63.4001 0.59994 58.3001C-0.70006 55.3001 6.29998 49.1001 18.2 41.9001C18.2666 42.2334 18.3333 42.6001 18.4 43.0001C13.9 46.0001 11.4 48.3001 11.9 49.4001C13.1 52.2001 31.7 46.9001 53.3 37.5001C74.9 28.1001 91.4999 18.3001 90.2999 15.5001C89.7999 14.3001 86.3999 14.5001 81.0999 15.8001C80.8999 15.4667 80.6667 15.1667 80.4 14.9001C93.8 11.1001 103.2 10.2001 104.5 13.2001Z'
			fill='#FFF3E0'
		/>
		<path
			d='M99.4 13.6003C101.1 17.7003 81.3 30.3003 55.1 41.6003C28.9 53.0003 6.20004 58.9003 4.40004 54.9003C3.40004 52.6003 8.90001 47.9003 18.3 42.2003C18.3667 42.467 18.4 42.7336 18.4 43.0003C13.9 46.0003 11.4 48.3003 11.9 49.4003C13.1 52.2003 31.7 46.9003 53.3 37.5003C74.9 28.1003 91.5 18.3003 90.3 15.5003C89.8 14.3003 86.4 14.5003 81.1 15.8003C80.9 15.6003 80.7333 15.4003 80.6 15.2003C91.2 12.2003 98.4 11.4003 99.4 13.6003Z'
			fill='#FFCC80'
		/>
	</svg>
)

export default V5
