import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import ButtonDefault from '@atoms/buttons/button'
import { TemplateERROR } from '@templates/404Template'
import useLogout from '@tools/hooks/useLogout'

const ErrorFallbackPage: FC<any> = ({ error, _resetErrorBoundary }: any) => {
	/* TODO parse for error */
	const { t } = useTranslation()
	const { logout } = useLogout()
	return (
		<TemplateERROR>
			<span>{error?.message ? error?.message : 'ERROR'}</span>
			<p>{error?.stack ? error?.stack : 'stack'}</p>
			<ButtonDefault
				name={t('Leaving')}
				classes={'btn-default btn-white'}
				action={() => logout()}
			/>
		</TemplateERROR>
	)
}

export default ErrorFallbackPage
