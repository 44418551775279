import React, { useEffect } from 'react'
import MyCompaniesContainer from '@containers/companies/my'
// import HomeTemplate from '@templates/homeTemplate';

const MyCompany: React.FC = () => {
	useEffect(() => {
		console.log('MyCompany PAGE')
	}, [])

	return <MyCompaniesContainer />
}

export default MyCompany
