import { Dropdown } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import type { stateType } from '@types'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import IconDelete from '@assets/icons/btn/delete'
import { IconMoveToArchive, IconMoveToRemove } from '@assets/icons/nav/myCompanies'
import DeleteCompanyModal from '@organisms/modals/confirms/deleteCompany'
import RemoveCompanyModal from '@organisms/modals/confirms/removeCompany'
import {
	addCompanyToArchive,
	removeCompany,
	removeCompanyFromList,
} from '@redux/reducers/companySlice'

import '../style.scss'

const DropdownCompaniesNav: FC<any> = ({ item, setSuccess }: any) => {
	const [open, setOpen] = useState<boolean>(false)

	const { info } = useSelector((state: stateType) => state.user)
	const { id, status, role } = item
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const ItemArchive = ({ onClick }: any) => {
		const action = () => {
			setOpen(false)
			onClick && onClick()
		}
		return (
			<div className='dropdown-overlay__item dropdown-companies' onClick={action}>
				<IconMoveToArchive />
				<span className='dropdown-overlay__text'>{t('Move to archive')}</span>
			</div>
		)
	}

	const ItemDelete = ({ onClick }: any) => {
		const action = () => {
			setOpen(false)
			onClick && onClick()
		}
		return (
			<div className='dropdown-overlay__item dropdown-companies' onClick={action}>
				<IconMoveToRemove />
				<span className='dropdown-overlay__text'>{t('Delete')}</span>
			</div>
		)
	}

	const items: MenuProps['items'] = [
		{
			key: 'removeCompany',
			label: (
				<RemoveCompanyModal
					component={ItemArchive}
					phone={info.phone}
					action={(callback: any) => {
						dispatch(
							addCompanyToArchive({
								request: { company_id: id },
								callback: () => {
									dispatch(removeCompanyFromList(id))
									callback()
									setSuccess({
										title: t('The company has been successfully archived!'),
										desc: t(
											'The company has been moved to the «Archive of Companies» section'
										),
									})
								},
							})
						)
					}}
				/>
			),
		},
	]

	if ((status.id === 1 || status === 3) && role.id === 1) {
		items.push({
			key: 'all.length',
			label: (
				<DeleteCompanyModal
					component={ItemDelete}
					phone={info.phone}
					action={(callback: any) => {
						dispatch(
							removeCompany({
								request: { company_id: id },
								callback: {
									success: () => {
										dispatch(removeCompanyFromList(id))
										callback()
										setSuccess({
											title: t('Company successfully deleted'),
											desc: t('Company successfully removed from database'),
										})
									},
									error: () => {
										callback()
									},
								},
							})
						)
					}}
				/>
			),
		})
	}

	return (
		<Dropdown
			overlayClassName={'dropdown-company-wrap'}
			className={'dropdown'}
			menu={{ items }}
			trigger={['click']}
			open={open}
			onOpenChange={(data: any) => setOpen(data)}
		>
			<div
				className='dropdown__triger'
				onClick={e => {
					e.preventDefault()
					setOpen(true)
				}}
			>
				<IconDelete />
			</div>
		</Dropdown>
	)
}

export default DropdownCompaniesNav
