import React from 'react'
import type { FC } from 'react'

export const IconZoomMinus: FC = () => (
	<svg width='10' height='2' viewBox='0 0 10 2' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.66667 1.33333H0.666667C0.489856 1.33333 0.320287 1.2631 0.195262 1.13807C0.070238 1.01305 0 0.843478 0 0.666667C0 0.489856 0.070238 0.320287 0.195262 0.195262C0.320287 0.070238 0.489856 0 0.666667 0H8.66667C8.84348 0 9.01305 0.070238 9.13807 0.195262C9.2631 0.320287 9.33333 0.489856 9.33333 0.666667C9.33333 0.843478 9.2631 1.01305 9.13807 1.13807C9.01305 1.2631 8.84348 1.33333 8.66667 1.33333Z'
			fill='#8D8BED'
		/>
	</svg>
)
