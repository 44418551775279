import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/*
 *
 * App reducer
 *
 */
const initialState = {
	ready: false,
	appUpdateId: new Date().toISOString(),
	error: null,
	showBanner: true,
	isTestModalOpen: false,
	lang: '',
	notification: {
		status: false,
		text: '',
	},
	appTitles: {} as any,
	page: '',
	status: '',
	success: {
		type: '',
		action: '',
	},
	options: {} as any,
	testFormOptions: {},
}

export type appInitStateType = typeof initialState

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		appUnother: () => console.log('appUnother'),
		getOptions: () => console.log('getOptions'),
		getTestFormOptions: () => console.log('getTestFormOptions'),
		setFile: () => console.log('setFile'),
		appUpdate: (state, action: PayloadAction<any>) => {
			state.appUpdateId = action.payload
		},
		setAppLang: (state, action: PayloadAction<any>) => {
			state.lang = action.payload
		},
		setAppTitles: (state, action: PayloadAction<any>) => {
			state.appTitles = action.payload
		},
		appNotification: (state, action: PayloadAction<any>) => {
			state.notification = action.payload
		},
		appPage: (state, action: PayloadAction<any>) => {
			localStorage.setItem('page', action.payload)
			state.page = action.payload
		},
		appError: (state, action: PayloadAction<any>) => {
			state.error = action.payload
		},
		setStatus: (state, action: PayloadAction<any>) => {
			state.status = action.payload
		},
		setSuccess: (state, action: PayloadAction<any>) => {
			state.success = action.payload
		},
		setOptions: (state, action: PayloadAction<any>) => {
			state.options = action.payload
		},
		updateOptions: (state, action: PayloadAction<any>) => {
			state.options = { ...state.options, ...action.payload }
		},
		setTestFormOptions: (state, action: PayloadAction<any>) => {
			state.testFormOptions = action.payload
		},
		setTestModal: (state, action: PayloadAction<any>) => {
			state.isTestModalOpen = action.payload
		},
		setShowBanner: (state, action: PayloadAction<any>) => {
			state.showBanner = action.payload
		},
	},
})

export default appSlice.reducer
export const {
	appUnother,
	appUpdate,
	getOptions,
	setAppTitles,
	getTestFormOptions,
	setFile,
	appNotification,
	setAppLang,
	appPage,
	appError,
	setStatus,
	setSuccess,
	setOptions,
	updateOptions,
	setTestFormOptions,
	setTestModal,
	setShowBanner,
}: any = appSlice.actions
