import { Col, Modal, Row } from 'antd'
import React, { useEffect } from 'react'
import type { FC } from 'react'
import CloseModalIcon from '@assets/icons/CloseModalIcon'
import successIcon from '@image/icons/succes-icon.svg'

interface StaticFormModalType {
	title?: string
	destroyOnClose?: boolean
	text?: string
	onCancel?: any
	visible: boolean
	changeVisible?: any
	closable?: boolean
	component: React.ReactNode
	img?: string
	isHideAside?: boolean
	isSuccess?: boolean
	successFn?: any
	successMessage?: string
	rest?: any
	onSubmit?: any
}

const StaticFormModal: FC<StaticFormModalType> = (props: StaticFormModalType) => {
	const {
		title,
		destroyOnClose,
		text,
		visible,
		changeVisible,
		component: FormComponent,
		img,
		isHideAside,
		isSuccess,
		successFn,
		successMessage,
		...rest
	} = props

	const currentTitle = isSuccess ? '' : title
	const currentText = isSuccess ? successMessage : text

	useEffect(() => {
		const index = setTimeout(() => {
			isSuccess && successFn()
		}, 2500)
		return () => {
			clearTimeout(index)
		}
	}, [isSuccess])

	return (
		<Modal
			className='static-modal'
			open={visible}
			footer={null}
			width={!isHideAside ? 984 : 504}
			onCancel={() => changeVisible(!visible)}
			styles={{ body: { padding: '64px' } }}
			style={{ paddingBottom: 0 }}
			centered
			closable={!isSuccess}
			closeIcon={<CloseModalIcon onClick={() => changeVisible(!visible)} />}
			{...rest}
		>
			<Row wrap={false} className={`static-modal__wrapper ${isHideAside ? 'small' : ''}`}>
				{!isHideAside ? (
					<Col flex={isSuccess ? '100%' : '360px'} className={'static-modal__aside'}>
						<div
							className={`static-modal__aside-content aside-content  ${
								isSuccess ? 'aside-content-success' : ''
							}`}
						>
							<div className='aside-content__body'>
								{isSuccess && (
									<img
										className='success__icon'
										src={successIcon}
										alt='successIcon'
									/>
								)}
								<h4 className={'aside-content__title'}>{currentTitle}</h4>
								<p className={'aside-content__message'}>{currentText}</p>
							</div>
							{img && (
								<div className='aside-content__footer'>
									<Row align='bottom' justify='center'>
										<Col>
											<img
												className='aside-content__img'
												src={img}
												alt='img-bg'
											/>
										</Col>
									</Row>
								</div>
							)}
						</div>
					</Col>
				) : null}
				{!isSuccess && (
					<Col flex={1} className='static-modal__content'>
						{FormComponent}
					</Col>
				)}
			</Row>
		</Modal>
	)
}

export default StaticFormModal
