import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
	SortableContext,
	arrayMove,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Table } from 'antd'
import React, { useState, useEffect } from 'react'
import type { DragEndEvent } from '@dnd-kit/core'
import type { ColumnsType } from 'antd/es/table'
import './styles/style.scss'
import IconDropDown from '@assets/icons/select/dropDown'
import { IconDelete } from '@assets/icons/uploaders'

interface DataType {
	order: number
	key: number
	name: string
}

interface TagsSelectProps {
	dataTags: number[]
	options: any
	onOrderChange?: (_newOrder: number[]) => void
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	'data-row-key': string
}

const Row = (props: RowProps) => {
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: props['data-row-key'],
	})

	const style: React.CSSProperties = {
		...props.style,
		transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
		transition,
		cursor: 'move',
		...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
	}

	return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />
}

const TagsSelect: React.FC<TagsSelectProps> = ({ dataTags, options, onOrderChange }) => {
	const [dataSource, setDataSource] = useState(
		options
			.filter(option => dataTags.includes(option.id))
			.sort((a, b) => dataTags.indexOf(a.id) - dataTags.indexOf(b.id))
			.map((item, index) => ({ key: item.id, name: item.name, order: index + 1 }))
	)

	useEffect(() => {
		setDataSource(
			options
				.filter(option => dataTags.includes(option.id))
				.sort((a, b) => dataTags.indexOf(a.id) - dataTags.indexOf(b.id))
				.map((item, index) => ({ key: item.id, name: item.name, order: index + 1 }))
		)
	}, [dataTags, options])

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 1,
			},
		})
	)

	const removeItem = (keyToRemove: number) => {
		const updatedDataSource = [...dataSource].filter(item => item.key !== keyToRemove)

		if (onOrderChange) {
			const newOrder = updatedDataSource.map(item => item.key)
			onOrderChange(newOrder)
		}
	}

	const columns: ColumnsType<DataType> = [
		{
			title: 'Name',
			dataIndex: 'name',
			render: (text, record) => (
				<div className='dropDownTable__wrap'>
					<div className='dropDownTable__text'>
						<IconDropDown />
						<i>{record.order}</i>
						<span>{text}</span>
					</div>
					<span onClick={() => removeItem(record.key)} className='dropDownTable__delete'>
						<IconDelete />
					</span>
				</div>
			),
		},
	]

	const onDragEnd = ({ active, over }: DragEndEvent) => {
		if (active.id !== over?.id) {
			setDataSource(prev => {
				const activeIndex = prev.findIndex(i => i.key === active.id)
				const overIndex = prev.findIndex(i => i.key === over?.id)
				const updatedDataSource = arrayMove(prev, activeIndex, overIndex).map(
					(item, index) => {
						if (typeof item === 'object' && item !== null) {
							return { ...item, order: index + 1 }
						}
						return item
					}
				)

				if (onOrderChange) {
					const newOrder = updatedDataSource.map((item: DataType) => item.key)
					onOrderChange(newOrder)
				}

				return updatedDataSource
			})
		}
	}

	if (dataSource.length < 1) return <></>

	return (
		<DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
			<SortableContext
				items={dataSource.map(i => i.key)}
				strategy={verticalListSortingStrategy}
			>
				<Table
					className='dropDownTable'
					pagination={false}
					showHeader={false}
					components={{
						body: {
							row: Row,
						},
					}}
					rowKey='key'
					columns={columns}
					dataSource={dataSource}
				/>
			</SortableContext>
		</DndContext>
	)
}

export default TagsSelect
