import { yupResolver } from '@hookform/resolvers/yup'
import { message } from 'antd'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'

import type { FormCodeData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault from '@atoms/inputs'
import TimeCountdown from '@molecules/timeCountdown'

import { profileConfirmPhone, updateUser } from '@redux/reducers/userSlice'
import i18n from '@src/i18n'

import { codeSchema } from '@tools/shemas'

import './style.scss'

const FormCode: FC<any> = ({ /*action,*/ loading, setVisible }: any) => {
	const schema = useMemo(() => codeSchema(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			code: undefined,
		},
		resolver: yupResolver(schema),
	})

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onSubmit = async (data: FormCodeData) => {
		const { code } = data
		const request = {
			confirm_phone_code: Number(code),
		}

		dispatch(
			profileConfirmPhone({
				request,
				callback: () => {
					dispatch(updateUser({ is_confirm_phone: 1 }))
					setVisible(false)
					message.success({ content: t('Phone verified successfully'), duration: 4 })
				},
			})
		)
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className='form code-form'>
				<div className='form__container'>
					<InputDefault
						name={'code'}
						label={t('SMS code')}
						placeholder={t('Enter code')}
						error={errors.code?.message}
						register={register}
						control={control}
					/>
					<div className='form__container-links'>
						<TimeCountdown />
					</div>
				</div>
				<div className='form__container'>
					<ButtonDefault
						name={t('Confirm')}
						type={'submit'}
						loading={loading}
						disabled={loading}
					/>
				</div>
			</form>
		</>
	)
}

export default FormCode
