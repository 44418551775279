/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import parse from 'html-react-parser'
import _ from 'lodash'
import { put, call } from 'redux-saga/effects'
import { updateOptions } from '../reducers/appSlice'
import { NOTIFICATION } from './notification'
import type { ApiResponse } from '@src/types/api'
import { api } from '@api/app'
import { updateUser } from '@redux/reducers/userSlice'

export function* sagaGetOptions(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.getOptions, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(updateOptions(data.list_data))
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	}
}

export function* sagaSetFile(action) {
	const { payload } = action
	const { request, callback } = payload
	const { success, error } = callback

	try {
		const response = yield call(api.setFile, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			error && error()
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(updateUser(data.user_data))
			success && success()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		//yield put(setLoading(false));
	}
}
