export const structureFields = [
	{
		name: 'manager_lname',
		label: 'Last name',
		placeholder: 'Last name',
		required: true,
		className: 'w-176',
	},
	{
		name: 'manager_name',
		label: 'Name',
		placeholder: 'Name',
		required: true,
		className: 'w-176',
	},
	{
		name: 'manager_sname',
		label: 'Patronymic',
		placeholder: 'Patronymic',
		className: 'w-176',
	},
	{
		name: 'employees',
		label: 'Number of employees in the company',
		placeholder: 'The number of employees',
		required: true,
		className: 'w-376',
		fieldType: 'selectDefault',
		optionKey: 'employees',
	},
	{
		name: 'departments',
		label: 'Names of departments / Divisions / Directions',
		placeholder: 'Select from list',
		required: true,
		optionKey: 'departments',
		modalBtnTxt: 'Add department',
		fieldType: 'selectMultiple',
		addButton: 'department',
		removeButton: 'department',
	},
	{
		tooltip: 'tooltip structure',
		name: 'organizational_structure',
		label: 'Organizational structure',
		placeholder: 'Select from list',
		required: true,
		fieldType: 'selectDefault',
		optionKey: 'organizational_structure',
	},
]
