import parse from 'html-react-parser'
import _ from 'lodash'
import { put, call } from 'redux-saga/effects'

import { NOTIFICATION } from './notification'
import type { ApiResponse } from '@src/types/api'
import { api } from '@api/notificationsUser'
import { updateNotifications, setNotificationsStatus } from '@redux/reducers/notificationsUserSlice'

export function* sagaGetNotificationsUserList(action) {
	const { payload } = action
	const { request } = payload

	try {
		const response = yield call(api.getNotificationsUserList, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(updateNotifications(data.notifications))
			//callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	}
}

export function* sagaGetNotificationsUserNew(action) {
	const { payload } = action
	const { request } = payload

	try {
		const response = yield call(api.getNotificationsUserNew, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			if (data.notifications > 0) {
				yield put(setNotificationsStatus(true))
			} else {
				yield put(setNotificationsStatus(false))
			}
			//callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	}
}

export function* sagaUpdateStatusView(action) {
	const { payload } = action
	const { request } = payload

	try {
		const response = yield call(api.updateStatusView, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield call(sagaGetNotificationsUserNew, action)
			//callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		//yield put(setLoading(false));
	}
}
