import React from 'react'

const V4 = () => (
	<svg width='78' height='78' viewBox='0 0 78 78' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M78 39.0411C78 60.5753 60.5753 78 39.0411 78C17.4247 78 0 60.5753 0 39.0411C0 17.4247 17.4247 0 39.0411 0C60.5753 0 78 17.4247 78 39.0411Z'
			fill='#F48625'
		/>
		<path
			d='M61.3973 71.0137C54.9863 75.452 47.3425 78 39.0411 78C17.4247 78 0 60.5753 0 39.0411C0 17.4247 17.4247 0 39.0411 0C47.3425 0 54.9863 2.54795 61.3973 6.9863C60.5753 7.9726 59.3425 8.63014 57.9452 8.63014H53.7534C51.2877 8.63014 49.3973 10.6027 49.3973 12.9863C49.3973 15.3699 51.2877 17.3425 53.7534 17.3425H57.9452C60.3288 17.3425 62.3014 19.2329 62.3014 21.6164C62.3014 24.0822 60.3288 25.9726 57.9452 25.9726H53.7534C51.2877 25.9726 49.3973 27.9452 49.3973 30.3288C49.3973 32.7123 51.2877 34.6849 53.7534 34.6849H57.9452C60.3288 34.6849 62.3014 36.5753 62.3014 39.0411C62.3014 41.4247 60.3288 43.3151 57.9452 43.3151H53.7534C51.2877 43.3151 49.3973 45.2877 49.3973 47.6712C49.3973 50.0548 51.2877 52.0274 53.7534 52.0274H57.9452C60.3288 52.0274 62.3014 53.9178 62.3014 56.3836C62.3014 58.7671 60.3288 60.6575 57.9452 60.6575H53.7534C51.2877 60.6575 49.3973 62.6301 49.3973 65.0137C49.3973 67.3973 51.2877 69.3699 53.7534 69.3699H57.9452C59.3425 69.3699 60.5753 70.0274 61.3973 71.0137Z'
			fill='#F9A81B'
		/>
		<path
			d='M39.0411 73.7261C39.0411 75.6987 37.6438 77.4247 35.7534 77.9179C15.6986 76.274 0 59.4247 0 39.0412C0 18.5754 15.6986 1.7261 35.7534 0.0822601C37.6438 0.575411 39.0411 2.30142 39.0411 4.27402C39.0411 6.73978 37.0685 8.6302 34.6849 8.6302H30.411C28.0274 8.6302 26.0548 10.6028 26.0548 12.9864C26.0548 15.3699 28.0274 17.3425 30.411 17.3425H34.6849C37.0685 17.3425 39.0411 19.2329 39.0411 21.6165C39.0411 24.0822 37.0685 25.9727 34.6849 25.9727H30.411C28.0274 25.9727 26.0548 27.9453 26.0548 30.3288C26.0548 32.7124 28.0274 34.685 30.411 34.685H34.6849C37.0685 34.685 39.0411 36.5754 39.0411 39.0412C39.0411 41.4247 37.0685 43.3151 34.6849 43.3151H30.411C28.0274 43.3151 26.0548 45.2877 26.0548 47.6713C26.0548 50.0549 28.0274 52.0275 30.411 52.0275H34.6849C37.0685 52.0275 39.0411 53.9179 39.0411 56.3836C39.0411 58.7672 37.0685 60.6576 34.6849 60.6576H30.411C28.0274 60.6576 26.0548 62.6302 26.0548 65.0138C26.0548 67.3973 28.0274 69.3699 30.411 69.3699H34.6849C37.0685 69.3699 39.0411 71.3425 39.0411 73.7261Z'
			fill='#F8CE8C'
		/>
	</svg>
)

export default V4
