import { t } from 'i18next'

export const messages = {
	registrationSuccess: t(
		'Congratulations to VI have successfully registered.A letter was sent to your e-mail with a link to confirm'
	),
	resetPasswdSuccess: t(
		'An email has been sent to your email with a password restoration instructions.'
	),

	isNotConfirmUser: t(
		'To continue it is necessary to activate your account.A letter was sent to your email address with reference to confirm.'
	),

	bannersText: {
		verifyWait: t(
			'Welcome to your profile.When you pass the verification, you will receive a notification in the mail.You can also use all the functions of the platform!'
		),
		getAccess: t(
			'Welcome to your profile, you gained access to all the functions of the platform.Verify information about the company to publish your first vacancies.'
		),
	},

	addVacancyMainTxt: t('The new vacancy was successfully created and added to the base'),
	deputyRegistration: t(
		'Deputies are successfully added to the base.A letter was sent to their e-mail with a link to confirm'
	),

	goToHome: t('You will be redirected to the main page through'),
	goToVerify: t('You will be redirected to the verification of the company through:'),
}

export const positionErrorsMsg = {
	FUNCTIONALITY: t('The functionality is not chosen'),
	TARGET: t('The goal is not chosen'),
	RESULT: t('Not chosen the result'),
	KPI: t('Not selected KPI'),
	ZUN: t('Zun is not chosen'),
}

export const rules = {
	required: t('Field required'),
}
