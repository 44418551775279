import { Button, Col, Form, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import { checkFormValues } from '@tools/helpers'
//import { useAddItemToDropDownList } from '@tools/hooks/useAddItemToDropDownList'
import { rules } from '@tools/messages'

const FormAddProgram: FC<any> = ({ changeVisible, onSubmitForm }: any) => {
	const [form] = Form.useForm()
	//const addProgram = useAddItemToDropDownList()
	const { dropDownList } = useSelector((state: stateType) => ({
		dropDownList: state.app.options,
	}))
	const onFinish = () => {
		const values = checkFormValues(form.getFieldsValue())
		onSubmitForm(values)
		form.resetFields()
		changeVisible()
	}
	const handleOnAddProgram = async () => {
		/*
      try {
      await addProgram('', name);
    } catch (err) {
      console.error(err);
      message.error(err.message);
      throw err;
    }
   */
	}

	const { t } = useTranslation()
	return (
		<Form
			style={{ height: '100%' }}
			name='add-program'
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
		>
			<Row style={{ height: '100%' }}>
				<Col span={24}>
					<AppText
						text={t('Software proficiency')}
						style={{
							color: '#20272E',
							fontSize: 18,
							fontWeight: 700,
							marginBottom: 24,
							lineHeight: 1.5715,
							display: 'block',
						}}
					/>
					<Form.Item
						className='form__container'
						label={t('Program')}
						name='name'
						rules={[{ required: true, message: rules.required }]}
					>
						<AppSelect
							options={dropDownList?.s_programs}
							placeholder={t('Write or select from the list')}
							isHaveFooter
							onSubmit={handleOnAddProgram}
						/>
					</Form.Item>
					<Form.Item
						className='form__container'
						label={t('Proficiency level')}
						name='levels'
						rules={[{ required: true, message: rules.required }]}
					>
						<AppSelect
							options={dropDownList?.s_program_level}
							placeholder={t('Select from list')}
						/>
					</Form.Item>
				</Col>

				<Col span={24}>
					<Row justify='end' align='bottom' style={{ height: '100%' }}>
						<Col>
							<Button type='primary' shape='round' htmlType='submit'>
								{t('Save')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	)
}

export default FormAddProgram
