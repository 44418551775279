import React from 'react'
import { useDispatch } from 'react-redux'
import type { FC } from 'react'
import CompanyNotVerifiedModal from '@organisms/modals/confirms/companyNotVerified'
import CompanyVerifiedModal from '@organisms/modals/confirms/companySuccessfullyVerified'
import { updateStatusView } from '@redux/reducers/notificationsUserSlice'
interface ModalItemsProps {
	notification?: any
}

const ModalItems: FC<ModalItemsProps> = ({ notification }) => {
	const dispatch = useDispatch()
	const { id, type } = notification

	const handleStatusVie = () => {
		id &&
			dispatch(
				updateStatusView({
					request: { notificationId: id },
					callback: {},
				})
			)
	}

	if (!notification) return <></>

	return (
		<>
			{type === 3 && <CompanyNotVerifiedModal action={handleStatusVie} />}
			{type === 4 && <CompanyVerifiedModal action={handleStatusVie} />}
		</>
	)
}

export default ModalItems
