import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { getNotificationsUserList } from '@redux/reducers/notificationsUserSlice'
import { getStoreToken } from '@tools/helpFunctions'

const useServiceWebSocket = () => {
	const userToken = getStoreToken()
	const WS_URL = `wss://api.prof.world/socket?userToken=${userToken}`
	const { lastMessage, readyState, sendMessage } = useWebSocket(WS_URL)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!userToken) return
		if (readyState === ReadyState.OPEN) {
			if (lastMessage) {
				const messageData = JSON.parse(lastMessage.data)

				if (messageData.notification === 'update')
					dispatch(getNotificationsUserList({ request: {}, callback: {} }))
			}
		}
	}, [readyState, lastMessage, dispatch])

	const sendCustomMessage = message => {
		if (readyState === ReadyState.OPEN) {
			sendMessage(message)
		}
	}

	return {
		lastMessage,
		readyState,
		sendCustomMessage,
	}
}

export default useServiceWebSocket
