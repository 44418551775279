import React from 'react'
import type { FC } from 'react'
//types
interface IconDeleteType {
	onClick?: () => void
}

const IconRecovry: FC<IconDeleteType> = ({ onClick }: IconDeleteType) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		onClick={onClick}
	>
		<path
			d='M2.84375 10.7067C3.40778 11.847 4.32255 12.7765 5.45375 13.3587C6.57684 13.9353 7.85734 14.13 9.10108 13.9133C10.3487 13.6939 11.4917 13.0765 12.3591 12.1533C13.2345 11.2214 13.7919 10.036 13.9511 8.76732C14.1127 7.49581 13.8692 6.20571 13.2551 5.08065C12.6468 3.96433 11.6961 3.07281 10.5431 2.53732C9.39755 2.00676 8.11018 1.86445 6.87642 2.13198C5.64308 2.39932 4.63175 3.00732 3.79642 3.96865C3.47033 4.32383 3.19647 4.72361 2.98308 5.15598'
			stroke='#D2D8E8'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M5.954 5.20864L2.594 5.81331L2 2.39331'
			stroke='#D2D8E8'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default IconRecovry
