import classNames from 'classnames'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'

import {
	IconProfileCompany,
	IconProfileConfirmPhone,
	IconProfileLocale,
} from '@assets/icons/nav/profileHeader'
import { IconRemoveUser } from '@assets/icons/nav/usersTable'
import ButtonDefault from '@atoms/buttons/button'
import { UserLcAvatar } from '@molecules/app/avatar'
import DropdownDefault from '@molecules/app/dropdwons/default'
import SocialList from '@molecules/lists/social'

import './style.scss'
import DeclineVerificationCompany from '@organisms/modals/confirms/declineVerificationCompany'
import RemoveLcCompanyModal from '@organisms/modals/confirms/removeLcCompany'
import { setStatus, setSuccess } from '@redux/reducers/appSlice'
import { setVerification, removeCompany, restoreCompany } from '@redux/reducers/lcVerificatorSlice'
import { NOTIFICATION } from '@redux/sagas/notification'
import { companyStatus } from '@tools/constants'

const { REJECTED, ACTIVE } = companyStatus

const CompanyInfo: FC = () => {
	//const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { id } = useParams()
	const { company }: any = useSelector((state: stateType) => state.lcVerificator)
	const { sessionProperties } = useSelector((state: stateType) => state.user)

	const companyId = id || sessionProperties?.companyId

	const IsCompanyDelete = company?.info?.company?.is_del === 1
	const isCompanyActiveOrRejected =
		company?.info?.company?.status?.id === REJECTED ||
		company?.info?.company?.status?.id === ACTIVE

	const RemoveBtn = ({ onClick }: any) => (
		<div className='btn-remove' onClick={onClick}>
			<IconRemoveUser />
		</div>
	)

	const DeclineBtn = ({ onClick }: any) => (
		<ButtonDefault
			name={t('Reject')}
			classes={isCompanyActiveOrRejected ? '' : 'btn-gray btn-transparent'}
			action={onClick}
			disabled={isCompanyActiveOrRejected}
		/>
	)

	const actionVerification = (verificationId: any, nameSuccess: string) => {
		dispatch(
			setVerification({
				request: {
					company_id: companyId,
					verification: verificationId,
				},
				callback: {
					success: () => {
						dispatch(setStatus('success'))
						dispatch(setSuccess({ type: nameSuccess }))
					},
				},
			})
		)
	}

	const actionRemoveCompany = () => {
		dispatch(
			removeCompany({
				request: {
					company_id: companyId,
				},
				callback: {
					success: () => {
						NOTIFICATION.success(t('The company was successfully deleted'))
						setTimeout(() => window.location.reload(), 1000)
						//setTimeout(() => navigate('/lc-companies'), 1000)
					},
				},
			})
		)
	}

	const actionRestoreCompany = () => {
		//'lc-verification-retrieve'
		dispatch(
			restoreCompany({
				request: {
					company_id: companyId,
				},
				callback: {
					success: () => {
						NOTIFICATION.success(t('Company successfully restored'))
						setTimeout(() => window.location.reload(), 1000)
						//setTimeout(() => navigate('/lc-companies'), 1000)
					},
				},
			})
		)
	}

	return (
		<div className='company-info-block'>
			<div className='company-info-block__body'>
				{company?.info && (
					<div className='company-info-block__buttons'>
						{!IsCompanyDelete ? (
							<>
								<ButtonDefault
									name={t('Verify')}
									action={() => actionVerification(1, 'lc-verification')}
									disabled={isCompanyActiveOrRejected}
									classes={'mn'}
								/>
								<DeclineVerificationCompany component={DeclineBtn} />
							</>
						) : (
							<ButtonDefault
								name={t('Retrieve')}
								action={() => actionRestoreCompany()}
								classes={'mn'}
							/>
						)}
					</div>
				)}

				<div className='company-info-block__tags'>
					<div className='company-info-block__label'>{t('Company')}</div>
					<div className='company-info-block__list'>
						{company?.info?.company?.name && (
							<div className='company-info-block__tag'>
								{company?.info?.company?.name}
							</div>
						)}
						{company?.info?.company?.code && (
							<div className='company-info-block__tag'>
								{company?.info?.company?.code}
							</div>
						)}
						{company?.info?.company?.activity?.name && (
							<div className='company-info-block__tag'>
								{company?.info?.company?.activity?.name}
							</div>
						)}
						{company?.info?.company?.services && (
							<div className='company-info-block__tag'>
								{company?.info?.company?.services}
							</div>
						)}
						{company?.info?.company?.employees?.name && (
							<div className='company-info-block__tag'>
								{company?.info?.company?.employees?.name}
							</div>
						)}
						{company?.info?.company?.website && (
							<div className='company-info-block__tag'>
								{company?.info?.company?.website}
							</div>
						)}
					</div>
				</div>
				<div className='company-info-block__tag-wrap'>
					<div className='company-info-block__tags'>
						<div className='company-info-block__label'>{t('Created Date')}</div>
						<div className='company-info-block__list'>
							<div className='company-info-block__tag'>
								{company?.info?.company?.create_date
									? dayjs(company?.info?.company?.create_date).format(
											'MM/DD/YYYY'
									  )
									: ''}
							</div>
						</div>
					</div>
					{IsCompanyDelete && (
						<div className='company-info-block__tags'>
							<div className='company-info-block__label'>{t('Closing Date')}</div>
							<div className='company-info-block__list'>
								<div className='company-info-block__tag'>
									{company?.info?.company?.del_date
										? dayjs(company?.info?.company?.del_date).format(
												'MM/DD/YYYY'
										  )
										: ''}
								</div>
							</div>
						</div>
					)}
				</div>
				<div className='company-info-block__status'>
					<div className='company-info-block__label'>{t('Status')}</div>
					{IsCompanyDelete ? (
						<div
							className={classNames(
								'company-info-block__status-value',
								'status-company-5'
							)}
						>
							{t('Remove')}
						</div>
					) : (
						<div
							className={classNames(
								'company-info-block__status-value',
								`company-info-block__status-value-${company?.info?.company?.status?.id}`
							)}
						>
							{company?.info?.company?.status?.name}
						</div>
					)}
				</div>
				<div className='company-info-block__owner-info'>
					<div className='company-info-block__label'>{t('Company Owner')}</div>
					{company?.info?.owner && (
						<div className='company-info-block__drop-block'>
							<UserLcAvatar />
							<DropdownDefault
								icon={<IconProfileCompany />}
								title={company?.info?.owner?.email}
								list={
									company?.info?.owner?.otherEmails
										? company?.info?.owner?.otherEmails
										: []
								}
							/>
							<DropdownDefault
								icon={<IconProfileConfirmPhone />}
								title={company?.info?.owner?.phone}
								list={
									company?.info?.owner?.otherPhones
										? company?.info?.owner?.otherPhones
										: []
								}
							/>
							<DropdownDefault
								icon={<IconProfileLocale />}
								title={t('Kyiv, Ukraine')}
								list={[]}
							/>
							<SocialList socials={company?.info?.owner?.socials} />
						</div>
					)}
				</div>
			</div>
			<div className='company-info-block__footer'>
				{!IsCompanyDelete && (
					<RemoveLcCompanyModal component={RemoveBtn} action={actionRemoveCompany} />
				)}
			</div>
		</div>
	)
}

export default CompanyInfo
