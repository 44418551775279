import React from 'react'

import TabsCompanies from '../tabsCompanies'
import type { FC } from 'react'
import StatsInfo from '@organisms/lcVerification/statsInfo'

import './style.scss'

const LcVerificationCompaniesContainer: FC = () => (
	<div className='lc-verification-container'>
		<StatsInfo />
		<TabsCompanies />
	</div>
)

export default LcVerificationCompaniesContainer
