/* eslint-disable react/prop-types */
import { Button, Col, Form, message, Row, Switch, Upload } from 'antd'
import axios from 'axios'
import __ from 'lodash'
import { func, number } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFile, setCompanyInfo } from '@api/asyncHelpers'
import { fileApi } from '@api/routers'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import Attachment from '@molecules/attachment'
import { getCompanyInfoAction } from '@redux/actions/companyActions'
import { getFileFormat, getFileName, getFileSize, normFile } from '@tools/helpers'
import { useAddItemToDropDownList } from '@tools/hooks/useAddItemToDropDownList'
import { rules } from '@tools/messages'

FormSetCompanyPersonManagement.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetCompanyPersonManagement({ changeStep, step }) {
	const addItemToDropDownList = useAddItemToDropDownList()
	const dispatch = useDispatch()
	const { lists, company } = useSelector(state => ({
		lists: state.dropDownList.lists,
		company: state.company.info,
	}))
	const [form] = Form.useForm()

	const [isSwitch, setIsSwitch] = useState(true)
	const [policyFiles, setPolicyFiles] = useState([])

	const dir = 'policy'

	const onFinish = async () => {
		const key = 'set-company-management'
		const formValues = form.getFieldsValue()
		message.loading({ content: t('Loading'), key })
		try {
			const json = await setCompanyInfo(formValues)
			if (json.ok) {
				message.success({ content: t('Company structure updated'), key })
				await dispatch(getCompanyInfoAction())
				changeStep(step + 1)
			} else {
				message.error({ content: t('Error'), key })
			}
		} catch (e) {
			message.error({ content: e.message, key })
		}
	}
	const handleOnChangeSwitch = switchState => {
		setIsSwitch(switchState)
	}

	const props = {
		name: 'file',
		action: `${fileApi.add_file}?token=${localStorage.getItem('token')}&dir=${dir}`,
		showUploadList: false,
		accept: '.doc,.docx,.pdf',
		beforeUpload: file => {
			const name = getFileName(file)
			const format = getFileFormat(file).toLocaleLowerCase()
			const size = getFileSize(file)

			const formats = ['doc', 'docx', 'pdf']
			const currentFile = policyFiles.find(item => item.name === name)

			if (currentFile) {
				message.error(t('A file named name already exists!', { name: file.name }))
				return false
			}

			if (!formats.includes(format)) {
				message.error(
					`${t('Company structure updated')} "${format}" ${t('we wont allow it')}`
				)
				return false
			}

			setPolicyFiles(prev => [
				...prev,
				{
					id: __.uniqueId(),
					name,
					format,
					size,
					href: '',
					status: 'loading',
					progress: 0,
				},
			])

			return file
		},

		onSuccess(files) {
			setPolicyFiles(prev =>
				prev.map(item => {
					const itemFullName = `${item.name}.${item.format}`
					files.forEach(file => {
						if (file.name.toLocaleLowerCase() === itemFullName.toLocaleLowerCase()) {
							item.href = file.url
							item.status = 'done'
							item.progress = 100
						}
					})
					return item
				})
			)
		},
		onError(err) {
			console.error(err)
			message.error(`${t('Something went wrong')}: ${err}`)
			setPolicyFiles(null)
		},
		customRequest: async props => {
			const { action, file, onError, onSuccess, withCredentials } = props
			const formData = new FormData()
			formData.append('file', file)

			await axios
				.post(action, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
					},
					withCredentials,
					onUploadProgress: e => {
						const progress = Math.floor((e.loaded / e.total) * 100)
						const name = getFileName(file)
						setPolicyFiles(prev =>
							prev.map(item => {
								if (item.name === name) {
									item.progress = progress - 1
								}
								return item
							})
						)
					},
				})
				.then(data => {
					if (data.status === 200) {
						onSuccess(data.data.files)
					}
				})
				.catch(err => onError(err))
			return {
				abort() {
					message.error(t('Something went wrong'))
				},
			}
		},
	}

	const handleOnRemoveFile = async fullName => {
		setPolicyFiles(prev =>
			prev.map(item => {
				if (`${item.name}.${item.format}` === fullName) {
					item.status = 'loading'
					item.progress = 99
				}
				return item
			})
		)
		const res = await deleteFile(dir, fullName)
		if (res === 200) {
			setPolicyFiles(prev => prev.filter(item => item.name + '.' + item.format !== fullName))
		}
	}

	useEffect(() => {
		if (company) {
			form.setFields([
				{
					name: 's_selection_stages',
					value: company?.s_selection_stages?.map(item => item.name),
				},
				{ name: 's_training', value: company?.s_training?.map(item => item.name) },
			])
		}
	}, [company])

	return (
		<Form
			name='set-company-management'
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
			className='form'
		>
			<AppText
				text={t('Personnel Management')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Form.Item
				name='s_selection_stages'
				label={t('Selection stages')}
				rules={[{ required: true, message: rules.required }]}
			>
				<AppSelect
					options={lists?.s_selection_stages}
					placeholder={t('It is necessary to select and sequence the selection stages')}
					mode='multiple'
				/>
			</Form.Item>

			<Form.Item
				label={t('Personnel training and development')}
				required={isSwitch}
				style={{ marginBottom: 0 }}
			>
				<Row align='middle' gutter={12} style={{ maxHeight: 64 }}>
					<Col span={4} style={{ transform: 'translateY(-50%)' }}>
						<Switch
							style={{ width: '100%' }}
							checkedChildren={t('Yes')}
							unCheckedChildren={t('No')}
							defaultChecked
							onChange={handleOnChangeSwitch}
						/>
					</Col>

					<Col flex={1}>
						<Form.Item
							name='s_training'
							rules={[{ required: isSwitch, message: rules.required }]}
						>
							<AppSelect
								options={lists?.s_training}
								disabled={!isSwitch}
								placeholder={t('Personnel training and development')}
								mode='multiple'
								isHaveFooter
								onSubmit={name => addItemToDropDownList('s_training', name)}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form.Item>

			<Form.Item label={t('Personnel policy')}>
				<Form.Item valuePropName='fileList' getValueFromEvent={normFile} noStyle>
					<Upload.Dragger
						{...props}
						className={`form__dragger ${policyFiles.length > 0 ? 'disabled' : ''}`}
					>
						<p className='_upload-text'>
							{t('Drag a file here or')}{' '}
							<span className='link _bold'>{t('Download something')}</span>
						</p>
					</Upload.Dragger>
				</Form.Item>
				<div
					className='form__row'
					style={{
						flexDirection: 'column',
						gap: 6,
						alignItems: 'stretch',
						paddingTop: 12,
					}}
				>
					{policyFiles.map(file => (
						<div key={file.id} className='form__attachment'>
							<Attachment
								file={file}
								isEditable
								isDownload
								showProgress
								onRemove={() => handleOnRemoveFile(`${file.name}.${file.format}`)}
							/>
						</div>
					))}
				</div>
			</Form.Item>

			<Form.Item>
				<div className='form__row _between'>
					<Button
						disabled={policyFiles?.status === 'loading'}
						shape='round'
						type='default'
						htmlType='button'
						onClick={() => changeStep(step - 1)}
					>
						{t('Back')}
					</Button>
					<Button
						disabled={policyFiles?.status === 'loading'}
						shape='round'
						type='primary'
						htmlType='submit'
					>
						{t('Save and continue')}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default FormSetCompanyPersonManagement
