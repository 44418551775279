import { notification } from 'antd'
import React from 'react'
import IconCloseBox from '@assets/icons/page/closeBox'

export const NOTIFICATION = {
	error: (message = '', description = '', key = '') => {
		notification.open({
			key: key,
			className: 'notification notification__error',
			message: message,
			description: description,
			closeIcon: <IconCloseBox />,
			icon: null,
			duration: 7,
		})
	},
	success: (message = '', description = '', key = '') => {
		notification.open({
			key: key,
			className: 'notification notification__success',
			message: message,
			description: description,
			closeIcon: <IconCloseBox />,
			icon: null,
			duration: 7,
		})
	},
}
