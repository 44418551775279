import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import type { PayloadAction } from '@reduxjs/toolkit'
import { transformedAddress } from '@tools/helpFunctions'
/*
 *
 * Company reducer
 *
 */

const initialState = {
	loading: false,
	loadingLogin: false,
	info: {} as any,
	list: [] as any,
	addressList: [] as any,
	departmentList: [] as any,
	dataCreated: {} as any,
}

export type companyInitStateType = typeof initialState

const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		addFileDefaultCompany: () => console.log('addFileDefaultCompany'),
		removeFileDefaultCompany: () => console.log('removeFileDefaultCompany'),
		setCompanyDepartmentAddress: () => console.log('setCompanyDepartmentAddress'),
		getCompanyDepartmentAddressList: () => console.log('getCompanyDepartmentAddressList'),
		updateDataCreated: (state, action: PayloadAction<any>) => {
			state.dataCreated = action.payload?.request
		},
		updateCompanyDepartmentAddressList: (state, action: PayloadAction<any>) => {
			state.addressList = transformedAddress(action.payload).reverse()
		},
		getCompanyDepartmentList: () => console.log('getCompanyDepartmentList'),
		updateCompanyDepartmentList: (state, action: PayloadAction<any>) => {
			state.departmentList = action.payload
		},
		removeCompanyDepartmentAddress: () => console.log('removeCompanyDepartmentAddress'),
		removeCompanyDepartment: () => console.log('removeCompanyDepartment'),
		addCompanyDepartment: () => console.log('addCompanyDepartment'),
		getCompanyInfo: () => console.log('getCompanyInfo'),
		createCompany: () => console.log('createCompany'),
		updateCompany: () => console.log('updateCompany'),
		getCompany: () => console.log('getCompany'),
		getCompanies: () => console.log('getCompanies'),
		addCompanyToArchive: () => console.log('addCompanyToArchive'),
		recoveryCompany: () => console.log('recoveryCompany'),
		removeCompany: () => console.log('removeCompany'),
		restoreCompany: () => console.log('restoreCompany'),
		loginToCompany: () => console.log('loginToCompany'),
		logoutFromCompany: () => console.log('logoutFromCompany'),
		setFileToCompany: () => console.log('setFileToCompany'),
		removeFileCompany: () => console.log('removeFileCompany'),
		setCompanyStatus: () => console.log('setCompanyStatus'),
		setCompanyInfoContacts: () => console.log('setCompanyInfoContacts'),
		searchUserInCompany: () => console.log('searchUserInCompany'),
		addUserToCompany: () => console.log('addUserToCompany'),
		updateUserToCompany: () => console.log('updateUserToCompany'),
		removeUserFromCompany: () => console.log('removeUserFromCompany'),
		transferCompany: () => console.log('transferCompany'),
		setCompanies: (state, action: PayloadAction<any>) => {
			state.list = action.payload
		},
		removeCompanyFromList: (state, action: PayloadAction<any>) => {
			state.list = _.filter(state.list, ({ id }) => id !== action.payload)
		},
		setCompanyAdmin: () => console.log('setCompanyAdmin'),
		addCompanyDeputy: () => console.log('addCompanyDeputy'),
		setCompany: (state, action: PayloadAction<any>) => {
			state.info = action.payload
		},
		setLoading: (state, action: PayloadAction<any>) => {
			state.loading = action.payload
		},
		setLoadingLogin: (state, action: PayloadAction<any>) => {
			state.loadingLogin = action.payload
		},
		setFile: () => console.log('setFile'),
		removeFile: () => console.log('removeFile'),
	},
})

export default companySlice.reducer
export const {
	getCompanyDepartmentList,
	updateCompanyDepartmentList,
	setCompanyDepartmentAddress,
	getCompanyDepartmentAddressList,
	updateCompanyDepartmentAddressList,
	addCompanyDepartment,
	getCompanyInfo,
	createCompany,
	getCompany,
	getCompanies,
	removeCompanyFromList,
	removeCompany,
	restoreCompany,
	setCompanies,
	recoveryCompany,
	addCompanyToArchive,
	loginToCompany,
	logoutFromCompany,
	setFileToCompany,
	removeFileCompany,
	setCompanyStatus,
	setCompanyInfoContacts,
	searchUserInCompany,
	addUserToCompany,
	updateUserToCompany,
	removeUserFromCompany,
	transferCompany,
	updateCompany,
	setCompanyAdmin,
	addCompanyDeputy,
	setCompany,
	setLoading,
	setLoadingLogin,
	setFile,
	removeFile,
	addFileDefaultCompany,
	removeFileDefaultCompany,
	updateDataCreated,
	removeCompanyDepartment,
	removeCompanyDepartmentAddress,
}: any = companySlice.actions
