import { Dropdown } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import type { stateType } from '@types'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import { IconArrowDropdown } from '@assets/icons/btn/arrow'

import './style.scss'

const DropdownDefault: FC<any> = ({ icon, title, list }: any) => {
	const [open, setOpen] = useState<any>(null)
	const [_name, setName] = useState<string>('')
	const { info } = useSelector((state: stateType) => state.user)

	useEffect(() => {
		info.company && setName(info.company.name)
	}, [info?.company?.name])

	if (list.length === 0)
		return (
			<div className='dropdown'>
				<div className='dropdown__prefix-icon'>{icon}</div>
				<span className='dropdown__name'>{title}</span>
			</div>
		)

	const items: MenuProps['items'] = list.map((item: any, index: any) => ({
		key: index,
		label: <div className='dropdown-overlay__item'>{item}</div>,
	}))

	return (
		<Dropdown
			menu={{ items }}
			className={'dropdown'}
			trigger={['click']}
			open={open}
			onOpenChange={(data: any) => {
				setOpen(data)
			}}
		>
			<div
				className='dropdown__triger'
				onClick={e => {
					e.preventDefault()
					setOpen(prev => !!prev)
				}}
			>
				<div className='dropdown__prefix-icon'>{icon}</div>
				<span className='dropdown__name'>{title}</span>
				<div
					className={classNames(
						'dropdown__icon',
						{ ['animation-dropdown-up']: open },
						{ ['animation-dropdown-back']: !open }
					)}
				>
					{list.length > 0 && (
						<div className='dropdown__icon-arrow'>
							<IconArrowDropdown />
						</div>
					)}
				</div>
			</div>
		</Dropdown>
	)
}

export default DropdownDefault
