import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import { FormHeaderCompanies } from '@atoms/text/formHeader'
import FormCompanyRegistration from '@organisms/forms/companies/create'
import { setStatus } from '@redux/reducers/appSlice'

const CompanyCreate: FC = () => {
	const { page } = useSelector((state: stateType) => state.app)

	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(setStatus('company-create'))
	}, [])

	return (
		<>
			<Helmet title={page} />
			<FormHeaderCompanies title={t('Registration')} iconClosed />
			<FormCompanyRegistration />
		</>
	)
}

export default CompanyCreate
