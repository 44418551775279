import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'

import './style.scss'

interface TooltipConfirmPhoneBodyType {
	onClick?: () => void
}

const TooltipConfirmPhoneBody: FC<TooltipConfirmPhoneBodyType> = ({
	onClick,
}: TooltipConfirmPhoneBodyType) => {
	const { t } = useTranslation()

	return (
		<div className='tooltip-confirm-phone'>
			<div className='tooltip-confirm-phone__title'>{t('Verify phone number')}:</div>
			<ul className='tooltip-confirm-phone__ul'>
				<li value={'1'} className='tooltip-confirm-phone__li'>
					{t('To protect against unauthorized use by others')}
				</li>
				<li value={'2'} className='tooltip-confirm-phone__li'>
					{t('Login to the program')}
				</li>
			</ul>
			<div className='tooltip-confirm-phone__footer' onClick={onClick}>
				{t('Confirm')}
			</div>
		</div>
	)
}

export default TooltipConfirmPhoneBody
