/* eslint-disable react/prop-types */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getPriorityName } from '@tools/helpers'

function PreviewVacancyPrint({ store, subTitle, users }) {
	const { positions, user, company, lists } = store
	const { info } = positions
	const { t } = useTranslation()

	const styles = {
		header: {
			marginBottom: 24,
		},
		title: {
			fontSize: 24,
			fontWeight: 600,
		},
		block: {
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: 400,
		},
		subtitle: {
			color: 'rgb(141, 151, 176)',
			fontSize: 14,
			fontWeight: 500,
		},
		description: {
			width: '100%',
			marginBottom: 5,
		},
		description_name: {
			fontSize: 14,
			fontWeight: 600,
			marginBottom: 5,
		},
		description_list: {
			marginLeft: 16,
		},
		description_list_item: {
			listStyle: 'disc',
			fontSize: 12,
			margin: 0,
		},
		grid: {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, minmax(45%, 1fr))',
			gridAutoRows: 'auto',
			gap: 12,
		},
	}
	return (
		<>
			<div style={{ ...styles.block }}>
				{/*header*/}
				<div style={styles.header}>
					<h3 style={styles.title}>{info.name}</h3>
					<span style={styles.subtitle}>{subTitle}</span>
				</div>
				{/*Functional*/}
				<div style={styles.description}>
					<span style={styles.description_name}>{t('Functional')}:</span>
					<ul style={styles.description_list}>
						{info?.functionality?.map(func => (
							<li style={styles.description_list_item} key={func.id}>
								{func.name.trim()}
							</li>
						))}
					</ul>
				</div>
				{/*Result*/}
				<div style={styles.description}>
					<span style={styles.description_name}>{t('Result')}:</span>
					<ul style={styles.description_list}>
						{info?.result?.map(func => (
							<li style={styles.description_list_item} key={func.id}>
								{func.name.trim()}
							</li>
						))}
					</ul>
				</div>

				{/*KPI*/}
				<div style={styles.description}>
					<span style={styles.description_name}>{t('KPI')}:</span>
					<ul style={styles.description_list}>
						{info?.kpi?.map(func => (
							<li style={styles.description_list_item} key={func.id}>
								{func.name.trim()}
							</li>
						))}
					</ul>
				</div>
				<span
					style={{
						margin: '24px 0',
						background: 'rgb(203, 213, 226)',
						width: '100%',
						height: 1,
						display: 'block',
					}}
				/>
				<div style={{ ...styles.grid }}>
					{/*Languages*/}
					<div style={styles.description}>
						<span style={styles.description_name}>{t('Languages')}:</span>
						<ul style={styles.description_list}>
							{info?.languages?.length > 0 ? (
								info?.languages.map(el => (
									<li style={styles.description_list_item} key={el.id}>
										{el.name}
									</li>
								))
							) : (
								<span>-</span>
							)}
						</ul>
					</div>
					{/*Programs*/}
					<div style={styles.description}>
						<span style={styles.description_name}>{t('Programs')}:</span>
						<ul style={{ ...styles.description_list }}>
							{info?.programs?.length > 0 ? (
								info?.programs.map(el => (
									<li style={styles.description_list_item} key={el.id}>
										{el.name}
									</li>
								))
							) : (
								<span>-</span>
							)}
						</ul>
					</div>
					{/*Position specifics:*/}
					<div style={styles.description}>
						<span style={styles.description_name}>{t('Position specifics')}:</span>
						<ul style={{ ...styles.description_list }}>
							<li style={{ ...styles.description_list_item }}>
								{t('Dress code')}:{' '}
								{info?.dress_code?.name ? info?.dress_code?.name : '-'}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Working process')}:{' '}
								{info?.work_process?.name ? info?.work_process?.name : '-'}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Workplace')}:{' '}
								{info?.work_place?.name ? info?.work_place?.name : '-'}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Communication with clients')}:{' '}
								{info?.customer_communications?.name
									? info?.customer_communications?.name
									: '-'}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Communication with the manager')}:{' '}
								{info?.supervisor_communications?.name
									? info?.supervisor_communications?.name
									: '-'}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Powers')}: {info?.credentials ? info?.credentials : '-'}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Business trips')}: {info?.missions ? info?.missions : '-'}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Opportunity for career growth')}:{' '}
								{info?.opportunities ? info?.opportunities : '-'}
							</li>
						</ul>
					</div>
					{/*Education*/}
					<div style={styles.description}>
						<span style={styles.description_name}>{t('Education')}:</span>
						<ul style={{ ...styles.description_list }}>
							<li>{info?.education?.name ? info?.education?.name : '-'}</li>
						</ul>
					</div>
					{/*Rights and Auto:*/}
					<div style={{ ...styles.description }}>
						<span style={styles.description_name}>{t('Rights and Auto')}:</span>
						<ul style={{ ...styles.description_list }}>
							<li style={{ ...styles.description_list_item }}>
								{t('Rights required')} - {info.driver_license}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Need your car')} -{' '}
								{info.car_available ? info.car_available : 'Нет'}
							</li>
							<li style={{ ...styles.description_list_item }}>
								{t('Category')}:{' '}
								{info?.driver_categories?.length > 0
									? info?.driver_categories?.map(({ name }) => name).join(', ')
									: '-'}
							</li>
						</ul>
					</div>
					{/*Competencies*/}
					<div style={{ ...styles.description }}>
						<span style={styles.description_name}>{t('Competencies')}:</span>
						<ul style={{ ...styles.description_list }}>
							{info?.competencies?.length > 0 ? (
								info?.competencies?.map(el => (
									<li style={{ ...styles.description_list_item }} key={el.idItem}>
										{el.name}
									</li>
								))
							) : (
								<span>-</span>
							)}
						</ul>
					</div>
					{/*Compensation package:*/}
					<div style={{ ...styles.description }}>
						<span style={styles.description_name}>{t('Compensation package')}:</span>
						<ul style={{ ...styles.description_list }}>
							{info?.compensation_package?.length > 0 ? (
								info?.compensation_package?.map(el => (
									<li style={{ ...styles.description_list_item }} key={el.idItem}>
										{el.name}
									</li>
								))
							) : (
								<span>-</span>
							)}
						</ul>
					</div>
					{/*Personal qualities*/}
					<div style={{ ...styles.description }}>
						<span style={styles.description_name}>Personal qualities:</span>
						<ul style={{ ...styles.description_list }}>
							{info?.personal_qualities?.length > 0 ? (
								info?.personal_qualities?.map(el => (
									<li style={{ ...styles.description_list_item }} key={el.idItem}>
										{el.name}
									</li>
								))
							) : (
								<span>-</span>
							)}
						</ul>
					</div>
					{/*Criteria:*/}
					<div style={{ ...styles.description }}>
						<span style={styles.description_name}>{t('Criteria')}:</span>
						<ul style={{ ...styles.description_list }}>
							{info?.criteria?.length > 0 ? (
								info?.criteria?.map(el => (
									<li style={{ ...styles.description_list_item }} key={el.id}>
										{el.name} - {t('priority')}:{' '}
										<strong>
											{getPriorityName(lists?.s_priority, el.priority)}
										</strong>
									</li>
								))
							) : (
								<span>-</span>
							)}
						</ul>
					</div>
				</div>
				<span
					style={{
						margin: '24px 0',
						background: 'rgb(203, 213, 226)',
						width: '100%',
						height: 1,
						display: 'block',
					}}
				/>
			</div>

			<div style={{ ...styles.block }}>
				<div style={{ ...styles.description }}>
					<span style={styles.description_name}>{t('Send for approval')}: </span>
					{users.length > 0 ? (
						users.map(el => (
							<li style={{ ...styles.description_list_item }} key={el.id}>
								{el.name} - {el.description}
							</li>
						))
					) : (
						<span> - </span>
					)}
				</div>

				<div style={{ ...styles.description }}>
					<span style={styles.description_name}>{t('Author')}:</span>
					<p style={{ ...styles.description_list_item }}>
						{user?.name} {user?.sname}
					</p>
				</div>

				<div style={{ ...styles.description }}>
					<span style={styles.description_name}>{t('Customer')}:</span>
					<p style={{ ...styles.description_list_item }}>
						{t('Name and surname of the Customer')}
					</p>
				</div>

				{company && (
					<>
						<div style={{ ...styles.description }}>
							<span style={styles.description_name}>{t('Company')}:</span>
							<p style={{ ...styles.description_list_item }}>
								{t('title')}: {company.companyName}
							</p>
							<p style={{ ...styles.description_list_item }}>
								{t('Code')}: {company.kod}
							</p>
							{company.s_activity?.name && (
								<p style={{ ...styles.description_list_item }}>
									{t('Field of activity')}: {company.s_activity.name}
								</p>
							)}
							{company.s_organizational_structure?.name && (
								<p style={{ ...styles.description_list_item }}>
									{t('Structure')}: {company.s_organizational_structure.name}
								</p>
							)}
							{company.s_workers?.name && (
								<p style={{ ...styles.description_list_item }}>
									{t('The number of employees')}: {company.s_workers.name}
								</p>
							)}
						</div>
						<div style={{ ...styles.description }}>
							<span style={styles.description_name}>{t('Director')}:</span>
							<p style={{ ...styles.description_list_item }}>
								{t('Name and surname of the Director')}
							</p>
						</div>
						<div style={{ ...styles.description }}>
							<span style={styles.description_name}>{t('Department name')}:</span>
							<p style={{ ...styles.description_list_item }}>
								{info.department_name}
							</p>
						</div>
					</>
				)}
			</div>
		</>
	)
}

export default PreviewVacancyPrint
