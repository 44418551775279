import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import ButtonAdd from '@atoms/buttons/add'
import { FormHeaderCompanies } from '@atoms/text/formHeader'
import CompaniesList from '@molecules/lists/companies'
import { setStatus } from '@redux/reducers/appSlice'

import './style.scss'

const MainCompaniesContainer: FC = () => {
	const { list, loading } = useSelector((state: stateType) => state.company)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { sort } = useParams()

	const initTitle = () => {
		switch (sort) {
			case '':
				return t('My Companies')
			case 'transfer':
				return t('Transferred Companies')
			case 'archive':
				return t('Archive of Companies')
			default:
				return t('My Companies')
		}
	}

	return (
		<div className='companies-container'>
			<FormHeaderCompanies
				title={initTitle()}
				desc={_.isEmpty(list) && sort === 'archive' ? '' : t('Companies')}
				classes={!_.isEmpty(list) && sort === 'archive' ? 'archive' : ''}
			/>
			{sort !== 'archive' && (
				<ButtonAdd
					action={() => {
						dispatch(setStatus('company-create'))
						navigate('/company-create')
					}}
				/>
			)}
			{_.isEmpty(list) && !loading && sort === 'archive' && (
				<div className='companies-container__empty-text'>
					{t('There are no companies in the archive')}
				</div>
			)}
			<CompaniesList />
		</div>
	)
}

export default MainCompaniesContainer
