import { Button, Form, Input } from 'antd'
import { func, number } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormAddButton from '@atoms/buttons/navAddButton'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import PositionDetailsList from '@molecules/positionDetailsList'
import { setNewPositionsInfo } from '@redux/actions/positionsActions'
import { checkFormValues } from '@tools/helpers'

FormSetPositionWorkExperience.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetPositionWorkExperience({ changeStep, step }) {
	const [form] = Form.useForm()

	const dispatch = useDispatch()
	const {
		lists,
		positions: { info },
	} = useSelector(state => ({
		lists: state.dropDownList.lists,
		positions: state.positions,
	}))

	const [casesSelected, setCasesSelected] = useState([])
	const [visibleCasesModal, setVisibleCasesModal] = useState(false)
	const handleOnClearCases = id => {
		setCasesSelected(prev => prev.filter(el => el.id !== id))
	}

	const onFinish = async () => {
		const values = checkFormValues(form.getFieldsValue())
		values.cases = casesSelected
		try {
			dispatch(setNewPositionsInfo(values))
			changeStep(step + 1)
		} catch (err) {
			console.error(err)
			throw err
		}
	}

	useEffect(() => {
		if (info) {
			const { cases, experience, experience_company_names, experience_job_titles } = info
			setCasesSelected(cases || [])
			form.setFields([
				{
					name: 'experience_job_titles',
					value: experience_job_titles?.length ? experience_job_titles : null,
				},
				{ name: 'cases', value: cases ? cases : null },
				{ name: 'experience', value: experience ? experience : null },
				{
					name: 'experience_company_names',
					value: experience_company_names ? experience_company_names : null,
				},
			])
		}
	}, [info])
	return (
		<Form
			name='set-position-work-experience'
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
			className='form'
		>
			<AppText
				text={t('Experience')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Form.Item label={t('Experience')} name='experience'>
				<AppSelect options={lists?.s_work_experience} placeholder={t('Experience')} />
			</Form.Item>

			<Form.Item
				style={{ flex: 1 }}
				name='experience_company_names'
				label={t('The name of the company(ies)')}
			>
				<Input className='form__input' placeholder={t('The name of the company(ies)')} />
			</Form.Item>

			<Form.Item style={{ flex: 1 }} name='experience_job_titles' label={t('Position')}>
				<Input className='form__input' placeholder={t('Position')} />
			</Form.Item>

			{/*  Кейсы  */}
			<Form.Item label={t('Cases')}>
				<Form.Item noStyle name='cases'>
					<FormAddButton
						extraClasses={'_card'}
						onClick={() => setVisibleCasesModal(!visibleCasesModal)}
						text={t('Add the case')}
					/>
				</Form.Item>
				<Form.Item noStyle>
					<PositionDetailsList
						details={casesSelected}
						clearDetails={handleOnClearCases}
						modalTitle={t('Cases')}
					/>
				</Form.Item>
			</Form.Item>

			<Form.Item>
				<div className='form__row _between'>
					<Button
						type='default'
						htmlType='button'
						shape='round'
						onClick={() => changeStep(step - 1)}
					>
						{t('Back')}
					</Button>
					<Button type='primary' shape='round' htmlType='submit'>
						{t('Save and continue')}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default FormSetPositionWorkExperience
