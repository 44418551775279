/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// api
import { message, notification } from 'antd'
import parse from 'html-react-parser'
import { t } from 'i18next'
import _ from 'lodash'
import { put, call, select } from 'redux-saga/effects'
import { NOTIFICATION } from './notification'
import type { ApiResponse } from '@src/types/api'
import { api } from '@api/company'

import { setAdrList } from '@redux/reducers/addressesSlice'
import {
	setLoading,
	setCompany,
	setCompanies,
	loginToCompany,
	setLoadingLogin,
	updateCompanyDepartmentAddressList,
	updateCompanyDepartmentList,
	updateDataCreated,
} from '@redux/reducers/companySlice'
import { updateUser } from '@redux/reducers/userSlice'

import { getAddress } from '@tools/helpers'

export function* sagaRemoveCompanyDepartmentAddress(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.removeCompanyDepartmentAddress, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRemoveCompanyDepartment(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.removeCompanyDepartment, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRemoveFileDefaultCompany(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.removeFileDefaultCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			console.log(data)
		}

		callback && callback()
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaAddFileDefaultCompany(action) {
	const { payload } = action
	const { request, dir, callback } = payload
	const getUserInfo = state => state.user.info
	const info = yield select(getUserInfo)

	try {
		const response = yield call(api.addFileDefaultCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(
				updateUser({
					company: {
						...info.company,
						fileList: { ...info.company.fileList, [dir]: data.files },
					},
				})
			)
		}

		callback && callback()
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaCreateCompany(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.createCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			yield put(loginToCompany({ request: { company_id: data.company_data.id } }))
			yield put(updateUser({ company: { ...data.company_data } }))
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaUpdateCompany(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.updateCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			notification.destroy('update-company')
			return NOTIFICATION.error(data.msg, '', 'update-company')
		}
		if (data.status) {
			if (data.status === 'check_confirm') {
				callback && callback.confirm()
			} else {
				yield put(updateUser({ company: { ...data.company_data } }))
				callback && callback.success()
			}
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaGetCompanies(action) {
	const { payload } = action
	const { request, callback } = payload

	yield put(setCompanies([]))
	yield put(setLoading(true))

	try {
		const response = yield call(api.getCompanies, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			yield put(setCompanies([]))
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			yield put(setCompanies([]))
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(setCompanies(data.company_list))
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaAddCompanyToArchive(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.addToArchive, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRecoveryCompany(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.recoveryCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			console.log(data)
		}

		callback && callback()
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaLoginToCompany(action) {
	const { payload } = action
	const { request, callback } = payload

	yield put(setLoadingLogin(true))

	try {
		const response = yield call(api.loginToCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoadingLogin(false))
	}
}

export function* sagaLogoutFromCompany(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.logoutFromCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(updateUser({ company: false }))
		}

		callback && callback()
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSetFileToCompany(action) {
	const { payload } = action
	const { request, dir } = payload
	const getUserInfo = state => state.user.info
	const info = yield select(getUserInfo)

	try {
		const response = yield call(api.setFileToCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(
				updateUser({
					company: {
						...info.company,
						fileList: { ...info.company.fileList, [dir]: data.files },
					},
				})
			)
			//callback && callback();
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRemoveFileCompany(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.removeFileCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			console.log(data)
		}

		callback && callback()
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaGetCompanyInfo(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.getCompanyInfo, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			yield put(setCompany(data.company))
		}

		callback && callback()
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSetCompanyStatus(action) {
	const { payload } = action
	const { request, callback } = payload

	try {
		const response = yield call(api.setCompanyStatus, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			console.log(data)
		}

		callback && callback()
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSetCompanyInfoContacts(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.setCompanyInfoContacts, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			callback && callback()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRemoveCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	const { success, error } = callback

	try {
		const response = yield call(api.removeCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			error()
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			callback && success()
		}
	} catch (e) {
		error()
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRestoreCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.restoreCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			callback && callback.success()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSearchUserInCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	const { success, error } = callback

	try {
		const response = yield call(api.searchUserInCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			notification.destroy('search-user')
			return NOTIFICATION.error(parse(data).toString(), '', 'search-user')
		}

		if (data.error) {
			error()

			notification.destroy('search-user')
			return NOTIFICATION.error(data.msg, '', 'search-user')
		}

		if (data.status) {
			notification.destroy('search-user')
			callback && success(data.users)
		}
	} catch (e) {
		error()
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaGetCompanyDepartmentAddressList(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.getCompanyDepartmentAddressList, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			if (callback) {
				yield put(updateCompanyDepartmentAddressList(data.department_addresses))
			}
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaGetCompanyDepartmentList(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.getCompanyDepartmentList, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			if (callback) {
				yield put(updateCompanyDepartmentList(data.company_department_list))
			}
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSetCompanyDepartmentAddress(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.setCompanyDepartmentAddress, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			if (data?.data) {
				yield put(
					updateDataCreated({
						request: { address: data?.data },
						callback: {},
					})
				)
			}
			callback && callback.success()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaAddCompanyDepartment(action) {
	const { payload } = action
	const { request, callback } = payload
	try {
		const response = yield call(api.addCompanyDepartment, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			return NOTIFICATION.error(data.msg)
		}
		if (data.status) {
			if (data?.data) {
				yield put(
					updateDataCreated({
						request: { department: data?.data },
						callback: {},
					})
				)
			}
			callback && callback.success()
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaAddUserToCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	const { success, error } = callback

	try {
		const response = yield call(api.addUserToCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			error()
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			if (callback) {
				yield put(updateUser(data.user_data))
				yield call(success)
			}
		}
	} catch (e) {
		error()
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaUpdateUserToCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	const { success, error } = callback

	try {
		const response = yield call(api.updateUserToCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			error()
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			if (callback) {
				yield put(updateUser(data.user_data))
				yield call(success)
			}
		}
	} catch (e) {
		error()
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaRemoveUserFromCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	const { success, error } = callback

	try {
		const response = yield call(api.removeUserFromCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			error()
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			error()
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			if (callback) {
				yield put(updateUser(data.user_data))
				yield call(success)
			}
		}
	} catch (e) {
		error()
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaTransferCompany(action) {
	const { payload } = action
	const { request, callback } = payload
	const { success, error } = callback

	try {
		const response = yield call(api.transferCompany, request)
		const data: ApiResponse = response.data

		if (!_.isObject(data)) {
			error()
			return NOTIFICATION.error(parse(data).toString())
		}

		if (data.error) {
			error()
			return NOTIFICATION.error(data.msg)
		}

		if (data.status) {
			if (callback) {
				yield put(updateUser(data.user_data))
				yield call(success)
			}
		}
	} catch (e) {
		error()
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

/* TODO sagas for old actions and old API */
export function* sagaGetCompany(action) {
	const { payload } = action
	const {} = payload

	try {
		const response = yield call(api.getCompany, payload)
		const data: ApiResponse = response.data

		if (data.ok) {
			yield put(setCompany({ ...data.data, files: data.files }))
		}
	} catch (e) {
		yield NOTIFICATION.error(parse(e.toString()).toString())
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaSetCompanyAdmin(action) {
	const { payload } = action
	const { callback, key } = payload

	try {
		const response = yield call(api.setCompanyAdmin, payload)
		const data: ApiResponse = response.data

		if (data.ok) {
			yield put(setCompany(data.data))
			callback && callback()
			return
		}
		message.error({ content: data.msg, key, duration: 4 })
	} catch (e) {
		message.error({ content: e.message, key, duration: 4 })
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaAddCompanyDeputy(action) {
	const { payload } = action
	const { callback } = payload
	yield put(setLoading(true))
	// yield put(setStatus('loading'));

	try {
		const response = yield call(api.addCompanyDeputy, payload)
		const data: ApiResponse = response.data

		if (data.ok) {
			// yield put(setCompany(data.data));
			callback && callback()
			return
			// yield put(setCompany())
			// yield put(setStatus('main'));
			// yield put(setToken(localStorage.getItem('token')));
			// yield put(setUser(data.data));
		}

		if (data.error) {
			let messageError = `${t('Error, user')} ${payload.data[0].email} ${t('already exists')}`
			if (data.data[payload.data[0].email].error === 6) {
				message.error({ content: messageError, duration: 5 })
				return
			}

			messageError = t('Something went wrong')
			message.error({ content: messageError, duration: 5 })
		}
		// message.error({ content: React.createElement(ErrorContent, 'email'), duration: 5 });
	} catch (e) {
		message.error({ content: e.message, duration: 4 })
		throw new Error(t('An error of creating a deputy'))

		// localStorage.clear();
		// yield put(setStatus(''));
		//yield put(setError(ERROR_MAP.auth(e)));
	} finally {
		yield put(setLoading(false))
	}
}

export function* sagaGetAddressesList(action) {
	const { payload } = action
	const { callback } = payload
	yield put(setLoading(true))
	// yield put(setStatus('loading'));

	try {
		const token = localStorage.getItem('token')
		if (!token) {
			message.error({ content: t('Incorrect user token'), duration: 4 })
			return
		}

		const response = yield call(api.getAddressesList, token)
		const data: ApiResponse = response.data

		if (data.ok) {
			const addresses = data.data.s_offices
				?.filter(el => el)
				.map(address => getAddress(address))
			yield put(setAdrList(addresses))
			// yield put(setCompany(data.data));
			callback && callback()
			return
			// yield put(setCompany())
			// yield put(setStatus('main'));
			// yield put(setToken(localStorage.getItem('token')));
			// yield put(setUser(data.data));
		}

		if (data.error) {
			let messageError = `${t('Error, user')} ${payload.data[0].email} ${t('already exists')}`
			if (data.data[payload.data[0].email].error === 6) {
				message.error({ content: messageError, duration: 5 })
				return
			}

			messageError = t('Something went wrong')
			message.error({ content: messageError, duration: 5 })
		}
		// message.error({ content: React.createElement(ErrorContent, 'email'), duration: 5 });
	} catch (e) {
		message.error({ content: e.message, duration: 4 })
		throw new Error(t('An error of creating a deputy'))

		// localStorage.clear();
		// yield put(setStatus(''));
		//yield put(setError(ERROR_MAP.auth(e)));
	} finally {
		yield put(setLoading(false))
	}
}
