import React from 'react'
import type { FC } from 'react'
import collapsedArrow from '@image/icons/collapsedArrow.svg'

import './style.scss'

interface CollapsedButtonType {
	onClick: () => void
	isCollapsed: boolean
}

const CollapsedButton: FC<CollapsedButtonType> = ({
	onClick,
	isCollapsed,
}: CollapsedButtonType) => {
	const collapsed = isCollapsed ? 'collapsed is_collapsed' : 'collapsed'
	return (
		<li className={collapsed} onClick={onClick}>
			<img className='collapsed__arrow' src={collapsedArrow} alt='collapsedArrow' />
		</li>
	)
}

export default CollapsedButton
