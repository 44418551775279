import classNames from 'classnames'
import React from 'react'
import type { FC } from 'react'
import IconSpinner from '@assets/icons/btn/spinner'

import './style.scss'

interface ButtonDefaultType {
	name: string
	classes?: string
	action?: any
	disabled?: boolean
	loading?: boolean
	type?: any
	buttonType?: string
}

export const ButtonDefault: FC<ButtonDefaultType> = ({
	name,
	classes,
	action,
	disabled,
	loading,
	type,
	buttonType,
}: ButtonDefaultType) => (
	<button
		className={classNames(
			'btn-default',
			{ [`btn-default__${buttonType}`]: buttonType },
			classes ? classes : ''
		)}
		onClick={action}
		disabled={disabled}
		type={type ? type : 'button'}
	>
		{loading && (
			<div className='icon-spinner'>
				<IconSpinner />
			</div>
		)}
		<div className='btn-default__text'>{name}</div>
	</button>
)

export default ButtonDefault
