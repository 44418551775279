import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDepartmentSteps from './useDepartmentSteps.jsx'
import usePositionSteps from './usePositionSteps.jsx'
import useProfileSteps from './useProfileSteps.jsx'

function useAddVacancySteps() {
	const { t } = useTranslation()
	const settings = {
		blockCompanyName: true,
		blockCompanyKod: true,
		hideOtherAddresses: true,
	}
	const { profileSteps, currentProfileStep, setCurrentProfileStep } = useProfileSteps(settings)
	const { departmentSteps, currentDepartmentSteps, setCurrentDepartmentSteps } =
		useDepartmentSteps()
	const { positionSteps, currentPositionSteps, setCurrentPositionSteps } = usePositionSteps()

	const [progressStep, setProgressStep] = useState(1)
	const [currentVacancyStep, setCurrentVacancyStep] = useState(1)
	const vacancySteps = [
		{
			id: 1,
			type: 'profile',
			text: t('Complete the profile of the company (optional).Fields are not necessary.'),
			name: t('Company Profile'),
			steps: [...profileSteps],
		},
		{
			id: 2,
			type: 'department',
			text: t('Complete the profile of the company (optional).Fields are not necessary.'),
			name: t('Department profile'),
			steps: [...departmentSteps],
		},
		{
			id: 3,
			type: 'position',
			text: t('Fill in all the obligatory fields of the post profile to continue.'),
			name: t('Position profile'),
			steps: [...positionSteps],
		},
	]

	useEffect(() => {
		if (currentVacancyStep === 1) {
			if (currentProfileStep > profileSteps.length) {
				setCurrentVacancyStep(currentVacancyStep + 1)
				setProgressStep(currentDepartmentSteps)
			} else {
				setProgressStep(currentProfileStep)
			}
		}
		if (currentVacancyStep === 2) {
			if (currentDepartmentSteps > departmentSteps.length) {
				setCurrentVacancyStep(currentVacancyStep + 1)
				setCurrentDepartmentSteps(departmentSteps.length)
				setProgressStep(currentPositionSteps)
			} else if (currentDepartmentSteps === 0) {
				setCurrentVacancyStep(currentVacancyStep - 1)
				setProgressStep(profileSteps.length)
				setCurrentProfileStep(profileSteps.length)
				setCurrentDepartmentSteps(1)
			} else {
				setProgressStep(currentDepartmentSteps)
			}
		}
		if (currentVacancyStep === 3) {
			if (currentPositionSteps > positionSteps.length) {
				setCurrentVacancyStep(currentVacancyStep + 1)
			} else if (currentPositionSteps === 0) {
				setCurrentVacancyStep(currentVacancyStep - 1)
				setProgressStep(departmentSteps.length)
				setCurrentDepartmentSteps(departmentSteps.length)
				setCurrentPositionSteps(1)
			} else {
				setProgressStep(currentPositionSteps)
			}
		}
	}, [currentProfileStep, currentDepartmentSteps, currentPositionSteps])

	return {
		currentVacancyStep,
		setProgressStep,
		progressStep,
		vacancySteps,
		setCurrentProfileStep,
		setCurrentVacancyStep,
		setCurrentDepartmentSteps,
	}
}

export default useAddVacancySteps
