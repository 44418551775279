import { Button, Form, Input, message, Switch } from 'antd'
import { func, number } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewPositionItem, getInteractions, removePositionItem } from '@api/asyncHelpers'
import FormAddButton from '@atoms/buttons/navAddButton'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import PositionDetailsList from '@molecules/positionDetailsList'
import PositionFunctionalityModal from '@organisms/modals/positionFunctionalityModal/PositionFunctionalityModal'
import { initPositionKPI } from '@redux/actions/kpiActions'
import { setNewPositionsInfo, setSelectPositionId } from '@redux/actions/positionsActions'
import { initPositionResult } from '@redux/actions/resultActions'
import { initPositionTarget } from '@redux/actions/targetActions'
import { addItemsTypes, removeItemsTypes } from '@tools/constants'
import { checkFormValues } from '@tools/helpers'
import usePositionData from '@tools/hooks/usePositionFunctionality'
import { positionErrorsMsg, rules } from '@tools/messages'

FormSetPositionRequirements.propTypes = {
	changeStep: func,
	step: number,
}

function FormSetPositionRequirements({ changeStep, step }) {
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const {
		lists,
		target: { categories: targetList },
		kpi: { categories: kpiList },
		result: { categories: resultList },
		positions: { id: positionsId, info, list: positionsList },
	} = useSelector(state => ({
		target: state.target,
		kpi: state.kpi,
		result: state.result,
		positions: state.positions,
		lists: state.dropDownList.lists,
	}))

	const [visibleTargetsModal, setVisibleTargetsModal] = useState(false)
	const [visibleResultsModal, setVisibleResultsModal] = useState(false)
	const [visibleKpiModal, setVisibleKpiModal] = useState(false)

	const [isSwitch, setIsSwitch] = useState(true)
	const handleOnChangeSwitch = () => setIsSwitch(!isSwitch)
	// interaction
	const [interactions, setInteractions] = useState([])
	const handleOnGetInteractions = async open => {
		if (open && !interactions.length) {
			const result = await getInteractions()
			setInteractions(result)
		}
	}
	// target
	const positionTargets = usePositionData(positionsId, targetList)
	const [targetsSelected, setTargetsSelected] = useState([])
	const handleOnClearTarget = id => {
		setTargetsSelected(prev => prev.filter(el => el.id !== id))
	}
	const handleOnAddTarget = async name => {
		try {
			await addNewPositionItem(name, addItemsTypes.TARGET)
			await dispatch(initPositionTarget())
		} catch (err) {
			console.error(err)
			message.error({ content: err.message })
			throw err
		}
	}
	const handleOnRemoveTarget = async id => {
		try {
			await removePositionItem(id, addItemsTypes.TARGET, removeItemsTypes.TARGET)
			await dispatch(initPositionTarget())
		} catch (err) {
			console.error(err)
			message.error({ content: err.message })
			throw err
		}
	}

	// result
	const positionResult = usePositionData(positionsId, resultList)
	const [resultsSelected, setResultsSelected] = useState([])
	const handleOnClearResults = id => {
		setResultsSelected(prev => prev.filter(el => el.id !== id))
	}
	const handleOnAddResult = async name => {
		try {
			await addNewPositionItem(name, addItemsTypes.RESULT)
			await dispatch(initPositionResult())
		} catch (err) {
			console.error(err)
			message.error({ content: err.message })
			throw err
		}
	}
	const handleOnRemoveResult = async id => {
		try {
			await removePositionItem(id, addItemsTypes.RESULT, removeItemsTypes.RESULT)
			await dispatch(initPositionResult())
		} catch (err) {
			console.error(err)
			message.error({ content: err.message })
			throw err
		}
	}

	// kpi
	const positionKpi = usePositionData(positionsId, kpiList)
	const [kpiSelected, setKpiSelected] = useState([])
	const handleOnClearKpi = id => {
		setKpiSelected(prev => prev.filter(el => el.id !== id))
	}
	const handleOnAddKpi = async name => {
		try {
			await addNewPositionItem(name, addItemsTypes.KPI)
			await dispatch(initPositionKPI())
		} catch (err) {
			console.error(err)
			message.error({ content: err.message })
			throw err
		}
	}
	const handleOnRemoveKpi = async id => {
		try {
			await removePositionItem(id, addItemsTypes.KPI, removeItemsTypes.KPI)
			await dispatch(initPositionKPI())
		} catch (err) {
			console.error(err)
			message.error({ content: err.message })
			throw err
		}
	}

	const onFinish = async () => {
		if (targetsSelected.length === 0) {
			return message.warning(positionErrorsMsg.TARGET)
		} else if (resultsSelected.length === 0) {
			return message.warning(positionErrorsMsg.RESULT)
		} else if (kpiSelected.length === 0) {
			return message.warning(positionErrorsMsg.KPI)
		}
		const values = checkFormValues(form.getFieldsValue())
		values.target = targetsSelected
		values.result = resultsSelected
		values.kpi = kpiSelected
		try {
			dispatch(setNewPositionsInfo(values))
			changeStep(step + 1)
		} catch (err) {
			message.error(err.message)
			throw err
		}
	}

	useEffect(() => {
		dispatch(initPositionTarget())
		dispatch(initPositionResult())
		dispatch(initPositionKPI())
	}, [])

	useEffect(() => {
		if (info) {
			const { credentials, interaction, kpi, opportunities, result, target } = info

			if (info) {
				setTargetsSelected(target || [])
				setResultsSelected(result || [])
				setKpiSelected(kpi || [])
				form.setFields([
					{ name: 'opportunities', value: opportunities ? opportunities : null },
					{ name: 'credentials', value: credentials ? credentials : null },
					{ name: 'interaction', value: interaction ? interaction : [] },
				])
			}
		}
	}, [info])

	useEffect(() => {
		if (!visibleTargetsModal || !visibleResultsModal || !visibleKpiModal) {
			const id = positionsList.find(el => el.name === info.name).id
			dispatch(setSelectPositionId(id.toString()))
		}
	}, [visibleTargetsModal, visibleResultsModal, visibleKpiModal])

	return (
		<>
			<PositionFunctionalityModal
				title={t('target')}
				visible={visibleTargetsModal}
				changeVisible={setVisibleTargetsModal}
				userSelectedList={targetsSelected}
				changeUserSelectedList={setTargetsSelected}
				positionsId={positionsId}
				setPositionId={id => dispatch(setSelectPositionId(id.toString()))}
				positionData={positionTargets}
				addItem={handleOnAddTarget}
				removeItem={handleOnRemoveTarget}
			/>

			<PositionFunctionalityModal
				title={t('kpi')}
				visible={visibleKpiModal}
				changeVisible={setVisibleKpiModal}
				userSelectedList={kpiSelected}
				changeUserSelectedList={setKpiSelected}
				positionsId={positionsId}
				setPositionId={id => dispatch(setSelectPositionId(id.toString()))}
				positionData={positionKpi}
				addItem={handleOnAddKpi}
				removeItem={handleOnRemoveKpi}
			/>

			<PositionFunctionalityModal
				title={t('result')}
				visible={visibleResultsModal}
				changeVisible={setVisibleResultsModal}
				userSelectedList={resultsSelected}
				changeUserSelectedList={setResultsSelected}
				positionsId={positionsId}
				setPositionId={id => dispatch(setSelectPositionId(id.toString()))}
				positionData={positionResult}
				addItem={handleOnAddResult}
				removeItem={handleOnRemoveResult}
			/>

			<Form
				name='set-position-requirements'
				layout='vertical'
				size='large'
				onFinish={onFinish}
				form={form}
				className='form'
			>
				<AppText
					text={t('Requirements and powers')}
					style={{
						color: '#20272E',
						fontSize: 18,
						fontWeight: 700,
						marginBottom: 24,
						lineHeight: 1.5715,
						display: 'block',
					}}
				/>

				{/*  Цель должности  */}
				<Form.Item label={t('Purpose of the position')} required>
					<Form.Item noStyle name='target'>
						<FormAddButton
							extraClasses={'_card'}
							onClick={() => setVisibleTargetsModal(!visibleTargetsModal)}
							text={t('Add a goal')}
						/>
					</Form.Item>
					<Form.Item noStyle>
						<PositionDetailsList
							details={targetsSelected}
							clearDetails={handleOnClearTarget}
							modalTitle={t('Positions')}
						/>
					</Form.Item>
				</Form.Item>

				{/*  Результат  */}
				<Form.Item label={t('Result')} required>
					<Form.Item noStyle name='result'>
						<FormAddButton
							extraClasses={'_card'}
							onClick={() => setVisibleResultsModal(!visibleResultsModal)}
							text={t('Add result')}
						/>
					</Form.Item>
					<Form.Item noStyle>
						<PositionDetailsList
							details={resultsSelected}
							clearDetails={handleOnClearResults}
							modalTitle={t('Result')}
						/>
					</Form.Item>
				</Form.Item>

				{/*  KPI  */}
				<Form.Item label={t('KPI')} required>
					<Form.Item noStyle name='kpi'>
						<FormAddButton
							extraClasses={'_card'}
							onClick={() => setVisibleKpiModal(!visibleKpiModal)}
							text={t('Add KPI')}
						/>
					</Form.Item>
					<Form.Item noStyle>
						<PositionDetailsList
							details={kpiSelected}
							clearDetails={handleOnClearKpi}
							modalTitle={t('KPI')}
						/>
					</Form.Item>
				</Form.Item>

				<Form.Item
					className='form__container'
					label={t('Authority')}
					name='credentials'
					rules={[{ required: true, message: rules.required }]}
				>
					<AppSelect
						options={lists?.s_credentials}
						placeholder={t('Write or select from the list')}
					/>
				</Form.Item>

				<Form.Item
					className='form__container'
					label={t('Interaction')}
					name='interaction'
					rules={[{ required: true, message: rules.required }]}
				>
					<AppSelect
						onDropdownVisibleChange={handleOnGetInteractions}
						options={interactions}
						placeholder={t('Write or select from the list')}
						mode='multiple'
					/>
				</Form.Item>

				<Form.Item label={t('Opportunity for career growth')}>
					<div className='form__row'>
						<Switch
							style={{ width: 80 }}
							defaultChecked
							onChange={handleOnChangeSwitch}
							checkedChildren={t('Yes')}
							unCheckedChildren={t('No')}
						/>
						<Form.Item name='opportunities' noStyle>
							<Input
								disabled={!isSwitch}
								className='form__input'
								placeholder={t('Notes')}
							/>
						</Form.Item>
					</div>
				</Form.Item>

				<Form.Item className='form__container'>
					<div className='form__row _between'>
						<Button
							type='default'
							htmlType='button'
							shape='round'
							onClick={() => changeStep(step - 1)}
						>
							{t('Back')}
						</Button>
						<Button type='primary' shape='round' htmlType='submit'>
							{t('Save and continue')}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</>
	)
}

export default FormSetPositionRequirements
