/* eslint-disable react/jsx-no-target-blank */
import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import { Link } from 'react-router-dom'

import type { FC } from 'react'
import { IconDelete, IconFile, IconUploaderMini } from '@assets/icons/uploaders'

import RemoveFileConfirmation from '@organisms/modals/confirms/removeFileConfirmation'
import './style.scss'

interface AttachmentType {
	isVerified?: any
	file: any
	isEditable?: boolean
	isDownload?: boolean
	showProgress?: boolean
	onRemove?: () => void
	status?: string | null
}

const Attachment: FC<AttachmentType> = ({
	/*showProgress,*/
	isVerified,
	file,
	isEditable,
	onRemove,
	isDownload,
	status,
}: AttachmentType) => (
	<div className='attachment' key={file.id}>
		<div className='attachment__body'>
			{file.status === 'loading' ? (
				<LoadingOutlined />
			) : (
				file.status === 'done' && <IconFile />
			)}
			<span className='attachment__name'>{file.name}</span>
			<span className='attachment__size'>({file.size})</span>
		</div>
		{isEditable && file.status === 'done' && (
			<div className='attachment__nav'>
				{isEditable && isDownload && (
					<Link
						to={'#'}
						download={file.name}
						onClick={() => {
							window.open(file.href)
						}}
					>
						<IconUploaderMini />
					</Link>
				)}
				{status ? (
					<RemoveFileConfirmation
						isVerified={isVerified}
						component={IconDelete}
						action={onRemove}
						status={status}
						submit={status ? true : false}
					/>
				) : (
					<IconDelete onClick={onRemove} />
				)}
			</div>
		)}
	</div>
)

export default Attachment
