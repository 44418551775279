export const removeConfirmation = {
	default: {
		title: 'Delete a file',
		description: 'Do you want the file permanently?',
	},
	general: {
		title: 'Deleting a PhotoScan of an extract',
		description: 'Do you really want the file permanently?',
	},
	last: {
		title: 'Deleting a PhotoScan of an extract',
		description:
			'To save information you must upload at least one file to check company verification',
	},
	address: {
		title: 'Deleting address',
		description: 'Do you really want to delete the created address?',
	},
	department: {
		title: 'Deleting department',
		description: 'Do you really want to delete the created department?',
	},
}
