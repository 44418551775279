import './style.scss'

import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import TabsCompany from '../tabsCompany'

import type { stateType } from '@types'
import type { FC } from 'react'

import BtnGoBack from '@atoms/buttons/goBackButton'
import CompanyInfo from '@organisms/lcVerification/companyInfo'
import { getCompany } from '@redux/reducers/lcVerificatorSlice'

const LcVerificationCompanyContainer: FC<{
	open?: boolean
	onClose?: () => void
}> = ({ open = true, onClose }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { id } = useParams()
	const { company }: any = useSelector((state: stateType) => state.lcVerificator)
	const { sessionProperties } = useSelector((state: stateType) => state.user)

	const companyId = id || sessionProperties?.companyId

	useEffect(() => {
		dispatch(
			getCompany({
				request: {
					company_id: Number(companyId),
				},
			})
		)
	}, [])

	const handleClose = () => {
		if (onClose) {
			onClose()
		} else {
			navigate('/lc-companies')
		}
	}

	return (
		<Modal
			className='modal-lc-company'
			open={open}
			footer={null}
			closable={true}
			centered
			style={{ width: '100%', resize: 'none', overflow: 'clip' }}
			onCancel={() => handleClose()}
			maskClosable={false}
			styles={{ mask: { backgroundColor: 'rgba(220, 222, 252, 0.6)' } }}
		>
			<div className='lc-verification-company-container'>
				<div className='lc-verification-company-container__list'>
					<div className='lc-verification-company-container__body'>
						<div className='lc-verification-company-container__title'>
							{company?.info?.company?.name}
						</div>
						<TabsCompany />
					</div>
					<div className='lc-verification-company-container__footer'>
						<BtnGoBack onClick={() => handleClose()} />
					</div>
				</div>
				<div className='lc-verification-company-container__info'>
					<CompanyInfo />
				</div>
			</div>
		</Modal>
	)
}

export default LcVerificationCompanyContainer
