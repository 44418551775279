import parse from 'html-react-parser'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { FC } from 'react'
import ButtonDefault from '@atoms/buttons/button'
import Template404 from '@templates/404Template'

const Page404: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	return (
		<Template404>
			<span>{t('Error!')}</span>
			<p>{parse(t('Unfortunately, the requested <br /> your page is not found ..'))}</p>
			<ButtonDefault
				name={t('Go to Home')}
				classes={'btn-default btn-white'}
				action={() => navigate('/')}
			/>
		</Template404>
	)
}

export default Page404
