import { Button, Col, Modal, Row } from 'antd'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { setUserStep } from '@api/asyncHelpers';
// import { resetVerification } from '@redux/actions/company-actions';
//import { getUser } from '@redux/actions/user-actions';
//import { useDispatch } from 'react-redux'
import type { FC } from 'react'
import danger from '@image/icons/danger-circle.svg'

const ChangeVerifyModal: FC<any> = ({ visible, changeVisible, text }: any) => {
	const { t } = useTranslation()
	//const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)

	const resetUser = async () => {
		/*try {
      setIsLoading(true);
      await setUserStep(1);
      dispatch(resetVerification());
      await dispatch(getUser());
    } catch (err) {
      message.error(err.message);
    }*/
	}

	useEffect(
		() => () => {
			setIsLoading(false)
		},
		[]
	)

	return (
		<Modal
			className='modal-verify_success'
			open={visible}
			width={504}
			footer={null}
			closable={false}
			styles={{ body: { padding: '44px 24px' } }}
			centered
		>
			<Row gutter={[32, 32]}>
				<Col flex={1}>
					<img className='modal-verify_success__success_img' src={danger} alt='success' />
				</Col>
				<Col flex={1}>
					<span className='modal-verify_success__success_text'>{text}</span>
				</Col>
				<Col flex={1}>
					<Row justify='space-between'>
						<Col>
							<Button type='default' onClick={() => changeVisible(!visible)}>
								{t('Cancel')}
							</Button>
						</Col>

						<Col>
							<Button
								type='primary'
								shape='round'
								onClick={() => resetUser()}
								loading={isLoading}
							>
								{t('Send to verification')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Modal>
	)
}

export default ChangeVerifyModal
