export const contactsFields = [
	{
		hidden: false,
		group: 'Legal address',
		items: [
			{
				name: 'legal_address_country',
				label: 'Country',
				placeholder: 'Ukraine',
				required: true,
				className: 'form__container-mini w-276',
			},
			{
				name: 'legal_address_city',
				label: 'City',
				placeholder: 'Kyiv',
				required: true,
				className: 'form__container-mini w-276',
			},
			{
				name: 'legal_address_street',
				label: 'Street',
				placeholder: 'Gagarin',
				required: true,
				className: 'form__container-mini w-456',
			},
			{
				name: 'legal_address_house',
				label: 'House',
				placeholder: '456',
				required: true,
				className: 'form__container-mini w-96',
			},
		],
	},
	{
		hidden: false,
		name: 'actual_is_legal_address',
		label: 'The actual address is the same as the legal address',
		fieldType: 'checkbox',
		className: 'form__container-mini',
	},
	{
		hidden: false,
		group: 'Actual address',
		group_id: 'actual_address',
		items: [
			{
				name: 'actual_address_country',
				label: 'Country',
				placeholder: 'Ukraine',
				required: true,
				className: 'form__container-mini w-276',
				width: '276px',
			},
			{
				name: 'actual_address_city',
				label: 'City',
				placeholder: 'Kyiv',
				required: true,
				className: 'form__container-mini w-276',
			},
			{
				name: 'actual_address_street',
				label: 'Street',
				placeholder: 'Gagarin',
				required: true,
				className: 'form__container-mini w-456',
			},
			{
				name: 'actual_address_house',
				label: 'House',
				placeholder: '456',
				required: true,
				className: 'form__container-mini w-96',
			},
		],
	},
	{
		tooltip: 'tooltip phone',
		hidden: false,
		name: 'corporate_phone',
		label: 'Corporate phone',
		placeholder: '+ XXXXXXXXXXXX',
		required: false,
		className: '',
		fieldType: 'InputMaskDefault',
		mask: '+ 999999999999999',
	},
	{
		hidden: false,
		name: 'website',
		label: 'Website',
		placeholder: 'Website',
		required: false,
		className: '',
	},
	{
		hidden: false,
		name: 'email',
		label: 'Email',
		placeholder: 'Email',
		required: false,
		className: '',
	},
	{
		plusAll: true,
		hidden: false,
		name: 'socials',
		fieldType: 'dropdownPrefix',
		label: 'Company social media profile',
		optionKey: 'social_media',
		placeholder: 'socials',
		required: false,
		className: '',
	},
]
