import React from 'react'

const V7 = () => (
	<svg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1.00012 3.7999H2.1001C2.9001 3.7999 3.50012 3.1999 3.50012 2.3999V1.2999C3.50012 0.7999 3.90015 0.399902 4.40015 0.399902C4.90015 0.399902 5.30011 0.7999 5.30011 1.2999V2.3999C5.30011 3.1999 5.90013 3.7999 6.70013 3.7999H7.70013C8.20013 3.7999 8.6001 4.1999 8.6001 4.6999C8.6001 5.1999 8.20013 5.5999 7.70013 5.5999H6.70013C5.90013 5.5999 5.30011 6.1999 5.30011 6.9999V7.9999C5.30011 8.4999 4.90015 8.8999 4.40015 8.8999C3.90015 8.8999 3.50012 8.4999 3.50012 7.9999V6.9999C3.50012 6.1999 2.9001 5.5999 2.1001 5.5999H1.00012C0.500122 5.5999 0.100098 5.1999 0.100098 4.6999C0.100098 4.1999 0.500122 3.7999 1.00012 3.7999Z'
			fill='#FAFAFA'
		/>
	</svg>
)

export default V7
