import React, { useEffect } from 'react'
// import { stateType } from '@types';

import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
import DropdownChoiceCompany from '../dropdwons/companyChoice'
import DropdownLang from '../dropdwons/lang'
import DropdownProfile from '../dropdwons/profile'

import type { stateType } from '@types'
import type { FC } from 'react'

import type { notificationItemType } from 'types/notifications'
import IconBell from '@assets/image/icons/header/bell'
import IconMessage from '@assets/image/icons/header/message'
import AppMessage from '@molecules/app/message'
import AppSearchInput from '@molecules/app/search'
import NotificationsModal from '@organisms/modals/app/notification'
import { getCompanies } from '@redux/reducers/companySlice'
import {
	getNotificationsUserList,
	getNotificationsUserNew,
} from '@redux/reducers/notificationsUserSlice'
// import useAppReset from '@tools/hooks/useAppReset';

const AppHeader: FC = () => {
	const dispatch = useDispatch()
	// const resetApp = useAppReset();
	const {} = useSelector((state: stateType) => state.user)
	const { notifications, notificationStatus, updateStatusView } = useSelector(
		(state: {
			notificationsUser: {
				notifications: notificationItemType[]
				notificationStatus: boolean
				updateStatusView: number
			}
		}) => state.notificationsUser
	)

	useEffect(() => {
		dispatch(getNotificationsUserList({ request: {}, callback: {} }))
		dispatch(getCompanies({ request: { type: 'active' } }))
	}, [])

	useEffect(() => {
		dispatch(getNotificationsUserNew({ request: {}, callback: {} }))
	}, [notifications])

	return (
		<header className='header'>
			<AppSearchInput />
			<div className='header__container'>
				<DropdownChoiceCompany />
				{/* <AppMessage
					img={IconBell}
					active={info?.verifier?.notificationList.length > 0 || !info.is_verifier}
				/> */}
				<NotificationsModal
					count={
						notifications?.filter(item => item.status_view === 0)?.length -
							updateStatusView ?? 0
					}
					component={IconBell}
					active={notifications?.length > 0 && notificationStatus}
					list={notifications ? notifications : []}
				/>
				<AppMessage img={IconMessage} />
				<DropdownLang />
				<DropdownProfile />
			</div>
		</header>
	)
}

export default AppHeader

export const LcAppHeader: FC = () => {
	// const resetApp = useAppReset();
	const dispatch = useDispatch()
	const { notifications, notificationStatus, updateStatusView } = useSelector(
		(state: {
			notificationsUser: {
				notifications: notificationItemType[]
				notificationStatus: boolean
				updateStatusView: number
			}
		}) => state.notificationsUser
	)

	// const { notifications, notificationStatus } = useSelector(
	// 	(state: stateType) => state.notificationsUser
	// )

	useEffect(() => {
		dispatch(getNotificationsUserList({ request: {}, callback: {} }))
		dispatch(getCompanies({ request: { type: 'active' } }))
	}, [])

	useEffect(() => {
		dispatch(getNotificationsUserNew({ request: {}, callback: {} }))
	}, [notifications])

	return (
		<header className='header'>
			<AppSearchInput modal />
			<div className='header__container'>
				<NotificationsModal
					count={
						notifications?.filter(item => item.status_view === 0)?.length -
							updateStatusView ?? 0
					}
					component={IconBell}
					active={notifications?.length > 0 && notificationStatus}
					list={notifications ? notifications : []}
				/>
				<AppMessage img={IconMessage} />
				<DropdownLang />
				<DropdownProfile />
			</div>
		</header>
	)
}

/*
      <AppMessage active img={IconBell} />
        <AppMessage active img={IconMessage} />
        <ButtonDefault
          action={() => {
            dispatch(
              logoutFromCompany({
                request: { company_id: info.company.id },
                callback: () => {
                  dispatch(setStatus('companies-profile'));
                  navigate('/companies');
                },
              }),
            );
          }}
          name="Выйти с компании"
        />
*/
