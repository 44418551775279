import { Modal } from 'antd'
import __ from 'lodash'
import React, { useEffect } from 'react'
import PositionFunctionalityModalBody from './PositionFunctionalityModalBody'
import PositionFunctionalityModalFooter from './PositionFunctionalityModalFooter'
import PositionFunctionalityModalHeader from './PositionFunctionalityModalHeader'
import type { FC } from 'react'
import CloseModalIcon from '@assets/icons/CloseModalIcon'

const PositionFunctionalityModal: FC<any> = (props: any) => {
	const {
		visible,
		changeVisible,
		userSelectedList,
		changeUserSelectedList,
		positionsId,
		setPositionId,
		positionData,
		addItem,
		removeItem,
		title,
	} = props
	const { checkedList, setCheckedList, setAllOptions } = positionData

	function handleOnSubmitFunctionality() {
		changeUserSelectedList(__.sortBy(checkedList, 'name').filter(el => el.selected))
		changeVisible(!visible)
	}

	useEffect(() => {
		if (visible) {
			setAllOptions((prev: any) => prev.map((p: any) => ({ ...p, selected: false })))
			setCheckedList(userSelectedList)
		}
	}, [visible])

	return (
		<Modal
			className='functionality-modal'
			open={visible}
			footer={null}
			styles={{ body: { padding: 0 } }}
			closable
			centered
			closeIcon={<CloseModalIcon onClick={() => changeVisible(!visible)} />}
		>
			<PositionFunctionalityModalHeader positionData={positionData} />

			<PositionFunctionalityModalBody
				title={title}
				positionsId={positionsId}
				setPositionId={setPositionId}
				positionData={positionData}
				addItem={addItem}
				removeItem={removeItem}
			/>

			<PositionFunctionalityModalFooter onClick={() => handleOnSubmitFunctionality()} />
		</Modal>
	)
}

export default PositionFunctionalityModal
