import { Avatar } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import type { stateType } from '@types'
import type { FC } from 'react'
import { getAvatarUrl } from '@tools/helpFunctions'

import './style.scss'

interface UserAvatarType {
	isCollapsed?: boolean
}

const UserAvatar: FC<UserAvatarType> = ({ isCollapsed }: UserAvatarType) => {
	const { t } = useTranslation()
	const { info } = useSelector((state: stateType) => state.user)

	const name: any = (info && info.name) || t('Name')
	const lname: any = (info && info.lname) || t('Last Name')
	const rang = (info && info?.company?.user?.role?.name) || t('Verifier')

	info.files?.avatar && getAvatarUrl(info.files?.avatar, '40')
	return (
		<div className={isCollapsed ? 'user-profile user-profile__is-collapsed' : 'user-profile'}>
			{info.files?.avatar[0]?.url ? (
				<div className='user-profile__avatar'>
					<img src={getAvatarUrl(info.files?.avatar, '40')} alt='avatar' />
				</div>
			) : (
				<Avatar
					className='user-profile__avatar'
					size={40}
					style={{ backgroundColor: '#6D6BE5' }}
				>
					{name[0].toUpperCase()}
				</Avatar>
			)}

			<div className='user-profile__block'>
				<p className='user-profile__name'>
					{name} {lname}
				</p>
				<span className='user-profile__position'>{rang}</span>
			</div>
		</div>
	)
}

export default UserAvatar

export const UserLcAvatar: FC = () => {
	const { company }: any = useSelector((state: stateType) => state.lcVerificator)
	const name: any = (company?.info?.owner && company?.info?.owner?.name) || 'User'
	const rang = (company?.info?.owner && company?.info?.owner?.role?.name) || 'Person'

	return (
		<div className={'user-profile'}>
			{company?.info?.owner.files?.avatar[0]?.url ? (
				<div className='user-profile__avatar'>
					{/*<img src={getAvatarUrl(company?.info.files?.avatar, '40')} alt="avatar" />*/}
					<img src={company?.info?.owner.files?.avatar[3]?.url} alt='avatar' />
				</div>
			) : (
				<Avatar
					className='user-profile__avatar'
					size={40}
					style={{ backgroundColor: '#6D6BE5' }}
				>
					{name[0].toUpperCase()}
				</Avatar>
			)}

			<div className='user-profile__block'>
				<p className='user-profile__name'>{name}</p>
				<span className='user-profile__position'>{rang}</span>
			</div>
		</div>
	)
}

export const UserProfile: FC<any> = ({ name, role, url }: any) => (
	<div className={'user-profile'}>
		{url ? (
			<div className='user-profile__avatar'>
				<img src={url} alt='avatar' />
			</div>
		) : (
			<Avatar
				className='user-profile__avatar'
				size={40}
				style={{ backgroundColor: '#6D6BE5' }}
			>
				{name[0].toUpperCase()}
			</Avatar>
		)}

		<div className='user-profile__block'>
			<p className='user-profile__name'>{name}</p>
			<span className='user-profile__position'>{role}</span>
		</div>
	</div>
)
