import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { menuItemType } from '@tools/menu'
/*
 *
 * Menu reducer
 *
 */
const initialState = {
	active: null,
	list: [] as Array<menuItemType>,
}

export type menuInitStateType = typeof initialState

const menuSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setMenuList: (state, action: PayloadAction<any>) => {
			state.list = action.payload
		},
		setActive: (state, action: PayloadAction<any>) => {
			state.active = action.payload
		},
	},
})

export default menuSlice.reducer
export const { setMenuList, setActive }: any = menuSlice.actions
