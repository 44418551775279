import React from 'react'
import type { FC } from 'react'

export const IconCompanies: FC<any> = ({}: any) => (
	<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.69145 2.4343C9.01441 2.4343 8.43842 2.90478 8.27674 3.5389H13.7133C13.5516 2.90478 12.9756 2.4343 12.2986 2.4343H9.69145ZM15.2491 3.53892H17.2499C19.372 3.53892 21.1 5.28787 21.1 7.43571C21.1 7.43571 21.0393 8.34496 21.0191 9.61116C21.0171 9.71139 20.9686 9.80958 20.8888 9.8689C20.4027 10.2279 19.9581 10.5245 19.9177 10.545C18.2402 11.67 16.291 12.4616 14.2144 12.8554C14.079 12.882 13.9456 12.8114 13.8769 12.6907C13.2948 11.6823 12.2075 11.0257 10.9949 11.0257C9.79039 11.0257 8.69298 11.6751 8.09375 12.6846C8.02403 12.8033 7.89266 12.8718 7.75826 12.8462C5.69885 12.4514 3.74959 11.6608 2.08225 10.5552L1.11217 9.88015C1.03133 9.82901 0.980804 9.73696 0.980804 9.63468C0.950489 9.11307 0.899963 7.43571 0.899963 7.43571C0.899963 5.28787 2.62793 3.53892 4.74999 3.53892H6.74068C6.93268 2.05589 8.1756 0.900146 9.69136 0.900146H12.2985C13.8142 0.900146 15.0571 2.05589 15.2491 3.53892ZM20.7564 11.8235L20.716 11.844C18.6748 13.2145 16.2192 14.1248 13.6425 14.5032C13.2787 14.5544 12.9149 14.3191 12.8138 13.9509C12.5915 13.1122 11.8741 12.5599 11.0151 12.5599H11.005H10.9848C10.1259 12.5599 9.40844 13.1122 9.18613 13.9509C9.08508 14.3191 8.7213 14.5544 8.35752 14.5032C5.78073 14.1248 3.3252 13.2145 1.28398 11.844C1.27387 11.8338 1.17282 11.7724 1.09198 11.8235C1.00104 11.8747 1.00104 11.9974 1.00104 11.9974L1.07177 17.2136C1.07177 19.3614 2.78963 21.1001 4.91169 21.1001H17.0782C19.2002 21.1001 20.9181 19.3614 20.9181 17.2136L20.9989 11.9974C20.9989 11.9974 20.9989 11.8747 20.908 11.8235C20.8575 11.7929 20.7968 11.8031 20.7564 11.8235ZM11.7529 16.1087C11.7529 16.5383 11.4195 16.8758 10.995 16.8758C10.5807 16.8758 10.2372 16.5383 10.2372 16.1087V14.7893C10.2372 14.37 10.5807 14.0222 10.995 14.0222C11.4195 14.0222 11.7529 14.37 11.7529 14.7893V16.1087Z'
			fill='#6D6BE5'
		/>
	</svg>
)

export const IconAwait: FC<any> = ({}: any) => (
	<svg width='24' height='26' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.3661 0.880127C11.2311 0.880127 9.33458 1.2816 7.54991 1.72853C5.72412 2.18303 3.88353 2.72085 2.80121 3.04658C2.3487 3.18422 1.94752 3.43664 1.64376 3.77484C1.33999 4.11304 1.14597 4.52331 1.08398 4.95851C0.10365 11.7412 2.37849 16.7679 5.13855 20.0934C6.309 21.5159 7.70458 22.7686 9.27866 23.8097C9.91357 24.2232 10.5024 24.5399 11.0025 24.7565C11.463 24.9565 11.9581 25.1201 12.3661 25.1201C12.774 25.1201 13.2674 24.9565 13.7296 24.7565C14.3324 24.4867 14.9091 24.1699 15.4534 23.8097C17.0276 22.7686 18.4231 21.5159 19.5935 20.0934C22.3536 16.7679 24.6285 11.7412 23.6481 4.95851C23.5862 4.5231 23.3923 4.11259 23.0885 3.77413C22.7848 3.43567 22.3835 3.18297 21.9309 3.04506C20.3597 2.57058 18.7764 2.13111 17.1822 1.72701C15.3975 1.28312 13.501 0.880127 12.3661 0.880127ZM11.4614 13.7607L10.8857 8.44755C10.8638 8.25642 10.8857 8.06318 10.9502 7.88038C11.0146 7.69757 11.12 7.52928 11.2596 7.38643C11.3992 7.24357 11.5698 7.12934 11.7604 7.05115C11.951 6.97296 12.1574 6.93255 12.3661 6.93255C12.5747 6.93255 12.7811 6.97296 12.9717 7.05115C13.1623 7.12934 13.3329 7.24357 13.4725 7.38643C13.6121 7.52928 13.7175 7.69757 13.7819 7.88038C13.8464 8.06318 13.8683 8.25642 13.8464 8.44755L13.2707 13.7607C13.2514 13.9694 13.1477 14.1638 12.9802 14.3055C12.8126 14.4472 12.5935 14.5258 12.3661 14.5258C12.1386 14.5258 11.9195 14.4472 11.7519 14.3055C11.5844 14.1638 11.4807 13.9694 11.4614 13.7607ZM12.3693 19.0601C11.9331 19.0601 11.5147 18.9005 11.2063 18.6164C10.8978 18.3323 10.7245 17.9469 10.7245 17.5451C10.7245 17.1433 10.8978 16.758 11.2063 16.4739C11.5147 16.1897 11.9331 16.0301 12.3693 16.0301C12.8056 16.0301 13.224 16.1897 13.5324 16.4739C13.8409 16.758 14.0142 17.1433 14.0142 17.5451C14.0142 17.9469 13.8409 18.3323 13.5324 18.6164C13.224 18.9005 12.8056 19.0601 12.3693 19.0601Z'
			fill='#FFC864'
		/>
	</svg>
)

export const IconVerification: FC<any> = ({}: any) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M6.7745 0.486084H16.7571C20.6604 0.486084 23.2741 3.22642 23.2741 7.30237V16.7105C23.2741 20.7738 20.6604 23.5141 16.7571 23.5141H6.7745C2.87125 23.5141 0.246063 20.7738 0.246063 16.7105V7.30237C0.246063 3.22642 2.87125 0.486084 6.7745 0.486084ZM11.1036 15.4424L16.5727 9.97323C16.9642 9.58175 16.9642 8.94848 16.5727 8.54549C16.1813 8.15402 15.5365 8.15402 15.145 8.54549L10.3897 13.3008L8.37477 11.2858C7.9833 10.8943 7.33851 10.8943 6.94704 11.2858C6.55556 11.6773 6.55556 12.3106 6.94704 12.7136L9.68737 15.4424C9.88311 15.6381 10.1364 15.7302 10.3897 15.7302C10.6545 15.7302 10.9079 15.6381 11.1036 15.4424Z'
			fill='#58C65C'
		/>
	</svg>
)

export const IconReject: FC<any> = ({}: any) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.1539 23.12C18.5129 23.12 23.6679 17.965 23.6679 11.606C23.6679 5.24703 18.5129 0.092041 12.1539 0.092041C5.79493 0.092041 0.639938 5.24703 0.639938 11.606C0.639938 17.965 5.79493 23.12 12.1539 23.12ZM12.1538 10.3883L8.72306 6.95755C8.57197 6.80646 8.36754 6.72024 8.15423 6.71758C7.91851 6.71758 7.69244 6.81122 7.52575 6.9779C7.35907 7.14458 7.26543 7.37065 7.26543 7.60638C7.27302 7.81117 7.35892 8.00523 7.50541 8.14855L10.9628 11.606L7.50541 15.019C7.42755 15.0952 7.36611 15.1866 7.32484 15.2875C7.28358 15.3884 7.26336 15.4966 7.26543 15.6056C7.26543 15.8413 7.35907 16.0674 7.52575 16.2341C7.69244 16.4007 7.91851 16.4944 8.15423 16.4944C8.26219 16.4931 8.36882 16.4704 8.46798 16.4277C8.56713 16.385 8.65685 16.3231 8.73195 16.2455L12.1538 12.8236L15.5402 16.2544C15.6202 16.3347 15.716 16.3975 15.8216 16.4389C15.9271 16.4802 16.0402 16.4991 16.1534 16.4944C16.3892 16.4944 16.6152 16.4007 16.7819 16.2341C16.9486 16.0674 17.0422 15.8413 17.0422 15.6056C17.0346 15.4008 16.9487 15.2067 16.8023 15.0634L13.3448 11.606L16.8023 8.19299C16.8801 8.11671 16.9416 8.02534 16.9828 7.92446C17.0241 7.82359 17.0443 7.71535 17.0422 7.60638C17.0422 7.37065 16.9486 7.14458 16.7819 6.9779C16.6152 6.81122 16.3892 6.71758 16.1534 6.71758C16.0433 6.71883 15.9346 6.74231 15.8338 6.7866C15.733 6.83089 15.6422 6.89509 15.5668 6.97533L12.1538 10.3883Z'
			fill='#FF776F'
		/>
	</svg>
)

export const IconLink: FC<any> = ({ onClick }: any) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		onClick={onClick}
	>
		<path d='M18.1875 14.25H17.0625C16.9133 14.25 16.7702 14.3093 16.6648 14.4148C16.5593 14.5202 16.5 14.6633 16.5 14.8125V18.75H5.25V7.5H10.3125C10.4617 7.5 10.6048 7.44074 10.7102 7.33525C10.8157 7.22976 10.875 7.08668 10.875 6.9375V5.8125C10.875 5.66332 10.8157 5.52024 10.7102 5.41475C10.6048 5.30926 10.4617 5.25 10.3125 5.25H4.6875C4.23995 5.25 3.81072 5.42779 3.49426 5.74426C3.17779 6.06072 3 6.48995 3 6.9375V19.3125C3 19.7601 3.17779 20.1893 3.49426 20.5057C3.81072 20.8222 4.23995 21 4.6875 21H17.0625C17.5101 21 17.9393 20.8222 18.2557 20.5057C18.5722 20.1893 18.75 19.7601 18.75 19.3125V14.8125C18.75 14.6633 18.6907 14.5202 18.5852 14.4148C18.4798 14.3093 18.3367 14.25 18.1875 14.25ZM20.1562 3H15.6562C14.905 3 14.5295 3.9109 15.0586 4.44141L16.3147 5.69754L7.74609 14.263C7.66743 14.3414 7.60502 14.4345 7.56244 14.5371C7.51985 14.6397 7.49793 14.7496 7.49793 14.8607C7.49793 14.9717 7.51985 15.0817 7.56244 15.1842C7.60502 15.2868 7.66743 15.3799 7.74609 15.4583L8.54309 16.2539C8.62147 16.3326 8.71462 16.395 8.81718 16.4376C8.91974 16.4802 9.02969 16.5021 9.14074 16.5021C9.25179 16.5021 9.36175 16.4802 9.46431 16.4376C9.56687 16.395 9.66001 16.3326 9.7384 16.2539L18.3028 7.68703L19.5586 8.94141C20.0859 9.46875 21 9.09961 21 8.34375V3.84375C21 3.61997 20.9111 3.40536 20.7529 3.24713C20.5946 3.08889 20.38 3 20.1562 3V3Z' />
	</svg>
)

export const IconFilter: FC<any> = ({}: any) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.17969 19.6406C8.17969 20.0555 8.5125 20.3906 8.925 20.3906H15.075C15.4875 20.3906 15.8203 20.0555 15.8203 19.6406V15.0469H8.17969V19.6406ZM20.6273 3.60938H3.37266C2.79844 3.60938 2.43985 4.23516 2.72813 4.73438L7.91485 13.5469H16.0898L21.2766 4.73438C21.5602 4.23516 21.2016 3.60938 20.6273 3.60938Z'
			fill='#8D8BED'
		/>
	</svg>
)

export const IconFiles: FC<any> = ({}: any) => (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M20.24 7.32994L15.8 2.32994C15.7065 2.22634 15.5924 2.14347 15.4649 2.08663C15.3375 2.0298 15.1995 2.00027 15.06 1.99994L7.06 1.99994C6.72775 1.99597 6.39797 2.0575 6.0895 2.18099C5.78103 2.30449 5.4999 2.48754 5.26218 2.71969C5.02446 2.95184 4.83479 3.22855 4.70402 3.534C4.57324 3.83946 4.50392 4.16768 4.5 4.49994L4.5 19.4999C4.50392 19.8322 4.57324 20.1604 4.70402 20.4659C4.83479 20.7713 5.02446 21.048 5.26218 21.2802C5.4999 21.5123 5.78103 21.6954 6.0895 21.8189C6.39797 21.9424 6.72775 22.0039 7.06 21.9999L17.94 21.9999C18.2723 22.0039 18.602 21.9424 18.9105 21.8189C19.219 21.6954 19.5001 21.5123 19.7378 21.2802C19.9755 21.048 20.1652 20.7713 20.296 20.4659C20.4268 20.1604 20.4961 19.8322 20.5 19.4999L20.5 7.99994C20.4994 7.7521 20.4067 7.51333 20.24 7.32994ZM9.5 11.9999L12.5 11.9999C12.7652 11.9999 13.0196 12.1053 13.2071 12.2928C13.3946 12.4804 13.5 12.7347 13.5 12.9999C13.5 13.2652 13.3946 13.5195 13.2071 13.707C13.0196 13.8946 12.7652 13.9999 12.5 13.9999L9.5 13.9999C9.23478 13.9999 8.98043 13.8946 8.79289 13.707C8.60536 13.5195 8.5 13.2652 8.5 12.9999C8.5 12.7347 8.60536 12.4804 8.79289 12.2928C8.98043 12.1053 9.23478 11.9999 9.5 11.9999ZM15.5 17.9999H9.5C9.23478 17.9999 8.98043 17.8946 8.79289 17.707C8.60536 17.5195 8.5 17.2652 8.5 16.9999C8.5 16.7347 8.60536 16.4804 8.79289 16.2928C8.98043 16.1053 9.23478 15.9999 9.5 15.9999L15.5 15.9999C15.7652 15.9999 16.0196 16.1053 16.2071 16.2928C16.3946 16.4804 16.5 16.7347 16.5 16.9999C16.5 17.2652 16.3946 17.5195 16.2071 17.707C16.0196 17.8946 15.7652 17.9999 15.5 17.9999ZM15.21 7.99994C15.0038 7.97963 14.8139 7.87917 14.6811 7.72019C14.5483 7.5612 14.4833 7.35641 14.5 7.14994V3.99994L18.24 7.99994H15.21Z'
			fill='#8D8BED'
		/>
	</svg>
)
