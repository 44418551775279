import React from 'react'
import type { FC } from 'react'
import LcSendMessage from '@atoms/textarea/lcMessage'
import LcMessagesList from '@molecules/lists/lcMessages'

import './style.scss'

const LcMessagesContainer: FC = () => (
	<div className='lc-messages-container'>
		<LcSendMessage />
		<LcMessagesList />
	</div>
)

export default LcMessagesContainer
