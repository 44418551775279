import Icon from '@ant-design/icons'
import React from 'react'
import type { FC } from 'react'

import './style.scss'

interface AppMessageType {
	img: any
	active?: boolean
}
const AppMessage: FC<AppMessageType> = ({ img, active }: AppMessageType) => (
	<div className='message'>
		{img && <Icon component={img} />}
		{active && <span className='message__span' />}
	</div>
)

export default AppMessage
