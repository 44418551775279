import type { AxiosResponse } from 'axios'
import { post } from '@api/api_helper'

export const api = {
	getVerificationCompanyInfo: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/getVerificationCompanyInfo', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getVerificationCompanyAdvanceSearch: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/findListVerificationCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getVerificationCompaniesList: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/getVerificationCompaniesList', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	setVerificationCompany: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/setVerificationCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	removeVerificationCompany: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/removeCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	restoreVerificationCompany: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/restoreCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	searchVerificationCompany: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/searchVerificationCompany', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	sendVerificationCompanyMessage: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/sendVerificationCompanyMessage', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	editVerificationCompanyMessage: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/editVerificationCompanyMessage', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	removeVerificationCompanyMessage: async (data: string): Promise<AxiosResponse> => {
		try {
			return await post('/verifier/removeVerificationCompanyMessage', data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},
}
