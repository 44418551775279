import { Breadcrumb } from 'antd'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import type { FC } from 'react'

import { IconBreadcrumbArrow } from '@assets/icons/nav/profileHeader'

import './style.scss'

const breadcrumbList: any = {
	settings: {
		name: 'Settings',
		link: '/settings/companies',
	},
	companies: {
		name: 'My Companies',
		link: '/settings/companies',
	},
	credentials: {
		name: 'Permissions',
		link: '/settings/credentials',
	},
	notifications: {
		name: 'Notifications',
		link: '/settings/notifications',
	},
	region: {
		name: 'Language and region',
		link: '/settings/region',
	},
	password: {
		name: 'Password',
		link: '/settings/password',
	},
	myProfile: {
		name: 'My profile',
		link: '/settings/myProfile',
	},
	// notifications: {
	// 	name: 'Notifications',
	// 	link: '/settings/My Companies',
	// },
	'my-company': {
		name: 'My company',
		link: '/my-company/',
	},
	profileCompany: {
		name: 'Profile company',
		link: '/my-company/profileCompany',
	},
	organizationalStructure: {
		name: 'Organizational structure',
		link: '/my-company/organizationalStructure',
	},
	departments: {
		name: 'Departments',
		link: '/my-company/departments',
	},
	employees: {
		name: 'Employees',
		link: '/my-company/employees',
	},
}

const PageBreadcrumb: FC<any> = ({ icon: Component }: any) => {
	const location = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const getList = useCallback(
		() => location.pathname.split('/').filter(el => el !== ''),
		[location.pathname]
	)

	const breadcrumbItems = getList().map((item: any, index: number) => ({
		title: (
			<span
				onClick={() => {
					if (index + 1 === getList().length) {
						return
					}
					navigate(breadcrumbList[item]?.link)
				}}
				className={classNames('app-breadcrumb__link link', {
					['app-breadcrumb__link-last']: index + 1 === getList().length ? true : false,
				})}
			>
				{index === 0 && <Component />}
				{t(`${breadcrumbList[item]?.name}`)}
			</span>
		),
	}))

	return (
		<div>
			<Breadcrumb
				className='app-breadcrumb'
				items={breadcrumbItems}
				separator={<IconBreadcrumbArrow />}
			/>
		</div>
	)
}

export default PageBreadcrumb
