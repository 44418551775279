import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import IconLogout from '@assets/icons/btn/logout'

import './style.scss'

interface BtnLogoutType {
	onClick?: () => void
	color?: string
}

const BtnLogout: FC<BtnLogoutType> = ({ color, onClick }: BtnLogoutType) => {
	const { t } = useTranslation()

	return (
		<div className='btn-back' onClick={onClick}>
			<div className='btn-back__icon'>
				<IconLogout color={color} />
			</div>
			<div className='btn-back__text'>
				<span>{t('Leaving')}</span>
			</div>
		</div>
	)
}

export default BtnLogout
