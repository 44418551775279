import React from 'react'
import type { FC } from 'react'
import { LcAppHeader } from '@molecules/app/header'
import Menu from '@organisms/menu'

import './style.scss'

interface HomeTemplateType {
	children: React.ReactNode
	showHeader?: boolean
}

const LcVerificationTemplate: FC<HomeTemplateType> = ({
	showHeader,
	children,
}: HomeTemplateType) => (
	<div className='lc-verification-template'>
		<div className='lc-verification-template__side'>
			<Menu set={'lc-verification'} />
		</div>
		<div className='lc-verification-template__container'>
			{showHeader && <LcAppHeader />}
			<div className='lc-verification-template__body'>{children}</div>
		</div>
	</div>
)

export default LcVerificationTemplate
