import { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'
const useTablePagination = ({ defaultPageSize, defaultTotal, tableRef, keyId }) => {
	const { sessionProperties } = useSelector((state: stateType) => state.user)
	const [pageIndex, setPageIndex] = useState(1)
	const [pageSize, setPageSize] = useState(defaultPageSize)
	const [loadingPages, setLoadingPages] = useState(false)
	const [tableRendered, setTableRendered] = useState(false)

	const handleChangePage = (page, size) => {
		setTimeout(() => {
			setPageSize(size)
			setPageIndex(page)
			setLoadingPages(false)
		}, 500)
	}

	const handleTableScroll = useCallback(
		event => {
			if (loadingPages || !defaultTotal) return

			const { target } = event
			const isAtBottom = target.scrollTop + 10 > target.scrollHeight - target.clientHeight

			if (isAtBottom && pageIndex * pageSize < defaultTotal) {
				setLoadingPages(true)
			}
		},
		[loadingPages, defaultTotal, pageIndex, pageSize]
	)

	const handleTableRender = (record, index) => {
		if (index === defaultPageSize - 1) {
			setTimeout(() => setTableRendered(true), 1000)
		}

		return {}
	}

	useEffect(() => {
		if (!loadingPages) return

		const nextButton = tableRef?.current?.querySelector(
			'.ant-pagination-next'
		) as HTMLElement | null

		if (nextButton) {
			setTimeout(() => {
				nextButton.click()
			}, 500)
		}
	}, [loadingPages, keyId])

	useEffect(() => {
		const antTableBody = tableRef?.current?.querySelector('.ant-table-body') as
			| HTMLElement
			| null
			| undefined

		if (antTableBody && defaultTotal > defaultPageSize) {
			antTableBody.addEventListener('scroll', handleTableScroll)
			return () => antTableBody.removeEventListener('scroll', handleTableScroll)
		}
	}, [tableRendered, pageIndex, pageSize, keyId, tableRef, sessionProperties?.tabActiveCompany])

	return {
		pageIndex,
		pageSize,
		loadingPages,
		tableRendered,
		handleChangePage,
		handleTableScroll,
		handleTableRender,
	}
}

export default useTablePagination
