import React from 'react'
import type { FC } from 'react'

const IconWarning: FC = () => (
	<svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M32 0.5C40.3543 0.5 48.3665 3.81874 54.2739 9.72614C60.1813 15.6335 63.5 23.6457 63.5 32C63.5 40.3543 60.1813 48.3665 54.2739 54.2739C48.3665 60.1813 40.3543 63.5 32 63.5C23.6457 63.5 15.6335 60.1813 9.72614 54.2739C3.81874 48.3665 0.5 40.3543 0.5 32C0.5 23.6457 3.81874 15.6335 9.72614 9.72614C15.6335 3.81874 23.6457 0.5 32 0.5ZM32 14C31.4287 13.9995 30.8635 14.1182 30.3407 14.3484C29.8178 14.5787 29.3487 14.9155 28.9633 15.3373C28.578 15.759 28.2848 16.2566 28.1026 16.798C27.9204 17.3395 27.8531 17.913 27.905 18.482L29.5475 36.509C29.6112 37.1151 29.897 37.6761 30.3499 38.0839C30.8028 38.4917 31.3906 38.7173 32 38.7173C32.6094 38.7173 33.1972 38.4917 33.6501 38.0839C34.103 37.6761 34.3888 37.1151 34.4525 36.509L36.0905 18.482C36.1424 17.9134 36.0752 17.3403 35.8933 16.7991C35.7113 16.2579 35.4186 15.7606 35.0337 15.3389C34.6488 14.9172 34.1802 14.5803 33.6579 14.3498C33.1356 14.1193 32.5709 14.0001 32 14ZM32 50C32.9548 50 33.8705 49.6207 34.5456 48.9456C35.2207 48.2705 35.6 47.3548 35.6 46.4C35.6 45.4452 35.2207 44.5295 34.5456 43.8544C33.8705 43.1793 32.9548 42.8 32 42.8C31.0452 42.8 30.1295 43.1793 29.4544 43.8544C28.7793 44.5295 28.4 45.4452 28.4 46.4C28.4 47.3548 28.7793 48.2705 29.4544 48.9456C30.1295 49.6207 31.0452 50 32 50Z'
			fill='#FFC864'
		/>
	</svg>
)

export default IconWarning
