import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import type { FC } from 'react'

import UpdatePassword from '@organisms/forms/updatePassword'
import ForgetPasswordModal from '@organisms/modals/settings/forgetPassword'
import LanguageAndRegion from '@organisms/profile/languageAndRegion'
import { appPage } from '@redux/reducers/appSlice'
import './style.scss'

const TabsProfileContainer: FC = () => {
	const { page } = useSelector((state: any) => state.app)
	const { info } = useSelector((state: any) => state.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const [modalActive, setModalActive] = useState(false)
	const { tab } = useParams()

	useEffect(() => {
		dispatch(appPage(tab))
	}, [tab])

	useEffect(() => {
		if (!info?.is_verifier && !tab) navigate('/settings/password')
	}, [])

	let items

	const itemsVerifier = [
		{
			label: t('Password'),
			key: 'password',
			children: (
				<>
					<div className='tab_pane_title'>{t('Change password')}</div>
					<UpdatePassword setModalActive={setModalActive} />
					<ForgetPasswordModal
						modalActive={modalActive}
						setModalActive={setModalActive}
					/>
				</>
			),
		},
		{
			label: t('Language and region'),
			key: 'region',
			children: (
				<>
					<div className='tab_pane_title'>{t('Language and region')}</div>
					<LanguageAndRegion />
				</>
			),
		},
	]

	if (info?.is_verifier) {
		items = [...itemsVerifier]
	} else {
		items = [
			{
				label: t('My Companies'),
				key: 'companies',
				children: (
					<>
						<div className='tab_pane_title'>{t('My Companies')}</div>
					</>
				),
			},
			{
				label: t('Permissions'),
				key: 'credentials',
				children: (
					<>
						<div className='tab_pane_title'>{t('Permissions')}</div>
					</>
				),
			},
			{
				label: t('My profile'),
				key: 'myProfile',
				children: (
					<>
						<div className='tab_pane_title'>{t('My profile')}</div>
					</>
				),
			},
			...itemsVerifier,
			{
				label: t('Notifications'),
				key: 'notifications',
				children: (
					<>
						<div className='tab_pane_title'>{t('Notifications')}</div>
					</>
				),
			},
		]
	}

	const onChange = (value: string) => {
		dispatch(appPage(value))
		if (!info?.is_verifier) {
			switch (value) {
				case 'companies':
				case 'credentials':
				case 'password':
				// dispatch(getCompanies({ request: { type: 'active' } }));
				case 'region':
				// dispatch(getCompanies({ request: { type: 'active' } }));
				case 'notifications':
				default:
					navigate(`/settings/${value}`)
					break
			}
		}
	}

	return (
		<Tabs
			onChange={onChange}
			activeKey={page ? page : 'password'}
			centered
			className={'settings-tabs'}
			items={items}
		/>
	)
}

export default TabsProfileContainer
