import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import ButtonDefault from '@atoms/buttons/button'
import { sendMessage } from '@redux/reducers/lcVerificatorSlice'
import './style.scss'

const LcSendMessage: FC = () => {
	const [value, setValue] = useState('')
	const { t } = useTranslation()

	const dispatch = useDispatch()
	const { id } = useParams()
	const { sessionProperties } = useSelector((state: stateType) => state.user)

	const companyId = id || sessionProperties?.companyId

	const onChange = (e: any) => {
		setValue(e.target.value)
	}

	const submit = () => {
		dispatch(
			sendMessage({
				request: {
					company_id: companyId,
					text: value,
				},
				callback: {
					success: () => {
						setValue('')
						//NOTIFICATION.success(t('Message saved successfully'));
					},
				},
			})
		)
	}
	return (
		<div className='send-message'>
			<textarea
				className='send-message__textarea'
				placeholder={`${t('Add new comment')}`}
				value={value}
				onChange={onChange}
			/>

			<div className='send-message__btns'>
				<ButtonDefault
					name={t('Cancellation')}
					action={() => setValue('')}
					buttonType={'clean'}
					disabled={value ? false : true}
				/>
				<ButtonDefault
					name={t('Save')}
					type={'submit'}
					disabled={value ? false : true}
					action={submit}
				/>
			</div>
		</div>
	)
}

export default LcSendMessage
