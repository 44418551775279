import React from 'react'
import type { FC } from 'react'

const IconClose: FC = () => (
	<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M14 1.41001L12.59 7.62939e-06L7 5.59001L1.41 7.62939e-06L0 1.41001L5.59 7.00001L0 12.59L1.41 14L7 8.41001L12.59 14L14 12.59L8.41 7.00001L14 1.41001Z'
			fill='#DCDEFC'
		/>
	</svg>
)

export default IconClose
