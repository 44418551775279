import { Modal } from 'antd'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ModalItems from './modalItems'
import type { stateType } from '@types'
import type { FC } from 'react'
import InfoBlock from '@molecules/InfoBlock'
import { initializeDayjsLang } from '@tools/helpFunctions'
import './style.scss'

interface DeleteCompanyModalType {
	component: any
	action?: any
	active?: boolean
	list?: any
	count?: number
}

const notificationModalTypes = [3, 4]

const NotificationsModal: FC<DeleteCompanyModalType> = ({
	component: Component,
	//action,
	count,
	active,
	list,
}: DeleteCompanyModalType) => {
	const typeStatus = {
		1: 'new',
		2: 'await',
		3: 'validation',
		4: 'doc',
	}
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const [visible, setVisible] = useState(false)

	const [listModal, setListModal] = useState(
		[...list]
			.filter(
				item =>
					item?.status_view === 0 &&
					item?.data?.company?.id === userInfo?.company?.id &&
					notificationModalTypes.includes(item?.type)
			)
			.map(item => ({
				id: item?.id,
				type: item?.type,
			})) || []
	)
	const [_loader, setLoader] = useState(false)

	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
		setLoader(false)
	}

	const addDateSortProperty = list =>
		list.map((item, index) => {
			const currentDate = dayjs(item.date, 'YYYY-MM-DD HH:mm:ss')
			const formattedDate = currentDate.format('DD MMMM YYYY')

			const dateSort =
				index === 0 ||
				formattedDate !==
					dayjs(list[index - 1].date, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')
					? formattedDate
					: undefined

			return { ...item, date_sort: dateSort }
		})

	// const call = () => {
	// 	setLoader(true)
	// 	action(onCancel)
	// }

	const langFromLocalStorage = localStorage.getItem('lang')

	useEffect(() => {
		if (langFromLocalStorage) {
			dayjs.locale(initializeDayjsLang(langFromLocalStorage))
		}
	}, [langFromLocalStorage])

	useEffect(() => {
		userInfo?.company?.id &&
			setListModal(
				[...list]
					.filter(
						item =>
							item?.status_view === 0 &&
							item?.data?.company?.id === userInfo?.company?.id &&
							notificationModalTypes.includes(item?.type)
					)
					.map(item => ({
						id: item?.id,
						type: item?.type,
					})) || []
			)
	}, [list, userInfo?.company?.id])

	return (
		<>
			{listModal.length > 0 && <ModalItems notification={listModal[0]} />}
			<div className='modal-notification-component'>
				<Component
					onClick={() => {
						setVisible(true)
					}}
				/>
				{active && <div className='modal-notification-component__active' />}
			</div>

			<Modal
				className='modal-notification'
				open={visible}
				width={505}
				footer={null}
				closable={true}
				styles={{
					body: { padding: '24px 0' },
					mask: { backgroundColor: 'rgba(220, 222, 252, 0.6)' },
				}}
				onCancel={onCancel}
			>
				<div className='modal-notification__body'>
					<div className='modal-notification__title'>
						{t('Notifications')}
						{count && count > 0 ? (
							<span className='notificationTitleCount'>{count}</span>
						) : (
							''
						)}
					</div>
					<div className='modal-notification__list'>
						{list &&
							addDateSortProperty(list).map((item: any) => (
								<div key={item.id}>
									{item.date_sort && (
										<div className='modal-notification__date'>
											{item.date_sort}
										</div>
									)}
									<InfoBlock
										changeStatus={!item.status_view}
										notificationId={item.id}
										companyId={item.data?.company?.id}
										classes={`modal-notification__item ${
											item.status_view === 1 ? 'viewed' : ''
										}`}
										title={item.message}
										subtitle={dayjs(item.date).format(
											'DD MMMM YYYY · hh:mm:ss'
										)}
										isWarning
										type={typeStatus[item.type]}
									/>
								</div>
							))}
					</div>
				</div>
			</Modal>
		</>
	)
}

export default NotificationsModal
