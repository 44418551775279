import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
//import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from './Loading'
import type { stateType } from '@types'
import type { FC } from 'react'
import Success from '@pages/success'

//import { setStatus } from '@redux/reducers/appSlice'
//import { getUserInfo } from '@redux/reducers/userSlice'
import CompaniesSetting from '@templates/formTemplate/companiesSetting'
import { getStoreToken } from '@tools/helpFunctions'

interface PageWrapperType {
	children: React.ReactNode
	showTemplate?: boolean
}

const SettingCompanyWrapper: FC<PageWrapperType> = ({
	children,
	showTemplate,
}: PageWrapperType) => {
	const { status, success } = useSelector((state: stateType) => state.app)
	const { info } = useSelector((state: stateType) => state.user)
	const token = getStoreToken()

	//const dispatch: any = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		token && _.isEmpty(info)
		//  && dispatch(getUserInfo('2')) !token && dispatch(setStatus('auth'))
	}, [])

	useEffect(() => {
		switch (status) {
			case 'company-setting':
				//return navigate('/company-create')
				break
		}
	}, [status])

	const controller = () => {
		switch (status) {
			// case 'main':
			// 	break
			// 	return navigate('/')

			case 'auth':
				break
				return navigate('/auth')

			case 'success':
				return <Success type={success.type} />

			case 'loading':
				return <Loading />
			case 'company-setting':
			default:
				return showTemplate ? (
					<CompaniesSetting>{children}</CompaniesSetting>
				) : (
					<>{children}</>
				)
		}
	}
	return <>{controller()}</>
}

export default SettingCompanyWrapper
