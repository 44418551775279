import React from 'react'
import type { FC } from 'react'

const IconPlus: FC = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M15.8419 10.8427H13.1558V8.15809C13.1558 7.5192 12.6381 7 11.9992 7C11.3603 7 10.8411 7.5192 10.8411 8.15809V10.8427H8.15654C7.51764 10.8427 7 11.3619 7 12.0008C7 12.6397 7.51764 13.1589 8.15654 13.1589H10.8411V15.8419C10.8411 16.4808 11.3603 17 11.9992 17C12.6381 17 13.1558 16.4808 13.1558 15.8419V13.1589H15.8419C16.4808 13.1589 17 12.6397 17 12.0008C17 11.3619 16.4808 10.8427 15.8419 10.8427Z'
			fill='#8D8BED'
		/>
	</svg>
)

export default IconPlus
