import { Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'

import IconWrong from '@assets/icons/modals/wrong'
import ButtonDefault from '@atoms/buttons/button'
import './style.scss'

interface CompanyNotVerifiedModalType {
	component?: any
	action?: any
	color?: any
}

const CompanyNotVerifiedModal: FC<CompanyNotVerifiedModalType> = ({
	action,
}: CompanyNotVerifiedModalType) => {
	const [visible, setVisible] = useState(true)
	const [loader] = useState(false)
	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
		action()
	}

	return (
		<Modal
			className='modal-confirm'
			open={visible}
			width={504}
			footer={null}
			closable={true}
			styles={{ body: { padding: '44px 40px' } }}
			centered
			onCancel={onCancel}
		>
			<div className='modal-confirm__header'>
				<IconWrong />
			</div>
			<div className='modal-confirm__body'>
				<div className='modal-confirm__title'>{t('The company is not verified!')}</div>
				<div className='modal-confirm__desc'>
					{t(
						'Please check whether the information about the Company has been filled out correctly'
					)}
				</div>
			</div>
			<div className='modal-confirm__btns'>
				<ButtonDefault
					name={t('Check information')}
					type={'submit'}
					loading={loader}
					disabled={loader}
					action={onCancel}
				/>
			</div>
		</Modal>
	)
}

export default CompanyNotVerifiedModal
