import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loading from './Loading'
import type { stateType } from '@types'
import type { FC } from 'react'
import Success from '@pages/success'
import UserRegistration from '@pages/user/registration'
import { setStatus } from '@redux/reducers/appSlice'
import { getUserInfo } from '@redux/reducers/userSlice'
import HomeTemplate from '@templates/homeTemplate'
import { getStoreToken } from '@tools/helpFunctions'
import usePageStatusController from '@tools/hooks/usePageStatusController'

interface PageWrapperType {
	children: React.ReactNode
	showTemplate?: boolean
}

const UserRegistrationWrapper: FC<PageWrapperType> = ({
	children,
	showTemplate,
}: PageWrapperType) => {
	const { status, success } = useSelector((state: stateType) => state.app)
	const { info } = useSelector((state: stateType) => state.user)
	const token = getStoreToken()
	const { navigatePageController } = usePageStatusController()
	const dispatch = useDispatch()

	useEffect(() => {
		token && _.isEmpty(info) && dispatch(getUserInfo())
		!token && dispatch(setStatus('auth'))
	}, [])

	useEffect(() => {
		navigatePageController()
	}, [status])

	const controller = () => {
		switch (status) {
			case 'success':
				return <Success type={success.type} />

			case 'loading':
				return <Loading />

			case 'profile-create':
				return <UserRegistration />

			case 'main':
				return showTemplate ? <HomeTemplate>{children}</HomeTemplate> : <>{children}</>
		}
	}
	return <>{controller()}</>
}

export default UserRegistrationWrapper
