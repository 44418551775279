import { yupResolver } from '@hookform/resolvers/yup'

import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormCompanyAddressData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import { addressFields } from '@configs/formFields'

import RenderFields from '@organisms/forms/renderFields'

import {
	setCompanyDepartmentAddress,
	getCompanyDepartmentAddressList,
} from '@redux/reducers/companySlice'
import { NOTIFICATION } from '@redux/sagas/notification'
import i18n from '@src/i18n'

import { setCompanyAddressSchema } from '@tools/shemas'

import './style.scss'
interface FormCompanyDepartmentProps {
	onCancel?: () => void
}

const FormCompanyAddress: FC<FormCompanyDepartmentProps> = ({ onCancel }) => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const schema = useMemo(() => setCompanyAddressSchema(), [i18n.language])
	const {
		register,
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			country: '',
			city: '',
			street: '',
			house: '',
		},
		resolver: yupResolver(schema),
	})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		if (_.isEmpty(userInfo.company)) {
			navigate('/my-company/profileCompany')
		}
	}, [])

	const onSubmit = (data: FormCompanyAddressData) => {
		const request = {
			...data,
		}

		if (userInfo.company) {
			dispatch(
				setCompanyDepartmentAddress({
					request: {
						company_id: userInfo.company.id,
						address: request,
					},
					callback: {
						success: () => {
							dispatch(
								getCompanyDepartmentAddressList({
									request: { company_id: userInfo.company.id },
									callback: {},
								})
							)
							reset()
							NOTIFICATION.success(t('New address successfully added'))
							onCancel && onCancel()
						},
					},
				})
			)
			return
		}
	}

	return (
		<form className='form form-company-contacts'>
			<div className='form__body'>
				<div className='form__sub-content'>
					<RenderFields
						items={addressFields}
						register={register}
						control={control}
						errors={errors}
					/>
				</div>
			</div>
			<div className='form-footer form-footer_submit'>
				<button
					className='btn-default btn-transparent'
					type={undefined}
					onClick={e => {
						e.preventDefault()
						onCancel && onCancel()
					}}
				>
					{t('Cancel')}
				</button>
				<ButtonDefault name={t('Add')} type='submit' action={handleSubmit(onSubmit)} />
			</div>
		</form>
	)
}

export default FormCompanyAddress
