import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { RegistrationData } from 'types/api'
import IconEye from '@assets/icons/inputs/eye'
import IconEyeClose from '@assets/icons/inputs/eyeClose'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault from '@atoms/inputs'
import GeneratePassword from '@molecules/generatePassword'
import { registration } from '@redux/reducers/authSlice'
import i18n from '@src/i18n'

// helpers
import { consts } from '@tools/consts'
import usePasswordHook from '@tools/hooks/forms/usePasswordHook'
import { regSchema } from '@tools/shemas'

import './style.scss'

const Registration: FC = () => {
	const { loading } = useSelector((state: stateType) => state.auth)
	const schema = useMemo(() => regSchema(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		//getValues,
		setValue,
		formState: { errors },
	} = useForm<RegistrationData>({
		defaultValues: {
			email: '',
			password: '',
			password_repeat: '',
		},
		resolver: yupResolver(schema),
	})

	const { passwordType: passwordType, changePasswordType: changePasswordType } = usePasswordHook()
	const { passwordType: passwordRepeatType, changePasswordType: changePasswordRepeatType } =
		usePasswordHook()

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onSubmit = async (data: RegistrationData) => {
		const { email, password } = data

		const request = {
			email: email,
			password: password,
			ref: consts.domain,
		}

		dispatch(registration({ ...request, callback: () => console.log('ads') }))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form reg-form'>
			<div className='form__container'>
				<InputDefault
					name={'email'}
					label={t('E-mail')}
					placeholder={t('E-mail address')}
					error={errors.email?.message}
					register={register}
					control={control}
				/>
			</div>
			<div className='form__container'>
				<InputDefault
					name={'password'}
					label={t('Create a password')}
					placeholder={t('Enter your password')}
					error={errors.password?.message}
					register={register}
					control={control}
					type={passwordType}
				/>
				<div className='form__container-icons'>
					<GeneratePassword setValue={setValue} />
					<div className='form__container-icon' onClick={changePasswordType}>
						{passwordType === 'input' ? <IconEye /> : <IconEyeClose />}
					</div>
				</div>
			</div>

			<div className='form__container'>
				<InputDefault
					name={'password_repeat'}
					label={t('Repeat password')}
					placeholder={t('Repeat your password')}
					error={errors.password_repeat?.message}
					register={register}
					control={control}
					type={passwordRepeatType}
				/>
				<div className='form__container-icons'>
					<div className='form__container-icon' onClick={changePasswordRepeatType}>
						{passwordRepeatType === 'input' ? <IconEye /> : <IconEyeClose />}
					</div>
				</div>
			</div>

			<div className='form__container'>
				<ButtonDefault
					name={t('Register')}
					type={'submit'}
					loading={loading}
					disabled={loading}
				/>
			</div>
		</form>
	)
}

export default Registration
