import React from 'react'
import type { FC } from 'react'

const IconSeccess: FC = () => (
	<svg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M36 3C17.775 3 3 17.775 3 36C3 54.225 17.775 69 36 69C54.225 69 69 54.225 69 36C69 17.775 54.225 3 36 3ZM50.304 30.42C50.5674 30.1189 50.7679 29.7682 50.8938 29.3885C51.0196 29.0088 51.0682 28.6077 51.0368 28.209C51.0054 27.8102 50.8945 27.4217 50.7107 27.0664C50.5269 26.7111 50.274 26.3961 49.9666 26.14C49.6593 25.8839 49.3039 25.6919 48.9213 25.5752C48.5387 25.4585 48.1366 25.4195 47.7387 25.4605C47.3407 25.5015 46.955 25.6216 46.6042 25.8139C46.2534 26.0062 45.9446 26.2666 45.696 26.58L32.796 42.057L26.121 35.379C25.5552 34.8325 24.7974 34.5301 24.0108 34.537C23.2242 34.5438 22.4718 34.8593 21.9155 35.4155C21.3593 35.9718 21.0438 36.7242 21.037 37.5108C21.0301 38.2974 21.3325 39.0552 21.879 39.621L30.879 48.621C31.1738 48.9156 31.5267 49.1455 31.9152 49.2962C32.3038 49.4468 32.7195 49.5149 33.1358 49.4959C33.5521 49.477 33.9599 49.3716 34.3332 49.1863C34.7065 49.0011 35.0372 48.7401 35.304 48.42L50.304 30.42Z'
			fill='#58C65C'
		/>
	</svg>
)

export default IconSeccess
