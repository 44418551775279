import React from 'react'
import type { FC } from 'react'
import AppHeader from '@molecules/app/header'
import Menu from '@organisms/menu'

import './style.scss'

interface HomeTemplateType {
	children: React.ReactNode
}

const HomeTemplate: FC<HomeTemplateType> = ({ children }: HomeTemplateType) => (
	<div className='home-template'>
		<div className='home-template__side'>
			<Menu set={'default'} />
		</div>
		<div className='home-template__container'>
			<AppHeader />
			<div className='home-template__body'>{children}</div>
		</div>
	</div>
)

export default HomeTemplate
