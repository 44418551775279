import { Dropdown } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import type { stateType } from '@types'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import { IconArrowDropdown, IconBellDropdown } from '@assets/icons/btn/arrow'
import IconPlus from '@assets/icons/uploaders/plus'
import { setStatus } from '@redux/reducers/appSlice'
import { loginToCompany, logoutFromCompany } from '@redux/reducers/companySlice'
import { getUserInfo } from '@redux/reducers/userSlice'

import '../style.scss'

const DropdownChoiceCompany: FC = () => {
	const [open, setOpen] = useState<boolean>(false)
	const [name, setName] = useState<any>('')

	const { info } = useSelector((state: stateType) => state.user)
	const { list } = useSelector((state: stateType) => state.company)

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const action = (id: number) => {
		dispatch(setStatus('loading'))
		dispatch(
			loginToCompany({
				request: { company_id: id },
				callback: () => {
					dispatch(getUserInfo('7'))
				},
			})
		)
	}

	const logout = () => {
		dispatch(
			logoutFromCompany({
				request: { company_id: info.company.id },
				callback: () => {
					dispatch(setStatus('companies-profile'))
					navigate('/companies')
				},
			})
		)
	}

	const items: MenuProps['items'] = list
		? list.map(item => ({
				key: item.id,
				label: item.name,
				onClick: () => action(item.id),
		  }))
		: []

	items &&
		items.push({
			key: 'logout',
			label: (
				<div className='dropdown-overlay__item'>
					<div className='dropdown-overlay__add'>
						<IconPlus />
					</div>
					{t('Company')}
				</div>
			),
			onClick: () => logout(),
		})

	useEffect(() => {
		info.company && setName(info.company.name)
	}, [info?.company?.name])
	return (
		<Dropdown
			className={'dropdown'}
			menu={{ items }}
			trigger={['click']}
			open={open}
			onOpenChange={(data: any) => setOpen(data)}
		>
			<div
				className='dropdown__triger'
				onClick={e => {
					e.preventDefault()
					setOpen(prev => !!prev)
				}}
			>
				<div className='dropdown__prefix-icon'>
					<IconBellDropdown />
				</div>
				<span className='dropdown__name'>{name}</span>
				<div
					className={classNames(
						'dropdown__icon',
						{ ['animation-dropdown-up']: open },
						{ ['animation-dropdown-back']: !open }
					)}
				>
					<div className='dropdown__icon-arrow'>
						<IconArrowDropdown />
					</div>
				</div>
			</div>
		</Dropdown>
	)
}

export default DropdownChoiceCompany
