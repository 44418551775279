export const stepsTexts = {
	general: {
		title: 'Primary information',
		description:
			'Check the filled fields if everything is correct then proceed to the next step',
	},
	contacts: {
		title: 'Contact details',
		description:
			'Fill in all required fields to enter information about the Companys contact details',
	},
	structure: {
		title: 'The structure of the company',
		description: 'Fill in all required fields to enter information about the structure',
	},
	management: {
		title: 'HR management',
		description: 'Set up stages of personnel selection, training and development',
	},
	mission: {
		title: 'Company Principles',
		description: 'Set up the main purpose of the organization and the reason for its existence',
	},
	'company-create': {
		title: 'Company Creation',
		description: 'Fill in the required fields to enter primary information about the Company',
	},
	verification: {
		title: 'Company Confirmation',
		description: 'To confirm the activities of your Company',
	},
}
