import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import type { stateType } from '@types'
import type { FC } from 'react'

// import BtnGoBack from '@atoms/buttons/goBackButton'
// import CompanyInfo from '@organisms/lcVerification/companyInfo'
import ViewFilesModal from '@organisms/modals/viewFile/ViewFilesModal'
import { getCompany } from '@redux/reducers/lcVerificatorSlice'

const ViewFilesContainer: FC<{
	open?: boolean
	onClose?: () => void
}> = ({ open = true, onClose }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { id } = useParams()
	// const { company }: any = useSelector((state: stateType) => state.lcVerificator)
	const { sessionProperties } = useSelector((state: stateType) => state.user)

	const companyId = id || sessionProperties?.companyId

	useEffect(() => {
		dispatch(
			getCompany({
				request: {
					company_id: Number(companyId),
				},
			})
		)
	}, [])

	const handleClose = () => {
		if (onClose) {
			onClose()
		} else {
			navigate('/lc-companies')
		}
	}

	return (
		<Modal
			className='modal-file'
			open={open}
			footer={null}
			closable={false}
			centered
			onCancel={() => handleClose()}
			maskClosable={false}
		>
			<ViewFilesModal onClose={handleClose} />
		</Modal>
	)
}

export default ViewFilesContainer
