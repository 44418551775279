import { Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import IconWarning from '@assets/icons/modals/warning'
import ButtonDefault from '@atoms/buttons/button'
import { removeConfirmation } from '@configs/texts'

import './style.scss'

interface OutFromCreateCompanyModalType {
	component: any
	action?: any
	color?: any
	status: string
	submit?: boolean
	isVerified?: any
}

const RemoveFileConfirmation: FC<OutFromCreateCompanyModalType> = ({
	component: Component,
	action,
	color,
	status,
	submit,
}: OutFromCreateCompanyModalType) => {
	const [visible, setVisible] = useState(false)
	const [loader, setLoader] = useState(false)

	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
	}

	const call = () => {
		setLoader(true)
		setVisible(false)
		action(onCancel)
	}

	return (
		//
		<>
			<Component
				color={color}
				onClick={() => {
					setVisible(true)
				}}
			/>
			<Modal
				className='modal-confirm rr'
				open={visible}
				width={504}
				footer={null}
				closable={true}
				styles={{ body: { padding: '40px 30px' } }}
				centered
				onCancel={onCancel}
			>
				<div className='modal-confirm__header'>
					<IconWarning />
				</div>
				<div className='modal-confirm__body'>
					<div className='modal-confirm__title'>
						{t(removeConfirmation[status].title)}
					</div>
					<div className='modal-confirm__desc'>
						{t(removeConfirmation[status].description)}
					</div>
				</div>
				<div className='modal-confirm__btns'>
					{submit && (
						<ButtonDefault
							name={t('Delete')}
							type={'submit'}
							loading={loader}
							disabled={loader}
							action={call}
						/>
					)}
					<button className='btn-default btn-gray' onClick={onCancel}>
						{t('Cancel')}
					</button>
				</div>
			</Modal>
		</>
	)
}

export default RemoveFileConfirmation
