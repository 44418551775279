import { Button, Form, Input, message } from 'antd'
import { bool, func, number, shape } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanyInfo } from '@api/asyncHelpers'

// import { value } from 'lodash/seq';

import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import ChangeVerifyModal from '@organisms/modals/changeVerifyModal'
import { getCompanyInfoAction } from '@redux/actions/companyActions'
import { companyStatusText } from '@tools/constants'
import { rules } from '@tools/messages'

FormSetCompanyOther.propTypes = {
	changeStep: func,
	step: number,
	settings: shape({
		blockCompanyName: bool,
		blockCompanyKod: bool,
		hideOtherAddresses: bool,
	}),
}

function FormSetCompanyOther({ changeStep, step, settings }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { lists } = useSelector(state => state.dropDownList)
	const { info: company, error } = useSelector(state => state.company)
	const [form] = Form.useForm()

	const [isValidateCompanyName, setIsValidateCompanyName] = useState(true)
	const [isValidateCompanyKod, setIsValidateCompanyKod] = useState(true)

	const [visible, setVisible] = useState(false)

	const validationText = {
		name: t('The value does not match the recorded company name'),
		kod: t('The value does not correspond to the specified company EDRPOU code'),
	}

	const handleOnValidate = (value, name) => {
		if (company?.companyName && name === 'companyName') {
			if (company?.companyName !== value.toString()) {
				setIsValidateCompanyName(false)
			} else {
				setIsValidateCompanyName(true)
			}
		}

		if (company?.kod && name === 'kod') {
			if (company?.kod.toString() !== value.toString()) {
				setIsValidateCompanyKod(false)
			} else {
				setIsValidateCompanyKod(true)
			}
		}
	}

	const onFinish = async () => {
		if (!isValidateCompanyName || !isValidateCompanyKod) {
			setVisible(!visible)
		} else {
			const key = 'update-company'
			message.loading({ content: t('Loading'), key })
			try {
				const json = await setCompanyInfo(form.getFieldsValue())
				await dispatch(getCompanyInfoAction())
				if (json.ok) {
					message.success({ content: t('Updated company data'), key })
					await dispatch(getCompanyInfoAction())
					changeStep(step + 1)
				} else {
					message.error({ content: json.message, key })
				}
			} catch (e) {
				message.error({ content: e.message, key })
			}
		}
	}
	useEffect(() => {
		if (company && !error) {
			company?.companyName &&
				form.setFields([{ name: 'companyName', value: company.companyName }])
			company?.companyBrand &&
				form.setFields([{ name: 'companyBrand', value: company.companyBrand }])
			company?.kod && form.setFields([{ name: 'kod', value: Number(company.kod) }])
			company?.s_activity?.name &&
				form.setFields([{ name: 's_activity', value: company.s_activity.name }])
			company?.services && form.setFields([{ name: 'services', value: company.services }])
		}
	}, [company])

	return (
		<>
			<ChangeVerifyModal
				visible={visible}
				changeVisible={setVisible}
				text={companyStatusText.RESET}
			/>
			<Form
				name='set-company-other'
				layout='vertical'
				size='large'
				onFinish={onFinish}
				form={form}
				className='form'
			>
				<AppText
					text={t('General')}
					style={{
						color: '#20272E',
						fontSize: 18,
						fontWeight: 700,
						marginBottom: 24,
						lineHeight: 1.5715,
						display: 'block',
					}}
				/>

				<Form.Item
					validateStatus={!isValidateCompanyName ? 'warning' : 'success'}
					help={!isValidateCompanyName ? validationText.name : null}
					name='companyName'
					label='Company Name'
					rules={[{ required: true, message: rules.required }]}
				>
					<Input
						disabled={settings && settings.blockCompanyName}
						onChange={e => handleOnValidate(e.target.value, 'companyName')}
						className='form__input'
						placeholder={t('LLC Company')}
					/>
				</Form.Item>

				<Form.Item name='companyBrand' label={t('Trademark Name')}>
					<Input className='form__input' placeholder={t('Trademark Name')} />
				</Form.Item>

				<Form.Item
					name='kod'
					label={t('EDRPOU code/TIN FOP')}
					validateStatus={!isValidateCompanyKod ? 'warning' : 'success'}
					help={!isValidateCompanyKod ? validationText.kod : null}
					rules={[{ required: true, message: rules.required }]}
				>
					<Input
						disabled={settings && settings.blockCompanyKod}
						onChange={e => handleOnValidate(e.target.value, 'kod')}
						type='number'
						className='form__input'
						placeholder='1234567'
					/>
				</Form.Item>

				<Form.Item
					name='s_activity'
					label={t('Field of activity')}
					rules={[{ required: true, message: rules.required }]}
				>
					<AppSelect options={lists?.s_activity} placeholder={t('Field of activity')} />
				</Form.Item>

				<Form.Item
					name='services'
					label={t('Services / Product')}
					rules={[{ required: true, message: rules.required }]}
				>
					<Input className='form__input' placeholder={t('Services')} />
				</Form.Item>

				<Form.Item>
					<div className='form__row _right'>
						<Button type='primary' shape='round' htmlType='submit'>
							{t('Save and continue')}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</>
	)
}

export default FormSetCompanyOther
