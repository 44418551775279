import Sider from 'antd/es/layout/Sider'
import React from 'react'
import type { FC } from 'react'

interface AppSiderType {
	children?: React.ReactNode
}

const AppSider: FC<AppSiderType> = ({ children }: AppSiderType) => (
	<Sider theme='light' trigger={null} collapsible className='sider _app-sider'>
		{children}
	</Sider>
)

export default AppSider
