export const departmentFields = [
	{
		name: 'name',
		label: 'Department name',
		placeholder: 'Write the name of the department',
		required: true,
		className: '',
	},
	{
		name: 'manager_position',
		label: 'Manager position title',
		placeholder: 'Supervisor',
		required: true,
		className: '',
	},
	{
		name: 'manager_lname',
		label: 'Last name',
		placeholder: 'Last name',
		required: true,
		className: 'w-176',
	},
	{
		name: 'manager_name',
		label: 'Name',
		placeholder: 'Name',
		required: true,
		className: 'w-176',
	},
	{
		name: 'manager_sname',
		label: 'Patronymic',
		placeholder: 'Patronymic',
		className: 'w-176',
	},
	{
		name: 'address_id',
		label: 'Address of the Department Directions Divisions',
		placeholder: 'Department name',
		required: true,
		optionKey: 'addressDepartment',
		fieldType: 'selectDefault',
		addButton: 'address',
		className: '',
		removeButton: 'address',
	},
]
