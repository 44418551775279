import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import type { stateType } from '@types'
import { setAppTitles } from '@redux/reducers/appSlice'

type TypeTitles = {
	title: string
	subTitle: string
}

const useAsideHook = (page?: string): TypeTitles => {
	const { appTitles } = useSelector((state: stateType) => state.app)
	const [titles, setTitles] = useState<{ title: string; subTitle: string }>({
		title: appTitles ? appTitles.title : '',
		subTitle: appTitles ? appTitles.subTitle : '',
	})

	const { t } = useTranslation()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setAppTitles(titles))
	}, [titles])

	useEffect(() => {
		switch (page) {
			case 'login':
				return setTitles({
					title: t('Sign in platform'),
					subTitle: t('Sign in description'),
				})
			case 'registration':
				return setTitles({
					title: t('Account registration'),
					subTitle: t('Account registration description'),
				})
			case 'user-registration':
				return setTitles({
					title: t('User registration'),
					subTitle: t('User registration description'),
				})
			case 'forgot':
				return setTitles({
					title: t('Retrieve password'),
					subTitle: t('Retrieve password description'),
				})

			case 'recovery':
				return setTitles({
					title: t('Password recovery'),
					subTitle: t('Recover your password'),
				})

			case 'confirm-phone':
				return setTitles({
					title: t('Phone Verification'),
					subTitle: t('We sent an SMS with a 6-digit code'),
				})

			case 'companies-profile':
				return setTitles({
					title: t('Company Profile'),
					subTitle: t('Company Profile description'),
				})

			case 'company-create':
				return setTitles({
					title: t('Company Registration'),
					subTitle: t('Company Registration description'),
				})

			case 'company-verification':
				return setTitles({
					title: t('Company Registration'),
					subTitle: t('Verify company data about your Company'),
				})

			case '404':
				return setTitles({
					title: '404',
					subTitle: t('Page not found...'),
				})

			case 'company-registration-step-1':
				return setTitles({
					title: t('Company Registration'),
					subTitle: t(
						'Fill out information about your company to start using all the advantages of the platform:'
					),
				})

			case 'company-registration-step-2':
				return setTitles({
					title: t('Registration Administrator'),
					subTitle: t(
						'Fill in information about yourself to start using all the advantages of the platform:'
					),
				})

			case 'company-confirm':
				return setTitles({
					title: t('Continuation of registration'),
					subTitle: t('Verify the companys data to get full access to the platform:'),
				})

			case 'add-deputy':
				return setTitles({
					title: t('Registration of the deputy'),
					subTitle: t(
						'Assign the deputy to give him access to the functionality of the platform:'
					),
				})

			case 'company-setting':
				return setTitles({
					title: t('Completing a company profile'),
					subTitle: t(
						'Fill in information about the Company and use all the functionality of the platform'
					),
				})

			default:
				return setTitles({
					title: t('Sign in platform'),
					subTitle: t('Sign in description'),
				})
		}
	}, [page, t])
	return titles
}

export default useAsideHook
