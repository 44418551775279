export const managementFields = [
	{
		name: 'selection_stages',
		label: 'Selection stages',
		placeholder: 'Enter a name or select from the list',
		required: false,
		optionKey: 'selection_stages',
		fieldType: 'selectDragDrop',
	},
	{
		name: 'is_training',
		label: 'Personnel training and development',
		placeholder: '',
		required: false,
		fieldType: 'switch',
	},
	{
		name: 'corporate_training',
		label: '',
		placeholder: 'Enter a name or select from the list',
		required: false,
		optionKey: 'corporate_training',
		fieldType: 'selectMultiple',
	},
	// {
	// 	name: 'fileList',
	// 	label: 'Personnel policy',
	// 	placeholder: 'Upload file',
	// 	fieldType: 'fileUpload',
	// 	required: false,
	// },
]
