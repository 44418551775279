import { Modal } from 'antd'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import FormCode from '@organisms/forms/code/confirmUpdate'
import { createPhoneMask } from '@tools/helpers'

import './style.scss'

interface ReVerificationCompanyModalType {
	component: any
	action?: any
	close?: any
	phone: string
	visibleDefault: boolean
}

const ReVerificationCompanyModal: FC<ReVerificationCompanyModalType> = ({
	action,
	phone,
	visibleDefault,
	close,
}: ReVerificationCompanyModalType) => {
	const [visible, setVisible] = useState(visibleDefault)
	const [loader, setLoader] = useState(false)

	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
		close()
	}

	const call = data => {
		setLoader(true)
		action(data.code)
		//setVisible(false)
	}

	return (
		<Modal
			className='modal-confirm modal-confirm__verification'
			open={visible}
			width={464}
			footer={null}
			closable={true}
			centered
			onCancel={onCancel}
		>
			<div className='modal-confirm__body'>
				<div className='modal-confirm__title'>{t('Re-verification of the Company')}</div>
				<div className='modal-confirm__desc'>
					{parse(
						t('We sent an SMS with a 6-digit code', {
							phone: phone ? createPhoneMask(phone) : '',
						})
					)}
				</div>
				<FormCode action={call} loading={loader} onCancel={onCancel} />
			</div>
		</Modal>
	)
}

export default ReVerificationCompanyModal
