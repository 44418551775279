import { AutoComplete, Button, Checkbox, Form, Input, message, Select } from 'antd'
import Text from 'antd/es/typography/Text'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { useSelector, useDispatch } from 'react-redux'

import type { stateType } from '@types'
import type { FC } from 'react'

import './style.scss'

import type { FormSetAdminType } from 'types/api'
import DropDownArrow from '@assets/icons/drop_down_arrow/dropDownArrow.jsx'
import AppText from '@atoms/text/AppText'
import { setCompanyAdmin } from '@redux/reducers/companySlice'
import { setUser } from '@redux/reducers/userSlice'
import { passwordGenerator } from '@tools/helpers'

const FormSetAdmin: FC<FormSetAdminType> = ({ setCurrent }: FormSetAdminType) => {
	const { t } = useTranslation()
	const [isAdmin, setIsAdmin] = useState<boolean>(true)
	const [newPassword, setNewPassword] = useState<string>('')

	const { info } = useSelector((state: stateType) => state.user)
	const { loading } = useSelector((state: stateType) => state.company)
	const { token } = useSelector((state: stateType) => state.auth)
	const key = 'set-admin'
	const dispatch = useDispatch()

	const [form] = Form.useForm()

	const onFinish = async () => {
		message.loading({ content: t('Loading...'), key })
		dispatch(
			setCompanyAdmin({
				data: form.getFieldsValue(),
				key,
				token: token,
				callback: () => {
					message.success({
						content: t('Administrator data has been successfully saved!'),
						key,
						duration: 4,
					})
					dispatch(setUser({ ...info, step: '3' }))
				},
			})
		)
	}

	useEffect(() => {
		if (isAdmin) {
			setIsAdmin(true)
			form.resetFields()
			form.setFields([
				{ name: 'email', value: info.email },
				{ name: 'tel', value: info.tel },
				{ name: 'first_name', value: info.name },
				{ name: 'surname', value: info.sname },
				{ name: 'middle_name', value: info.lname },
			])
		} else {
			setIsAdmin(false)
			const valueAdmin = form.getFieldValue('is_admin')
			form.resetFields()
			form.setFields([{ name: 'is_admin', value: valueAdmin }])
		}
	}, [isAdmin])

	const handleOnChangeAdmin = () => {
		const valueAdmin = form.getFieldValue('is_admin')
		valueAdmin === '0' ? setIsAdmin(true) : setIsAdmin(false)
	}

	return (
		<Form
			name='set-company-admin'
			initialValues={{
				is_admin: '0', // Передача прав Администратора
				// 0 - Назначить меня администратором Компании
				// 1 - Назначить другого человека администратором Компании
				first_name: '', // імя
				surname: '', // фамилия
				middle_name: '', // отчество
				tel: '', // Phone*
				email: '', // E-mail*
				password: '', // Password*
				confirm: '', // Подтвердите пароль*
				agreement: true, // Я принимаю Политику конфиденциальности и Условия пользования
			}}
			layout='vertical'
			size='large'
			onFinish={onFinish}
			form={form}
			className='form set-company-admin'
		>
			<AppText
				text={t('Administrator')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Form.Item
				name='is_admin'
				label={t('Transfer of Administrator rights')}
				className='form__container'
				rules={[{ required: true, message: t('Please enter Administrator!') }]}
			>
				<Select
					listHeight={300}
					placeholder={t('The number of employees')}
					suffixIcon={<DropDownArrow />}
					onChange={handleOnChangeAdmin}
				>
					<Select.Option value='0' style={{ borderBottom: '1px solid #D9DCF0' }}>
						<Text className='form__select_title'>
							{t('Appoint me as a Company administrator')}
						</Text>
						<Text className='form__select_disabled'>
							{t('You will be able to independently verify the company')}
						</Text>
					</Select.Option>
					<Select.Option value='1'>
						<Text className='form__select_title'>
							{t('Appoint another person as Company administrator')}
						</Text>
						<Text className='form__select_disabled'>
							{t('You will be added to the Company as a regular employee')}
						</Text>
					</Select.Option>
				</Select>
			</Form.Item>
			<div className='form__row'>
				<Form.Item
					className='form__container'
					label={t('Last name')}
					name='surname'
					rules={[{ required: true, message: t('Please enter your Last Name!') }]}
				>
					<Input className='form__input' placeholder={t('Last name')} />
				</Form.Item>

				<Form.Item
					className='form__container'
					label={t('Name')}
					name='first_name'
					rules={[{ required: true, message: t('Please enter a Name!') }]}
				>
					<Input className='form__input' placeholder={t('Name')} />
				</Form.Item>

				<Form.Item className='form__container' label={t('Patronymic')} name='middle_name'>
					<Input className='form__input' placeholder={t('Patronymic')} />
				</Form.Item>
			</div>
			<div className='form__row'>
				<Form.Item
					className='form__container'
					label={t('Phone')}
					name='tel'
					rules={[{ required: true, message: t('Please enter your phone number!') }]}
				>
					<InputMask
						placeholder='+380'
						className='ant-input ant-input-lg form__input'
						mask='+380 99 99 99 999'
					/>
				</Form.Item>

				<Form.Item
					className='form__container'
					label={t('E-mail')}
					name='email'
					rules={[
						{ type: 'email', message: t('Invalid address!') },
						{ required: !isAdmin, message: t('Please enter your Email address!') },
					]}
				>
					<Input
						disabled={isAdmin}
						className='form__input'
						placeholder='new_email@gmail.com'
					/>
				</Form.Item>
			</div>

			<div className='form__row'>
				<Form.Item
					className='form__container'
					name='password'
					label={t('Password')}
					rules={[{ required: !isAdmin, message: t('Please enter your password!') }]}
				>
					<AutoComplete
						disabled={isAdmin}
						options={[{ value: newPassword, option: '1' }]}
						onFocus={() => newPassword || setNewPassword(passwordGenerator())}
						onSelect={(value: any) =>
							form.setFields([
								{ name: 'password', value, errors: [], touched: true },
								{ name: 'confirm', value, errors: [], touched: true },
							])
						}
					>
						<Input.Password
							disabled={isAdmin}
							className='form__input'
							placeholder={t('Enter your password')}
						/>
					</AutoComplete>
				</Form.Item>

				<Form.Item
					className='form__container'
					name='confirm'
					label={t('Confirm the password')}
					rules={[
						{ required: !isAdmin, message: t('Please enter your password!') },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve()
								}
								return Promise.reject(new Error('Passwords do not match!'))
							},
						}),
					]}
				>
					<Input.Password
						disabled={isAdmin}
						className='form__input'
						placeholder={t('Enter your password')}
					/>
				</Form.Item>
			</div>

			<Form.Item
				className='form__container'
				name='agreement'
				valuePropName='checked'
				rules={[
					{
						validator: (_, value) =>
							value
								? Promise.resolve()
								: Promise.reject(new Error(t('The agreement must be accepted!'))),
					},
				]}
			>
				<Checkbox className='form__input-checkbox '>
					<span className='_required '>
						{t('I accept')} <span className='link _bold'>{t('Privacy Policy')}</span>{' '}
						{t('and')} <span className='link _bold'>{t('Terms of use')}</span>
					</span>
				</Checkbox>
			</Form.Item>

			<Form.Item className='form__container'>
				<div className='form__row _between'>
					<Button
						disabled={loading}
						type='default'
						htmlType='button'
						onClick={() => setCurrent(1)}
					>
						{t('Back')}
					</Button>
					<Button type='primary' shape='round' htmlType='submit' disabled={loading}>
						{t('Save and continue')}
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default FormSetAdmin
