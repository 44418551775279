import { Col, Row, Tabs } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'

const PositionFunctionalityModalHeader: FC<any> = ({ positionData }: any) => {
	const { t } = useTranslation()
	const { changeTabIndex } = positionData
	return (
		<Row>
			<Col span={12}>
				<Tabs
					items={[
						{
							label: t('All'),
							key: '1',
						},
						{
							label: t('Database'),
							key: '2',
						},
						{
							label: t('My'),
							key: '3',
						},
					]}
					className='functionality-modal__header'
					onChange={index => changeTabIndex(index)}
				/>
			</Col>
		</Row>
	)
}

export default PositionFunctionalityModalHeader
