import React from 'react'

const V3 = () => (
	<svg
		width='286'
		height='106'
		viewBox='0 0 286 106'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M45.1 106V83.4H0.699951V65.3L51.6 0H65.3999V65.2H78.7999V83.4H65.3999V106H45.1ZM21.1 65.2H47.3999V30.8L21.1 65.2Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M251.6 106V83.4H207.2V65.3L258.1 0H271.9V65.2H285.3V83.4H271.9V106H251.6ZM227.6 65.2H253.9V30.8L227.6 65.2Z'
			fill='white'
		/>
	</svg>
)

export default V3
