import { yupResolver } from '@hookform/resolvers/yup'

import dayjs from 'dayjs'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'

import './style.scss'

import type { FormUserRegistrationData } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import BtnLogout from '@atoms/buttons/logoutButton'
import InputDefault, { InputDatePicker, InputMaskDefault, InputSelectDefault } from '@atoms/inputs'
import LogoutFromSystemModal from '@organisms/modals/confirms/logoutFromSystem'
import { setStatus, setSuccess } from '@redux/reducers/appSlice'
import { profileCreate, sendSmsOnPhone, updateUser } from '@redux/reducers/userSlice'
import i18n from '@src/i18n'
import useLogout from '@tools/hooks/useLogout'
import { regUserSchema } from '@tools/shemas'

const FormUserRegistration: FC = () => {
	const { info } = useSelector((state: stateType) => state.user)
	const { options } = useSelector((state: stateType) => state.app)
	const { birth_date, lname, name, sname, gender_id, phone } = info
	const schema = useMemo(() => regUserSchema(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		getValues,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			birth_date: birth_date ? dayjs(birth_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
			lname: lname ? lname : '',
			name: name ? name : '',
			sname: sname ? sname : '',
			gender_id: gender_id ? gender_id : undefined,
			phone: phone ? phone : '',
		},
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		phone && setValue('phone', phone)
	}, [info])

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { logout } = useLogout()

	const watchPhone = watch('phone')

	const onSubmit = async (data: FormUserRegistrationData) => {
		const request = {
			...data,
			phone: info?.is_confirm_phone ? data.phone : data.phone.replace(/[^\d]/g, '').slice(2),
			birth_date: dayjs(data.birth_date).format('YYYY-MM-DD'),
		}

		dispatch(
			profileCreate({
				request,
				callback: () => {
					localStorage.setItem('confirm-phone', '')
					dispatch(setStatus('success'))
					dispatch(setSuccess({ type: 'user-registration' }))
					//request.phone && navigate('/confirm-phone');
					//!request.phone && navigate('/');
				},
			})
		)
	}

	const prepareForConfirmPhone = () => {
		dispatch(
			sendSmsOnPhone({
				request: {
					phone: getValues('phone')
						.replace(/[^\d]/g, '')
						.slice(watchPhone[0] === '+' ? 2 : 0),
				},
				callback: () => {
					dispatch(
						updateUser({
							name: getValues('name'),
							lname: getValues('lname'),
							sname: getValues('sname'),
							birth_date: getValues('birth_date'),
							phone: getValues('phone'),
							gender_id: getValues('gender_id'),
						})
					)
					localStorage.setItem(
						'confirm-phone',
						`${getValues('phone')
							.replace(/[^\d]/g, '')
							.slice(watchPhone[0] === '+' ? 2 : 0)}`
					)
					navigate('/confirm-phone')
				},
			})
		)
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className='form user-registration-form'>
				<div className='form__body'>
					<div className='form__container-columns'>
						<InputDefault
							name={'lname'}
							label={t('Last name')}
							placeholder={t('Last name')}
							error={errors.lname?.message}
							register={register}
							control={control}
							required
						/>
						<InputDefault
							name={'name'}
							label={t('Name')}
							placeholder={t('Name')}
							error={errors.name?.message}
							register={register}
							control={control}
							required
						/>
						<InputDefault
							name={'sname'}
							label={t('Patronymic')}
							placeholder={t('Patronymic')}
							error={errors.sname?.message}
							register={register}
							control={control}
						/>
					</div>
					<div className='form__container'>
						<InputDatePicker
							name={'birth_date'}
							label={t('Date of birth')}
							placeholder={'10/08/1983'}
							error={errors.birth_date?.message}
							register={register}
							control={control}
							required
						/>
					</div>
					<div className='form__container'>
						<InputSelectDefault
							name={'gender_id'}
							optionKey={'gender'}
							label={t('Gender')}
							placeholder={t('Choose gender')}
							error={errors.gender_id?.message}
							register={register}
							control={control}
							options={options?.gender ? options?.gender : []}
							required
						/>
					</div>
					<div className='form__container'>
						<InputMaskDefault
							name={'phone'}
							label={t('Phone')}
							placeholder={t('Phone')}
							error={errors.phone?.message}
							register={register}
							control={control}
							disabled={info?.is_confirm_phone ? true : 0}
							required
						/>

						{watchPhone.replace(/[^\d]/g, '').slice(watchPhone[0] === '+' ? 2 : 0)
							.length === 10 && (
							<span className='confirm-phone' onClick={prepareForConfirmPhone}>
								{t('Verify phone')}
							</span>
						)}
					</div>
					<div className='form__container'>
						<InputDefault
							label={'E-Mail'}
							placeholder={'email'}
							defaultValue={info?.email}
							disabled
						/>
					</div>
				</div>
				<div className='form-footer form-footer_submit'>
					<LogoutFromSystemModal
						component={BtnLogout}
						action={() => {
							logout()
						}}
					/>
					<ButtonDefault name={t('Next')} type={'submit'} />
				</div>
			</form>
		</>
	)
}

export default FormUserRegistration
