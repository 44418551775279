import { Button, Divider, Empty, Input, message, Select, Spin, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropDownArrow from '@assets/icons/drop_down_arrow/dropDownArrow.jsx'
import SelectRemoveIcon from '@assets/icons/select/SelectRemoveIcon.jsx'

const TooltipSelectOptionHelp = ({ help, children }: any) => (
	<Tooltip
		title={<span style={{ fontSize: 12 }}>{help}</span>}
		placement='left'
		style={{ fontSize: 12 }}
	>
		{children}
	</Tooltip>
)

function AppSelect(props: any) {
	const { t } = useTranslation()
	const {
		placeholder,
		options,
		mode,
		isHaveFooter,
		isHaveModal,
		modalBtnTxt,
		changeVisible,
		isLoading,
		onSubmit,
		notFoundDescription,
		isIdValue,
		...rest
	} = props
	const [name, setName] = useState('')
	const [loading, setLoading] = useState(false)
	const handleChange = (value: any) => setName(value)

	const onFinish = async () => {
		const el = options?.find(
			(item: any) => item.name.toLowerCase().trim() === name.toLowerCase().trim()
		)
		if (!el) {
			setLoading(true)
			try {
				await onSubmit(name.trim())
			} catch (err: any) {
				console.error(err)
				message.error(err.message)
			} finally {
				setLoading(false)
				setName('')
			}
		} else {
			message.warning({ content: t('This name already exists in the database'), duration: 4 })
		}
	}

	return (
		<Select
			className={`${mode === 'multiple' && 'form__select-multiple'}`}
			maxTagCount={mode === 'multiple' && 'responsive'}
			style={{ width: '100%' }}
			allowClear
			showSearch
			notFoundContent={
				isLoading ? (
					<Spin size='small' />
				) : (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={notFoundDescription} />
				)
			}
			onDropdownVisibleChange={open => !open && setName('')}
			mode={mode}
			placeholder={placeholder}
			menuItemSelectedIcon={null}
			suffixIcon={<DropDownArrow />}
			removeIcon={<SelectRemoveIcon />}
			dropdownRender={menu => (
				<>
					{menu}
					{isHaveFooter && (
						<>
							<Divider style={{ margin: '4px 0' }} />
							{isHaveModal ? (
								<span className='select-modal-btn' onClick={() => changeVisible()}>
									{modalBtnTxt}
								</span>
							) : (
								<div className='form__row' style={{ gap: 6, padding: '6px 12px' }}>
									<Input
										size='middle'
										value={name}
										onChange={e => handleChange(e.target.value)}
									/>
									<Button
										loading={loading}
										style={{ height: 28, padding: '0 24px' }}
										onClick={onFinish}
										type='primary'
										disabled={!name}
									>
										{t('Add')}
									</Button>
								</div>
							)}
						</>
					)}
				</>
			)}
			{...rest}
		>
			{options &&
				options.map((item: any) => (
					<Select.Option
						key={item.id || item.name}
						selected={false}
						disabled={item.disabled}
						className={mode === 'multiple' && 'multiple-select-option'}
						value={isIdValue ? item.id : item.name}
					>
						<>
							{item.help ? (
								<TooltipSelectOptionHelp help={item.help}>
									{item.name}
								</TooltipSelectOptionHelp>
							) : (
								item.name
							)}
							{/*{item.name}*/}
							{item.description && (
								<div className='select__description'>
									<span className='select__description-name'>
										{item.description}
									</span>
								</div>
							)}
						</>
					</Select.Option>
				))}
		</Select>
	)
}

export default AppSelect
