import { t } from 'i18next'

export const oldCompanyStatus = {
	PROGRESS: 'PROGRESS',
	SUCCESS: 'SUCCESS',
	FAILED: 'FAILED',
	DONE: 'DONE',
	RESET: 'RESET',
}

export const companyStatus = {
	NEW: 1,
	UNDER_REVIEW: 2,
	REJECTED: 3,
	ACTIVE: 4,
	INACTIVE: 5,
}

export const companyStatusText = {
	PROGRESS: t(
		'Thanks for the confirmation of the company.Verification of information will take up to 2 hours.'
	),
	SUCCESS: t(
		'Your company is successfully verified!Fill out information about your company or appoint your deputy to continue.'
	),
	FAILED: t(
		'Your company is not verified.Please check if information about the company was filled correctly.'
	),
	RESET: t(
		'Your company will be sent for repeated verification.Are you sure you want to make these changes?'
	),
}

export const companyAddDeputyText = {
	titleReplace: t('Editing a deputy'),
	titleAdd: t('Registration of the deputy'),
	subTitle: t('Assign the deputy to give him access to the functionality of the platform:'),
}
export const addItemsTypes = {
	POSITION: 'tl_dolgnost',
	FUNCTIONALITY: 'tl_task',
	TARGET: 'tl_target',
	KPI: 'tl_kpi',
	RESULT: 'tl_result',
	ZUN: 'tl_zun',
	CASE: 'tl_case',
}
export const removeItemsTypes = {
	FUNCTIONALITY: 'id_task',
	TARGET: 'id_target',
	KPI: 'id_kpi',
	RESULT: 'id_result',
	ZUN: 'id_zun',
	CASE: 'id_case',
}

export const statuses = {
	WARNING: 'warning',
	SUCCESS: 'success',
	ERROR: 'error',
}
