import { AutoComplete, Button, Col, Form, Input, Row } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'
import AppText from '@atoms/text/AppText'
import AppSelect from '@molecules/app/select'
import { passwordGenerator } from '@tools/helpers'

const DeputyForm: FC<any> = ({ form, isSubmit, onSubmit }: any) => {
	const { t } = useTranslation()
	const { lists } = useSelector((state: stateType) => ({
		lists: state.app.options,
	}))

	const [newPassword, setNewPassword] = useState('')

	const onFinish = () => {
		onSubmit && onSubmit(form.getFieldsValue())
	}

	return (
		<Form name='add-deputy' layout='vertical' size='large' form={form} onFinish={onFinish}>
			<AppText
				text={t('Deputies')}
				style={{
					color: '#20272E',
					fontSize: 18,
					fontWeight: 700,
					marginBottom: 24,
					lineHeight: 1.5715,
					display: 'block',
				}}
			/>

			<Row gutter={[24, 0]}>
				<Col span={8}>
					<Form.Item
						label={t('Last name of the Deputy')}
						name='last_name'
						rules={[{ required: true, message: t('Enter Last Name!') }]}
					>
						<Input className='form__input' placeholder={t('Last name')} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label={t('Deputy Name')}
						name='first_name'
						rules={[{ required: true, message: t('Enter your name!') }]}
					>
						<Input className='form__input' placeholder={t('Name')} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label={t('Middle name of the Deputy')} name='middle_name'>
						<Input className='form__input' placeholder={t('Patronymic')} />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col span={12}>
					<Form.Item
						label={t('Phone')}
						name='tel'
						rules={[{ required: true, message: t('Please enter your phone number!') }]}
						initialValue=''
					>
						<InputMask
							placeholder='+380'
							className='ant-input ant-input-lg form__input'
							mask='+380 99 99 99 999'
							maskChar=' '
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label={t('E-mail')}
						name='email'
						rules={[
							{ type: 'email', message: t('Invalid address!') },
							{ required: true, message: t('Please enter your Email address!') },
						]}
					>
						<Input className='form__input' placeholder='new_email@gmail.com' />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col span={12}>
					<Form.Item
						name='password'
						label={t('Password')}
						rules={[{ required: true, message: t('Please enter your password!') }]}
					>
						<AutoComplete
							options={[{ value: newPassword, option: '1' }]}
							onFocus={() => newPassword || setNewPassword(passwordGenerator())}
							onSelect={(value: any) =>
								form.setFields([
									{ name: 'password', value, errors: [], touched: true },
									{ name: 'confirm', value, errors: [], touched: true },
								])
							}
						>
							<Input.Password
								className='form__input'
								placeholder={t('Enter your password')}
							/>
						</AutoComplete>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name='confirm'
						label={t('Confirm the password')}
						rules={[
							{ required: true, message: t('Please enter your password!') },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve()
									}
									return Promise.reject(new Error('Passwords do not match!'))
								},
							}),
						]}
					>
						<Input.Password
							className='form__input'
							placeholder={t('Enter your password')}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item name='s_access_z' label={t('Deputy access rights')}>
				<AppSelect
					options={lists?.s_access_z}
					placeholder={t('Permissions')}
					mode='multiple'
				/>
			</Form.Item>
			{isSubmit && (
				<Row justify='end'>
					<Col>
						<Button type='primary' shape='round' htmlType='submit'>
							{t('Save and continue')}
						</Button>
					</Col>
				</Row>
			)}
		</Form>
	)
}

export default DeputyForm
