import { Modal } from 'antd'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import IconWrong from '@assets/icons/modals/wrong'
import ButtonDefault from '@atoms/buttons/button'

import './style.scss'

interface RemoveLcCompanyModalType {
	component: any
	action?: any
}

const RemoveLcCompanyModal: FC<RemoveLcCompanyModalType> = ({
	component: Component,
	action,
}: RemoveLcCompanyModalType) => {
	const [visible, setVisible] = useState(false)
	const [_loader, setLoader] = useState(false)

	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
	}

	const call = () => {
		setLoader(true)
		action(onCancel)
	}

	return (
		<>
			<>
				<Component
					onClick={() => {
						setVisible(true)
					}}
				/>
				<Modal
					className='modal-confirm'
					open={visible}
					width={504}
					footer={null}
					closable={true}
					styles={{ body: { padding: '64px' } }}
					centered
					onCancel={onCancel}
				>
					<div className='modal-confirm__header'>
						<IconWrong />
					</div>
					<div className='modal-confirm__body'>
						<div className='modal-confirm__title'>{t('Removing the company')}</div>
						<div className='modal-confirm__desc'>
							{parse(t('Are you sure you want to remove the company?'))}
						</div>
						<div className='modal-confirm__btns'>
							<ButtonDefault action={call} name={t('Delete')} />
							<ButtonDefault
								action={onCancel}
								name={t('Cancel')}
								classes={'btn-gray'}
							/>
						</div>
					</div>
				</Modal>
			</>
		</>
	)
}

export default RemoveLcCompanyModal
