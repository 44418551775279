import classNames from 'classnames'
import parse from 'html-react-parser'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'

import './style.scss'
import { IconClose } from '@assets/icons/nav/usersTable'
import OutFromCreateCompanyModal from '@organisms/modals/confirms/outFromCreateCompany'
import { setStatus } from '@redux/reducers/appSlice'
import { logoutFromCompany } from '@redux/reducers/companySlice'
import { setUser } from '@redux/reducers/userSlice'

import useFormHeader from '@tools/hooks/forms/useFormHeader'

interface FormHeaderType {
	type: string
	data?: any
}

interface FormHeaderCompaniesType {
	title?: any
	desc?: any
	iconClosed?: any
	classes?: any
}

const FormHeader: FC<FormHeaderType> = ({ type, data }: FormHeaderType) => {
	const { titles } = useFormHeader(type, data)

	return (
		<div className='form-header'>
			<div className='form-header__title'>{titles.title}</div>
			<div className='form-header__desc'>{parse(titles.desc)}</div>
		</div>
	)
}

export default FormHeader

export const FormHeaderCompanies: FC<FormHeaderCompaniesType> = ({
	title,
	desc,
	iconClosed,
	classes,
}: FormHeaderCompaniesType) => {
	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const goBack = () => {
		if (userInfo?.company?.id) {
			dispatch(
				logoutFromCompany({
					request: { company_id: userInfo.company.id },
					callback: () => {
						dispatch(setUser({ ...userInfo, company: false }))
						dispatch(setStatus('companies-profile'))
						navigate('/companies')
					},
				})
			)
			return
		}

		dispatch(setStatus('companies-profile'))
		navigate('/companies')
	}

	return (
		<div className={classNames('simple-header', classes)}>
			<div className='simple-header__text'>
				{title && <div className='simple-header__title'>{title}</div>}
				{desc && <div className='simple-header__desc'>{desc}</div>}
			</div>

			{iconClosed && (
				<div className='simple-header__close'>
					<OutFromCreateCompanyModal component={IconClose} action={goBack} />
				</div>
			)}
		</div>
	)
}
