import { Button, Checkbox, Col, Divider, Input, message, Row } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import AppSearchInput from '@molecules/app/search'
import { useListFunctionality } from '@tools/hooks/useListFunctionality'

const PositionFunctionalityModalPositionItems: FC<any> = ({
	positionData,
	addItem,
	removeItem,
}: any) => {
	const { t } = useTranslation()
	const [isAdd, setIsAdd] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [name, setName] = useState('')
	const [searchItem, _setSearchItem] = useState('')

	const {
		allOptions,
		indeterminate,
		onChangeAllFunctionality,
		removeCheckListItem,
		checkAll,
		onChangeFunctionality,
		positionName,
		tabIndex,
	} = positionData

	const list = useListFunctionality(allOptions, tabIndex)
	const handleAddName = async () => {
		const props = {
			key: 'add',
			duration: 4,
		}
		if (name) {
			const indexEl = allOptions.findIndex(
				(el: any) => el.name.toLowerCase().trim() === name.toLowerCase().trim()
			)
			if (indexEl >= 0) {
				return message.warning({
					content: (
						<span>
							{t('Meaning')} <strong>{name}</strong> {t('Already added!')}
						</span>
					),
					...props,
				})
			}
			try {
				message.loading({
					content: (
						<span>
							{t('Trying to add a meaning')} <strong>{name}</strong> ...
						</span>
					),
					...props,
				})
				setIsLoading(true)
				await addItem(name)
				message.success({
					content: (
						<span>
							{t('Meaning')} <strong>{name}</strong> {t('added!')}
						</span>
					),
					...props,
				})
			} catch (err: any) {
				console.error(err)
				message.error({ content: `${t('Error')}: ${err.message}`, ...props })
			} finally {
				setIsLoading(false)
				setIsAdd(!isAdd)
				setName('')
			}
		} else setIsAdd(!isAdd)
	}

	const handleOnRemoveItem = async (id: any, name: any) => {
		const props = {
			key: 'remove',
			duration: 4,
		}
		message.loading({
			...props,
			content: (
				<span>
					{t('We are trying to remove the meaning')} <strong>{name}</strong> ...
				</span>
			),
		})
		try {
			await removeItem(id)
			removeCheckListItem(id)
			message.success({
				...props,
				content: (
					<span>
						{t('Meaning')} <strong>{name}</strong> {t('remove!')}
					</span>
				),
			})
		} catch (err: any) {
			console.error(err)
			message.error({ ...props, content: `${t('Error')}: ${err.message}` })
		}
	}

	return (
		<>
			<AppSearchInput />
			<div className='functionality-list'>
				<Row className='functionality-list__group_row'>
					<Col span={24} className='functionality-list__group_col'>
						<Checkbox
							className='functionality-list__item'
							indeterminate={indeterminate}
							onChange={e => onChangeAllFunctionality(e, tabIndex, list)}
							checked={checkAll}
						>
							<span className='functionality-list__name position-name'>
								{positionName}
							</span>
						</Checkbox>
						<span className='functionality-list__add' onClick={() => setIsAdd(!isAdd)}>
							{' '}
						</span>
					</Col>
				</Row>
				<Divider dashed style={{ margin: '5px 0 18px' }} />
				<Row gutter={[5, 5]}>
					{isAdd && (
						<div className='form__row' style={{ gap: 6, marginBottom: 16 }}>
							<Input
								size='small'
								value={name}
								onChange={e => setName(e.target.value)}
								style={{ height: 24 }}
							/>
							<Button
								size='small'
								onClick={() => handleAddName()}
								type='primary'
								style={{ fontSize: 10 }}
								disabled={isLoading}
								loading={isLoading}
							>
								{name ? t('Add') : t('Cancel')}
							</Button>
						</div>
					)}
					{list
						.filter(opt => opt?.name?.toLowerCase().includes(searchItem.toLowerCase()))
						.map(opt => (
							<Col key={opt.id} span={24} className='functionality-list__group_col'>
								<Checkbox
									onChange={() => onChangeFunctionality(opt.id)}
									className='functionality-list__item'
									value={opt.name}
									checked={opt.selected}
								>
									<span className='functionality-list__name'>{opt.name}</span>
								</Checkbox>
								{opt.isUserAdd && (
									<span
										className='functionality-list__icon'
										onClick={() => handleOnRemoveItem(opt.id, opt.name)}
									>
										{' '}
									</span>
								)}
							</Col>
						))}
				</Row>
			</div>
		</>
	)
}

export default PositionFunctionalityModalPositionItems
