import { Table, Typography } from 'antd'
import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { stateType } from '@types'
import type { FC } from 'react'

import { columns } from '@configs/tables/lcCompany'
import useCalculatePageSize from '@tools/hooks/useCalculatePageSize'
import useTablePagination from '@tools/hooks/useTablePagination'
import './style.scss'

const { Text } = Typography

const LcVerificationCompanyTable: FC = () => {
	const tableRef = useRef<HTMLTableElement | null>(null)
	const { company }: any = useSelector((state: stateType) => state.lcVerificator)
	const { t } = useTranslation()
	const [maxHeight, setMaxHeight] = useState(5)

	const excludeNewCompany = company?.history
		?.filter(item => item.status.id !== 1)
		.map((item, index) => ({
			...item,
			index: index + 1,
		}))

	const heightElement = 48
	const defaultTotal = excludeNewCompany?.length
	const calculatePageSize = useCalculatePageSize() - 1
	const defaultPageSize = defaultTotal > 0 && calculatePageSize > 0 ? calculatePageSize : 0
	const keyId = null

	const { pageIndex, loadingPages, handleChangePage, handleTableRender } = useTablePagination({
		defaultPageSize,
		defaultTotal,
		tableRef,
		keyId,
	})

	useEffect(() => {
		setMaxHeight(defaultPageSize * heightElement)
	}, [defaultPageSize])

	return (
		<div className='lc-verification-companies-table'>
			{defaultTotal ? (
				<div ref={tableRef as React.MutableRefObject<any>}>
					<Table
						onRow={handleTableRender}
						rowKey={'update_date'}
						showSorterTooltip={false}
						locale={{
							emptyText: (
								<div className='lc-verification-companies-table__empty'>
									{t('No data')}
								</div>
							),
						}}
						loading={loadingPages}
						pagination={
							defaultTotal > defaultPageSize && {
								className: 'table-default__pagination',
								defaultPageSize: defaultPageSize,
								pageSize: defaultPageSize,
								current: pageIndex,
								showSizeChanger: false,
								total: defaultTotal,
								onChange: handleChangePage,
							}
						}
						scroll={{ y: maxHeight, scrollToFirstRowOnChange: true }}
						className='lc-verification-companies-table__container'
						size='middle'
						columns={columns}
						dataSource={excludeNewCompany}
						sortDirections={['ascend', 'descend']}
					/>
				</div>
			) : (
				<Text className='lc-verification-companies-table__empty'>{t('No data')}</Text>
			)}
		</div>
	)
}

export default LcVerificationCompanyTable
