import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const AppBenefits: React.FC = () => {
	const benefits = [
		'HR Automation',
		'Integration with job portals',
		'Personnel assessment',
		'Sync with Outlook',
		'Data Security',
		'Resume Parsing',
		'Multi-language',
		'Reporting Designer',
	]

	const { t } = useTranslation()
	return (
		<div className='benefits'>
			<ul className='benefits__list'>
				{benefits.map((benefit, ind) => (
					<li key={ind} className='benefits__item'>
						<div className='benefits__icon' />
						{t(benefit)}
					</li>
				))}
			</ul>
		</div>
	)
}

export default AppBenefits
