import React from 'react'

function StepIconCurrent() {
	return (
		<svg
			width='23'
			height='23'
			viewBox='0 0 23 23'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx='10' cy='13' r='9' fill='#4E5AF2' stroke='white' strokeWidth='2' />
			<circle cx='13' cy='10' r='9' fill='#4E5AF2' stroke='white' strokeWidth='2' />
			<path
				d='M11.0001 12.78L8.2201 9.99999L7.27344 10.94L11.0001 14.6667L19.0001 6.66665L18.0601 5.72665L11.0001 12.78Z'
				fill='white'
			/>
		</svg>
	)
}

export default StepIconCurrent
