import React, { useEffect } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import type { FC } from 'react'
import IconTime from '@assets/icons/inputs/time'

import './style.scss'

const TimeCountdown: FC = () => {
	const { t } = useTranslation()
	const renderer = ({ /*hours*/ minutes, seconds, completed, api }: any) => {
		// api.start();

		if (completed) {
			return (
				<Link to='#' onClick={() => console.log('dispatch')}>
					{t('Resend code')}
				</Link>
			)
		} else {
			return <Beggin api={api} minutes={minutes} seconds={seconds} />
		}
	}
	return <Countdown date={Date.now() + 90000} renderer={renderer} autoStart={false} />
}

export default TimeCountdown

export const Beggin: React.FC<any> = ({ api, minutes, seconds /*time*/ }: any) => {
	const { t } = useTranslation()
	useEffect(() => {
		api.start()
	}, [])

	return (
		<div className='timeout-block'>
			<IconTime />
			{minutes > 0 && <div className='timeout-block__time'>{`${minutes} ${t('min')}`}</div>}
			{seconds > 0 && <div className='timeout-block__time'>{`${seconds} ${t('sec')}`}</div>}
		</div>
	)
}
