import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { FC } from 'react'

import './style.scss'

import type { FormCodeData, FormCodeType } from 'types/api'
import ButtonDefault from '@atoms/buttons/button'
import InputDefault from '@atoms/inputs'
import TimeCountdown from '@molecules/timeCountdown'
import i18n from '@src/i18n'
import { codeSchema } from '@tools/shemas'

const FormCode: FC<FormCodeType> = ({ action, loading, onCancel }: FormCodeType) => {
	const schema = useMemo(() => codeSchema(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			code: undefined,
		},
		resolver: yupResolver(schema),
	})

	const { t } = useTranslation()

	const onSubmit = (data: FormCodeData) => {
		action(data)
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className='form code-form'>
				<div className='form__container'>
					<InputDefault
						name={'code'}
						label={t('SMS code')}
						placeholder={t('Enter code')}
						error={errors.code?.message}
						register={register}
						control={control}
					/>
					<div className='form__container-links'>
						<TimeCountdown />
					</div>
				</div>
				<div className='form__container'>
					<ButtonDefault
						name={t('Confirm')}
						type={'submit'}
						loading={loading}
						disabled={loading}
					/>
					<ButtonDefault
						classes='btn-gray'
						name={t('Cancel')}
						//type={'default'}
						loading={loading}
						disabled={loading}
						action={onCancel && onCancel}
					/>
				</div>
			</form>
		</>
	)
}

export default FormCode
