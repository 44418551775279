import React from 'react'
import type { FC } from 'react'

const IconWrong: FC = () => (
	<svg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M36 3C44.7521 3 53.1458 6.47678 59.3345 12.6655C65.5232 18.8542 69 27.2479 69 36C69 44.7521 65.5232 53.1458 59.3345 59.3345C53.1458 65.5232 44.7521 69 36 69C27.2479 69 18.8542 65.5232 12.6655 59.3345C6.47678 53.1458 3 44.7521 3 36C3 27.2479 6.47678 18.8542 12.6655 12.6655C18.8542 6.47678 27.2479 3 36 3ZM36 31.9976L28.3346 24.3321C27.8038 23.8014 27.084 23.5032 26.3334 23.5032C25.5828 23.5032 24.8629 23.8014 24.3321 24.3321C23.8014 24.8629 23.5032 25.5828 23.5032 26.3334C23.5032 27.084 23.8014 27.8038 24.3321 28.3346L31.9976 36L24.3321 43.6654C24.0693 43.9282 23.8609 44.2402 23.7186 44.5836C23.5764 44.927 23.5032 45.295 23.5032 45.6666C23.5032 46.0383 23.5764 46.4063 23.7186 46.7497C23.8609 47.0931 24.0693 47.4051 24.3321 47.6679C24.5949 47.9307 24.9069 48.1391 25.2503 48.2814C25.5937 48.4236 25.9617 48.4968 26.3334 48.4968C26.705 48.4968 27.073 48.4236 27.4164 48.2814C27.7598 48.1391 28.0718 47.9307 28.3346 47.6679L36 40.0024L43.6654 47.6679C43.9282 47.9307 44.2402 48.1391 44.5836 48.2814C44.927 48.4236 45.295 48.4968 45.6666 48.4968C46.0383 48.4968 46.4063 48.4236 46.7497 48.2814C47.0931 48.1391 47.4051 47.9307 47.6679 47.6679C47.9307 47.4051 48.1391 47.0931 48.2814 46.7497C48.4236 46.4063 48.4968 46.0383 48.4968 45.6666C48.4968 45.295 48.4236 44.927 48.2814 44.5836C48.1391 44.2402 47.9307 43.9282 47.6679 43.6654L40.0024 36L47.6679 28.3346C47.9307 28.0718 48.1391 27.7598 48.2814 27.4164C48.4236 27.073 48.4968 26.705 48.4968 26.3334C48.4968 25.9617 48.4236 25.5937 48.2814 25.2503C48.1391 24.9069 47.9307 24.5949 47.6679 24.3321C47.4051 24.0693 47.0931 23.8609 46.7497 23.7186C46.4063 23.5764 46.0383 23.5032 45.6666 23.5032C45.295 23.5032 44.927 23.5764 44.5836 23.7186C44.2402 23.8609 43.9282 24.0693 43.6654 24.3321L36 31.9976Z'
			fill='#FF776F'
		/>
	</svg>
)

export default IconWrong
