// lazy
// const Home = React.lazy(() => impor'@pages/home'));

export type menuItemType = {
	key: number
	label: string
	disabled: boolean
	icon: string
	link: string
	component: string
}

export const menuLists: Array<menuItemType> = [
	{
		key: 1,
		label: 'Home',
		disabled: false,
		icon: 'HomeIcon',
		link: '/',
		component: 'Home',
	},
	{
		key: 2,
		label: 'Testing',
		disabled: false,
		icon: 'TestIcon',
		link: '/testing',
		component: 'Testing',
	},
	{
		key: 3,
		label: 'Personnel selection',
		disabled: true,
		icon: 'PersonnelSelectionIcon',
		link: '/personne',
		component: 'Personne',
	},
	{
		key: 4,
		label: 'Personnel adaptation',
		disabled: true,
		icon: 'StaffAdaptationIcon',
		link: '/staff-adaptation',
		component: 'StaffAdaptation',
	},
	{
		key: 5,
		label: 'Personnel assessment',
		disabled: true,
		icon: 'PersonnelEvaluationIcon',
		link: '/staff-assessment',
		component: 'StaffAssessment',
	},

	{
		key: 6,
		label: 'Evaluation of the performance',
		disabled: true,
		icon: 'PerformersEvaluationIcon',
		link: '/performers',
		component: 'Performens',
	},

	{
		key: 7,
		label: 'Corporate culture',
		disabled: true,
		icon: 'GameIcon',
		link: '/culture',
		component: 'Culture',
	},
	{
		key: 8,
		label: 'HR administration',
		disabled: true,
		icon: 'HrAdministrationIcon',
		link: '/hr-administration',
		component: 'HrAdministration',
	},

	{
		key: 9,
		label: 'Personnel training',
		disabled: true,
		icon: 'StaffTrainingIcon',
		link: '/staff-traning',
		component: 'StaffTraning',
	},
	{
		key: 10,
		label: 'HR analytics',
		disabled: true,
		icon: 'HrAnalyticsIcon',
		link: '/hr-analyrics',
		component: 'HrAnalytics',
	},
	{
		key: 11,
		label: 'Polls',
		disabled: true,
		icon: 'SurveysIcon',
		link: '/surveys',
		component: 'Surveys',
	},
	{
		key: 12,
		label: 'My company',
		disabled: false,
		icon: 'MyCompanyIcon',
		link: '/my-company',
		component: 'MyCompany',
	},
	{
		key: 13,
		label: 'My calendar',
		disabled: false,
		icon: 'CalendarIcon',
		link: '/my-calendar',
		component: 'MyCalendar',
	},
	{
		key: 14,
		label: 'My Tasks',
		disabled: true,
		icon: 'TasksIcon',
		link: '/tasks',
		component: 'Tasks',
	},
	{
		key: 15,
		label: 'Settings',
		disabled: false,
		icon: 'SettingsIcon',
		link: '/settings',
		component: 'Settings',
	},
]

export const menuLcVerificationLists: Array<menuItemType> = [
	{
		key: 1,
		label: 'Companies',
		disabled: false,
		icon: 'MyCompanyIcon',
		link: '/lc-companies',
		component: 'LcVerificationCompanies',
	},
	{
		key: 2,
		label: 'Settings',
		disabled: false,
		icon: 'SettingsIcon',
		link: '/lc-settings',
		component: 'LcVerificationSettings',
	},
]
