import { yupResolver } from '@hookform/resolvers/yup'

import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { stateType } from '@types'
import type { FC } from 'react'
import type { FormDepartmentData } from 'types/api'

import ButtonDefault from '@atoms/buttons/button'

import { departmentFields } from '@configs/formFields'
import RenderFields from '@organisms/forms/renderFields'
//import AddAddress from '@organisms/modals/confirms/addAddress'

import {
	getCompanyDepartmentAddressList,
	addCompanyDepartment,
	getCompanyDepartmentList,
	updateDataCreated,
} from '@redux/reducers/companySlice'
import { NOTIFICATION } from '@redux/sagas/notification'
import i18n from '@src/i18n'

import { setCompanyDepartmentSchema } from '@tools/shemas'

import './style.scss'
interface FormCompanyDepartmentProps {
	onCancel?: () => void
}

const FormCompanyDepartment: FC<FormCompanyDepartmentProps> = ({ onCancel }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { info: userInfo } = useSelector((state: stateType) => state.user)
	const { addressList, dataCreated } = useSelector((state: stateType) => state.company)

	const schema = useMemo(() => setCompanyDepartmentSchema(), [i18n.language])
	const {
		register,
		control,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: '',
			manager_position: '',
			manager_lname: '',
			manager_name: '',
			manager_sname: '',
			address_id: undefined,
		},
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (_.isEmpty(userInfo.company)) {
			navigate('/my-company/profileCompany')
		}
		dispatch(
			getCompanyDepartmentAddressList({
				request: { company_id: userInfo.company.id },
				callback: {},
			})
		)
		dispatch(
			updateDataCreated({
				request: {},
				callback: {},
			})
		)
	}, [])

	useEffect(() => {
		//addressList?.length > 0 &&
		dataCreated?.address && setValue('address_id', dataCreated?.address)
	}, [dataCreated?.address])

	const onSubmit = (data: FormDepartmentData) => {
		const request = {
			name: data.name,
			manager_position: data.manager_position,
			manager_name: data.manager_name,
			manager_sname: data.manager_sname,
			manager_lname: data.manager_lname,
		}

		if (data.address_id) request['address_id'] = data.address_id

		if (userInfo.company) {
			dispatch(
				addCompanyDepartment({
					request: {
						company_id: userInfo.company.id,
						department: request,
					},
					callback: {
						success: () => {
							dispatch(
								getCompanyDepartmentList({
									request: { company_id: userInfo.company.id },
									callback: {},
								})
							)
							reset()
							NOTIFICATION.success(t('New department added successfully'))
							onCancel && onCancel()
						},
					},
				})
			)
			return
		}
	}

	return (
		<form className='form form-company-contacts'>
			<div className='form__body'>
				<div className='form__sub-content'>
					<RenderFields
						items={departmentFields}
						register={register}
						control={control}
						errors={errors}
						options={{ addressDepartment: addressList }}
					/>
				</div>
			</div>
			<div className='form-footer form-footer_submit'>
				<button
					className='btn-default btn-transparent'
					type={undefined}
					onClick={e => {
						e.preventDefault()
						onCancel && onCancel()
					}}
				>
					{t('Cancel')}
				</button>
				<ButtonDefault
					name={t('Add department')}
					type='submit'
					action={handleSubmit(onSubmit)}
				/>
			</div>
		</form>
	)
}

export default FormCompanyDepartment
