// AUTH
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED'
export const AUTH_REG_SUCCESS = 'AUTH_REG_SUCCESS'
export const AUTH_REG_FAILED = 'AUTH_REG_FAILED'
export const AUTH_RESET = 'AUTH_RESET'

// RESET PASS
export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS'
export const EMAIL_VERIFY_FAILED = 'EMAIL_VERIFY_FAILED'
export const RESET_PASS_INIT = 'RESET_PASS_INIT'
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS'
export const RESET_PASS_FAILED = 'RESET_PASS_FAILED '
export const CLEAR_INFO_RESET_PASS = 'CLEAR_INFO_RESET_PASS'

// user
export const SET_USER_INIT = 'SET_USER_INIT'
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS'
export const SET_USER_FAILED = 'SET_USER_FAILED'
export const DEL_USER = 'DEL_USER'

// VACANCIES
export const INIT_VACANCIES = 'INIT_VACANCIES'
export const SET_VACANCIES_SUCCESS = 'SET_VACANCIES_SUCCESS'
export const SET_VACANCIES_FAILED = 'SET_VACANCIES_FAILED'

// COMPANY
export const SET_COMPANY_START = 'SET_COMPANY_START'
export const SET_COMPANY_SUCCESS = 'SET_COMPANY_SUCCESS'
export const SET_COMPANY_FAILED = 'SET_COMPANY_FAILED'
export const SET_VERIFICATION_IN_PROGRESS = 'SET_VERIFICATION_IN_PROGRESS'
export const SET_VERIFICATION_SUCCESS = 'SET_VERIFICATION_SUCCESS'
export const SET_VERIFICATION_FAILED = 'SET_VERIFICATION_FAILED'
export const SET_VERIFICATION_DONE = 'SET_VERIFICATION_DONE'
export const RESET_VERIFICATION = 'RESET_VERIFICATION'
export const RESET_COMPANY = 'RESET_COMPANY'

// ADDRESSES
export const INIT_ADDRESSES = 'INIT_ADDRESSES'
export const SET_ADDRESSES_SUCCESS = 'SET_ADDRESSES_SUCCESS'
export const SET_ADDRESSES_FAILED = 'SET_ADDRESSES_FAILED'
export const INIT_AUTH_LOGIN = 'INIT_AUTH_LOGIN'

// DEPUTY
export const INIT_DEPUTY = 'INIT_DEPUTY'
export const SET_DEPUTY_SUCCESS = 'SET_DEPUTY_SUCCESS'
export const SET_DEPUTY_FAILED = 'SET_DEPUTY_FAILED'

// DROP_DOWN_LISTS
export const INIT_DROP_DOWN_LISTS = 'INIT_DROP_DOWN_LISTS'
export const INIT_DROP_DOWN_LISTS_FAILED = 'INIT_DROP_DOWN_LISTS_FAILED'
export const RESET_DROP_DOWN_LIST = 'RESET_DROP_DOWN_LIST'

// GLOBAL
export const SHOW_BANNER = 'SHOW_BANNER'
export const HIDE_BANNER = 'HIDE_BANNER'
export const SHOW_TESTING_MODAL = 'SHOW_TESTING_MODAL'

// DEPARTMENTS
export const INIT_DEPARTMENTS_LIST = 'INIT_DEPARTMENTS_LIST'
export const SET_DEPARTMENTS_LIST_SUCCESS = 'SET_DEPARTMENTS_LIST_SUCCESS'
export const SET_DEPARTMENTS_LIST_FAILED = 'SET_DEPARTMENTS_LIST_FAILED'
export const SET_DEPARTMENT_NAME = 'SET_DEPARTMENT_NAME'
export const SET_DEPARTMENT_INFO = 'SET_DEPARTMENT_INFO'

// POSITIONS
export const INIT_POSITIONS_LIST = 'INIT_POSITIONS_LIST'
export const SET_POSITIONS_LIST_SUCCESS = 'SET_POSITIONS_LIST_SUCCESS'
export const SET_POSITIONS_LIST_FAILED = 'SET_POSITIONS_LIST_FAILED'
export const SET_POSITION_NAME = 'SET_POSITION_NAME'
export const SET_POSITION_ID = 'SET_POSITION_ID'
export const RESET_POSITIONS_LIST = 'RESET_POSITIONS_LIST'
export const SET_NEW_POSITION_INFO = 'SET_NEW_POSITION_INFO'

// FUNCTIONALITY
export const INIT_FUNCTIONALITY = 'INIT_FUNCTIONALITY'
export const GET_FUNCTIONALITY_SUCCESS = 'GET_FUNCTIONALITY_SUCCESS'
export const GET_FUNCTIONALITY_FAILED = 'GET_FUNCTIONALITY_FAILED'
export const ADD_FUNCTIONALITY_ITEM = 'ADD_FUNCTIONALITY_ITEM'
export const REMOVE_FUNCTIONALITY_ITEM = 'REMOVE_FUNCTIONALITY_ITEM'
export const CLEAR_FUNCTIONALITY = 'CLEAR_FUNCTIONALITY'

// TARGETS
export const INIT_TARGETS = 'INIT_TARGETS'
export const GET_TARGETS_SUCCESS = 'GET_TARGETS_SUCCESS'
export const GET_TARGETS_FAILED = 'GET_TARGETS_FAILED'
export const ADD_TARGETS_ITEM = 'ADD_TARGETS_ITEM'
export const REMOVE_TARGETS_ITEM = 'REMOVE_TARGETS_ITEM'
export const CLEAR_TARGETS = 'CLEAR_TARGETS'

// KPI
export const INIT_KPI = 'INIT_KPI'
export const GET_KPI_SUCCESS = 'GET_KPI_SUCCESS'
export const GET_KPI_FAILED = 'GET_KPI_FAILED'
export const ADD_KPI_ITEM = 'ADD_KPI_ITEM'
export const REMOVE_KPI_ITEM = 'REMOVE_KPI_ITEM'
export const CLEAR_KPI = 'CLEAR_KPI'

// RESULT
export const INIT_RESULT = 'INIT_RESULT'
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS'
export const GET_RESULT_FAILED = 'GET_RESULT_FAILED'
export const ADD_RESULT_ITEM = 'ADD_RESULT_ITEM'
export const REMOVE_RESULT_ITEM = 'REMOVE_RESULT_ITEM'
export const CLEAR_RESULT = 'CLEAR_RESULT'

// ZUN
export const INIT_ZUN = 'INIT_ZUN'
export const GET_ZUN_SUCCESS = 'GET_ZUN_SUCCESS'
export const GET_ZUN_FAILED = 'GET_ZUN_FAILED'
export const ADD_ZUN_ITEM = 'ADD_ZUN_ITEM'
export const REMOVE_ZUN_ITEM = 'REMOVE_ZUN_ITEM'
export const CLEAR_ZUN = 'CLEAR_ZUN'

// CANDIDATES
export const GET_CANDIDATE = 'GET_CANDIDATE'
export const RESET_CANDIDATE_INFO = 'RESET_CANDIDATE_INFO'
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE'
export const REMOVE_CANDIDATE = 'REMOVE_CANDIDATE'
export const GET_CANDIDATES_LIST = 'RESET_CANDIDATE_INFO'

// TASKS
export const GET_TASKS_LIST = 'GET_TASKS_LIST'
export const RESET_TASKS_LIST = 'RESET_TASKS_LIST'
export const SET_TASK = 'SET_TASK'
export const RESET_TASK = 'RESET_TASK'
