import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
/*
 *
 * User reducer
 *
 */
const initialState = {
	notificationStatus: false,
	loading: false,
	notifications: [],
	updateStatusView: 0,
}

export type notificationsUserInitStateType = typeof initialState

const notificationsUserSlice = createSlice({
	name: 'notificationsUser',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<any>) => {
			state.loading = action.payload
		},
		getNotificationsUserList: () => console.log('getNotificationsUserList'),
		getNotificationsUserNew: () => console.log('getNotificationsUserNew'),
		updateNotifications: (state, action: PayloadAction<any>) => {
			state.notifications = action.payload
		},
		updateStatusView: state => {
			state.updateStatusView += 1
		},
		setNotificationsStatus: (state, action: PayloadAction<any>) => {
			state.notificationStatus = action.payload
		},
	},
})

export default notificationsUserSlice.reducer
export const {
	getNotificationsUserList,
	getNotificationsUserNew,
	setLoading,
	setNotificationsStatus,
	updateStatusView,
	updateNotifications,
}: any = notificationsUserSlice.actions
