import classNames from 'classnames'
import React from 'react'
import type { FC } from 'react'
import AsideFormBlock from '@containers/app/aside/form'

import './style.scss'

interface AsideTemplateType {
	children: React.ReactNode
	center?: boolean
	page?: string
}

const FormTemplate: FC<AsideTemplateType> = ({ children, center, page }: AsideTemplateType) => (
	<div className='form-template'>
		<AsideFormBlock page={page} background />
		<div
			className={classNames('form-template__body', {
				'form-template__body-center': center,
			})}
		>
			{children}
		</div>
	</div>
)

export default FormTemplate
