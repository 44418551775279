import React from 'react'

const V6 = () => (
	<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M2.39994 8.80006H7.39994C7.99994 8.80006 8.49994 8.30006 8.49994 7.70006V2.80006C8.49994 1.60006 9.39993 0.500059 10.5999 0.500059C11.8999 0.400059 12.8999 1.40006 12.8999 2.70006V7.70006C12.8999 8.30006 13.3999 8.80006 13.9999 8.80006H18.8999C20.0999 8.80006 21.1999 9.70006 21.1999 10.9001C21.2999 12.1001 20.2999 13.2001 18.9999 13.2001H13.9999C13.3999 13.2001 12.8999 13.7001 12.8999 14.3001V19.2001C12.8999 20.4001 11.9999 21.4001 10.7999 21.5001C9.59993 21.6001 8.49994 20.6001 8.49994 19.3001V14.3001C8.49994 13.7001 7.99994 13.2001 7.39994 13.2001H2.49993C1.29993 13.2001 0.299935 12.3001 0.199935 11.1001C0.0999354 9.80006 1.19994 8.80006 2.39994 8.80006Z'
			fill='#FAFAFA'
		/>
	</svg>
)

export default V6
