import React from 'react'
import type { FC } from 'react'

const IconPlus: FC = () => (
	<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.84191 3.84269H6.15576V1.15809C6.15576 0.519198 5.63812 0 4.99922 0C4.36033 0 3.84113 0.519198 3.84113 1.15809V3.84269H1.15654C0.517643 3.84269 0 4.36188 0 5.00078C0 5.63967 0.517643 6.15887 1.15654 6.15887H3.84113V8.84191C3.84113 9.4808 4.36033 10 4.99922 10C5.63812 10 6.15576 9.4808 6.15576 8.84191V6.15887H8.84191C9.4808 6.15887 10 5.63967 10 5.00078C10 4.36188 9.4808 3.84269 8.84191 3.84269Z'
			fill='#4E5AF2'
		/>
	</svg>
)

export default IconPlus
