import { Modal } from 'antd'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import FormCode from '@organisms/forms/code/confirmRecoveryCompany'
import { createPhoneMask } from '@tools/helpers'

import './style.scss'

interface DeleteCompanyModalType {
	component: any
	action?: any
	phone: string
}

const DeleteCompanyModal: FC<DeleteCompanyModalType> = ({
	component: Component,
	action,
	phone,
}: DeleteCompanyModalType) => {
	const [visible, setVisible] = useState(false)
	const [loader, setLoader] = useState(false)

	const { t } = useTranslation()

	const onCancel = () => {
		setVisible(false)
		setLoader(false)
	}

	const call = () => {
		setLoader(true)
		action(onCancel)
	}

	return (
		<>
			<Component
				onClick={() => {
					setVisible(true)
				}}
			/>
			<Modal
				className='modal-confirm'
				open={visible}
				width={504}
				footer={null}
				closable={true}
				styles={{ body: { padding: '64px' } }}
				centered
				onCancel={onCancel}
			>
				<div className='modal-confirm__body'>
					<div className='modal-confirm__title'>{t('Deletion Confirmation')}</div>
					<div className='modal-confirm__desc'>
						{parse(
							t('We sent an SMS with a 6-digit code', {
								phone: phone ? createPhoneMask(phone) : '',
							})
						)}
					</div>
					<FormCode action={call} loading={loader} />
					<button className='btn-default btn-gray' onClick={onCancel}>
						{t('Cancel')}
					</button>
				</div>
			</Modal>
		</>
	)
}

export default DeleteCompanyModal
