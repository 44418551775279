/* eslint-disable react/no-unescaped-entities */
import { Button, Col, Form, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DeputyForm from './DeputyForm'
import type { stateType } from '@types'
import type { FC } from 'react'
import FormAddButton from '@atoms/buttons/navAddButton'
import bgAside from '@image/bg-aside.svg'
import AppDescriptions from '@molecules/app/description'
import { setStatus, setSuccess } from '@redux/reducers/appSlice'
import { addCompanyDeputy } from '@redux/reducers/companySlice'
import { checkFormValues } from '@tools/helpers'
import { messages } from '@tools/messages'

//import {addCompanyDeputy} from "@redux/actions/deputy-actions";

// const ErrorContent: FC<string> = ({ email }: any) => (
// 	<span>
// 		Ошибка, пользователь
// 		{<span style={{ fontWeight: 600 }}>"{email}"</span>}, уже существует
// 	</span>
// )

const FormAddDeputy: FC<any> = (props: any) => {
	const { t } = useTranslation()
	const {
		/* isSuccess, changeSuccess, */ changeVisible,
		otherDeputy,
		onRemoveDeputy,
		onOpenDeputy,
	} = props
	const [form] = Form.useForm()

	const { token } = useSelector((state: stateType) => state.auth)
	const { loading } = useSelector((state: stateType) => state.company)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const onFinish = () => {
		form.submit()
		setTimeout(async () => {
			const values = form.getFieldsValue()
			const email = otherDeputy.find((item: any) => item.email === values.email)?.email
			if (email) {
				form.setFields([
					{
						name: 'email',
						errors: [t('A deputy with this address has already been added!')],
					},
				])
				return
			}

			const errorsLength = form.getFieldsError().filter(({ errors }) => errors.length).length
			if (errorsLength) {
				return
			}

			const checkValues = checkFormValues(values)
			const deputy = [...otherDeputy, checkValues]

			dispatch(
				addCompanyDeputy({
					token: token,
					data: deputy,
					callback: () => {
						setSuccess({
							title: messages.deputyRegistration,
							message: messages.goToHome,
							img: bgAside,
							successFn: () => {
								dispatch(setStatus('main'))
								navigate('/')
							},
						})
					},
				})
			)
			/*
      try {
        const json = await addCompanyDeputy(deputy);
        if (json.ok) {
          setIsLoading(false);
          changeSuccess(!isSuccess);
        } else if (json.error) {
          const emails = Object.keys(json?.data);
          emails.forEach((email) => {
            message.error({ content: React.createElement(ErrorContent, { email }), duration: 5 });
          });
          setIsLoading(false);
        } else {
          throw new Error('t('An error of creating a deputy')');
        }
      } catch (err) {
        setIsLoading(false);
        message.error(err.message);
      }
      */
		}, 0)
	}
	return (
		<>
			<DeputyForm form={form} isSubmit={false} />
			<Row wrap justify='end' gutter={[24, 24]}>
				<Col span={24}>
					<FormAddButton
						onClick={() => changeVisible()}
						extraClasses='_card'
						text={t('Add another deputy')}
					/>
				</Col>
				{!!otherDeputy.length && (
					<Col span={24}>
						<Row wrap gutter={[24, 24]}>
							{otherDeputy.map(
								({ id, last_name, first_name, middle_name, tel, email }: any) => (
									<Col key={id} span={24}>
										<AppDescriptions
											title={{ last_name, first_name, middle_name }}
											descriptions={{ email, tel }}
											onRemove={() => onRemoveDeputy(id)}
											onOpen={() => onOpenDeputy(id)}
										/>
									</Col>
								)
							)}
						</Row>
					</Col>
				)}
				<Col>
					<Button
						type='primary'
						shape='round'
						loading={loading}
						onClick={() => onFinish()}
					>
						{t('Save and continue')}
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default FormAddDeputy
