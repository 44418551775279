import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
/*
 *
 * Departments reducer
 *
 */
const initialState = {
	loading: false,
	name: null,
	error: null,
	list: [] as any,
	info: null as any,
}

export type departmentsInitStateType = typeof initialState

const departmentsSlice = createSlice({
	name: 'departments',
	initialState,
	reducers: {
		createDepartment: () => console.log('createDepartment'),
		getDepartments: () => console.log('getDepartments'),
		getDepartmentInfo: () => console.log('getDepartmentInfo'),
		setDepList: (state, action: PayloadAction<any>) => {
			state.list = action.payload
		},
		setDePInfo: (state, action: PayloadAction<any>) => {
			state.info = action.payload
		},
		setDepLoading: (state, action: PayloadAction<any>) => {
			state.info = action.payload
		},
		setDepName: (state, action: PayloadAction<any>) => {
			state.name = action.payload
		},
	},
})

export default departmentsSlice.reducer
export const {
	createDepartment,
	getDepartments,
	getDepartmentInfo,
	setDepList,
	setDePInfo,
	setDepLoading,
	setDepName,
}: any = departmentsSlice.actions
